<template>
  <v-row>
    <ExpandCollapseToggle
      id="ratingsPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel style="margin-top: 20px" value="inspectionStatus">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panel.includes('inspectionStatus')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Inspection Status</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <LabelSelect
            :labelXl="2"
            :labelLg="3"
            :labelmd="4"
            :labelsm="5"
            :selectXl="3"
            :selectLg="4"
            :selectMd="5"
            :selectSm="6"
            id="inspectionStatus"
            label="1A09 Inspection Status"
            :refTable="REFERENCE_TABLE.INSPECTION_STATUS"
            :isEditable="false"
            v-model="structure.InspEvnt.INSPSTAT"
          ></LabelSelect>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="keyFields">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="panel.includes('keyFields')" icon="fa-solid fa-minus" />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Key Field Comparison since Last Accepted Inspection</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <FieldComparison> </FieldComparison>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="conditionRatings">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panel.includes('conditionRatings')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Condition Ratings</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="validConditionRatings" ref="conditionRatingsForm">
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.DK_DURATION,
                      structure?.T_Insp_Condition_Hist?.DK_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      deckConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="deck"
                  label="1A01 Deck"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.DKRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.SUB_DURATION,
                      structure?.T_Insp_Condition_Hist?.SUB_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      substructureConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="substructure"
                  label="1A02 Substructure"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.SUBRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.CULV_DURATION,
                      structure?.T_Insp_Condition_Hist?.CULV_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      culvertConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="culvert"
                  label="1A03 Culvert"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.CULVRATING"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.SUP_DURATION,
                      structure?.T_Insp_Condition_Hist?.SUP_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      superstructureConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="superStructure"
                  label="1A04 Superstructure"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.SUPRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.CHANRATE_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.CHANRATE_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      channelConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="channel"
                  label="1A05 Channel"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.CHANRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.WADEQ_DURATION,
                      structure?.T_Insp_Condition_Hist?.WADEQ_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      waterwayConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="waterway"
                  label="1A06 Waterway"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.WATERADEQ"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="nstmInspectionCondition"
                  label="1A15 NSTM Inspection Condition"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="nstmInspectionCondition"
                  @update:modelValue="
                    inspectionStore.setNstmInspectionConditionChangedByUser(
                      true
                    )
                  "
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="lowestConditionRating"
                  label="1A16 Lowest Condition Rating"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  v-model="lowestConditionRating"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <LabelText
                  labelXl="12"
                  labelLg="12"
                  labelMd="12"
                  labelSm="12"
                  textXl="12"
                  textLg="12"
                  textMd="12"
                  textSm="12"
                  id="text_Inspection_Notes"
                  label="2A02 Inspection Notes"
                  :isEditable="isEditable"
                  v-model="structure.InspEvnt.NOTES"
                  :maxlength="notesMaxLength"
                  type="textarea"
                  counter
                  persistentCounter
                  class="pb-0"
                >
                </LabelText>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="appraisalRatings">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panel.includes('appraisalRatings')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Appraisal Ratings</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="validAppraisalRatings" ref="appraisalRatingsForm">
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.RAILRATING_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.RAILRATING_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      approachRailingUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="railing"
                  label="IA02 Railing"
                  :refTable="REFERENCE_TABLE.RAILING_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.RAILRATING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.TRANSRATIN_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.TRANSRATIN_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      approachTransitionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="transition"
                  label="IA02 Transition"
                  :refTable="REFERENCE_TABLE.TRANSITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.TRANSRATIN"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.ARAILRATIN_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.ARAILRATIN_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      approachGuideRailUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="approachGuideRail"
                  label="IA02 Approach Guide Rail"
                  :refTable="REFERENCE_TABLE.APPROACH_GUIDE_RAIL_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.ARAILRATIN"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.AEBDRATING_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.AEBDRATING_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      approachRailEndUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="approachRailEnds"
                  label="IA02 Approach Rail End"
                  :refTable="REFERENCE_TABLE.APPROACH_RAIL_END_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.AENDRATING"
                ></LabelSelect>
                <LabelCheckbox
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  id="newCoating"
                  class="labelCheckbox"
                  label="6B35 New Protective Coating?"
                  :showColon="false"
                  editIndent
                  :isEditable="isEditable"
                  v-model="structure.UserInsp.NEW_PAINT"
                >
                </LabelCheckbox>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.PANIT_DURATION,
                      structure?.T_Insp_Condition_Hist?.PANIT_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      protectiveCoatingUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="protectiveCoatings"
                  label="6B36 Protective Coating"
                  :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.UserInsp.PAINT_COND_RATE"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.PAINT_EXT_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.PAINT_EXT_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      protectiveCoatingExtentUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="protectiveCoatingExtents"
                  label="6B37 Protective Coating (Extent)"
                  :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.UserInsp.PAINT_EXTENT_RATE"
                ></LabelSelect>
                <LabelSelect
                  :class="isEditable ? 'py-1' : ''"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="posting"
                  :isEditable="false"
                  label="4B03 Posting"
                  :refTable="REFERENCE_TABLE.POSTING"
                  v-model="structure.Bridge.POSTING"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="select_Seismic_Vulnerability"
                  :isEditable="isEditable"
                  isRequired
                  label="4A26 Seismic Vulnerability"
                  :refTable="REFERENCE_TABLE.SEISMIC_VULNERABILITY"
                  v-model="seismicVulnerability"
                  @update:modelValue="
                    inspectionStore.setSeismicVulnerabilityChangedByUser(true)
                  "
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.APPRALIGN_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.APPRALIGN_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      approachAlignmentUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="approachAlignments"
                  label="4A02 Approach Alignment"
                  :refTable="
                    REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL
                  "
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.InspEvnt.APPRALIGN"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.APPR_SLAB_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.APPR_SLAB_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      approachSlabUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="approachSlabs"
                  label="6B38 Approach Slab"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.UserInsp.APPR_SLAB_COND_RATE"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.APPR_ROADWAY_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.APPR_ROADWAY_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      approachRoadwayUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="approachRoadways"
                  label="6B39 Approach Roadway"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.UserInsp.APPR_ROAD_COND_RATE"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.DECKGEOM_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.DECKGEOM_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      deckGeometryUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="deckGeometry"
                  label="4A10 Deck Geometry"
                  :refTable="
                    REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL
                  "
                  :isEditable="isEditable"
                  v-model="structure.InspEvnt.DECKGEOM"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.UNDERCLR_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.UNDERCLR_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      underclearanceUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="underClearance"
                  label="4A11 Underclearance"
                  :refTable="
                    REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL
                  "
                  :isEditable="isEditable"
                  v-model="structure.InspEvnt.UNDERCLR"
                ></LabelSelect>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.DECK_WS_SURF_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.DECK_WS_SURF_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      deckWearingSurfaceUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  id="deckWearingSurfaces"
                  label="6B40 Deck Wearing Surface"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  isRequired
                  v-model="structure.UserInsp.DECK_WS_COND_RATE"
                ></LabelSelect>
                <LabelSelect
                  :class="isEditable ? 'py-1' : ''"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="scbi"
                  :isEditable="false"
                  label="4A08 SCBI"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.SCOUTCRIT_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.SCOUTCRIT_DURATION_CHANGES
                    )
                  "
                  :refTable="REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR"
                  v-model="structure.InspEvnt.SCOURCRIT"
                ></LabelSelect>
                <LabelText
                  :class="isEditable ? 'py-1' : ''"
                  :labelXl="5"
                  :labelLg="5"
                  :labelMd="6"
                  :labelSm="6"
                  :textXl="6"
                  :textLg="5"
                  :textMd="4"
                  :textSm="3"
                  id="scbiCat"
                  :isEditable="false"
                  label="4A08b SCBI Category"
                  v-model="structure.InspEvnt.SCOUR_CRIT_CTGRY"
                ></LabelText>
                <LabelSelect
                  :class="isEditable ? 'py-1' : ''"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="bridgeCondition"
                  :isEditable="false"
                  label="4A14 Bridge Condition"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.BRGCOND_DURATION
                    )
                  "
                  :refTable="REFERENCE_TABLE.BRIDGE_CONDITION_RATING"
                  v-model="bridgeCondition"
                ></LabelSelect>
                <LabelSelect
                  :class="isEditable ? 'py-1' : ''"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="deficiencyScore"
                  :isEditable="false"
                  label="6B46 Deficiency Score"
                  v-model="structure.UserInsp.MAINT_DEF_RATE"
                ></LabelSelect>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { getHistDuration } from "@/composables/util";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import LabelText from "@/components/common/LabelText.vue";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import FieldComparison from "@/components/shared/FieldComparison.vue";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionRatings",
  setup() {
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });
    let structure = computed(() => inspectionStore.selectedInspection);
    const allPanels = [
      "inspectionStatus",
      "keyFields",
      "conditionRatings",
      "appraisalRatings",
    ];
    const panel = ref(allPanels);
    let validConditionRatings = ref(true);
    let validAppraisalRatings = ref(true);
    let valid = ref(true);
    let conditionRatingsForm = ref(null);
    let appraisalRatingsForm = ref(null);

    async function validate() {
      await conditionRatingsForm.value.validate();
      await appraisalRatingsForm.value.validate();
      if (validConditionRatings.value && validAppraisalRatings.value) {
        valid.value = true;
      } else {
        valid.value = false;
      }
    }

    if (!isEditable.value) {
      panel.value = allPanels;
    }
    onMounted(async () => {
      await validate();
      inspectionStore.setDirtyFlag(false);
    });
    const conditionRatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.CONDITION_RATING)
    );
    const railingRatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.RAILING_RATING)
    );
    const transitionRatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.TRANSITION_RATING)
    );
    const approachGuideRailRatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.APPROACH_GUIDE_RAIL_RATING)
    );
    const approachRailEndRatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.APPROACH_RAIL_END_RATING)
    );
    const protectiveCoatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.PAINT_CONDITION_RATING)
    );
    const protectiveCoatingExtents = computed(() =>
      configStore.getReferenceValues(
        REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT
      )
    );
    const approachAlignments = computed(() =>
      configStore.getReferenceValues(
        REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL
      )
    );

    function getNSTMInspectionCondition() {
      if (
        structure.value.UserBrdg.MAIN_FC_GROUP_NUM < 5 ||
        structure.value.UserBrdg.APPR_FC_GROUP_NUM < 5
      ) {
        return structure.value.InspEvnt.SUPRATING;
      } else {
        return "N";
      }
    }

    function setLowestConditionRating() {
      if (parseInt(structure.value.UserInsp.LOWEST_CONDITION) >= 7) {
        structure.value.InspEvnt.CONDITION = "G";
      } else if (
        structure.value.UserInsp.LOWEST_CONDITION == "5" ||
        structure.value.UserInsp.LOWEST_CONDITION == "6"
      ) {
        structure.value.InspEvnt.CONDITION = "F";
      } else if (parseInt(structure.value.UserInsp.LOWEST_CONDITION) <= 4) {
        structure.value.InspEvnt.CONDITION = "P";
      } else if (structure.value.UserInsp.LOWEST_CONDITION == "N") {
        structure.value.InspEvnt.CONDITION = "N";
      }
    }

    const lowestConditionRating = computed({
      get() {
        return structure.value.UserInsp.LOWEST_CONDITION;
      },
    });

    const bridgeCondition = computed({
      get() {
        setLowestConditionRating();
        return structure.value.InspEvnt.CONDITION;
      },
    });

    const nstmInspectionConditionChangedByUser = computed(
      () => inspectionStore.getNstmInspectionConditionChangedByUser
    );

    const nstmInspectionCondition = computed({
      get() {
        if (
          !structure.value.UserInsp.NSTM_CONDITION &&
          !nstmInspectionConditionChangedByUser.value
        ) {
          return getNSTMInspectionCondition();
        } else {
          return structure.value.UserInsp.NSTM_CONDITION;
        }
      },
      set(inspectionConditionParam) {
        structure.value.UserInsp.NSTM_CONDITION = inspectionConditionParam;
      },
    });
    let seismicVulnerabilityChangedByUser = ref(false);

    const seismicVulnerability = computed({
      get() {
        if (
          !structure.value.UserBrdg.SEISMIC_VULN &&
          !seismicVulnerabilityChangedByUser.value
        ) {
          return "0";
        } else {
          return structure.value.UserBrdg.SEISMIC_VULN;
        }
      },
      set(seismicVulnerabilityParam) {
        structure.value.UserBrdg.SEISMIC_VULN = seismicVulnerabilityParam;
      },
    });

    const saveConditionFields = async () => {
      structure.value.UserInsp.NSTM_CONDITION = nstmInspectionCondition.value;
    };

    const resetChangedByUser = async () => {
      inspectionStore.setNstmInspectionConditionChangedByUser(false);
      inspectionStore.setSeismicVulnerabilityChangedByUser(false);
    };

    const notesMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.STRUCTURE_NOTES_MAX_LENGTH
      );
    });

    let deckConditionUpdated = ref(false);
    let superstructureConditionUpdated = ref(false);
    let substructureConditionUpdated = ref(false);
    let channelConditionUpdated = ref(false);
    let culvertConditionUpdated = ref(false);
    let waterwayConditionUpdated = ref(false);
    let conditionUpdated = ref(false);

    const updateConditionRatings = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {};
      }
      if (deckConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.DK_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.DK_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.DK_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.DK_DURATION,
            structure.value.T_Insp_Condition_Hist.DK_DURATION_CHANGES
          );
      }
      if (superstructureConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.SUP_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.SUP_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.SUP_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.SUP_DURATION,
            structure.value.T_Insp_Condition_Hist.SUP_DURATION_CHANGES
          );
      }
      if (substructureConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.SUB_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.SUB_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.SUB_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.SUB_DURATION,
            structure.value.T_Insp_Condition_Hist.SUB_DURATION_CHANGES
          );
      }
      if (channelConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION,
            structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION_CHANGES
          );
      }
      if (culvertConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.CULV_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.CULV_DURATION,
            structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES
          );
      }
      if (waterwayConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.WADEQ_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.WADEQ_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.WADEQ_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.WADEQ_DURATION,
            structure.value.T_Insp_Condition_Hist.WADEQ_DURATION_CHANGES
          );
      }
      deckConditionUpdated.value = false;
      superstructureConditionUpdated.value = false;
      substructureConditionUpdated.value = false;
      channelConditionUpdated.value = false;
      culvertConditionUpdated.value = false;
      waterwayConditionUpdated.value = false;
    };

    let approachAlignmentUpdated = ref(false);
    let approachRoadwayUpdated = ref(false);
    let approachSlabUpdated = ref(false);
    let approachRailingUpdated = ref(false);
    let approachTransitionUpdated = ref(false);
    let approachGuideRailUpdated = ref(false);
    let approachRailEndUpdated = ref(false);

    let deckGeometryUpdated = ref(false);

    let underclearanceUpdated = ref(false);

    let protectiveCoatingUpdated = ref(false);

    let deckWearingSurfaceUpdated = ref(false);

    let protectiveCoatingExtentUpdated = ref(false);

    const updateAppraisalRatings = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {};
      }
      if (approachAlignmentUpdated.value) {
        structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION,
            structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION_CHANGES
          );
      }
      if (approachRoadwayUpdated.value) {
        structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION,
            structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION_CHANGES
          );
      }
      if (approachSlabUpdated.value) {
        structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION,
            structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION_CHANGES
          );
      }
      if (approachRailingUpdated.value) {
        structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION,
            structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION_CHANGES
          );
      }
      if (approachTransitionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION,
            structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION_CHANGES
          );
      }
      if (approachGuideRailUpdated.value) {
        structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION,
            structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION_CHANGES
          );
      }
      if (approachRailEndUpdated.value) {
        structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION,
            structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION_CHANGES
          );
      }

      if (deckGeometryUpdated.value) {
        structure.value.T_Insp_Condition_Hist.DECKGEOM_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.DECKGEOM_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.DECKGEOM_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.DECKGEOM_DURATION,
            structure.value.T_Insp_Condition_Hist.DECKGEOM_DURATION_CHANGES
          );
      }

      if (underclearanceUpdated.value) {
        structure.value.T_Insp_Condition_Hist.UNDERCLR_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.UNDERCLR_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.UNDERCLR_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.UNDERCLR_DURATION,
            structure.value.T_Insp_Condition_Hist.UNDERCLR_DURATION_CHANGES
          );
      }

      if (protectiveCoatingUpdated.value) {
        structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.PANIT_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.PANIT_DURATION,
            structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES
          );
      }

      if (deckWearingSurfaceUpdated.value) {
        structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION,
            structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION_CHANGES
          );
      }

      if (protectiveCoatingExtentUpdated.value) {
        structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION,
            structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES
          );
      }

      deckGeometryUpdated.value = false;

      underclearanceUpdated.value = false;

      protectiveCoatingUpdated.value = false;

      deckWearingSurfaceUpdated.value = false;

      protectiveCoatingExtentUpdated.value = false;

      approachRailEndUpdated.value = false;
      approachGuideRailUpdated.value = false;
      approachTransitionUpdated.value = false;
      approachAlignmentUpdated.value = false;
      approachRailingUpdated.value = false;
      approachRoadwayUpdated.value = false;
      approachSlabUpdated.value = false;
    };
    const updateDuration = () => {
      updateConditionRatings();
      updateAppraisalRatings();
      conditionUpdated.value = false;
    };
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.InspEvnt,
        structure.value.UserInsp,
        structure.value.T_Underwater_Insp,
        structure.value.Bridge,
        structure.value.UserBrdg,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      inspectionStore,
      underclearanceUpdated,
      protectiveCoatingExtentUpdated,
      deckWearingSurfaceUpdated,
      protectiveCoatingUpdated,
      deckGeometryUpdated,
      approachSlabUpdated,
      approachRailEndUpdated,
      approachGuideRailUpdated,
      approachTransitionUpdated,
      approachRailingUpdated,
      approachRoadwayUpdated,
      updateDuration,
      approachAlignmentUpdated,
      updateConditionRatings,
      waterwayConditionUpdated,
      culvertConditionUpdated,
      channelConditionUpdated,
      substructureConditionUpdated,
      superstructureConditionUpdated,
      deckConditionUpdated,
      conditionUpdated,
      resetChangedByUser,
      nstmInspectionConditionChangedByUser,
      seismicVulnerabilityChangedByUser,
      bridgeCondition,
      seismicVulnerability,
      saveConditionFields,
      nstmInspectionCondition,
      lowestConditionRating,
      structure,
      REFERENCE_TABLE,
      conditionRatings,
      railingRatings,
      transitionRatings,
      approachGuideRailRatings,
      approachRailEndRatings,
      protectiveCoatings,
      protectiveCoatingExtents,
      approachAlignments,
      getHistDuration,
      panel,
      isEditable,
      notesMaxLength,
      conditionRatingsForm,
      appraisalRatingsForm,
      validate,
      valid,
      validConditionRatings,
      validAppraisalRatings,
      toggleAllPanels,
    };
  },
  components: {
    LabelSelect,
    LabelCheckbox,
    LabelText,
    FieldComparison,
    ExpandCollapseToggle,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  order: 1;
  color: c.$p-white !important;
}
</style>
