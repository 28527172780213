import { ref, computed } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { SAFETY_FEATURE_TYPES } from "@/constants/InspectionConstants";

export default () => {
  const inspectionStore = useInspectionStore();

  let structure = computed(() => inspectionStore.selectedInspection);
  let safetyFeatureObj = computed({
    get() {
      return structure.value?.T_Safety_Feature;
    },
    set(value) {
      structure.value.T_Safety_Feature = value;
    },
  });
  let pavementReliefJointsPresent = ref("No");

  structure.value?.UserBrdg?.RELIEF_JOINT != "0" &&
    (pavementReliefJointsPresent.value = "Yes");

  function getDefaultDescriptionFromSafetyFeature(sfArray, sfType) {
    return sfArray?.length > 0
      ? sfArray.find((featureType) => featureType.SAFETY_FEATURE_TYPE == sfType)
          ?.SF_DESC
      : null;
  }

  function getSafetyFeature(safetyFeatureType, safetyFeatureDescription) {
    return {
      BRKEY: structure.value.Bridge.BRKEY,
      CONTROL_FEATURE: null,
      INSPKEY: structure.value.InspEvnt.INSPKEY,
      LOCATION: null,
      MODTIME: null,
      SAFETY_FEATURE_TYPE: safetyFeatureType,
      SF_DESC: safetyFeatureDescription,
      USERKEY: structure.value.UserInsp.USERKEY,
    };
  }

  function setSafetyFeature(safetyFeatureType, safetyFeatureDescription) {
    const safetyObj = safetyFeatureObj.value?.find(
      (sf) => sf.SAFETY_FEATURE_TYPE == safetyFeatureType
    );
    return (
      safetyObj || getSafetyFeature(safetyFeatureType, safetyFeatureDescription)
    );
  }

  const bridgeRailingDesc = computed({
    get() {
      return getDefaultDescriptionFromSafetyFeature(
        structure.value?.T_Safety_Feature,
        SAFETY_FEATURE_TYPES.BRIDGE_RAILING
      );
    },
    set(param) {
      let bridgeFeatureType = safetyFeatureObj.value?.find(
        (sf) => sf.SAFETY_FEATURE_TYPE == SAFETY_FEATURE_TYPES.BRIDGE_RAILING
      );
      if (!bridgeFeatureType) {
        safetyFeatureObj.value = [
          getSafetyFeature(SAFETY_FEATURE_TYPES.BRIDGE_RAILING, param),
          setSafetyFeature(SAFETY_FEATURE_TYPES.TRANSITION, null),
        ];
      } else {
        bridgeFeatureType.SF_DESC = param;
      }
    },
  });

  const transitionDesc = computed({
    get() {
      return getDefaultDescriptionFromSafetyFeature(
        structure.value?.T_Safety_Feature,
        SAFETY_FEATURE_TYPES.TRANSITION
      );
    },
    set(param) {
      let transitionFeatureType = safetyFeatureObj.value?.find(
        (sf) => sf.SAFETY_FEATURE_TYPE == SAFETY_FEATURE_TYPES.TRANSITION
      );
      if (!transitionFeatureType) {
        safetyFeatureObj.value = [
          setSafetyFeature(SAFETY_FEATURE_TYPES.BRIDGE_RAILING, null),
          getSafetyFeature(SAFETY_FEATURE_TYPES.TRANSITION, param),
        ];
      } else {
        transitionFeatureType.SF_DESC = param;
      }
    },
  });

  return {
    pavementReliefJointsPresent,
    bridgeRailingDesc,
    transitionDesc,
  };
};
