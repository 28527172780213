export const styles = {
  header: {
    fontSize: 12,
    bold: true,
    margin: [0, 0, 0, 10],
  },
  sectionHeader: {
    bold: true,
    fontSize: 13,
    color: "#000000",
  },
  tableHeader: {
    bold: true,
    fillColor: "#d4d4d4",
    color: "#000000",
    alignment: "center",
  },
  tableBody: {
    alignment: "center",
  },
  waterwayLeftAlignBody: {
    alignment: "left",
  },
  fontawesome: {
    font: "FontAwesomeSolid",
    color: "#464646",
  },
  fontAwesomeTableBody: {
    alignment: "center",
    font: "FontAwesomeSolid",
    color: "#464646",
  },
  customFontAwesome: {
    font: "CustomFontAwesome",
    color: "#464646",
  },
  customFontAwesomeTableBody: {
    alignment: "center",
    font: "CustomFontAwesome",
    color: "#464646",
  },
  subSectionHeader: {
    bold: true,
    fontSize: 10,
    color: "#000000",
  },
  bprTitleHeader: {
    bold: true,
    fontSize: 13,
  },
};
