import { defineStore } from "pinia";
import { ref } from "vue";

export const useUserStore = defineStore("user", () => {
  let loggedInUser = ref(null);
  let isAuthenticated = ref(false);
  let userDistrict = ref(null);

  const setUser = (user) => {
    loggedInUser.value = user;
  };
  const setIsAuthenticated = (value) => {
    isAuthenticated.value = value;
  };
  const getUserDistrict = () => {
    return loggedInUser.value?.WorkUnitCode?.substring(0, 2);
  };

  const getUserRoles = () => {
    return loggedInUser.value?.groups?.split("^") || [];
  };

  const getUserSub = () => {
    return loggedInUser?.value?.sub;
  };

  const getHAUserName = () => {
    return loggedInUser.value?.HAUserID;
  };

  return {
    userDistrict,
    loggedInUser,
    isAuthenticated,
    setUser,
    setIsAuthenticated,
    getUserDistrict,
    getUserRoles,
    getUserSub,
    getHAUserName,
  };
});
