export const ACCESS_LEVELS = {
  VIEW: "view",
  EDIT: "edit",
  EXPORT: "export",
  ADD: "add",
  DRAFT: "draft",
  DELETE: "delete",
  APPROVE: "approve",
  SUBMIT: "submit",
  CUSTOM: {
    BPR_CRITICAL_FINDING: "bprCriticalFinding",
    BPR_EDIT_IN_NON_DRAFT_STATE: "bprEditInNonDraftState",
    VIEW_APPROVED_INSPECTION: "viewApprovedInspection",
    EDIT_APPROVED_INSPECTION: "editApprovedInspection",
  },
  EDIT_A: "editA",
  EDIT_I: "editI",
  EDIT_O: "editO",
  EDIT_P: "editP",
};
