import { defineStore } from "pinia";
import { STRUCTURE_ENDPOINT } from "@/constants/Endpoints";
import { LOGGER } from "@/util/logger";
import axios from "axios";
import { ref } from "vue";
import router from "@/router";
import { useUserPermissionStore } from "@/stores/userPermission";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";
import { STRUCTURE_SEARCH_MESSAGES } from "@/constants/StructureSearches";

export const useQuickSearchStore = defineStore("quickSearch", () => {
  let structure = ref({
    BRKEY: "",
    BMSID: "",
    REPORTGROUP: "",
    SERVTYPON: "",
    INSPECTIONID: "",
  });
  let hasQuickSearchSucceeded = ref(false);
  let errorMessage = ref(false);
  let snackbarId = ref(null);
  let userPermissionStore = useUserPermissionStore();
  const snackbarStore = useSnackbarStore();
  const getStructure = () => {
    return structure.value;
  };
  const setStructure = (value) => {
    structure.value = value;
  };
  const getErrorMessage = () => {
    return errorMessage.value;
  };
  const setErrorMessage = (value) => {
    errorMessage.value = value;
  };

  const resetStructure = () => {
    structure.value = {
      BRKEY: "",
      BMSID: "",
      REPORTGROUP: "",
      SERVTYPON: "",
      INSPECTIONID: "",
    };
    snackbarId.value = null;
  };

  const redirectUserToStructureSearch = (e, id) => {
    let path = router.currentRoute.value.path;
    if (path === "/" || path === "/landingPage") {
      path = "/inventoryLinks/structureIdentification";
    }
    router.push({
      name: "StructureSearch",
      query: { bmsId: id, path },
    });
  };

  const getStructureMetaDataByBMSID = async () => {
    if (structure.value.BMSID) {
      let url = STRUCTURE_ENDPOINT.GET_STRUCTURE_META_DATA_BY_BMSID.replace(
        "{bmsId}",
        structure.value.BMSID
      );
      return await axios
        .get(url)
        .then(async (response) => {
          structure.value.REPORTGROUP = response.data.data.reportGroup;
          structure.value.BRKEY = response.data.data.brkey;
          structure.value.SERVTYPON = response.data.data.servTypOn;
          hasQuickSearchSucceeded.value = true;
          await userPermissionStore.getUserPermissionsForBrkey(
            structure.value.BRKEY
          );
        })
        .catch((e) => {
          LOGGER.logException(e);
          structure.value.REPORTGROUP = "";
          structure.value.BRKEY = "";
          structure.value.SERVTYPON = "";
          structure.value.INSPECTIONID = "";
          hasQuickSearchSucceeded.value = false;
          if (e?.response?.status === 412) {
            redirectUserToStructureSearch(e, structure.value.BMSID);
            throw new Error(
              `Multiple BRKEYs found for BMSID-${structure.value.BMSID}`
            );
          } else {
            errorMessage.value = true;
            _showErrorMessage();
          }
        });
    } else {
      resetStructure();
    }
  };

  const getStructureMetaDataByBRKEY = async () => {
    if (structure.value.BRKEY) {
      let url = STRUCTURE_ENDPOINT.GET_STRUCTURE_META_DATA_BY_BRKEY.replace(
        "{brkey}",
        structure.value.BRKEY
      );
      return await axios
        .get(url)
        .then(async (response) => {
          structure.value.REPORTGROUP = response.data.data.reportGroup;
          structure.value.BMSID = response.data.data.bmsId;
          structure.value.SERVTYPON = response.data.data.servTypOn;
          hasQuickSearchSucceeded.value = true;
          await userPermissionStore.getUserPermissionsForBrkey(
            structure.value.BRKEY
          );
        })
        .catch((e) => {
          LOGGER.logException(e);
          structure.value.REPORTGROUP = "";
          structure.value.BMSID = "";
          structure.value.SERVTYPON = "";
          structure.value.INSPECTIONID = "";
          hasQuickSearchSucceeded.value = false;
          errorMessage.value = true;
          _showErrorMessage();
        });
    } else {
      resetStructure();
    }
  };

  const _showErrorMessage = () => {
    if (snackbarId.value === null) {
      snackbarId.value = snackbarStore.showMessage({
        displayText: STRUCTURE_SEARCH_MESSAGES.SEARCH_NO_RESULTS,
        messageType: SNACKBAR_MESSAGE_TYPES.ERROR_CUSTOM,
        timeout: 10000,
        callback: () => {
          hasQuickSearchSucceeded.value = false;
          snackbarId.value = null;
          errorMessage.value = false;
        },
      });
    }
  };

  return {
    hasQuickSearchSucceeded,
    resetStructure,
    getErrorMessage,
    setErrorMessage,
    getStructureMetaDataByBRKEY,
    getStructureMetaDataByBMSID,
    getStructure,
    setStructure,
  };
});
