import pouchdb from "pouchdb";
import { defineStore } from "pinia";
import { LOGGER } from "@/util/logger";
import pouchdbFind from "pouchdb-find";
import { useUserStore } from "@/stores/userStore";
import { ref, computed } from "vue";

export const useEDocsIDBStore = defineStore("eDocsIDB", () => {
  const userStore = useUserStore();
  pouchdb.plugin(pouchdbFind);

  const dbName = computed(() => {
    const userName = userStore.loggedInUser?.sub;
    return userName ? `bms-${userName.toLowerCase()}-eDocs` : "";
  });

  let eDocsdb = ref(null);
  const initDB = async () => {
    eDocsdb.value = pouchdb(dbName.value, {
      revs_limit: 0,
      auto_compaction: true,
    });
  };

  const saveAttachments = async (payload) => {
    const key = payload.Bridge.BRKEY + "";
    try {
      let doc = await eDocsdb.value.get(key);
      const { attachments } = appendAttachments(doc, payload);
      //Concat EDocs (download status of each document)
      if (payload.EDocs) {
        const map = new Map();
        payload.EDocs.forEach((item) => {
          map.set(item.docContainerSeqNum, item);
        });

        doc.EDocs?.forEach((existingEDoc) => {
          if (map.has(existingEDoc.docContainerSeqNum)) {
            const newEdoc = map.get(existingEDoc.docContainerSeqNum);
            Object.assign(existingEDoc, newEdoc);
          } else {
            map.set(existingEDoc.docContainerSeqNum, existingEDoc);
          }
        });
        const edocs = Array.from(map.values());

        await eDocsdb.value.put({
          _id: key,
          _attachments: attachments,
          EDocs: edocs,
          _rev: doc._rev,
        });
      }
    } catch (e) {
      let error = Object.assign(e, {
        method: "saveEDocs",
        payload,
      });
      LOGGER.logException(error);
      const { attachments } = appendAttachments({}, payload);
      await eDocsdb.value.put({
        _id: key,
        _attachments: attachments,
        EDocs: payload.EDocs,
      });
    }
  };

  const appendAttachments = (savedStructure, payload) => {
    const savedAttachmentsSize = savedStructure._attachments
      ? Object.keys(savedStructure._attachments)?.length
      : 0;
    const newAttachmentsSize = payload._attachments
      ? Object.keys(payload._attachments)?.length
      : 0;

    if (savedAttachmentsSize > 0 && newAttachmentsSize > 0) {
      return {
        attachments: {
          ...savedStructure._attachments,
          ...payload._attachments,
        },
      };
    } else if (savedAttachmentsSize > 0) {
      return {
        attachments: savedStructure._attachments,
      };
    } else if (newAttachmentsSize > 0) {
      return {
        attachments: payload._attachments,
      };
    } else {
      return {
        attachments: null,
      };
    }
  };

  const getAttachmentsFromIDB = async (brkey) => {
    try {
      let attachments = await eDocsdb.value.get(brkey + "", {
        attachments: true,
      });
      return attachments;
    } catch (e) {
      let error = Object.assign(e, {
        method: "getAttachmentsFromIDB",
      });
      LOGGER.logException(error);
    }
  };

  const deleteEDocsFromIDB = async (brKey) => {
    eDocsdb.value
      .get(brKey + "")
      .then(function (doc) {
        return eDocsdb.value.remove(doc);
      })
      .catch((e) => {
        let error = Object.assign(e, {
          method: "deleteEDocsFromIDB",
          brKey,
        });
        LOGGER.logException(error);
      });
  };

  return {
    saveAttachments,
    appendAttachments,
    getAttachmentsFromIDB,
    initDB,
    deleteEDocsFromIDB,
  };
});
