import { defineStore } from "pinia";
import { ref } from "vue";

export const useInspectionLinksStore = defineStore("inspectionLinks", () => {
  let isDirty = ref(false);
  let pageReference = ref(null);
  let isSearching = ref(false);
  let isSaving = ref(false);
  let isPrinting = ref(false);
  let isAddingOrEditingLoadRatingDetails = ref(false);
  let isAddingOrEditingLoadRatingPostingDetails = ref(false);
  let showValidationSnackbar = ref(false);

  return {
    showValidationSnackbar,
    isAddingOrEditingLoadRatingDetails,
    isAddingOrEditingLoadRatingPostingDetails,
    isDirty,
    pageReference,
    isSearching,
    isSaving,
    isPrinting,
  };
});
