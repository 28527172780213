import { CWOPA_ROLES } from "@/rbac/roles.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const EDIT_ROLES = [
  CWOPA_ROLES.BMS,
  CWOPA_ROLES.BMS_APPLICATION_SECURITY_ADMINISTRATION,
  CWOPA_ROLES.BMS_APRAS_BROWSER,
  CWOPA_ROLES.BMS_APRAS_EDIT,
  CWOPA_ROLES.BMS_BROWSER,
  CWOPA_ROLES.BMS_BROWSER_INSPECTION,
  CWOPA_ROLES.BMS_BROWSER_INVENTORY,
  CWOPA_ROLES.BMS_LBI_USER,
  CWOPA_ROLES.BMS_POWERUSER,
  CWOPA_ROLES.BMS_REGISTRAR,
  CWOPA_ROLES.BMS_SUPERUSER,
  CWOPA_ROLES.SPN,
  CWOPA_ROLES.SPN_APPLICATION_SECURITY_ADMINISTRATOR,
  CWOPA_ROLES.SPN_APPROVE_DIST,
  CWOPA_ROLES.SPN_APPROVE_STATE,
  CWOPA_ROLES.SPN_EDIT,
  CWOPA_ROLES.SPN_VIEW,
  CWOPA_ROLES.BMS_INSPECTOR,
  CWOPA_ROLES.BMS_INSPECTOR_SUPERVISOR,
];

export const ALL_CWOPA_ROLES = {
  [ACCESS_LEVELS.VIEW]: [...EDIT_ROLES],
  [ACCESS_LEVELS.EDIT]: [...EDIT_ROLES],
};
