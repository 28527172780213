const underwaterInsp = {
  ALIGNMENT: null,
  BRKEY: "",
  CALC_SCOUR_DEPTH_100: null,
  CALC_SCOUR_DEPTH_500: null,
  CHG_SINCE_LAST_INSP: null,
  COUNTERMEASURES: null,
  DEBRIS_POTENTIAL: null,
  FOUND_TYPE: null,
  INSPKEY: "",
  INV_FOUND_TYPE: null,
  MAX_WATER_DEPTH: null,
  MODTIME: "",
  MOVEMENT: null,
  OBS_SCOUR_DEPTH: null,
  OBS_SCOUR_RATING: null,
  OPEN_ADEQ_CHANNEL: null,
  PREVIOUS_CURRENT: null,
  SAR: 0,
  SCBI_CASE: null,
  SCBI_CODE: null,
  SCOUR_HOLE: null,
  SCOUR_UNDERMINE: null,
  SED_DEPOSIT: null,
  STREAM_BED_MATERIAL: null,
  STRUNITKEY: "",
  SUBUNIT_TYPE: null,
  SUB_SCOUR: null,
  UNDERWATER_INSP_DESC: null,
  UNDERWATER_INSP_TYPE: null,
  USERKEY: "",
  VELO_STREAM_SLOPE: null,
};

export default underwaterInsp;
