import { CWOPA_ROLES } from "@/rbac/roles.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_ROLES = [
  CWOPA_ROLES.BMS,
  CWOPA_ROLES.BMS_APPLICATION_SECURITY_ADMINISTRATION,
  CWOPA_ROLES.BMS_BROWSER,
  CWOPA_ROLES.BMS_BROWSER_INSPECTION,
  CWOPA_ROLES.BMS_BROWSER_INVENTORY,
  CWOPA_ROLES.BMS_INSPECTOR,
  CWOPA_ROLES.BMS_INSPECTOR_SUPERVISOR,
  CWOPA_ROLES.BMS_POWERUSER,
  CWOPA_ROLES.BMS_REGISTRAR,
  CWOPA_ROLES.BMS_SUPERUSER,
];

export const BI_REPORTS_PERMITTED_ROLES = {
  [ACCESS_LEVELS.VIEW]: [...VIEW_ROLES],
  [ACCESS_LEVELS.EDIT]: [CWOPA_ROLES.BMS_SUPERUSER],
};
