import { defineStore } from "pinia";
import { ref } from "vue";
import { LOGGER } from "@/util/logger";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

export const useSnackbarStore = defineStore("snackBar", () => {
  let messages = ref({});
  const _setMessageTimeout = (id) => {
    const message = messages.value[id];
    if (message.timeout && message.timeout > 0) {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        if (message.callback) {
          message.callback();
        }
        removeMessage(id);
      }, message.timeout);
    }
    return () => delete messages[id];
  };
  const _generateId = () => {
    if (!_generateId.lastId) {
      _generateId.lastId = Date.now();
    }
    _generateId.lastId += 1;
    return `sb_${_generateId.lastId}`;
  };

  const showMessage = ({
    displayText,
    timeout = -1,
    messageType = SNACKBAR_MESSAGE_TYPES.WARNING,
    callback,
    showCloseButton = false,
  }) => {
    if (!displayText) {
      LOGGER.logException("display text is not provided.");
      return "";
    }
    if (!SNACKBAR_MESSAGE_TYPES[messageType.toUpperCase()]) {
      LOGGER.logException("display text is not provided.");
      return "";
    }
    const id = _generateId();
    messages.value[id] = {
      displayText,
      timeout,
      messageType,
      id,
      callback,
      showCloseButton,
    };
    _setMessageTimeout(id);
    return id;
  };

  const removeMessage = (id) => {
    if (id) {
      delete messages.value[id];
    }
  };

  const removeAllMessage = () => {
    messages.value = {};
  };

  return {
    messages,
    showMessage,
    removeMessage,
    removeAllMessage,
  };
});
