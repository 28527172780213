<template>
  <v-row>
    <v-col>
      <div v-for="link in leftLinks" :key="link.linkOrder">
        <a :href="link.url" target="_blank"> {{ link.linkDescription }} </a>
      </div>
    </v-col>
    <v-col>
      <div v-for="link in rightLinks" :key="link.linkOrder">
        <a :href="link.url" target="_blank">
          {{ link.linkDescription }}
        </a>
      </div></v-col
    >
  </v-row>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { useAdminStore } from "@/stores/admin";

const adminStore = useAdminStore();
let links = ref([]);

const props = defineProps({
  isExternal: { default: false, type: Boolean },
});

onMounted(async () => {
  await adminStore.loadLinks(props.isExternal);
  setLinksValue();
});

const setLinksValue = () => {
  links.value = adminStore.getLinks();
};

const leftLinks = computed(() => {
  return links.value
    .filter((a) => a.linkColumn == "L")
    .sort((a, b) => a.linkOrder - b.linkOrder);
});
const rightLinks = computed(() => {
  return links.value
    .filter((a) => a.linkColumn == "R")
    .sort((a, b) => a.linkOrder - b.linkOrder);
});
</script>
