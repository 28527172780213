import axios from "axios";
import { INSPECTION_ENDPOINT } from "@/constants/Endpoints";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";
import { useConnectivityStore } from "@/stores/connectivity";
import { LOGGER } from "@/util/logger";
import { useUserPermissionStore } from "@/stores/userPermission";

let interval;

export function setupSyncInterval() {
  const configStore = useConfigStore();
  const duration = configStore.getEnvConfigValue(
    ENV_CONFIG_PROPERTY.CLIENT_SYNC_INTERVAL
  );

  const connectivity = useConnectivityStore();
  interval = setInterval(syncInspectionData(), 60000 * duration);

  function syncInspectionData() {
    return () => {
      LOGGER.logEvent(
        LOGGER.EventAction.Sync,
        LOGGER.EventAction.Interval,
        "API",
        "syncInspectionData"
      );
      //if the connectivity is offline, don't run the background sync
      if ((!connectivity.getisOnline && interval) || (!duration && interval)) {
        clearInterval(interval);
        return;
      }
      //get dirty records from IDB
      syncAllDirtyRecords();
    };
  }
}

export async function syncAllDirtyRecords() {
  const structureIDBStore = useStructureIDBStore();
  const userPermissionStore = useUserPermissionStore();
  await structureIDBStore.initDB(false);
  const connectivity = useConnectivityStore();
  try {
    let result = await structureIDBStore.getDirtyStructuresFromIDB();
    if (result == [] || !result) {
      await connectivity.getServiceStatus();
    } else {
      for (const structure of result.docs) {
        if (!structure.userStateId) {
          const response = await axios.post(
            INSPECTION_ENDPOINT.SYNC_INSPECTION,
            structure,
            {
              params: { brkey: structure.Bridge.BRKEY },
            }
          );
          //set user state id
          structure.userStateId = response.data.data.userStateId;
        } else {
          await axios.put(INSPECTION_ENDPOINT.SYNC_INSPECTION, structure, {
            params: { userStateId: structure.userStateId },
          });
        }
        //set backed up flag to true
        structure.backedUp = "Y";
        //set backed up date
        structure.backedUpTimestamp = new Date();
        //save structure after removing the _rev from payload as it is conflicting when saving the structure to DB
        delete structure._rev;
        await userPermissionStore.updateStructurePermission(structure);
        await structureIDBStore.saveStructure(structure);
      }
    }
  } catch (e) {
    let error = Object.assign(e, {
      method: "syncAllDirtyRecords",
    });
    LOGGER.logException(error);
  }
}
