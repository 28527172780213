import { PERMISSIONS } from "@/rbac/permissions.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_PERMISSIONS = [PERMISSIONS.BRIDGEADMINVIEW];

const EDIT_PERMISSIONS = [PERMISSIONS.BRIDGEADMINEDIT];

const ADD_PERMISSIONS = [PERMISSIONS.BRIDGEADMINADD];

export const BP_ASSIGNMENT_USER_PERMISSIONS = {
  [ACCESS_LEVELS.VIEW]: VIEW_PERMISSIONS,
  [ACCESS_LEVELS.EDIT]: EDIT_PERMISSIONS,
  [ACCESS_LEVELS.ADD]: ADD_PERMISSIONS,
};
