import { STREAM_BED_MATERIALS } from "@/constants/Inspections";
import { useConfigStore } from "@/stores/config";

function getIN15Value(Structure_Unit, T_Underwater_Insp) {
  const configStore = useConfigStore();
  const subUnits = configStore.filterWingwalls(
    Structure_Unit,
    T_Underwater_Insp
  );
  let arr = [];
  subUnits?.forEach((subUnit) => {
    //get the matching material and rank and put it in an array
    arr.push({
      streamBedMaterial: subUnit?.STREAM_BED_MATERIAL,
      rank: STREAM_BED_MATERIALS.get(subUnit?.STREAM_BED_MATERIAL),
    });
  });
  //filter the matched materials with rank in ascending order and get the lowest rank material
  //destruture the array(arr) because of sonar issue, Array-mutating methods should not be used misleadingly
  const sortedMaterials = [...arr].sort((a, b) => {
    return a.rank < b.rank ? -1 : 1;
  });
  if (sortedMaterials[0]) {
    return sortedMaterials[0].streamBedMaterial;
  } else {
    return "--";
  }
}
function getIN15ValueFromMaterials(materials) {
  let arr = [];
  materials?.forEach((material) => {
    //get the matching material and rank and put it in an array
    arr.push({
      streamBedMaterial: material.streamBedMaterial,
      rank: STREAM_BED_MATERIALS.get(material.streamBedMaterial),
    });
  });
  //filter the matched materials with rank in ascending order and get the lowest rank material
  //destruture the array(arr) because of sonar issue, Array-mutating methods should not be used misleadingly
  const sortedMaterials = [...arr].sort((a, b) => {
    return a.rank < b.rank ? -1 : 1;
  });
  if (sortedMaterials[0]) {
    return sortedMaterials[0].streamBedMaterial;
  } else {
    return "--";
  }
}

function areThereNonControllingIN15Changes(
  currentInspStructureUnit,
  currentInspTUnderwaterInsp,
  previousInspMaterials
) {
  const configStore = useConfigStore();
  let subUnits = configStore.filterWingwalls(
    currentInspStructureUnit,
    currentInspTUnderwaterInsp
  );
  // Easiest check: are there now fewer subunits listed than before? If so, we have a worthy change
  if (subUnits?.length < previousInspMaterials?.length) return true;

  // Filter out any entries that have an invalid/null value for their stream bed material
  subUnits = subUnits?.filter(
    (currentSubUnit) =>
      currentSubUnit?.STREAM_BED_MATERIAL !== null &&
      currentSubUnit?.STREAM_BED_MATERIAL !== undefined
  );

  // Find the set of subunits that do not match, either because the stream bed material was changed,
  // or because the entire subunit was deleted
  let unmatchedUnits = subUnits?.filter((currentSubUnit) => {
    return !previousInspMaterials?.some(
      (prevSubUnit) =>
        currentSubUnit["STRUNITKEY"] === prevSubUnit["strUnitKey"] &&
        currentSubUnit["STREAM_BED_MATERIAL"] ===
          prevSubUnit["streamBedMaterial"]
    );
  });

  // Since the row in the key field comparison will already be highlighted if the **controlling** stream
  // bed material changes, we need to find it and remove it from our list of unmatched units
  // We only need to bother with this if we actually find an unmatched subunit
  if (unmatchedUnits?.length > 0) {
    subUnits?.forEach((subUnit) => {
      subUnit.rank = STREAM_BED_MATERIALS.get(subUnit?.STREAM_BED_MATERIAL);
    });
    subUnits?.sort((a, b) => {
      return a.rank < b.rank ? -1 : 1;
    });
    unmatchedUnits?.splice(
      unmatchedUnits?.findIndex(
        (subunit) => subunit.strUnitKey === subUnits?.[0].strUnitKey
      ),
      1
    );
  }

  // If the unmatched units still contain an item, then we definitely have a non-controlling change
  return unmatchedUnits.length > 0;
}

export {
  getIN15Value,
  getIN15ValueFromMaterials,
  areThereNonControllingIN15Changes,
};
