<template>
  <v-expansion-panels v-model="pierPanels" multiple color="#fff">
    <v-expansion-panel>
      <v-expansion-panel-title
        class="d-flex justify-start"
        hide-actions
        expand-icon="fa-solid fa-plus"
        collapse-icon="fa-solid fa-minus"
      >
        <v-icon v-if="!pierPanels.includes(0)" icon="fa-solid fa-plus" />
        <v-icon v-if="pierPanels.includes(0)" icon="fa-solid fa-minus" />
        <h3>
          Pier
          {{
            pier?.STRUNITLABEL.includes("Pier")
              ? pier?.STRUNITLABEL.split(" ")[1].length == 1
                ? "0" + pier?.STRUNITLABEL.split(" ")[1]
                : pier?.STRUNITLABEL.split(" ")[1]
              : pier?.STRUNITLABEL.split("P")[1]
          }}
        </h3>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-expansion-panels v-model="pierSubPanels" multiple color="#fff">
          <v-expansion-panel>
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon
                v-if="!pierSubPanels.includes(0)"
                icon="fa-solid fa-plus"
              />
              <v-icon
                v-if="pierSubPanels.includes(0)"
                icon="fa-solid fa-minus"
              />
              <h3>
                Pier
                {{
                  pier?.STRUNITLABEL.includes("Pier")
                    ? pier?.STRUNITLABEL.split(" ")[1].length == 1
                      ? "0" + pier?.STRUNITLABEL.split(" ")[1]
                      : pier?.STRUNITLABEL.split(" ")[1]
                    : pier?.STRUNITLABEL.split("P")[1]
                }}
                Inspection Notes
              </h3>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <v-row class="pt-4 pb-4">
                <v-btn
                  id="btn_copyPierData"
                  variant="outlined"
                  class="mx-3 my-2"
                  :disabled="!isEditable"
                  @click="showCopyPierDataPopupFunction(pier)"
                  >Copy Pier Notes</v-btn
                >
              </v-row>
              <StructureUnitCommentTypeTextField
                id="textarea_backwall_pier"
                :inspectionCommentType="
                  INSPECTION_COMMENTS.CONDITION_SUMMARY_PIER
                "
                :readOnly="!isEditable"
                label="Condition Summary"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="pier?.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                id="textarea_bridge_seats_pier"
                :inspectionCommentType="INSPECTION_COMMENTS.BRIDGE_SEATS_PIER"
                :readOnly="!isEditable"
                label="Bridge Seats"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="pier?.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                id="textarea_cheekwalls_pier"
                :inspectionCommentType="INSPECTION_COMMENTS.CHEEKWALLS_PIER"
                :readOnly="!isEditable"
                label="Cheekwalls"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="pier?.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                id="textarea_stem_pier"
                :inspectionCommentType="INSPECTION_COMMENTS.COLUMNS_STEMS_PIER"
                :readOnly="!isEditable"
                label="Columns/Stems"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="pier?.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                id="textarea_settlement_pier"
                :inspectionCommentType="INSPECTION_COMMENTS.SETTLEMENT_PIER"
                :readOnly="!isEditable"
                label="Settlement"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="pier?.STRUNITKEY"
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel v-if="pier.IN_500YR_FP == '1'">
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon
                v-if="!pierSubPanels.includes(1)"
                icon="fa-solid fa-plus"
              />
              <v-icon
                v-if="pierSubPanels.includes(1)"
                icon="fa-solid fa-minus"
              />
              <h3>
                Pier
                {{
                  pier?.STRUNITLABEL.includes("Pier")
                    ? pier?.STRUNITLABEL.split(" ")[1].length == 1
                      ? "0" + pier?.STRUNITLABEL.split(" ")[1]
                      : pier?.STRUNITLABEL.split(" ")[1]
                    : pier?.STRUNITLABEL.split("P")[1]
                }}
                - Waterway
              </h3>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <UnderwaterInspection
                ref="pierUnderwater"
                :id="`pier_${pier?.STRUNITKEY}_Underwater`"
                :subStructure="pier"
            /></v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon
                v-if="!pierSubPanels.includes(subPanelValue)"
                icon="fa-solid fa-plus"
              />
              <v-icon
                v-if="pierSubPanels.includes(subPanelValue)"
                icon="fa-solid fa-minus"
              />
              <h3>
                Pier
                {{
                  pier?.STRUNITLABEL.includes("Pier")
                    ? pier?.STRUNITLABEL.split(" ")[1].length == 1
                      ? "0" + pier?.STRUNITLABEL.split(" ")[1]
                      : pier?.STRUNITLABEL.split(" ")[1]
                    : pier?.STRUNITLABEL.split("P")[1]
                }}
                - NSTM/Fatigue
              </h3>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <NSTMFatigueTable
                id="'pier_NSTMFatigue_Table'"
                substructureFatigueTable
                :unitKey="pier?.STRUNITKEY"
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="showCopyPierDataPopup" :width="800">
    <v-card>
      <v-card-title>
        <span class="text-h5">Copy/Append Pier Data</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col xl="6" lg="7" md="8" sm="9" cols="9" class="pt-6">
            <v-label class="bold">Target Pier: </v-label></v-col
          >
          <v-col
            xl="6"
            lg="5"
            md="4"
            sm="3"
            cols="3"
            style="display: flex; align-items: center"
          >
            <v-select
              id="select_pier_one"
              :items="pierLabels"
              hide-details
              v-model="selectedPier"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="btn_Details_Save"
          variant="outlined"
          class="mx-5"
          :disabled="selectedPier == null"
          @click="copyPierData()"
        >
          Ok
        </v-btn>
        <v-btn
          id="btn_Details_Close"
          variant="outlined"
          class="mx-5"
          @click="showCopyPierDataPopup = false"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="saveWarning" :width="400">
    <v-card class="pa-3 warning-dialog">
      <p class="h3 mb-6"><strong>&nbsp;</strong></p>
      <p class="mb-2">Save is necessary to save the copied data.</p>

      <button
        @click="saveWarning = false"
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon size="x-large" icon="fas fa-xmark mx-2" />
        <small>CLOSE</small>
      </button>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref } from "vue";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import StructureUnitCommentTypeTextField from "@/components/common/StructureUnitCommentTypeTextField.vue";
import NSTMFatigueTable from "@/components/shared/NSTMFatigueTable.vue";
import UnderwaterInspection from "@/components/inspection/substructureComponents/UnderwaterInspection.vue";

export default {
  name: "PiersSubstructure",
  props: {
    pier: { default: null },
  },
  setup(props) {
    //Common Code
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    let structure = computed(() => inspectionStore.selectedInspection);
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    let piers = computed(() => inspectionStore.getPiers());

    const pierPanels = ref([0]);

    const pierSubPanels = ref([0, 1, 2]);

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    const currentDate = new Date();

    let saveWarning = ref(false);

    let pierUnderwater = ref(null);
    //Pier Code

    let subPanelValue = ref(props.pier.IN_500YR_FP == "1" ? 2 : 1);

    let pierLabels = ref([]);

    let showCopyPierDataPopup = ref(false);
    let currentPierConditionSummaryIndex;
    let currentPierBridgeSeatsIndex;
    let currentPierCheekWallsIndex;
    let currentPierStemIndex;
    let currentPierSettlementIndex;
    let currentPierCombustibleIndex;
    function showCopyPierDataPopupFunction(pier) {
      currentPierConditionSummaryIndex = inspectionStore.getCommentIndex(
        pier,
        INSPECTION_COMMENTS.CONDITION_SUMMARY_PIER
      );
      currentPierBridgeSeatsIndex = inspectionStore.getCommentIndex(
        pier,
        INSPECTION_COMMENTS.BRIDGE_SEATS_PIER
      );
      currentPierCheekWallsIndex = inspectionStore.getCommentIndex(
        pier,
        INSPECTION_COMMENTS.CHEEKWALLS_PIER
      );
      currentPierStemIndex = inspectionStore.getCommentIndex(
        pier,
        INSPECTION_COMMENTS.COLUMNS_STEMS_PIER
      );
      currentPierSettlementIndex = inspectionStore.getCommentIndex(
        pier,
        INSPECTION_COMMENTS.SETTLEMENT_PIER
      );
      currentPierCombustibleIndex = inspectionStore.getCommentIndex(
        pier,
        INSPECTION_COMMENTS.COMBUSTIBLE_MATERIAL_PIER
      );
      pierLabels.value = [];
      piers.value?.forEach((pierFromArray) => {
        let selectedPierConditionSummaryIndex = inspectionStore.getCommentIndex(
          pierFromArray,
          INSPECTION_COMMENTS.CONDITION_SUMMARY_PIER
        );
        let selectedPierBridgeSeatsIndex = inspectionStore.getCommentIndex(
          pierFromArray,
          INSPECTION_COMMENTS.BRIDGE_SEATS_PIER
        );
        let selectedPierCheekWallsIndex = inspectionStore.getCommentIndex(
          pierFromArray,
          INSPECTION_COMMENTS.CHEEKWALLS_PIER
        );
        let selectedPierStemIndex = inspectionStore.getCommentIndex(
          pierFromArray,
          INSPECTION_COMMENTS.COLUMNS_STEMS_PIER
        );
        let selectedPierSettlementIndex = inspectionStore.getCommentIndex(
          pierFromArray,
          INSPECTION_COMMENTS.SETTLEMENT_PIER
        );
        let selectedPierCombustibleIndex = inspectionStore.getCommentIndex(
          pierFromArray,
          INSPECTION_COMMENTS.COMBUSTIBLE_MATERIAL_PIER
        );

        let condidtionSummary =
          structure.value?.T_Struc_Unit_Insp_Comment[
            currentPierConditionSummaryIndex
          ]?.NOTES ==
          structure.value?.T_Struc_Unit_Insp_Comment[
            selectedPierConditionSummaryIndex
          ]?.NOTES;
        let bridgeSeats =
          structure.value?.T_Struc_Unit_Insp_Comment[
            currentPierBridgeSeatsIndex
          ]?.NOTES ==
          structure.value?.T_Struc_Unit_Insp_Comment[
            selectedPierBridgeSeatsIndex
          ]?.NOTES;
        let cheekWalls =
          structure.value?.T_Struc_Unit_Insp_Comment[currentPierCheekWallsIndex]
            ?.NOTES ==
          structure.value?.T_Struc_Unit_Insp_Comment[
            selectedPierCheekWallsIndex
          ]?.NOTES;
        let pierStem =
          structure.value?.T_Struc_Unit_Insp_Comment[currentPierStemIndex]
            ?.NOTES ==
          structure.value?.T_Struc_Unit_Insp_Comment[selectedPierStemIndex]
            ?.NOTES;
        let settlement =
          structure.value?.T_Struc_Unit_Insp_Comment[currentPierSettlementIndex]
            ?.NOTES ==
          structure.value?.T_Struc_Unit_Insp_Comment[
            selectedPierSettlementIndex
          ]?.NOTES;
        let combustibleMaterials =
          structure.value?.T_Struc_Unit_Insp_Comment[
            currentPierCombustibleIndex
          ]?.NOTES ==
          structure.value?.T_Struc_Unit_Insp_Comment[
            selectedPierCombustibleIndex
          ]?.NOTES;
        const pierObjects = [
          condidtionSummary,
          bridgeSeats,
          cheekWalls,
          pierStem,
          settlement,
          combustibleMaterials,
        ];
        pierLabels.value = pushToPierLabels(
          pierLabels.value,
          pier,
          pierFromArray,
          pierObjects
        );
      });
      showCopyPierDataPopup.value = true;
    }

    function pushToPierLabels(pierLabels, pier, pierFromArray, pierObjects) {
      if (
        pier?.STRUNITLABEL != pierFromArray?.STRUNITLABEL &&
        !(
          pierObjects[0] &&
          pierObjects[1] &&
          pierObjects[2] &&
          pierObjects[3] &&
          pierObjects[4] &&
          pierObjects[5]
        )
      )
        pierLabels.push(pierFromArray?.STRUNITLABEL);
      return pierLabels;
    }

    let selectedPier = ref(null);

    function copyPierData() {
      let selectedPierConditionSummaryIndex = inspectionStore.getCommentIndex(
        inspectionStore.getStructureUnitFromLabel(selectedPier.value),
        INSPECTION_COMMENTS.CONDITION_SUMMARY_PIER
      );
      copyData(
        selectedPierConditionSummaryIndex,
        currentPierConditionSummaryIndex,
        INSPECTION_COMMENTS.CONDITION_SUMMARY_PIER
      );

      let selectedPierBridgeSeatsIndex = inspectionStore.getCommentIndex(
        inspectionStore.getStructureUnitFromLabel(selectedPier.value),
        INSPECTION_COMMENTS.BRIDGE_SEATS_PIER
      );
      copyData(
        selectedPierBridgeSeatsIndex,
        currentPierBridgeSeatsIndex,
        INSPECTION_COMMENTS.BRIDGE_SEATS_PIER
      );
      let selectedPierCheekWallsIndex = inspectionStore.getCommentIndex(
        inspectionStore.getStructureUnitFromLabel(selectedPier.value),
        INSPECTION_COMMENTS.CHEEKWALLS_PIER
      );
      copyData(
        selectedPierCheekWallsIndex,
        currentPierCheekWallsIndex,
        INSPECTION_COMMENTS.CHEEKWALLS_PIER
      );
      let selectedPierStemIndex = inspectionStore.getCommentIndex(
        inspectionStore.getStructureUnitFromLabel(selectedPier.value),
        INSPECTION_COMMENTS.COLUMNS_STEMS_PIER
      );
      copyData(
        selectedPierStemIndex,
        currentPierStemIndex,
        INSPECTION_COMMENTS.COLUMNS_STEMS_PIER
      );
      let selectedPierSettlementIndex = inspectionStore.getCommentIndex(
        inspectionStore.getStructureUnitFromLabel(selectedPier.value),
        INSPECTION_COMMENTS.SETTLEMENT_PIER
      );
      copyData(
        selectedPierSettlementIndex,
        currentPierSettlementIndex,
        INSPECTION_COMMENTS.SETTLEMENT_PIER
      );
      let selectedPierCombustibleIndex = inspectionStore.getCommentIndex(
        inspectionStore.getStructureUnitFromLabel(selectedPier.value),
        INSPECTION_COMMENTS.COMBUSTIBLE_MATERIAL_PIER
      );
      copyData(
        selectedPierCombustibleIndex,
        currentPierCombustibleIndex,
        INSPECTION_COMMENTS.COMBUSTIBLE_MATERIAL_PIER
      );
      showCopyPierDataPopup.value = false;
      saveWarning.value = true;
      selectedPier.value = null;
    }

    async function validate() {
      await pierUnderwater.value.validate();

      return pierUnderwater.value.valid;
    }

    function copyData(select, current, commentType) {
      if (select != -1 && current != -1) {
        structure.value.T_Struc_Unit_Insp_Comment[select].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[current].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[select].MODTIME =
          currentDate.toISOString();
      } else if (select == -1 && current != -1) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: commentType,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES: structure.value.T_Struc_Unit_Insp_Comment[current].NOTES,
          STRUNITKEY: inspectionStore.getStructureUnitFromLabel(
            selectedPier.value
          ).STRUNITKEY,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
      } else if (select != -1 && current == -1) {
        structure.value.T_Struc_Unit_Insp_Comment[select].NOTES = "";
        structure.value.T_Struc_Unit_Insp_Comment[select].MODTIME =
          currentDate.toISOString();
      }
    }

    return {
      subPanelValue,
      piers,
      pierSubPanels,
      validate,
      pierUnderwater,
      structure,
      saveWarning,
      showCopyPierDataPopupFunction,
      selectedPier,
      pierLabels,
      showCopyPierDataPopup,
      copyData,
      copyPierData,
      pierPanels,
      commentsMaxLength,
      isEditable,
      INSPECTION_COMMENTS,
    };
  },
  components: {
    StructureUnitCommentTypeTextField,
    NSTMFatigueTable,
    UnderwaterInspection,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  order: 1;
  color: c.$p-white !important;
}

.v-expansion-panel-title__icon {
  order: 0;
}

.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}

.v-label.v-field-label {
  color: c.$p-black !important;
  opacity: 1 !important;
  font-weight: bold !important;
}
</style>
