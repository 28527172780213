<template>
  <ExpandCollapseToggle
    id="loadRatingsPanelToggle"
    @toggleAllPanels="toggleAllPanels"
    styleClass="mt-3"
    :panel="panel"
  />
  <v-form ref="loadRatingsForm" v-model="valid">
    <div v-if="!isTunnel">
      <v-row :class="$vuetify?.display?.mdAndDown ? 'mt-1' : 'mt-3'">
        <v-col
          xl="5"
          lg="12"
          md="12"
          sm="12"
          cols="12"
          class="d-sm-block d-md-flex py-0"
        >
          <LabelCheckbox
            labelXl="5"
            labelLg="9"
            labelMd="9"
            labelSm="10"
            id="recommendedReview"
            class="noGridCheckbox handleFlex pr-2 pb-0"
            label="IR01a Load Rating Review Recommended"
            :isEditable="isEditable"
            noGrid
            v-model="structure.UserInsp.RECOMMEND_RATING_REVIEW"
            @update:modelValue="checkChangeReviewerAction()"
          ></LabelCheckbox>
          <v-btn
            id="btn_considerations"
            variant="outlined"
            @click="showConsiderationsDialog = true"
            >Considerations</v-btn
          >
        </v-col>
        <v-col xl="4" lg="12" md="12" sm="12" cols="12" class="py-0">
          <LabelSelect
            class="reviewActionField"
            noGrid
            id="reviewerAction"
            label="IR01b Reviewer Action"
            :refTable="REFERENCE_TABLE.REVIEWER_ACTION"
            v-model="structure.UserInsp.LR_REVIEW_ACTION"
          ></LabelSelect>
        </v-col>
        <v-col xl="3" lg="12" md="12" sm="12" class="py-0">
          <LabelCheckbox
            class="noGridCheckbox handleFlex pr-2 pb-0"
            noGrid
            id="postingReviewRecommended"
            label="VP11 Posting Review Recommended"
            v-model="structure.UserInsp.POST_REVIEW_REC"
            @update:model-value="validate()"
            :isEditable="isEditable"
          ></LabelCheckbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            class="details-text"
            label="Inspection Team Notes"
            id="field_recommendedReview"
            :inspectionCommentType="
              INSPECTION_COMMENTS.REVIEW_RECOMMENDED_DUE_TO_COMMENTS
            "
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            secondCharacterCount
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            class="details-text"
            label="Posting Recommended Summary"
            id="field_Posting_Recommended_Summary_Notes"
            :inspectionCommentType="
              INSPECTION_COMMENTS.LOAD_RATING_POSTING_RECOMMENDED_SUMMARY
            "
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            :readonly="!isEditable"
            :isRequired="structure.UserInsp.POST_REVIEW_REC === '1'"
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            label="Previous Inspection Team Notes"
            id="field_Previous_Inspection_Team_Notes"
            :inspectionCommentType="
              INSPECTION_COMMENTS.REVIEW_RECOMMENDED_DUE_TO_COMMENTS
            "
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            readonly
            previousData
          />
        </v-col>
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <CommentTypeTextField
            label="Previous Load Rating Engineer Notes"
            id="field_Previous_Load_Rating_Engineer_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.LOAD_RATING_REVIEW"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
            readonly
            previousData
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6" md="12" sm="12"
          ><LabelDatefield
            noGrid
            label="IR03 Calculation Date"
            id="calculationDate"
            v-model="structure.UserInsp.RATING_DATE"
        /></v-col>
        <v-col lg="6" md="12" sm="12"
          ><LabelDatefield
            noGrid
            label="IR02 Assigned Rating Approval Date"
            id="approvalDate"
            v-model="structure.UserInsp.RATING_APPR_DATE"
        /></v-col>
      </v-row>
      <v-row>
        <v-col sm="9">
          <LabelText
            type="text"
            noGrid
            label="IR02a Assigned Rating Approval Engineer"
            id="approvalEngineer"
            counter="45"
            maxlength="45"
            v-model="structure.UserInsp.APPROVER_NAME"
          />
        </v-col>
      </v-row>
    </div>
  </v-form>
  <v-row>
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel class="mt-5" v-if="!isTunnel" :value="0">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Load Ratings Details</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-row class="mb-2">
            <v-col cols="12">
              <v-data-table
                v-model:expanded="expandedRows"
                :headers="detailsHeaders"
                :items="details"
                class="lessPaddingTableColumns"
                v-model:sort-by="sortBy"
                hide-default-footer
                must-sort
                show-expand
                return-object
              >
                <template v-slot:[`header.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`header.LOAD_TYPE`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.NBI_RATING_IND`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.RATING_ANALYSIS_METHOD`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.IRLOAD`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.ORLOAD`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.SLC_RATING`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.IR_LOAD_FACTOR`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.OR_LOAD_FACTOR`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>
                <template v-slot:[`header.SUPPORT_DATASET`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortBy" />
                </template>

                <template v-slot:item="{ item }">
                  <tr :class="rowClassDetails(item)">
                    <td class="text-center">
                      <button
                        v-if="!expandedRows.includes(item)"
                        @click="toggleRowDetails(item)"
                      >
                        <v-icon
                          :id="`icon_Chevron_Right_${item.UNIQUE_KEY}`"
                          icon="fa-solid fa-chevron-right"
                        ></v-icon>
                      </button>

                      <button
                        v-else-if="expandedRows.includes(item)"
                        @click="toggleRowDetails(item)"
                      >
                        <v-icon
                          :id="`icon_Chevron_Down_${item.UNIQUE_KEY}`"
                          icon="fa-solid fa-chevron-down"
                        ></v-icon>
                      </button>
                    </td>
                    <td class="text-center">
                      {{
                        item?.LOAD_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.LOAD_TYPE,
                              item?.LOAD_TYPE
                            )
                          : ""
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        item?.NBI_RATING_IND
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.NBI_RATING,
                              item?.NBI_RATING_IND
                            )
                          : ""
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        item?.RATING_ANALYSIS_METHOD
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.LOAD_RATING_METHOD,
                              item?.RATING_ANALYSIS_METHOD
                            )
                          : ""
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.IRLOAD,
                          ROUNDING_FACTOR.ROUND
                        )
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.ORLOAD,
                          ROUNDING_FACTOR.ROUND
                        )
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.SLC_RATING,
                          ROUNDING_FACTOR.ROUND,
                          null,
                          [METRIC_CONVERSION_TYPES.W1]
                        )
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        parseFloat(item.IR_LOAD_FACTOR)
                          ? parseFloat(item.IR_LOAD_FACTOR).toFixed(2)
                          : item.IR_LOAD_FACTOR
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        parseFloat(item.OR_LOAD_FACTOR)
                          ? parseFloat(item.OR_LOAD_FACTOR).toFixed(2)
                          : item.OR_LOAD_FACTOR
                      }}
                    </td>
                    <td class="text-center">
                      {{ item.SUPPORT_DATASET }}
                    </td>
                  </tr>
                </template>
                <template v-slot:expanded-row="{ item }">
                  <td :colspan="detailsHeaders.length">
                    <v-row class="mt-1">
                      <v-col
                        xl="6"
                        lg="6"
                        md="12"
                        sm="12"
                        cols="12"
                        :class="$vuetify?.display?.mdAndDown ? '' : 'pb-0'"
                      >
                        <LabelDatefield
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="calcDate"
                          label="IR03 Calculation Date"
                          v-model="item.RATING_DATE"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        xl="6"
                        lg="6"
                        md="12"
                        sm="12"
                        cols="12"
                        :class="$vuetify?.display?.mdAndDown ? '' : 'pt-0 pb-0'"
                      >
                        <LabelSelect
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="loadType"
                          label="IR04 Load Type"
                          v-model="item.LOAD_TYPE"
                          :refTable="REFERENCE_TABLE.LOAD_TYPE"
                        />
                        <LabelSelect
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="nbiRating"
                          label="IR05 NBI"
                          v-model="item.NBI_RATING_IND"
                          :refTable="REFERENCE_TABLE.NBI_RATING"
                        />
                        <LabelSelect
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="ratingMethod"
                          label="IR06 Load Rating Method"
                          v-model="item.RATING_ANALYSIS_METHOD"
                          :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                        />
                        <LabelSelect
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="memberType"
                          label="IR07 Ctrl Member Type"
                          v-model="item.CONTROL_MEM_TYPE"
                          :refTable="REFERENCE_TABLE.CONTROLLING_MEMBER_TYPE"
                        />
                        <LabelSelect
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="stressCategoryType"
                          label="IR08 Fatigue Stress Category"
                          v-model="item.FATIG_STRESS_CAT"
                          :refTable="
                            REFERENCE_TABLE.CONTROLLING_MEMBER_FATIGUE_STRESS_CATEGORY
                          "
                        />
                        <LabelSelect
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="fatigueLoadType"
                          label="IR09 Fatigue Load Type"
                          v-model="item.FATIG_LOAD_TYPE"
                          :refTable="REFERENCE_TABLE.FATIGUE_LOAD_TYPE"
                        />
                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="manYr"
                          label="IR14 AASHTO Man Yr"
                          v-model="item.AASHTO_MANUAL_YEAR"
                        />
                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="specYr"
                          label="IR15 AASHTO Spec Yr"
                          v-model="item.AASHTO_SPEC_YEAR"
                        />
                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelSm="6"
                          type="text"
                          label="IR16 Engineer"
                          id="engineer"
                          v-model="item.ANALYSIS_ENGINEER"
                          counter="30"
                        />
                      </v-col>
                      <v-col
                        xl="6"
                        lg="6"
                        md="12"
                        sm="12"
                        cols="12"
                        :class="$vuetify?.display?.mdAndDown ? '' : 'pt-0'"
                      >
                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="invRating"
                          label="IR10 Inventory Rating"
                          v-model="item.IRLOAD"
                          appendWith=" Tons"
                          :pairCode="PAIR_CODE.METRICTON_TO_TON"
                          :roundingFactor="ROUNDING_FACTOR.ROUND"
                          type="number"
                        />
                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="invRating"
                          label="IR11 Operating Rating"
                          v-model="item.ORLOAD"
                          appendWith=" Tons"
                          :pairCode="PAIR_CODE.METRICTON_TO_TON"
                          :roundingFactor="ROUNDING_FACTOR.ROUND"
                          type="number"
                        />

                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="slcRating"
                          label="IR11a SLC Rating"
                          v-model="item.SLC_RATING"
                          appendWith=" Tons"
                          :pairCode="PAIR_CODE.METRICTON_TO_TON"
                          :roundingFactor="ROUNDING_FACTOR.ROUND"
                          type="number"
                          :conversionTypes="[METRIC_CONVERSION_TYPES.W1]"
                        />

                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="irRatingFactor"
                          label="IR20 IR Rating Factor"
                          v-model="item.IR_LOAD_FACTOR"
                          decimalPlaces="2"
                          type="number"
                        />

                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="orRatingFactor"
                          label="IR21 OR Rating Factor"
                          v-model="item.OR_LOAD_FACTOR"
                          decimalPlaces="2"
                          type="number"
                        />
                        <LabelSelect
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="critInventory"
                          label="IR12 Govern Crit Inventory"
                          v-model="item.INV_RATING_STRESS_METHOD"
                          :refTable="REFERENCE_TABLE.GOVERNING_CRITERIA"
                        />

                        <LabelSelect
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="critOperating"
                          label="IR13 Govern Crit Operating"
                          :refTable="REFERENCE_TABLE.GOVERNING_CRITERIA"
                          v-model="item.OPR_RATING_STRESS_METHOD"
                        ></LabelSelect>

                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="stressRange"
                          label="IR18 Stress Range"
                          :pairCode="PAIR_CODE.MPA_TO_KSI"
                          :roundingFactor="ROUNDING_FACTOR.TENTH"
                          decimalPlaces="1"
                          v-model="item.STRESS_RANGE"
                          appendWith=" ksi"
                          type="number"
                        />
                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="legalLoadRatingFactor"
                          label="IR22 Legal Load Rating Factor"
                          :roundingFactor="ROUNDING_FACTOR.HUNDRETH"
                          decimalPlaces="2"
                          v-model="item.LEGAL_LOAD_RF"
                          type="number"
                        />
                        <LabelText
                          labelXl="5"
                          labelLg="6"
                          labelMd="6"
                          labelsm="6"
                          id="vehiclePostingValue"
                          label="IR23 Vehicle Posting Value"
                          appendWith=" Tons"
                          :pairCode="PAIR_CODE.METRICTON_TO_TON"
                          :roundingFactor="ROUNDING_FACTOR.ROUND"
                          type="number"
                          v-model="item.VEH_POST_WEIGHT"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="12" class="pb-0">
                        <LabelText
                          style="
                            padding-left: 4px !important;
                            padding-bottom: 0px !important;
                          "
                          labelXl="12"
                          labelLg="12"
                          labelMd="12"
                          labelSm="12"
                          textXl="12"
                          textLg="12"
                          textMd="12"
                          textSm="12"
                          type="textarea"
                          label="IR17 Rating Dataset"
                          id="ratingDataset"
                          v-model="item.SUPPORT_DATASET"
                          counter="100"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="12" class="pt-0 pb-0">
                        <LabelText
                          style="
                            padding-left: 4px !important;
                            padding-top: 0px !important;
                          "
                          labelXl="12"
                          labelLg="12"
                          labelMd="12"
                          labelSm="12"
                          textXl="12"
                          textLg="12"
                          textMd="12"
                          textSm="12"
                          type="textarea"
                          label="IR19 Notes"
                          id="notes"
                          v-model="item.NOTES"
                          :counter="notesMaxLength"
                        />
                      </v-col>
                    </v-row>
                  </td> </template
                ><template v-slot:bottom> </template></v-data-table></v-col
          ></v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel v-if="!isTunnel" :value="1"
        ><v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />
          <h3>NBI & Alternate Ratings</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-label class="bold pt-2">NBI:</v-label>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6">
              <LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                id="designLoad"
                label="4B01 Design Load"
                :refTable="REFERENCE_TABLE.DESIGN_LOAD"
                v-model="structure.Bridge.DESIGNLOAD"
              ></LabelSelect>
            </v-col>
            <v-col cols="12" lg="6" xl="6">
              <LabelDatefield
                class="pt-1"
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                label="4B02 Rating Date"
                id="ratingDate"
                v-model="structure.Bridge.RATINGDATE"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6">
              <LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B03 Posting"
                id="posting"
                :refTable="REFERENCE_TABLE.POSTING"
                v-model="structure.Bridge.POSTING"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"> </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6">
              <LabelText
                v-if="isRF(nbiRating1, true)"
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="inventoryRating"
                label="4B07 Inventory Rating"
                v-model="nbiRating1.IR_LOAD_FACTOR"
                decimalPlaces="2"
                type="number"
              />
              <LabelText
                v-else
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="inventoryRating"
                label="4B07 Inventory Rating"
                v-model="nbiRating1.IRLOAD"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6">
              <LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B06 Inventory Type"
                id="inventoryType"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="nbiRating1.RATING_ANALYSIS_METHOD"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="isRF(nbiRating1, true)"
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="operatingRating"
                label="4B05 Operating Rating"
                v-model="nbiRating1.OR_LOAD_FACTOR"
                decimalPlaces="2"
                type="number"
              />
              <LabelText
                v-else
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="operatingRating"
                label="4B05 Operating Rating"
                v-model="nbiRating1.ORLOAD"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="4B04 Operating Type"
                id="operatingType"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="nbiRating1.RATING_ANALYSIS_METHOD"
              />
            </v-col>
          </v-row>
          <v-label class="bold pt-2">Alternate:</v-label>
          <v-row class="pl-6 pt-1">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="isRF(loadRatingWithLoadType1, false)"
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="inventoryRating"
                label="4B11 H20 Inventory Rating"
                v-model="loadRatingWithLoadType1.IR_LOAD_FACTOR"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="inventoryRating"
                label="4B11 H20 Inventory Rating"
                v-model="loadRatingWithLoadType1.IRLOAD"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B10 H20 Inventory Type"
                id="inventoryType"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="loadRatingWithLoadType1.RATING_ANALYSIS_METHOD"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="isRF(loadRatingWithLoadType1, false)"
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="operatingRating"
                label="4B09 H20 Operating Rating"
                v-model="loadRatingWithLoadType1.OR_LOAD_FACTOR"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="operatingRating"
                label="4B09 H20 Operating Rating"
                v-model="loadRatingWithLoadType1.ORLOAD"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="4B08 H20 Operating Type"
                id="operatingType"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="loadRatingWithLoadType1.RATING_ANALYSIS_METHOD"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="isRF(loadRatingWithLoadType8, false)"
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="ml80InventoryRating"
                label="4B12 ML80 Inventory Rating"
                v-model="loadRatingWithLoadType8.IR_LOAD_FACTOR"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="ml80InventoryRating"
                label="4B12 ML80 Inventory Rating"
                v-model="loadRatingWithLoadType8.IRLOAD"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B12b ML80 Inventory Type"
                id="ml80InventoryType"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="loadRatingWithLoadType8.RATING_ANALYSIS_METHOD"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="isRF(loadRatingWithLoadType8, false)"
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="ml80OperatingRating"
                label="4B12a ML80 Operating Rating"
                v-model="loadRatingWithLoadType8.OR_LOAD_FACTOR"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="ml80OperatingRating"
                label="4B12a ML80 Operating Rating"
                v-model="loadRatingWithLoadType8.ORLOAD"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B12c ML80 Operating Type"
                id="ml80OperatingType"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="loadRatingWithLoadType8.RATING_ANALYSIS_METHOD"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="isRF(loadRatingWithLoadType0, false)"
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="tk527InventoryRating"
                label="4B13 TK527 Inventory Rating"
                v-model="loadRatingWithLoadType0.IR_LOAD_FACTOR"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="tk527InventoryRating"
                label="4B13 TK527 Inventory Rating"
                v-model="loadRatingWithLoadType0.IRLOAD"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B13b TK527 Inventory Type"
                id="tk527InventoryType"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="loadRatingWithLoadType0.RATING_ANALYSIS_METHOD"
              />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="12" lg="6" xl="6"
              ><LabelText
                v-if="isRF(loadRatingWithLoadType0, false)"
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="tk527OperatingRating"
                label="4B13a TK527 Operating Rating"
                v-model="loadRatingWithLoadType0.OR_LOAD_FACTOR"
                decimalPlaces="2"
                type="number"
              /><LabelText
                v-else
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelsm="6"
                id="tk527OperatingRating"
                label="4B13a TK527 Operating Rating"
                v-model="loadRatingWithLoadType0.ORLOAD"
                appendWith=" Tons"
                :pairCode="PAIR_CODE.METRICTON_TO_TON"
                :roundingFactor="ROUNDING_FACTOR.ROUND"
                type="number"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6"
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="4B13c TK527 Operating Type"
                id="tk527OperatingType"
                :refTable="REFERENCE_TABLE.LOAD_RATING_METHOD"
                v-model="loadRatingWithLoadType0.RATING_ANALYSIS_METHOD"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel v-if="isTunnel" :value="2">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(2)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(2)" icon="fa-solid fa-minus" />
          <h3>Load Ratings Details</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="L.1 Rating Method"
                id="lRatingMethod"
                :refTable="REFERENCE_TABLE.TUNNEL_LOAD_RATING_METHOD"
                v-model="structure.T_Tunnel_Load_Rating.RATING_METHOD"
              />
            </v-col>
            <v-col
              ><LabelText
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                textXl="6"
                textLg="4"
                textMd="4"
                textSm="4"
                label="L.7 Posting Load - Type 3"
                id="type3PostLoad"
                v-model="structure.T_Tunnel_Load_Rating.TYPE3_POST_LOAD"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelText
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="L.2 Inventory Rating Factor"
                id="invLRFactor"
                v-model="structure.T_Tunnel_Load_Rating.INV_LR_FACTOR"
              />
            </v-col>
            <v-col
              ><LabelText
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                textXl="6"
                textLg="4"
                textMd="4"
                textSm="4"
                label="L.8 Posting Load - Type 3S2"
                id="type33s2PostLoad"
                v-model="structure.T_Tunnel_Load_Rating.TYPE3S2_POST_LOAD"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelText
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="L.3 Operating Rating Factor"
                id="oplrFactor"
                v-model="structure.T_Tunnel_Load_Rating.OP_LR_FACTOR"
              />
            </v-col>
            <v-col
              ><LabelText
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                textXl="6"
                textLg="4"
                textMd="4"
                textSm="4"
                label="L.9 Posting Load - Type 3-3"
                id="type33PostLoad"
                v-model="structure.T_Tunnel_Load_Rating.TYPE3_3_POST_LOAD"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelSelect
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="7"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                label="L.4 Load Posting Status"
                id="postStatus"
                :refTable="REFERENCE_TABLE.POSTING_STATUS"
                v-model="
                  structure.T_Posting[structure.T_Posting.length - 1]
                    .POST_STATUS
                "
              />
            </v-col>
            <v-col
              ><LabelSelect
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="6"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="L.10 Height Restriction"
                id="heightRestriction"
                :refTable="REFERENCE_TABLE.YES_NO"
                v-model="structure.T_Tunnel_Load_Rating.HEIGHT_RESTR"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelText
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="L.5 Posting Load - Gross"
                id="loadGross"
                v-model="structure.T_Tunnel_Load_Rating.GROSS_POST_LOAD"
              />
            </v-col>
            <v-col
              ><LabelSelect
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="6"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="L.11 Hazardous Material Restriction"
                id="heightRestriction"
                :refTable="REFERENCE_TABLE.YES_NO"
                v-model="structure.T_Tunnel_Load_Rating.HAZMAT_RESTR"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><LabelText
                labelXl="5"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                label="L.6 Posting Load - Axle"
                id="loadAxle"
                v-model="structure.T_Tunnel_Load_Rating.AXLE_POST_LOAD"
              />
            </v-col>
            <v-col
              ><LabelSelect
                labelXl="6"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="6"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="L.12 Other Restrictions"
                id="otherRestriction"
                :refTable="REFERENCE_TABLE.YES_NO"
                v-model="structure.T_Tunnel_Load_Rating.OTHER_RESTR"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel :value="3">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(3)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(3)" icon="fa-solid fa-minus" />
          <h3>Posting History</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-row class="mb-2">
            <v-col cols="12">
              <v-data-table
                v-model:expanded="expandedRowsPosting"
                :headers="headersPosting"
                :items="postingHistory"
                class="lessPaddingTableColumns"
                v-model:sort-by="sortByPosting"
                hide-default-footer
                must-sort
                show-expand
                return-object
              >
                <template v-slot:[`header.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`header.POST_STATUS_DATE`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.POST_STATUS`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.SPEC_RESTRICT_POST`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.POST_LIMIT_WEIGHT`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.POST_LIMIT_COMB`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>
                <template v-slot:[`header.POST_REASON`]="{ column }">
                  <DataTableHeader :column="column" :sortBy="sortByPosting" />
                </template>

                <template v-slot:item="{ item }">
                  <tr :class="rowClassPosting(item)">
                    <td class="text-center">
                      <button
                        v-if="!expandedRowsPosting.includes(item)"
                        @click="toggleRowPosting(item)"
                      >
                        <v-icon
                          :id="`icon_Chevron_Right_${item.POST_STATUS_DATE}`"
                          icon="fa-solid fa-chevron-right"
                        ></v-icon>
                      </button>

                      <button
                        v-else-if="expandedRowsPosting.includes(item)"
                        @click="toggleRowPosting(item)"
                      >
                        <v-icon
                          :id="`icon_Chevron_Down_${item.POST_STATUS_DATE}`"
                          icon="fa-solid fa-chevron-down"
                        ></v-icon>
                      </button>
                    </td>
                    <td class="text-center">
                      {{ getFormattedDateStringNoTime(item?.POST_STATUS_DATE) }}
                    </td>
                    <td class="text-center">
                      {{
                        item?.POST_STATUS
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.POSTING_STATUS,
                              item?.POST_STATUS
                            )
                          : ""
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        item?.SPEC_RESTRICT_POST
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SPECIAL_RESTRICT_POST,
                              item?.SPEC_RESTRICT_POST
                            )
                          : ""
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.POST_LIMIT_WEIGHT,
                          ROUNDING_FACTOR.ROUND
                        )
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          item.POST_LIMIT_COMB,
                          ROUNDING_FACTOR.ROUND
                        )
                      }}
                    </td>

                    <td class="text-center">
                      {{
                        item?.POST_REASON
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.POST_REASON,
                              item?.POST_REASON
                            )
                          : ""
                      }}
                    </td>
                  </tr>
                </template>

                <template v-slot:expanded-row="{ item }">
                  <td :colspan="headersPosting.length">
                    <v-form
                      ref="postingHistoryForm"
                      v-model="valid"
                      class="mt-2"
                      validate-on="blur"
                    >
                      <v-row>
                        <v-col
                          xl="6"
                          lg="6"
                          md="12"
                          sm="12"
                          cols="12"
                          :class="
                            $vuetify?.display?.mdAndDown ? 'px-1' : 'py-0'
                          "
                        >
                          <LabelDatefield
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            textXl="6"
                            textLg="5"
                            textMd="5"
                            textSm="5"
                            id="txt_Status_Date"
                            label="VP01 Status Date"
                            v-model="item.POST_STATUS_DATE"
                          >
                          </LabelDatefield>

                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Posting_Status"
                            label="VP02 Posting Status"
                            :refTable="REFERENCE_TABLE.POSTING_STATUS"
                            v-model="item.POST_STATUS"
                          ></LabelSelect>
                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Posting_Type"
                            label="VP02a Posting Type"
                            :refTable="REFERENCE_TABLE.POSTING_TYPE"
                            v-model="item.SNBI_POST_STATUS"
                          ></LabelSelect>
                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Posting_Condition"
                            label="VP02b Posting Condition"
                            :refTable="REFERENCE_TABLE.POSTING_CONDITION"
                            v-model="item.SNBI_POST_CONDITION"
                          ></LabelSelect>
                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Special_Restrictive_Posting"
                            label="VP03 Special Restrictive Posting"
                            :refTable="REFERENCE_TABLE.SPECIAL_RESTRICT_POST"
                            v-model="item.SPEC_RESTRICT_POST"
                          ></LabelSelect>
                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Posting_Type"
                            label="VP03a Vehicle Posting Type"
                            :refTable="REFERENCE_TABLE.VEHICLE_POSTING_TYPE"
                            v-model="item.VEH_POST_TYPE"
                          ></LabelSelect>
                          <LabelText
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            textXl="6"
                            textLg="5"
                            textMd="5"
                            textSm="5"
                            id="txt_Posted Weight Limit"
                            label="VP04 Posted Weight Limit"
                            v-model="item.POST_LIMIT_WEIGHT"
                            appendWith=" Tons"
                            :pairCode="PAIR_CODE.METRICTON_TO_TON"
                            :roundingFactor="ROUNDING_FACTOR.ROUND"
                            type="number"
                          />

                          <LabelText
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            textXl="6"
                            textLg="5"
                            textMd="5"
                            textSm="5"
                            id="txt_Posted_Limit_Combination"
                            label="VP05 Posted Limit Combination"
                            v-model="item.POST_LIMIT_COMB"
                            appendWith=" Tons"
                            :pairCode="PAIR_CODE.METRICTON_TO_TON"
                            :roundingFactor="ROUNDING_FACTOR.ROUND"
                            type="number"
                          />
                        </v-col>

                        <v-col
                          xl="6"
                          lg="6"
                          md="12"
                          sm="12"
                          cols="12"
                          :class="
                            $vuetify?.display?.mdAndDown ? 'px-1' : 'py-0'
                          "
                        >
                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Posting_Reason"
                            label="VP06 Posting Reason"
                            :refTable="REFERENCE_TABLE.POST_REASON"
                            v-model="item.POST_REASON"
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Field_Conditions"
                            label="VP07 Field Conditions"
                            :refTable="REFERENCE_TABLE.FIELD_COND"
                            v-model="item.FIELD_COND"
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_Special_Conditions"
                            label="VP08 Special Conditions"
                            :refTable="REFERENCE_TABLE.SPECIAL_CONDITIONS"
                            v-model="item.SPEC_COND"
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            selectXl="6"
                            selectLg="5"
                            selectMd="5"
                            selectSm="5"
                            id="sel_AASHTO_Impact_Code"
                            label="VP09 AASHTO Impact Code"
                            :refTable="REFERENCE_TABLE.AASHTO_IMPACT_CODE"
                            v-model="item.IMPACT"
                          ></LabelSelect>

                          <LabelCheckbox
                            labelXl="6"
                            labelLg="7"
                            labelMd="7"
                            labelSm="7"
                            class="labelCheckbox"
                            id="checkbox_Active"
                            label="Active"
                            v-model="item.ACTIVE"
                          >
                          </LabelCheckbox>
                        </v-col>
                      </v-row>
                    </v-form>
                  </td>
                </template>

                <template v-slot:bottom> </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>

  <v-dialog
    v-model="showConsiderationsDialog"
    scrollable
    persistent
    class="dialogForm"
  >
    <v-card>
      <v-card-title> Considerations: </v-card-title>
      <v-divider />
      <v-card-text>
        <ul class="pl-3">
          <li v-for="(item, id) in CONSIDERATIONS_POINTS" :key="id">
            {{ item }}
          </li>
        </ul>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          variant="outlined"
          class="mx-5"
          @click="
            {
              showConsiderationsDialog = false;
            }
          "
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { clone } from "@/util/clone";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { convertToInt } from "@/composables/util";
import useStructureType from "@/composables/structureType";
import {
  CONSIDERATIONS_POINTS,
  METRIC_CONVERSION_TYPES,
} from "@/constants/InspectionConstants";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import useDateField from "@/composables/dateField.js";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionLoadRatings",
  setup() {
    const allPanels = [0, 1, 2, 3];
    let panel = ref(allPanels);
    const inspectionStore = useInspectionStore();
    let structure = computed(() => inspectionStore.selectedInspection);

    let showConsiderationsDialog = ref(false);
    let loadRatingsForm = ref();

    let valid = ref(true);

    async function validate() {
      await loadRatingsForm.value.validate();
    }

    onMounted(async () => {
      await validate();
    });
    const { getFormattedDateStringNoTime } = useDateField();
    const sortByPosting = ref([{ key: "POST_STATUS_DATE", order: "desc" }]);
    const headersPosting = ref([
      {
        key: "data-table-expand",
      },
      {
        line1: "VP01",
        line2: "Status",
        line3: "Date",
        key: "POST_STATUS_DATE",
        sortable: true,
      },
      {
        line1: "VP02",
        line2: "Posting",
        line3: "Status",
        key: "POST_STATUS",
        sortable: true,
      },
      {
        line1: "VP03",
        line2: "Special Restrictive",
        line3: "Posting",
        key: "SPEC_RESTRICT_POST",
        sortable: true,
      },
      {
        line1: "VP04",
        line2: "Posted Weight",
        line3: "Limit (Tons)",
        key: "POST_LIMIT_WEIGHT",
        sortable: false,
      },
      {
        line1: "VP05",
        line2: "Posted Limit",
        line3: "Combination (Tons)",
        key: "POST_LIMIT_COMB",
        sortable: false,
      },
      {
        line1: "VP06",
        line2: "Posting",
        line3: "Reason",
        key: "POST_REASON",
        sortable: true,
      },
    ]);

    let postingHistory = ref([]);

    const getPostingHistoryDetails = () => {
      postingHistory.value = clone(structure.value.T_Posting);
    };
    getPostingHistoryDetails();

    let expandedRowsPosting = ref([]);

    const rowClassPosting = (data) => {
      return expandedRowsPosting.value?.find(
        (a) => a.POSTING_KEY === data.POSTING_KEY
      )
        ? "rowSelect"
        : "rowUnselect";
    };

    const toggleRowPosting = (detail) => {
      if (expandedRowsPosting.value.includes(detail)) {
        const index = expandedRowsPosting.value.findIndex(
          (d) => d.POSTING_KEY == detail.POSTING_KEY
        );
        expandedRowsPosting.value.splice(index, 1);
      } else {
        expandedRowsPosting.value.push(detail);
      }
    };

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    const inspCommentMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    const notesMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.NOTES_MAX_LENGTH
      );
    });

    let expandedRows = ref([]);
    const configStore = useConfigStore();
    const { getStructureType } = useStructureType();

    const sortBy = ref([{ key: "LOAD_TYPE", order: "asc" }]);
    const detailsHeaders = ref([
      {
        key: "data-table-expand",
        width: "3%",
      },
      {
        line1: "IR04",
        line2: "Load",
        line3: "Type",
        key: "LOAD_TYPE",
        sortable: true,
      },
      {
        line1: "IR05",
        line2: "NBI",
        line3: "",
        key: "NBI_RATING_IND",
        sortable: true,
      },
      {
        line1: "IR06",
        line2: "Load Rating",
        line3: "Method",
        key: "RATING_ANALYSIS_METHOD",
        sortable: true,
      },
      {
        line1: "IR10",
        line2: "IR",
        line3: "Tons",
        key: "IRLOAD",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR11",
        line2: "OR",
        line3: "Tons",
        key: "ORLOAD",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR11a",
        line2: "SLC",
        line3: "Tons",
        key: "SLC_RATING",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR20",
        line2: "IR",
        line3: "RF",
        key: "IR_LOAD_FACTOR",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR21",
        line2: "OR",
        line3: "RF",
        key: "OR_LOAD_FACTOR",
        sortable: true,
        width: "8%",
      },
      {
        line1: "IR17",
        line2: "Rating",
        line3: "Dataset",
        key: "SUPPORT_DATASET",
        width: "12%",
        sortable: true,
      },
    ]);

    const sortDetails = (arr) => {
      arr.value = arr.value?.sort(function (a, b) {
        return (
          convertToInt(a.LOAD_TYPE) - convertToInt(b.LOAD_TYPE) ||
          convertToInt(a.NBI_RATING_IND) - convertToInt(b.NBI_RATING_IND)
        );
      });
    };

    const getLoadRatingDetails = () => {
      let detailsValue = [];
      if (!structure.value?.T_Rating_Load?.[0]) {
        //remove null values from array(if any)
        structure.value.T_Rating_Load = structure.value.T_Rating_Load?.filter(
          (a) => !!a
        );
      }

      detailsValue = clone(structure.value.T_Rating_Load);
      //set unique key for toggle expansion
      for (const element of detailsValue) {
        element.UNIQUE_KEY = element.LOAD_TYPE + "-" + element.NBI_RATING_IND;
      }
      sortDetails(detailsValue);
      return detailsValue;
    };

    let details = computed(() => {
      return getLoadRatingDetails();
    });

    const rowClassDetails = (data) => {
      return expandedRows.value?.find((a) => a.UNIQUE_KEY === data.UNIQUE_KEY)
        ? "rowSelect"
        : "rowUnselect";
    };

    const nbiRating1 = computed(() => {
      return (
        structure.value.T_Rating_Load?.find((a) => a.NBI_RATING_IND === "1") ||
        {}
      );
    });

    const getLoadRating = (loadType) => {
      return (
        structure.value.T_Rating_Load?.find(
          (a) => a.LOAD_TYPE === loadType && a.NBI_RATING_IND === "0"
        ) || {}
      );
    };
    const checkChangeReviewerAction = () => {
      if (structure.value.UserInsp.RECOMMEND_RATING_REVIEW == "1") {
        structure.value.UserInsp.LR_REVIEW_ACTION = "0";
      }
      if (structure.value.UserInsp.RECOMMEND_RATING_REVIEW == "0") {
        structure.value.UserInsp.LR_REVIEW_ACTION = "1";
      }
    };

    const loadRatingWithLoadType1 = computed(() => {
      return getLoadRating("1");
    });
    const loadRatingWithLoadType8 = computed(() => {
      return getLoadRating("8");
    });
    const loadRatingWithLoadType0 = computed(() => {
      return getLoadRating("0");
    });

    const isRF = (loadType, isNBI) => {
      if (
        loadType.RATING_ANALYSIS_METHOD == "8" ||
        loadType.RATING_ANALYSIS_METHOD == "D" ||
        loadType.RATING_ANALYSIS_METHOD == "E" ||
        loadType.RATING_ANALYSIS_METHOD == "F"
      ) {
        return true;
      } else if (
        isNBI &&
        loadType.RATING_ANALYSIS_METHOD == "6" &&
        loadType.LOAD_TYPE == "D"
      ) {
        return true;
      } else {
        return false;
      }
    };

    const isTunnel = computed(() => {
      return getStructureType(structure.value.Bridge?.SERVTYPON) === "T";
    });

    const toggleRowDetails = (detail) => {
      if (expandedRows.value.includes(detail)) {
        const index = expandedRows.value.findIndex(
          (d) => d.UNIQUE_KEY == detail.UNIQUE_KEY
        );
        expandedRows.value.splice(index, 1);
      } else {
        expandedRows.value.push(detail);
      }
    };
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.Bridge,
        structure.value.UserInsp,
        structure.value.T_Insp_Comment,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      isRF,
      checkChangeReviewerAction,
      detailsHeaders,
      sortBy,
      toggleRowPosting,
      rowClassPosting,
      expandedRowsPosting,
      panel,
      REFERENCE_TABLE,
      isEditable,
      structure,
      INSPECTION_COMMENTS,
      details,
      expandedRows,
      configStore,
      inspCommentMaxLength,
      rowClassDetails,
      PAIR_CODE,
      ROUNDING_FACTOR,
      nbiRating1,
      loadRatingWithLoadType1,
      loadRatingWithLoadType8,
      loadRatingWithLoadType0,
      getStructureType,
      isTunnel,
      notesMaxLength,
      showConsiderationsDialog,
      CONSIDERATIONS_POINTS,
      loadRatingsForm,
      validate,
      valid,
      headersPosting,
      postingHistory,
      toggleRowDetails,
      getFormattedDateStringNoTime,
      sortByPosting,
      METRIC_CONVERSION_TYPES,
      toggleAllPanels,
    };
  },
  components: {
    DataTableHeader,
    LabelSelect,
    LabelText,
    LabelCheckbox,
    LabelDatefield,
    CommentTypeTextField,
    ExpandCollapseToggle,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/general" as g;
@use "@/styles/colors" as c;
.handleFlex {
  flex: none !important;
}

.details-text :deep(.v-input__details) {
  display: flex !important;
  justify-content: space-between !important;
}

.details-text :deep(.v-messages) {
  display: none !important;
}

@media #{g.$p-breakPointLgAndUp} {
  .reviewActionField {
    padding-top: 5px !important;
  }
}
.alignCenter {
  text-align: center;
}
.alignEnd {
  text-align: end;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
</style>
