<template>
  <v-row v-if="canAddTo">
    <v-col class="px-0">
      <v-btn
        :disabled="!isEditable || anyEditable"
        :id="`btn_add_${id}`"
        variant="outlined"
        class="my-2 ml-1"
        @click="addCandidate"
        >Add</v-btn
      >
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-data-table
        v-model:page="pageInspection.page"
        :items-per-page="pageInspection.itemsPerPage"
        class="noScrollTable table-headers_top"
        :items="computedItems"
        :headers="computedHeaders"
        v-model:expanded="expanded"
        return-object
        must-sort
        v-model:sort-by="sortBy"
        @update:sort-by="maintenanceFooter.setPageValue(1)"
      >
        <template v-slot:[`header.data-table-expand`]="{ column }">
          {{ column.title }}
        </template>
        <template v-slot:[`header.AGENCY_PRIORITY`]="{ column }">
          <DataTableHeader :sortBy="sortBy" :column="column" />
        </template>
        <template v-slot:[`header.ACTCODE`]="{ column }">
          <DataTableHeader :sortBy="sortBy" :column="column" />
        </template>
        <template
          v-slot:[`header.ESTIMQUANTITY`]="{ column }"
          v-if="!isCompletedCandidates"
        >
          <DataTableHeader :sortBy="sortBy" :column="column" />
        </template>
        <template
          v-slot:[`header.WORKRECDATE`]="{ column }"
          v-if="!isCompletedCandidates"
        >
          <DataTableHeader :sortBy="sortBy" :column="column" />
        </template>

        <template
          v-slot:[`header.COMPLETED_DATE`]="{ column }"
          v-if="isCompletedCandidates"
        >
          <DataTableHeader :sortBy="sortBy" :column="column" />
        </template>
        <template v-slot:[`header.AGENCY_STATUS`]="{ column }">
          <DataTableHeader :sortBy="sortBy" :column="column" />
        </template>
        <template
          v-slot:[`header.LOCATION`]="{ column }"
          v-if="!isCompletedCandidates"
        >
          <DataTableHeader :sortBy="sortBy" :column="column" />
        </template>

        <template
          v-slot:[`header.Action`]="{ column }"
          v-if="!isCompletedCandidates"
        >
          <DataTableHeader :sortBy="sortBy" :column="column" />
        </template>
        <template v-slot:item="{ item }">
          <tr :class="rowClass(item)">
            <td class="text-center">
              <template v-if="expanded?.length == 0 || !anyEditable">
                <button
                  v-if="!expanded?.includes(item)"
                  @click="toggleRow(item)"
                >
                  <v-icon
                    :id="`icon_Chevron_Right_${id}`"
                    icon="fa-solid fa-chevron-right"
                  ></v-icon>
                </button>

                <button
                  v-if="expanded?.includes(item)"
                  @click="toggleRow(item)"
                >
                  <v-icon
                    :id="`icon_Chevron_Down_${id}`"
                    icon="fa-solid fa-chevron-down"
                  ></v-icon>
                </button>
              </template>
            </td>
            <td class="alignCenter">
              {{ item.AGENCY_PRIORITY }}
            </td>
            <td class="alignCenter" v-if="item.ACTKIND == '1'">
              {{ configStore.getBridgeActionTitle(item.ACTCODE) }}
            </td>
            <td class="alignCenter" v-else>
              {{ configStore.getFlexActionTitle(item.ACTCODE) }}
            </td>
            <td class="alignCenter" v-if="!isCompletedCandidates">
              {{ item.ESTIMQUANTITY }}
            </td>
            <td class="alignCenter" v-if="isCompletedCandidates">
              {{ getFormattedDateStringNoTime(item.COMPLETED_DATE) }}
            </td>
            <td v-else class="alignCenter">
              {{ getFormattedDateStringNoTime(item.WORKRECDATE) }}
            </td>
            <td class="alignCenter">
              {{
                configStore.getReferenceValue(
                  REFERENCE_TABLE.AGENCY_STATUS,
                  item.AGENCY_STATUS
                )
              }}
            </td>
            <td class="alignCenter" v-if="!isCompletedCandidates">
              {{ item.LOCATION }}
            </td>
            <td class="alignCenter">
              <div
                style="white-space: nowrap"
                v-if="!item.readOnly && isEditable && !isCompletedCandidates"
              >
                <IconTooltip
                  :id="`icon_Update_${id}`"
                  icon="fa-solid fa-check"
                  tooltip="Update"
                  @click="saveEvent(item)"
                />
                <IconTooltip
                  :id="`icon_Close_${id}`"
                  icon="fa-solid fa-xmark"
                  tooltip="Close"
                  @click="close"
                />
              </div>
              <div
                style="white-space: nowrap"
                v-else-if="isEditable && !anyEditable && !isCompletedCandidates"
              >
                <IconTooltip
                  :id="`icon_Edit_${id}`"
                  icon="fa-solid fa-pencil"
                  tooltip="Edit"
                  @click="edit(item)"
                />
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-row="{ item }">
          <td :colspan="computedHeaders.length">
            <v-form v-model="valid" ref="modifyForm">
              <v-row class="pt-3 pl-6">
                <v-col cols="12" lg="6" xl="6" class="pt-0 pb-1 pr-0">
                  <LabelText
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    textXl="5"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    :id="`${id}_Candidate_ID`"
                    label="3A02 Candidate ID"
                    v-model="item.WC_ID"
                    type="text"
                  >
                  </LabelText>
                </v-col>
                <v-col cols="12" lg="6" xl="6" class="pt-0 pb-1 pr-0">
                  <LabelText
                    :id="`${id}_Scope`"
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    textXl="5"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    label="IM01 Scope"
                    :refTable="REFERENCE_TABLE.ACTION_SCOPE"
                    v-model="item.ACTKIND"
                  ></LabelText>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="12" lg="6" xl="6" class="pt-0 pb-1 pr-0">
                  <LabelSelect
                    :id="`${id}_Action`"
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    selectXl="5"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    label="IM03 Action"
                    isRequired
                    :options="getActionOptions(item.ACTKIND)"
                    :isEditable="!item.readOnly && setStatus != 'D'"
                    v-model="item.ACTCODE"
                    @update:modelValue="changesDetected = true"
                  ></LabelSelect>
                </v-col>
                <v-col cols="12" lg="6" xl="6" class="pt-0 pb-1 pr-0">
                  <LabelSelect
                    :id="`${id}_Structure_Unit`"
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    selectXl="5"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    label="3A03 Structure Unit"
                    :options="structureUnits"
                    :isEditable="!item.readOnly && setStatus != 'D'"
                    v-model="item.STRUNITKEY"
                    @update:modelValue="changesDetected = true"
                  ></LabelSelect>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="12" lg="6" xl="6" class="pt-0 pb-1 pr-0">
                  <LabelSelect
                    :id="`${id}_Status`"
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    selectXl="5"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    label="IM07 Status"
                    isRequired
                    :options="agencyStatus(item.AGENCY_STATUS)"
                    :isEditable="!item.readOnly && setStatus != 'D'"
                    v-model="item.AGENCY_STATUS"
                    @update:modelValue="updateStatusDependencies(item)"
                  ></LabelSelect>
                  <LabelText
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    textXl="5"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    :id="`${id}_Estimated_Quantity`"
                    label="IM04 Estimated Quantity"
                    :allowCommas="false"
                    :showMax="false"
                    :min="1"
                    :maxlength="7"
                    v-model="item.ESTIMQUANTITY"
                    :appendWith="getUOM(item)"
                    isRequired
                    :isEditable="!item.readOnly"
                    type="number"
                    @update:modelValue="changesDetected = true"
                  >
                  </LabelText>
                  <LabelSelect
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    selectXl="5"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    :id="`${id}_Priority`"
                    isRequired
                    label="IM05 Priority"
                    :refTable="REFERENCE_TABLE.AGENCY_PRIORITY"
                    :isEditable="!item.readOnly && setStatus != 'D'"
                    v-model="item.AGENCY_PRIORITY"
                    @update:modelValue="
                      validateForm();
                      changesDetected = true;
                    "
                  ></LabelSelect>
                  <LabelDatefield
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    textXl="5"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    label="IM06 Initial Recommended Date"
                    :id="`${id}_Edit_Recommended_Date`"
                    v-model="item.WORKRECDATE"
                    :isEditable="!item.readOnly"
                    isRequired
                    @update:modelValue="changesDetected = true"
                  >
                  </LabelDatefield>
                </v-col>
                <v-col cols="12" lg="6" xl="6" class="pt-0 pb-1 pr-0">
                  <LabelText
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    textXl="5"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    :id="`${id}_Target_Year`"
                    label="IM08 Target Year"
                    v-model="item.TARGETYEAR"
                    :isEditable="!item.readOnly"
                    type="number"
                    :showComma="false"
                    :maxlength="4"
                    @update:modelValue="changesDetected = true"
                  >
                  </LabelText>
                  <LabelSelect
                    :id="`${id}_Work_Assign`"
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    selectXl="5"
                    selectLg="5"
                    selectMd="5"
                    selectSm="5"
                    label="IM11 Work Assign"
                    :refTable="REFERENCE_TABLE.WORK_ASSIGNMENT"
                    :isEditable="
                      !item.readOnly &&
                      item.AGENCY_STATUS != '1' &&
                      setStatus != 'D'
                    "
                    v-model="item.WORKASSIGNMENT"
                    @update:modelValue="changesDetected = true"
                  ></LabelSelect>
                  <LabelDatefield
                    labelXl="6"
                    labelLg="6"
                    labelMd="6"
                    labelSm="6"
                    textXl="5"
                    textLg="5"
                    textMd="5"
                    textSm="5"
                    label="IM14a Completed Date"
                    :id="`${id}_Completed_Date`"
                    v-model="item.COMPLETED_DATE"
                    :isEditable="
                      (item.AGENCY_STATUS == '5' ||
                        item.AGENCY_STATUS == '6' ||
                        item.AGENCY_STATUS == '7') &&
                      !item.readOnly
                    "
                    :maxDate="new Date()"
                    :isRequired="
                      item.AGENCY_STATUS == '5' ||
                      item.AGENCY_STATUS == '6' ||
                      item.AGENCY_STATUS == '7'
                    "
                    @update:modelValue="changesDetected = true"
                  >
                  </LabelDatefield>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pl-6 pb-0 pr-2 pt-1">
                  <LabelText
                    :labelXl="12"
                    :labelLg="12"
                    :labelMd="12"
                    :labelSm="12"
                    :textXl="12"
                    :textLg="12"
                    :textMd="12"
                    :textSm="12"
                    :id="`${id}_Location`"
                    v-model="item.LOCATION"
                    :isEditable="!item.readOnly"
                    :counter="locationMaxLength"
                    :maxlength="locationMaxLength"
                    label="IM09 Location"
                    persistent-counter
                    type="textarea"
                    auto-grow
                    rows="1"
                    @update:modelValue="changesDetected = true"
                  >
                  </LabelText>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0 pl-6 pb-4 pr-2">
                  <LabelText
                    class="pt-0"
                    :labelXl="12"
                    :labelLg="12"
                    :labelMd="12"
                    :labelSm="12"
                    :textXl="12"
                    :textLg="12"
                    :textMd="12"
                    :textSm="12"
                    :id="`${id}_Work_Candidate_Notes`"
                    v-model="item.NOTES"
                    :isEditable="!item.readOnly"
                    :counter="commentsMaxLength"
                    :maxlength="commentsMaxLength"
                    label="IM15a Work Candidate Notes"
                    :isRequired="
                      item.AGENCY_PRIORITY == '0' || item.AGENCY_PRIORITY == '1'
                    "
                    persistent-counter
                    type="textarea"
                    auto-grow
                    @update:modelValue="changesDetected = true"
                  >
                  </LabelText>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="pt-0 pl-6 pb-4 pr-2"
                  v-if="!isCompletedCandidates"
                >
                  <LabelText
                    class="pt-0"
                    :labelXl="12"
                    :labelLg="12"
                    :labelMd="12"
                    :labelSm="12"
                    :textXl="12"
                    :textLg="12"
                    :textMd="12"
                    :textSm="12"
                    :id="`${id}_Work_Candidate_deferred_Notes`"
                    v-model="item.DEFERRED_ACTION_DESC"
                    :isEditable="!item.readOnly"
                    :counter="commentsMaxLength"
                    :maxlength="commentsMaxLength"
                    label="IM15b Deferred Notes"
                    persistent-counter
                    type="textarea"
                    auto-grow
                    @update:modelValue="changesDetected = true"
                  >
                  </LabelText>
                </v-col>
              </v-row>
            </v-form>
          </td>
        </template>
        <template v-slot:bottom>
          <DataTableFooter
            ref="maintenanceFooter"
            id="inspection_maintenance_footer"
            :items="computedItems"
            v-model="pageInspection"
          ></DataTableFooter>
        </template>
      </v-data-table> </v-col
  ></v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>
<script setup>
import { computed, ref, nextTick, watch, toRefs } from "vue";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import LabelSelect from "@/components/common/LabelSelect.vue";
import IconTooltip from "@/components/common/IconTooltip.vue";
import LabelText from "@/components/common/LabelText.vue";
import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useInspectionStore } from "@/stores/inspection";
import { BUTTONS } from "@/constants/CommonWebConstants";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { clone } from "@/util/clone";
import maintenanceCandidate from "@/data/maintenanceCandidate";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import useDateField from "@/composables/dateField.js";

const props = defineProps({
  id: { default: "" },
  items: {
    default: [],
  },
  isCompletedCandidates: {
    default: false,
    type: Boolean,
  },
  isEditable: { default: false, type: Boolean },
  canAddTo: { default: false, type: Boolean },
});
const emits = defineEmits(["save", "showMessage", "hideMessage"]);
let expanded = ref([]);
const closeConfirm = ref(null);
const inspectionStore = useInspectionStore();
const structure = computed(() => inspectionStore.selectedInspection);
const { getFormattedDateStringNoTime } = useDateField();

const computedHeaders = computed(() => {
  if (props.isCompletedCandidates) {
    return completedHeaders;
  } else {
    return proposedHeaders;
  }
});
const sortBy = ref([{ key: "AGENCY_PRIORITY", order: "asc" }]);
let previousSort = ref([{ key: "AGENCY_PRIORITY", order: "asc" }]);
const completedHeaders = [
  {
    key: "data-table-expand",
  },
  {
    line1: "IM05",
    line2: "Priority",
    line3: null,
    sortable: true,
    key: "AGENCY_PRIORITY",
  },
  {
    line1: "IM03",
    line2: "Action",
    line3: null,
    sortable: true,
    key: "ACTCODE",
  },
  {
    line1: "IM14a",
    line2: "Completed",
    line3: "Date",
    sortable: true,
    key: "COMPLETED_DATE",
  },
  {
    line1: "IM07",
    line2: "Status",
    line3: null,
    sortable: true,
    key: "AGENCY_STATUS",
  },
  {
    line1: null,
    line2: "Action",
    line3: null,
    sortable: false,
    key: "Action",
  },
];

const proposedHeaders = [
  {
    key: "data-table-expand",
  },
  {
    line1: "IM05",
    line2: "Priority",
    line3: null,
    sortable: true,
    key: "AGENCY_PRIORITY",
  },
  {
    line1: "IM03",
    line2: "Action",
    line3: null,
    sortable: true,
    key: "ACTCODE",
  },
  {
    line1: "IM04",
    line2: "Est",
    line3: "Qty",
    sortable: false,
    key: "ESTIMQUANTITY",
  },
  {
    line1: "IM06",
    line2: "Initial",
    line3: "Recm'd Date",
    sortable: true,
    key: "WORKRECDATE",
  },
  {
    line1: "IM07",
    line2: "Status",
    line3: null,
    sortable: true,
    key: "AGENCY_STATUS",
  },
  {
    line1: "IM09",
    line2: "Location",
    line3: null,
    sortable: true,
    key: "LOCATION",
    width: "20%",
  },
  {
    line1: null,
    line2: "Action",
    line3: null,
    sortable: false,
    key: "Action",
  },
];

const computedMessage = computed(() => {
  if (props.isCompletedCandidates) {
    //message for completed candidates
    return INSPECTION_MESSAGES.MAINTENANCE_COMPLETED_SAVE_MESSAGE;
  } else {
    return INSPECTION_MESSAGES.MAINTENANCE_PROPOSED_SAVE_MESSAGE;
  }
});

const rowClass = (detail) => {
  return expanded.value.includes(detail) ? "rowSelect" : "rowUnselect";
};

const toggleRow = (detail) => {
  if (expanded.value.includes(detail)) {
    const index = expanded.value.findIndex((d) => d == detail);
    expanded.value.splice(index, 1);
  } else {
    expanded.value.push(detail);
  }
};

const anyEditable = computed(() => {
  return computedItems.value?.some((element) => !element.readOnly);
});

const configStore = useConfigStore();
let modifyForm = ref(null);

const getActionOptions = (scope) => {
  if (scope && scope == "1") {
    return configStore.getBridgeActions;
  } else {
    return configStore.getFlexActions(structure.value.Bridge.SERVTYPON);
  }
};
const getUOM = (item) => {
  if (item.ACTKIND !== "1") {
    return configStore.getFlexAction(item.ACTCODE)?.uom;
  } else {
    return "";
  }
};

const commentsMaxLength = computed(() => {
  return configStore.getEnvConfigValue(
    ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
  );
});

const locationMaxLength = computed(() => {
  return configStore.getEnvConfigValue(
    ENV_CONFIG_PROPERTY.ELEM_DESC_MAX_LENGTH
  );
});

const agencyStatus = (status) => {
  let options = [];
  if (status == "3") {
    options = configStore.getReferenceValues(REFERENCE_TABLE.AGENCY_STATUS);
  } else {
    options = configStore
      .getReferenceValues(REFERENCE_TABLE.AGENCY_STATUS)
      ?.filter((a) => a.value != "3");
  }
  if (status && isStatusSet.value) {
    options = options.filter(
      (a) => parseInt(a.value) >= parseInt(setStatus.value) || a.value == "D"
    );
  }
  return options;
};
const structureUnits = computed(() => {
  let structures = [];

  structures.push({ value: -1, title: "All Units" });

  structure.value.Structure_Unit.forEach((unit) => {
    structures.push({
      value: unit.STRUNITKEY,
      title: unit.STRUNITTYPE + " - " + unit.STRUNITLABEL,
    });
  });

  return structures;
});
let valid = ref(false);
let changesDetected = ref(false);
let setStatus = ref(null);
let isStatusSet = ref(false);
let { items } = toRefs(props);
let localItems = ref(clone(props.items));
let maintenanceFooter = ref(null);
const pageInspection = ref({
  itemsPerPage: 30,
  page: 1,
});

const resetItems = () => {
  localItems.value = clone(props.items);
};

const computedItems = computed({
  get: () => localItems.value,
  set: (value) => (localItems.value = value),
});

const close = () => {
  if (props.isEditable && changesDetected.value) {
    //show confirmation dialog on closing
    closeConfirm.value
      .open(
        INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
        BUTTONS.YES
      )
      .then((response) => {
        if (!response) {
          hideMessageEvent();
          changesDetected.value = false;
          expanded.value = [];
          resetItems();
          sortBy.value = previousSort.value?.map((x) => x);
          inspectionStore.setAddingOrEditing(false);
        }
      });
  } else {
    sortBy.value = previousSort.value?.map((x) => x);
    hideMessageEvent();
    expanded.value = [];
    resetItems();
    inspectionStore.setAddingOrEditing(false);
  }
};
const edit = (detail) => {
  previousSort.value = sortBy.value.map((x) => x);
  showMessageEvent();
  inspectionStore.setAddingOrEditing(true);
  detail.readOnly = false;
  expanded.value.push(detail);
  setStatus.value = detail.AGENCY_STATUS;
  nextTick(() => {
    modifyForm.value?.validate();
  });
  sortBy.value = [];
  moveEditableElementToTop(detail);
  maintenanceFooter.value?.setPageValue(1);
};

const moveEditableElementToTop = (detail) => {
  const index = localItems.value.findIndex((d) => d.WCKEY === detail.WCKEY);
  let editableElement = localItems.value[index];
  //then remove that element and use unshift
  localItems.value.splice(index, 1);
  localItems.value.unshift(editableElement);
};

const getNewCandidate = () => {
  let candidate = clone(maintenanceCandidate);
  candidate.BRKEY = structure.value.Bridge.BRKEY;
  candidate.MODTIME = new Date().toISOString();
  candidate.USERKEY = "BMS3";
  candidate.WCKEY = candidate.WC_ID = generateWCID();
  candidate.ACTKIND = "3";
  return candidate;
};

const addCandidate = () => {
  showMessageEvent();
  let candidate = getNewCandidate();
  setStatus.value = null;
  candidate.isAdd = true;
  localItems.value.unshift(candidate);
  previousSort.value = sortBy.value.map((x) => x);
  sortBy.value = [];
  expanded.value.push(candidate);
  changesDetected.value = false;
  isStatusSet.value = false;
  maintenanceFooter.value?.setPageValue(1);
  inspectionStore.setAddingOrEditing(true);
  nextTick(() => {
    modifyForm.value?.validate();
  });
};

const generateWCID = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);
  const milliseconds = ("00" + date.getMilliseconds()).slice(-3);
  const paddedValue = structure.value.Bridge.BRKEY.padStart(6, "0");
  const output = `BMS3-${paddedValue}-${year}-${month}${day}-${hours}${minutes}${seconds}${milliseconds}`;
  return output.slice(0, 30);
};

const saveEvent = (detail) => {
  detail.MODTIME = new Date().toISOString();
  detail.USERKEY = "BMS3";
  modifyForm.value?.validate().then(() => {
    if (
      valid.value &&
      (!(
        detail.AGENCY_STATUS == "5" ||
        detail.AGENCY_STATUS == "6" ||
        detail.AGENCY_STATUS == "7"
      ) ||
        detail.COMPLETED_DATE)
    ) {
      inspectionStore.setShowValidationSnackbar(false);
      inspectionStore.setAddingOrEditing(false);
      changesDetected.value = false;
      setStatus.value = detail.AGENCY_STATUS;
      isStatusSet.value = true;
      emits("save", detail);
      hideMessageEvent();
      sortBy.value = previousSort.value?.map((x) => x);
    } else {
      inspectionStore.setShowValidationSnackbar(true);
    }
  });
};

const validateForm = () => {
  modifyForm.value?.validate();
};

const checkFormValidation = () => {
  nextTick(() => {
    modifyForm.value?.validate();
  });
};

const showMessageEvent = () => {
  emits("showMessage", computedMessage.value);
};
const hideMessageEvent = () => {
  emits("hideMessage");
};

const updateStatusDependencies = (detail) => {
  changesDetected.value = true;
  if (detail.AGENCY_STATUS == "1") {
    detail.WORKASSIGNMENT = "0";
  }
  checkFormValidation();
};

watch(items, (newItems) => {
  computedItems.value = clone(newItems);
});
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;

.v-expansion-panel-title,
.v-expansion-panel-title:hover {
  background-color: c.$p-pa-light-blue !important;
}

h3 {
  order: 1;
  color: c.$p-white !important;
}
.v-expansion-panel-title__icon {
  order: 0;
}
.alignCenter {
  text-align: center;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}
</style>
