import { BUSINESS_PARTNER_ROLES, CWOPA_ROLES } from "@/rbac/roles.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_ROLES = [
  CWOPA_ROLES.BMS_INSPECTOR,
  BUSINESS_PARTNER_ROLES.BMSBP_CONSULTANT_INSPECTOR_SUPERVISOR,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER_INSPECTOR,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER_INSPECTOR_SUPERVISOR,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER_INSPECTOR,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER_INSPECTOR_SUPERVISOR,
];

const EDIT_ROLES = [
  CWOPA_ROLES.BMS_INSPECTOR_SUPERVISOR,
  CWOPA_ROLES.BMS_POWERUSER,
  BUSINESS_PARTNER_ROLES.BMSBP_CONSULTANT_OWNER_AGENT,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER,
  BUSINESS_PARTNER_ROLES.BMSBP_GOVT_BRIDGE_OWNER_AGENT,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER,
  BUSINESS_PARTNER_ROLES.BMSBP_PRIV_BRIDGE_OWNER_AGENT,
];

const ADD_ROLES = [CWOPA_ROLES.BMS_SUPERUSER];

export const BP_ASSIGNMENT_USER_PERMITTED_ROLES = {
  [ACCESS_LEVELS.VIEW]: [...VIEW_ROLES, ...EDIT_ROLES],
  [ACCESS_LEVELS.EDIT]: [...EDIT_ROLES],
  [ACCESS_LEVELS.ADD]: [...ADD_ROLES],
};
