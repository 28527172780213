/**
 * Calculates the next key value based on an array of objects and a specified field value.
 *
 * @param {Array} arr - The input array of objects.
 * @param {string} fieldValue - The field value to extract from each object.
 * @returns {number} - The next key value.
 */
export const getNextKey = (arr, fieldValue) => {
  let nextKey = 0;
  if (!Array.isArray(arr) || !fieldValue) {
    return nextKey;
  }
  arr.forEach((item) => {
    const key = item[fieldValue];
    if (typeof key === "number" && key >= nextKey) {
      nextKey = key + 1;
    }
  });
  return nextKey;
};
