import { PERMISSIONS } from "@/rbac/permissions.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_PERMISSIONS = [PERMISSIONS.INSPVIEW, PERMISSIONS.INSPDOWNLOAD];

const EDIT_PERMISSIONS = [PERMISSIONS.INSPEDIT];

const DELETE_PERMISSION = [PERMISSIONS.INSP_DEL];

const SUBMIT_PERMISSIONS = [PERMISSIONS.INSPSUBMIT];

export const INSPECTION_PERMISSIONS = {
  [ACCESS_LEVELS.VIEW]: VIEW_PERMISSIONS,
  [ACCESS_LEVELS.EDIT]: EDIT_PERMISSIONS,
  [ACCESS_LEVELS.DELETE]: DELETE_PERMISSION,
  [ACCESS_LEVELS.SUBMIT]: SUBMIT_PERMISSIONS,
};
