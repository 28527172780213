<template>
  <v-dialog v-model="localShowValue" persistent scrollable>
    <v-card>
      <v-card-title>
        <h3 class="withBackground">Inspection Validation Messages</h3>
      </v-card-title>
      <v-card-subtitle>
        Inspection Validations were found during the validation process.
        Validation failures must be addressed prior to submission. Validation
        errors should be addressed prior to submission. If the validation error
        cannot be addressed at this time or are special exceptions, a comment
        must be entered explaining the situation in order to submit the
        inspection. Validation warnings should be reviewed but are not required
        to be addressed prior to submission and entering comments is not
        required. Validation messages can be exported to Word or Notepad via
        Copy and Paste.
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          class="compactTable lessPaddingTableColumns"
          :items="validationErrors"
          :headers="headers"
          :items-per-page="-1"
          v-model:sort-by="sortBySeverity"
          must-sort
          hide-default-footer
        >
          <template v-slot:[`item.severity`]="{ item }">
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.SEVERITY,
                item.severity
              )
            }}
          </template>
          <template v-slot:bottom> </template>
        </v-data-table>
        <v-form ref="submissionCommentsForm" v-model="isValid">
          <v-row
            ><v-col md="6" sm="12">
              <CommentTypeTextField
                label="Current Inspection Comments"
                class="details-text"
                id="text_area_current_comments"
                :inspectionCommentType="
                  INSPECTION_COMMENTS.SUBMISSION_VALIDATION_COMMENT
                "
                :readonly="false"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                secondCharacterCount
                :isRequired="getErrors"
              />
            </v-col>
            <v-col md="6" sm="12">
              <CommentTypeTextField
                label="Previous Inspection Comments"
                id="text_area_previous_comments"
                :inspectionCommentType="
                  INSPECTION_COMMENTS.SUBMISSION_VALIDATION_COMMENT
                "
                readonly
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                previousData
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="align-center">
            <v-btn
              variant="outlined"
              @click="handleSave"
              :disabled="getFailures ? true : !isValid"
              >Save Comment</v-btn
            >
            <v-btn
              variant="outlined"
              @click="handleReSubmit"
              :disabled="getFailures ? true : !isValid"
              >Proceed with submission</v-btn
            >
            <v-btn variant="outlined" @click="closePopup">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-actions></v-card
    >
  </v-dialog>
</template>
<script setup>
import { ref, toRefs, watch, computed, nextTick, onMounted } from "vue";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { useInspectionStore } from "@/stores/inspection";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";

const props = defineProps({
  show: { default: false },
  validationErrors: { default: [] },
});

const emit = defineEmits(["close", "resubmit", "reloadNotesComments"]);

let { show } = toRefs(props);
let localShowValue = ref(show.value);
const structureIDBStore = useStructureIDBStore();
const inspectionStore = useInspectionStore();
const configStore = useConfigStore();
const structure = computed(() => inspectionStore.selectedInspection);
let submissionCommentsForm = ref(null);
let isValid = ref(false);

const handleSave = async () => {
  //Save the submitted structure to DB
  await structureIDBStore.saveStructure(structure.value);
  closePopup();
  inspectionStore.setDirtyFlag(false);
  inspectionStore.setUnsavedChangesWarning(false);
  emit("reloadNotesComments");
};

const handleReSubmit = async () => {
  handleSave();
  const comment = structure.value?.T_Insp_Comment?.find(
    (element) =>
      element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUBMISSION_VALIDATION_COMMENT
  )?.NOTES;
  emit("resubmit", comment);
};

const closePopup = async () => {
  await inspectionStore.setSelectedInspection(
    await structureIDBStore.getLatestInspectionFromIDB(
      structure.value.Bridge.BRKEY
    )
  );
  inspectionStore.setLatestInspKey(
    inspectionStore.selectedInspection?.InspEvnt?.INSPKEY
  );
  inspectionStore.setDirtyFlag(false);
  inspectionStore.setUnsavedChangesWarning(false);
  inspectionStore.setAddingOrEditing(false);
  localShowValue.value = false;
  emit("close");
};

const headers = [
  {
    title: "Exp ID",
    align: "center",
    key: "expid",
    sortable: true,
    width: "7%",
  },
  {
    title: "Severity",
    align: "center",
    key: "severity",
    sortable: true,
    width: "10%",
  },
  {
    title: "Message",
    align: "start",
    key: "message",
    sortable: false,
    width: "83%",
  },
];

let sortBySeverity = ref([{ key: "severity", order: "asc" }]);
const commentsMaxLength = computed(() => {
  return configStore.getEnvConfigValue(
    ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
  );
});

const getFailures = computed(() => {
  return props.validationErrors?.some((e) => e.severity == "1");
});

const getErrors = computed(() => {
  return props.validationErrors?.some((e) => e.severity == "2");
});

onMounted(async () => {
  await structureIDBStore.initDB(false);
});

watch(show, async (newValue) => {
  localShowValue.value = newValue;
  if (localShowValue.value) {
    await nextTick(() => {
      submissionCommentsForm.value?.validate();
    });
  }
});
</script>
<style scoped lang="scss">
.details-text :deep(.v-input__details) {
  display: flex !important;
  justify-content: space-between !important;
}

.details-text :deep(.v-messages) {
  display: none !important;
}
.alignCenter {
  text-align: center;
}
.alignEnd {
  text-align: end;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}

.v-card-subtitle {
  white-space: normal;
}
</style>
