import { defineStore } from "pinia";
import { ref } from "vue";

export const useAppViewStateStore = defineStore("appViewState", () => {
  let isRouteChanged = ref(false);
  
  const setIsRouteChanged = (value) => {
    isRouteChanged.value = value;
  };
  
  return {
    isRouteChanged,
    setIsRouteChanged,
  };
});