<template>
  <v-row v-if="expansionPanel" class="d-flex mx-1 whiteText">
    <v-col cols="4" class="d-flex align-center increaseContrast">
      <div class="align-self-center pl-4" v-if="showNoOfRecords">
        <span class="font-weight-bold mr-2" v-if="title">{{ title }}</span>
        <span
          class="font-weight-bold"
          v-if="
            itemsPerPageValue == -1 ||
            pageValue == Math.ceil(totalItems / itemsPerPageValue) ||
            itemsPerPageValue >= totalItems
          "
          >Records
          {{ itemsPerPageValue * (pageValue - 1) + (totalItems ? 1 : 0) }}
          to {{ totalItems }} of
          {{ totalItems }}
        </span>

        <span class="font-weight-bold" v-else-if="pageValue == 1"
          >Records 1 to {{ itemsPerPageValue }} of
          {{ totalItems }}
        </span>
        <span class="font-weight-bold" v-else-if="pageValue != 1"
          >Records
          {{ itemsPerPageValue * (pageValue - 1) + 1 }}
          to {{ itemsPerPageValue * pageValue }} of
          {{ totalItems }}
        </span>
      </div>
    </v-col>
    <v-col cols="3" class="increaseContrast">
      <div class="align-self-center">
        <v-row align="center">
          <v-col cols="3">
            <v-row align="center">
              <v-col cols="6" class="pa-0">
                <v-btn
                  aria-label="tablePageBackToOne"
                  :disabled="pageValue == 1 || itemsPerPageValue == -1"
                  variant="plain"
                  icon="fas fa-step-backward "
                  @click="setPageValue(1)"
                />
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-btn
                  aria-label="tablePageBackOne"
                  :disabled="pageValue == 1 || itemsPerPageValue == -1"
                  @click="setPageValue(pageValue - 1)"
                  variant="plain"
                  icon="fas fa-chevron-left"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" class="pt-2">
            <v-row align="center">
              <v-col
                v-if="itemsPerPageValue != -1"
                cols="12"
                justify-center
                class="pa-0"
                >Page {{ totalItems ? pageValue : 0 }} of
                {{ Math.ceil(totalItems / itemsPerPageValue) }}</v-col
              >
              <v-col v-else cols="12" justify-center class="pa-0"
                >Page {{ pageValue }} of 1
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col cols="6" class="pa-0">
                <v-btn
                  aria-label="tablePageForwardToMaxPage"
                  :disabled="
                    pageValue == Math.ceil(totalItems / itemsPerPageValue) ||
                    itemsPerPageValue == -1
                  "
                  @click="setPageValue(pageValue + 1)"
                  variant="plain"
                  icon="fas fa-chevron-right "
                />
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-btn
                  aria-label="tablePageForwardOne"
                  :disabled="
                    pageValue == Math.ceil(totalItems / itemsPerPageValue) ||
                    itemsPerPageValue == -1
                  "
                  variant="plain"
                  icon="fas fa-step-forward"
                  @click="
                    setPageValue(Math.ceil(totalItems / itemsPerPageValue))
                  "
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col cols="5" class="align-center d-flex justify-end increaseContrast">
      <LabelSelect
        :id="`select_${id}`"
        class="align-center d-flex tableFooterSelect"
        label="Records per page"
        :isEditable="true"
        expansionPanel
        no-grid
        :options="rowsPerPageOptions"
        v-model="itemsPerPageValue"
        :clearable="false"
      >
      </LabelSelect>
    </v-col>
  </v-row>
  <v-row v-else class="d-flex mx-1">
    <div class="align-self-center me-auto">
      <span class="font-weight-bold mr-2" v-if="title">{{ title }}</span>
      <span
        class="font-weight-bold"
        v-if="
          itemsPerPageValue == -1 ||
          pageValue == Math.ceil(totalItems / itemsPerPageValue) ||
          itemsPerPageValue >= totalItems
        "
        >Records
        {{ itemsPerPageValue * (pageValue - 1) + (totalItems ? 1 : 0) }}
        to {{ totalItems }} of
        {{ totalItems }}
      </span>

      <span class="font-weight-bold" v-else-if="pageValue == 1"
        >Records 1 to {{ itemsPerPageValue }} of
        {{ totalItems }}
      </span>
      <span class="font-weight-bold" v-else-if="pageValue != 1"
        >Records
        {{ itemsPerPageValue * (pageValue - 1) + 1 }}
        to {{ itemsPerPageValue * pageValue }} of
        {{ totalItems }}
      </span>
    </div>
    <LabelSelect
      :id="`select_${id}`"
      class="align-self-center tableFooterSelect"
      label="Records per page"
      :isEditable="true"
      no-grid
      :options="rowsPerPageOptions"
      v-model="itemsPerPageValue"
      :clearable="false"
    >
    </LabelSelect>
    <div class="align-self-center">
      <v-row align="center">
        <v-col cols="3">
          <v-row align="center">
            <v-col cols="6" class="pa-0">
              <v-btn
                aria-label="tablePageBackToOne"
                :disabled="pageValue == 1 || itemsPerPageValue == -1"
                variant="plain"
                icon="fas fa-step-backward "
                @click="setPageValue(1)"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-btn
                aria-label="tablePageBackOne"
                :disabled="pageValue == 1 || itemsPerPageValue == -1"
                @click="setPageValue(pageValue - 1)"
                variant="plain"
                icon="fas fa-chevron-left"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" class="pt-2">
          <v-row align="center">
            <v-col
              v-if="itemsPerPageValue != -1"
              cols="12"
              justify-center
              class="pa-0"
              >Page {{ totalItems ? pageValue : 0 }} of
              {{ Math.ceil(totalItems / itemsPerPageValue) }}</v-col
            >
            <v-col v-else cols="12" justify-center class="pa-0"
              >Page {{ pageValue }} of 1
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row align="center">
            <v-col cols="6" class="pa-0">
              <v-btn
                aria-label="tablePageForwardToMaxPage"
                :disabled="
                  pageValue == Math.ceil(totalItems / itemsPerPageValue) ||
                  itemsPerPageValue == -1
                "
                @click="setPageValue(pageValue + 1)"
                variant="plain"
                icon="fas fa-chevron-right "
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-btn
                aria-label="tablePageForwardOne"
                :disabled="
                  pageValue == Math.ceil(totalItems / itemsPerPageValue) ||
                  itemsPerPageValue == -1
                "
                variant="plain"
                icon="fas fa-step-forward"
                @click="setPageValue(Math.ceil(totalItems / itemsPerPageValue))"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-row>
</template>
<script>
import LabelSelect from "@/components/common/LabelSelect.vue";
import { computed, ref, watch } from "vue";
export default {
  name: "DataTableFooter",
  props: {
    id: {},
    modelValue: {
      default: { itemsPerPage: 30, page: 1 },
    },
    expansionPanel: { type: Boolean, default: false },
    items: { default: [] },
    itemsLength: { default: null },
    updatePage: { default: false },
    rowsPerPageOptions: {
      default: [
        { title: "30", value: 30 },
        { title: "60", value: 60 },
        { title: "90", value: 90 },
        { title: "All", value: -1 },
      ],
    },
    showNoOfRecords: { type: Boolean, default: true },
    title: { default: "" },
  },
  setup(props, context) {
    let pageValue = ref(props.modelValue.page);
    let totalItems = ref();
    totalItems.value = props.itemsLength
      ? props.itemsLength
      : props.items?.length;

    const itemsPerPageValue = computed({
      get: () => props.modelValue.itemsPerPage,
      set: (value) => {
        let updateValue = {
          itemsPerPage: value,
          page: pageValue.value,
        };
        setPageValue(1);
        context.emit("update:modelValue", updateValue);
      },
    });
    function setPageValue(value) {
      pageValue.value = value;
      let updateValue = {
        itemsPerPage: itemsPerPageValue.value,
        page: pageValue.value,
      };
      context.emit("update:modelValue", updateValue);
    }

    watch(
      () => [props],
      () => {
        if (props.updatePage) {
          setPageValue(1);
        }
        totalItems.value = props.itemsLength
          ? props.itemsLength
          : props.items?.length;
      },
      { deep: true }
    );

    return {
      setPageValue,
      itemsPerPageValue,
      pageValue,
      totalItems,
    };
  },
  components: {
    LabelSelect,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.increaseContrast {
  filter: contrast(1.5);
}
.tableFooterSelect :deep(.v-field) {
  max-width: 85px !important;
}
.v-col {
  padding: 0 !important;
}
.v-row {
  margin: 0 !important;
}
.whiteText {
  color: c.$p-white;
}
</style>
