import { defineStore } from "pinia";
import { ref } from "vue";
import { SPN_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";

export const useStructurePlanStore = defineStore("structurePlan", () => {
  let pageReference = ref(null);
  let isDirty = ref(false);
  let showValidationSnackbar = ref(false);
  let addingOrEditingTable = ref(false);
  let reviewQue = ref(false);
  let isSearching = ref(false);
  let isSaving = ref(false);
  let errorMessage = ref(false);
  let addingStructurePlanDetail = ref(false);
  let passedSPN = ref(null);
  let passedStructureReqId = ref(null);
  let isAltDesign = ref(false);

  const getPageReference = () => {
    return pageReference.value;
  };
  const getIsDirty = () => {
    return isDirty.value;
  };
  const getShowValidationSnackbar = () => {
    return showValidationSnackbar.value;
  };
  const setPageReference = (value) => {
    pageReference.value = value;
  };
  const setIsDirty = (value) => {
    isDirty.value = value;
  };
  const setShowValidationSnackbar = (value) => {
    showValidationSnackbar.value = value;
  };
  const setIsSaving = (value) => {
    isSaving.value = value;
  };
  const getIsSaving = () => {
    return isSaving.value;
  };
  const setIsSearching = (value) => {
    isSearching.value = value;
  };
  const getIsSearching = () => {
    return isSearching.value;
  };
  const getErrorMessage = () => {
    return errorMessage.value;
  };
  const setErrorMessage = (value) => {
    errorMessage.value = value;
  };
  const getAddingStructurePlanDetail = () => {
    return addingStructurePlanDetail.value;
  };
  const setAddingStructurePlanDetail = (value) => {
    addingStructurePlanDetail.value = value;
  };
  const getAddingOrEditingTable = () => {
    return addingOrEditingTable.value;
  };
  const setAddingOrEditingTable = (value) => {
    addingOrEditingTable.value = value;
  };
  const getReviewQue = () => {
    return reviewQue.value;
  };
  const setReviewQue = (value) => {
    reviewQue.value = value;
  };
  const getPassedSPN = () => {
    return passedSPN.value;
  };
  const setPassedSPN = (value) => {
    passedSPN.value = value;
  };
  const getPassedStructureReqId = () => {
    return passedStructureReqId.value;
  };
  const setPassedStructureReqId = (value) => {
    passedStructureReqId.value = value;
  };
  const getIsAltDesign = () => {
    return isAltDesign.value;
  };
  const setIsAltDesign = (value) => {
    isAltDesign.value = value;
  };
  const getStructurePlanBySlNumber = async (
    slNumberPrefixSearch,
    slNumberSearch,
    slNumberSuffixSearch,
    showError
  ) => {
    let url = SPN_ENDPOINT.GET_STRUCTURE_PLAN_BY_SL_NUMBER;
    isSearching.value = true;
    return await axios
      .get(url, {
        params: {
          ...(slNumberPrefixSearch ? { prefix: slNumberPrefixSearch } : {}),
          ...(slNumberSuffixSearch ? { suffix: slNumberSuffixSearch } : {}),
          ...(slNumberSearch ? { planNum: slNumberSearch } : {}),
        },
      })
      .then((response) => {
        isSearching.value = false;
        return response.data.data;
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
        if (showError) {
          errorMessage.value = true;
        }
      });
  };
  const getSpnDetailByStructureRequestID = async (
    structureRequestIdSearch,
    showError
  ) => {
    let url = SPN_ENDPOINT.GET_STRUCTURE_PLAN_BY_STRUCTURE_REQUEST_ID.replace(
      "{structureReqId}",
      structureRequestIdSearch
    );
    isSearching.value = true;
    return await axios
      .get(url)
      .then((response) => {
        isSearching.value = false;
        return response.data.data;
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
        if (showError) {
          errorMessage.value = true;
        }
      });
  };
  const getMaxSuffixByPlanNumAndPrefix = async (planNum, prefix) => {
    let url = SPN_ENDPOINT.GET_MAX_SUFFIX_BY_PLAN_NUM_AND_PREFIX.replace(
      "{planNum}",
      planNum
    ).replace("{prefix}", prefix);
    isSearching.value = true;
    return await axios
      .get(url)
      .then((response) => {
        isSearching.value = false;
        return response.data.data;
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
      });
  };
  const getSPNsByParams = async (spnSearchInputPayload) => {
    let url = SPN_ENDPOINT.GET_STRUCTURE_PLANS_BY_PARAMS;
    isSearching.value = true;
    return await axios
      .post(url, spnSearchInputPayload)
      .then((response) => {
        isSearching.value = false;
        return response.data.data;
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
      });
  };

  const updateSPN = async (spnStructureReq) => {
    let url = SPN_ENDPOINT.STRUCTURE_PLAN_DEFFAULT;
    let returnValue = null;
    await axios
      .put(url, spnStructureReq)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update Structure Identification",
          response
        );
        returnValue = response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
    return returnValue;
  };

  const addSPN = async (spnStructureReq) => {
    let url = SPN_ENDPOINT.STRUCTURE_PLAN_DEFFAULT;
    let returnValue = null;
    await axios
      .post(url, spnStructureReq)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Add,
          LOGGER.EventCat.RequestResponse,
          "Add Structure Identification",
          response
        );
        returnValue = response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
    return returnValue;
  };

  const getLinkedStructureMetaDataByBrKey = async (brKey) => {
    let url = SPN_ENDPOINT.GET_LINKED_STRUCTURE_META_DATA_BY_BRKEY.replace(
      "{brkey}",
      brKey
    );
    isSearching.value = true;
    return await axios
      .get(url)
      .then((response) => {
        isSearching.value = false;
        return response.data.data;
      })
      .catch((e) => {
        isSearching.value = false;
        LOGGER.logException(e);
      });
  };

  return {
    getPassedStructureReqId,
    setPassedStructureReqId,
    getMaxSuffixByPlanNumAndPrefix,
    getIsAltDesign,
    setIsAltDesign,
    setPassedSPN,
    getPassedSPN,
    setReviewQue,
    getReviewQue,
    setAddingStructurePlanDetail,
    getAddingStructurePlanDetail,
    getLinkedStructureMetaDataByBrKey,
    setErrorMessage,
    getErrorMessage,
    setIsSearching,
    getIsSearching,
    setIsSaving,
    getIsSaving,
    addSPN,
    updateSPN,
    getSPNsByParams,
    getSpnDetailByStructureRequestID,
    getStructurePlanBySlNumber,
    getShowValidationSnackbar,
    setShowValidationSnackbar,
    isDirty,
    pageReference,
    setIsDirty,
    setPageReference,
    getIsDirty,
    getPageReference,
    getAddingOrEditingTable,
    setAddingOrEditingTable,
  };
});
