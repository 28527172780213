import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import useDateField from "@/composables/dateField.js";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { clone } from "@/util/clone";
import { convertToInt } from "@/composables/util";
import { METRIC_CONVERSION_TYPES } from "@/constants/InspectionConstants";

function setLoadRatingsTunnelDocDefinition(structure) {
  const configStore = useConfigStore();
  const inspectionStore = useInspectionStore();
  const { getFormattedDateStringNoTime } = useDateField();
  let docDefinition = {
    header: function () {
      return getReportHeader("Load Ratings Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Load Ratings Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Load Ratings Details",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "30%",
            text: "L.1 Rating Method: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.TUNNEL_LOAD_RATING_METHOD,
              structure?.T_Tunnel_Load_Rating?.RATING_METHOD
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "30%",
            text: "L.7 Posting Load - Type 3: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.T_Tunnel_Load_Rating?.TYPE3_POST_LOAD,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "30%",
            text: "L.2 Inventory Rating Factor: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.T_Tunnel_Load_Rating?.INV_LR_FACTOR,
            margin: [0, 5, 0, 0],
          },
          {
            width: "30%",
            text: "L.8 Posting Load - Type 3S2: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.T_Tunnel_Load_Rating?.TYPE3S2_POST_LOAD,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "30%",
            text: "L.3 Operating Rating Factor: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.T_Tunnel_Load_Rating?.OP_LR_FACTOR,
            margin: [0, 5, 0, 0],
          },
          {
            width: "30%",
            text: "L.9 Posting Load - Type 3-3: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.T_Tunnel_Load_Rating?.TYPE3_3_POST_LOAD,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "30%",
            text: "L.4 Load Posting Status: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.POSTING_STATUS,
              structure?.T_Posting?.[structure.T_Posting.length - 1]
                ?.POST_STATUS
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "30%",
            text: "L.10 Height Restriction: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.YES_NO,
              structure?.T_Tunnel_Load_Rating?.HEIGHT_RESTR
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "30%",
            text: "L.5 Posting Load - Gross: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.T_Tunnel_Load_Rating?.GROSS_POST_LOAD,
            margin: [0, 5, 0, 0],
          },
          {
            width: "30%",
            text: "L.11 Hazardous Material Restriction: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.YES_NO,
              structure?.T_Tunnel_Load_Rating?.HAZMAT_RESTR
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "30%",
            text: "L.6 Posting Load - Axle: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.T_Tunnel_Load_Rating?.AXLE_POST_LOAD,
            margin: [0, 5, 0, 0],
          },
          {
            width: "30%",
            text: "L.12 Other Restrictions: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.YES_NO,
              structure?.T_Tunnel_Load_Rating?.OTHER_RESTR
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Posting History",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  docDefinition.content.push({
    table: {
      widths: ["*", "*", "*", "15%", "18%", "*"],
      headerRows: 1,
      body: getPostingHistoryTable(
        configStore,
        structure,
        getFormattedDateStringNoTime,
        inspectionStore
      ),
    },
    margin: [0, 5, 0, 0],
  });
  return docDefinition;
}
function setLoadRatingsDocDefinition(structure) {
  const inspectionStore = useInspectionStore();
  const configStore = useConfigStore();
  const { getFormattedDateStringNoTime } = useDateField();
  const getFloatValue = (value) => {
    //replace commas in the pasted value so parsefloat will work
    value = value?.toString()?.replace(/,/g, "");
    return parseFloat(value) || parseFloat(value) === 0
      ? parseFloat(value)
      : null;
  };

  const isRF = (ratingAnalysisMethod, loadType, isNBI) => {
    if (
      ratingAnalysisMethod == "8" ||
      ratingAnalysisMethod == "D" ||
      ratingAnalysisMethod == "E" ||
      ratingAnalysisMethod == "F"
    ) {
      return true;
    } else if (isNBI && ratingAnalysisMethod == "6" && loadType == "D") {
      return true;
    } else {
      return false;
    }
  };

  let docDefinition = {
    header: function () {
      return getReportHeader("Load Ratings Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Load Ratings Page" },
      {
        columns: [
          {
            width: "27%",
            text: "IR01a Load Rating Rev. Recom'd: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "2%",
            text: inspectionStore.getReportCheckbox(
              structure?.UserInsp?.RECOMMEND_RATING_REVIEW
            ),
            margin: [0, 5, 0, 0],
            style: "customFontAwesome",
          },
          {
            width: "21%",
            text: "IR01b Reviewer Action: ",
            bold: true,
            margin: [5, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.REVIEWER_ACTION,
              structure?.UserInsp?.LR_REVIEW_ACTION
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "23%",
            text: "VP11 Posting Rev. Recom'd: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "2%",
            text: inspectionStore.getReportCheckbox(
              structure?.UserInsp?.POST_REVIEW_REC
            ),
            style: "customFontAwesome",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "45%",
            text: "Posting Review Recommended Summary: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "5%",
            text: "",
            margin: [0, 5, 0, 0],
          },
          {
            width: "45%",
            text: "Previous Posting Review Recommended Summary: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "45%",
            text: {
              text: structure?.T_Insp_Comment_Prev?.find(
                (element) =>
                  element?.COMMENT_TYPE ==
                  INSPECTION_COMMENTS.LOAD_RATING_POSTING_RECOMMENDED_SUMMARY
              )?.NOTES,
            },
          },
          {
            width: "5%",
            text: {
              text: "",
            },
          },
          {
            width: "*",
            text: {
              text: structure?.T_Insp_Comment_Prev?.find(
                (element) =>
                  element?.COMMENT_TYPE ==
                  INSPECTION_COMMENTS.LOAD_RATING_POSTING_RECOMMENDED_SUMMARY
              )?.NOTES,
            },
          },
        ],
      },
      {
        columns: [
          {
            width: "45%",
            text: "Inspection Team Notes: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "5%",
            text: "",
            margin: [0, 5, 0, 0],
          },
          {
            width: "45%",
            text: "Previous Inspection Team Notes: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "45%",
            text: {
              text: structure?.T_Insp_Comment?.find(
                (element) =>
                  element?.COMMENT_TYPE ==
                  INSPECTION_COMMENTS.REVIEW_RECOMMENDED_DUE_TO_COMMENTS
              )?.NOTES,
            },
          },
          {
            width: "5%",
            text: {
              text: "",
            },
          },
          {
            width: "*",
            text: {
              text: structure?.T_Insp_Comment_Prev?.find(
                (element) =>
                  element?.COMMENT_TYPE ==
                  INSPECTION_COMMENTS.REVIEW_RECOMMENDED_DUE_TO_COMMENTS
              )?.NOTES,
            },
          },
        ],
      },
      {
        columns: [
          {
            width: "45%",
            text: {
              text: "Load Rating Engineer Notes: ",
            },
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "5%",
            text: "",
            margin: [0, 5, 0, 0],
          },
          {
            width: "45%",
            text: {
              text: "Previous Load Rating Engineer Notes: ",
            },
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "45%",
            text: structure?.T_Insp_Comment?.find(
              (element) =>
                element?.COMMENT_TYPE == INSPECTION_COMMENTS.LOAD_RATING_REVIEW
            )?.NOTES,
          },
          {
            width: "5%",
            text: {
              text: "",
            },
          },
          {
            width: "*",
            text: {
              text: structure?.T_Insp_Comment_Prev?.find(
                (element) =>
                  element?.COMMENT_TYPE ==
                  INSPECTION_COMMENTS.LOAD_RATING_REVIEW
              )?.NOTES,
            },
          },
        ],
      },
      {
        columns: [
          {
            width: "19%",
            text: "IR03 Calculation Date: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "31%",
            text: getFormattedDateStringNoTime(
              structure?.UserInsp?.RATING_DATE
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "31%",
            text: "IR02 Assigned Rating Approval Date: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: getFormattedDateStringNoTime(
              structure?.UserInsp?.RATING_APPR_DATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "35%",
            text: "IR02a Assigned Rating Approval Engineer: ",
            bold: true,
            margin: [0, 5, 0, 5],
          },
          {
            width: "*",
            text: structure?.UserInsp?.APPROVER_NAME,
            margin: [0, 5, 0, 5],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Load Ratings Details",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };

  const detailsValue =
    getDetails(structure)?.sort((a, b) =>
      a?.LOAD_TYPE?.localeCompare(b?.LOAD_TYPE)
    ) || [];

  let loadRatings = [];
  loadRatings.push([
    {
      text: "IR04\n  Load\n Type",
      style: ["tableHeader"],
    },
    {
      text: "IR05\n NBI\n",
      style: ["tableHeader"],
    },
    {
      text: "IR06\n Load Rating\n Method",
      style: ["tableHeader"],
    },
    {
      text: "IR10\n IR\n Tons",
      style: ["tableHeader"],
    },
    {
      text: "IR11\n OR\n Tons",
      style: ["tableHeader"],
    },
    {
      text: "IR11a\n SLC\n Tons",
      style: ["tableHeader"],
    },
    {
      text: "IR20\n IR\n RF",
      style: ["tableHeader"],
    },
    {
      text: "IR21\n OR\n RF",
      style: ["tableHeader"],
    },
    {
      text: "IR17\n Rating Dataset\n",
      style: ["tableHeader"],
    },
  ]);
  for (const element of detailsValue) {
    loadRatings = updateLoadRatings(
      loadRatings,
      element,
      configStore,
      getFormattedDateStringNoTime
    );
  }
  docDefinition.content.push({
    table: {
      headerRows: 1,
      body: loadRatings,
    },
    margin: [0, 5, 0, 0],
  });

  docDefinition.content.push(
    {
      table: {
        headerRows: 0,
        widths: ["100%"],
        body: [
          [
            {
              text: "NBI & Alternate Ratings",
              fillColor: "#ebebeb",
              alignment: "center",
              style: ["sectionHeader"],
            },
          ],
        ],
      },
      layout: "noBorders",
      margin: [0, 5, 0, 5],
    },
    {
      columns: [
        {
          width: "*",
          text: "NBI: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "B.LR.01 Design Load: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.DESIGN_LOAD,
            structure?.Bridge?.DESIGNLOAD
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "23%",
          text: "4B02/B.LR.03/X Rating Date: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: structure?.nbiAndAlternateRating?.inspectionDate,
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "4B07/B.LR.05 Inventory Rating: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: isRF(
            structure?.nbiAndAlternateRating.nbi1RatingAnalysisMethod,
            structure?.nbiAndAlternateRating.nbi1LoadType,
            true
          )
            ? getFloatValue(
                structure?.nbiAndAlternateRating?.nbi1IRLoadFactor
              )?.toFixed(2)
            : getFloatValue(
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METRICTON_TO_TON,
                  structure?.nbiAndAlternateRating?.nbi1IRLoad,
                  ROUNDING_FACTOR.ROUND,
                  false,
                  null
                )
              )?.toFixed(0) + " Tons",
          margin: [0, 3, 0, 0],
        },
        {
          width: "23%",
          text: "4B04/B.LR.04 Operating Rating Method: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: structure?.nbiAndAlternateRating?.nbi1OperatingRatingMethod,
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "4B05/B.LR.06 Operating Rating: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: isRF(
            structure?.nbiAndAlternateRating?.nbi1RatingAnalysisMethod,
            structure?.nbiAndAlternateRating?.nbi1LoadType,
            true
          )
            ? getFloatValue(
                structure?.nbiAndAlternateRating?.nbi1ORLoadFactor
              )?.toFixed(2)
            : getFloatValue(
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METRICTON_TO_TON,
                  structure?.nbiAndAlternateRating?.nbi1ORLoad,
                  ROUNDING_FACTOR.ROUND,
                  false,
                  null
                )
              )?.toFixed(0) + " Tons",
          margin: [0, 3, 0, 0],
        },
        {
          width: "23%",
          text: "B.LR.02 Design Method: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.DESIGN_METHOD,
            structure?.Bridge?.DESIGN_METHOD
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "B.LR.07 Controlling Legal Load Rating Factor: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: structure?.nbiAndAlternateRating
            ?.controllingLegalLoadRatingFactor,
          margin: [0, 3, 0, 0],
        },
        {
          width: "23%",
          text: "B.LR.08 Routine Permit Loads: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.ROUTINE_PERMIT_LOADS,
            structure?.Bridge?.ROUTINE_PERMIT_LOADS
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: "Alternate: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "4B11 H20 Inv. Rating: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: isRF(
            structure?.nbiAndAlternateRating?.nbi1RatingAnalysisMethod,
            structure?.nbiAndAlternateRating?.h20LoadType,
            true
          )
            ? getFloatValue(
                structure?.nbiAndAlternateRating?.h20IRLoadFactor
              )?.toFixed(2)
            : getFloatValue(
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METRICTON_TO_TON,
                  structure?.nbiAndAlternateRating?.h20IRLoad,
                  ROUNDING_FACTOR.ROUND,
                  false,
                  null
                )
              )?.toFixed(0) + " Tons",
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "4B09 H20 Oper. Rating: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: isRF(
            structure?.nbiAndAlternateRating?.nbi1RatingAnalysisMethod,
            structure?.nbiAndAlternateRating?.h20LoadType,
            true
          )
            ? getFloatValue(
                structure?.nbiAndAlternateRating?.h20ORLoadFactor
              )?.toFixed(2)
            : getFloatValue(
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METRICTON_TO_TON,
                  structure?.nbiAndAlternateRating?.h20ORLoad,
                  ROUNDING_FACTOR.ROUND,
                  false,
                  null
                )
              )?.toFixed(0) + " Tons",
          margin: [0, 3, 0, 0],
        },
        {
          width: "23%",
          text: "4B08 H20 Oper. Type: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.LOAD_RATING_METHOD,
            structure?.nbiAndAlternateRating?.h20RatingAnalysisMethod
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "4B12 ML80 Inv. Rating: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: isRF(
            structure?.nbiAndAlternateRating?.nbi1RatingAnalysisMethod,
            structure?.nbiAndAlternateRating?.ml80LoadType,
            true
          )
            ? getFloatValue(
                structure?.nbiAndAlternateRating?.ml80IRLoadFactor
              )?.toFixed(2)
            : getFloatValue(
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METRICTON_TO_TON,
                  structure?.nbiAndAlternateRating?.ml80IRLoad,
                  ROUNDING_FACTOR.ROUND,
                  false,
                  null
                )
              )?.toFixed(0) + " Tons",
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "4B12a ML80 Oper. Rating: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: isRF(
            structure?.nbiAndAlternateRating?.nbi1RatingAnalysisMethod,
            structure?.nbiAndAlternateRating?.ml80LoadType,
            true
          )
            ? getFloatValue(
                structure?.nbiAndAlternateRating?.ml80ORLoadFactor
              )?.toFixed(2)
            : getFloatValue(
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METRICTON_TO_TON,
                  structure?.nbiAndAlternateRating?.ml80ORLoad,
                  ROUNDING_FACTOR.ROUND,
                  false,
                  null
                )
              )?.toFixed(0) + " Tons",
          margin: [0, 3, 0, 0],
        },
        {
          width: "23%",
          text: "4B12c ML80 Oper. Type: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.LOAD_RATING_METHOD,
            structure?.nbiAndAlternateRating?.ml80RatingAnalysisMethod
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "4B13 TK527 Inv. Rating: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: isRF(
            structure?.nbiAndAlternateRating?.nbi1RatingAnalysisMethod,
            structure?.nbiAndAlternateRating?.tk527LoadType,
            true
          )
            ? getFloatValue(
                structure?.nbiAndAlternateRating?.tk527IRLoadFactor
              )?.toFixed(2)
            : getFloatValue(
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METRICTON_TO_TON,
                  structure?.nbiAndAlternateRating?.tk527IRLoad,
                  ROUNDING_FACTOR.ROUND,
                  false,
                  null
                )
              )?.toFixed(0) + " Tons",
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "23%",
          text: "4B13a TK527 Oper. Rating: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: isRF(
            structure?.nbiAndAlternateRating?.nbi1RatingAnalysisMethod,
            structure?.nbiAndAlternateRating?.tk527LoadType,
            true
          )
            ? getFloatValue(
                structure?.nbiAndAlternateRating?.tk527ORLoadFactor
              )?.toFixed(2)
            : getFloatValue(
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METRICTON_TO_TON,
                  structure?.nbiAndAlternateRating?.tk527ORLoad,
                  ROUNDING_FACTOR.ROUND,
                  false,
                  null
                )
              )?.toFixed(0) + " Tons",
          margin: [0, 3, 0, 0],
        },
        {
          width: "23%",
          text: "4B13c TK527 Oper. Type: ",
          bold: true,
          margin: [3, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.LOAD_RATING_METHOD,
            structure?.nbiAndAlternateRating?.tk527RatingAnalysisMethod
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      table: {
        headerRows: 0,
        widths: ["100%"],
        body: [
          [
            {
              text: "Posting History",
              fillColor: "#ebebeb",
              alignment: "center",
              style: ["sectionHeader"],
            },
          ],
        ],
      },
      layout: "noBorders",
      margin: [0, 5, 0, 0],
    }
  );

  docDefinition.content.push({
    table: {
      widths: ["11%", "*", "*", "11%", "13%", "*"],
      headerRows: 1,
      body: getPostingHistoryTable(
        configStore,
        structure,
        getFormattedDateStringNoTime,
        inspectionStore
      ),
    },
    margin: [0, 5, 0, 0],
  });

  return docDefinition;
}
function getDetails(structure) {
  let detailsValue = [];
  if (!structure?.T_Rating_Load?.[0]) {
    structure.T_Rating_Load = structure?.T_Rating_Load?.filter((a) => !!a);
  }
  detailsValue = clone(structure.T_Rating_Load);
  sortDetails(detailsValue);
  return detailsValue;
}
function sortDetails(arr) {
  arr.value = arr.value?.sort(function (a, b) {
    return (
      convertToInt(a?.LOAD_TYPE) - convertToInt(b?.LOAD_TYPE) ||
      convertToInt(a?.NBI_RATING_IND) - convertToInt(b?.NBI_RATING_IND)
    );
  });
}
function getPostingHistoryTable(
  configStore,
  structure,
  getFormattedDateStringNoTime,
  inspectionStore
) {
  let postingHistory = clone(structure?.T_Posting) || [];
  postingHistory?.sort(function (a, b) {
    return new Date(b?.POST_STATUS_DATE) - new Date(a?.POST_STATUS_DATE);
  });
  let postingHistoryTable = [];
  postingHistoryTable.push([
    {
      text: "VP01\n  Status\n Date",
      style: ["tableHeader"],
    },
    {
      text: "VP02\n Posting\n Status",
      style: ["tableHeader"],
    },
    {
      text: "VP03\n Special Restr.\n Posting",
      style: ["tableHeader"],
    },
    {
      text: "VP04\n Posted Wt.\n Limit (Tons)",
      style: ["tableHeader"],
    },
    {
      text: "VP05\n Posted Limit\n Comb. (Tons)",
      style: ["tableHeader"],
    },
    {
      text: "VP06\n Posting\n Reason",
      style: ["tableHeader"],
    },
  ]);
  for (const element of postingHistory) {
    postingHistoryTable.push([
      {
        text: getFormattedDateStringNoTime(element?.POST_STATUS_DATE),
        style: ["tableBody"],
      },
      {
        text: element?.POST_STATUS
          ? configStore.getReferenceValue(
              REFERENCE_TABLE.POSTING_STATUS,
              element?.POST_STATUS
            )
          : "",
        style: ["tableBody"],
      },
      {
        text: element?.SPEC_RESTRICT_POST
          ? configStore.getReferenceValue(
              REFERENCE_TABLE.SPECIAL_RESTRICT_POST,
              element?.SPEC_RESTRICT_POST
            )
          : "",
        style: ["tableBody"],
      },
      {
        text: configStore.getMetricToEnglishConversions(
          PAIR_CODE.METRICTON_TO_TON,
          element?.POST_LIMIT_WEIGHT,
          ROUNDING_FACTOR.ROUND
        ),
        style: ["tableBody"],
      },
      {
        text: configStore.getMetricToEnglishConversions(
          PAIR_CODE.METRICTON_TO_TON,
          element?.POST_LIMIT_COMB,
          ROUNDING_FACTOR.ROUND
        ),
        style: ["tableBody"],
      },
      {
        text: element?.POST_REASON
          ? configStore.getReferenceValue(
              REFERENCE_TABLE.POST_REASON,
              element?.POST_REASON
            )
          : "",
        style: ["tableBody"],
      },
    ]);
    postingHistoryTable.push([
      {
        colSpan: 6,
        stack: [
          {
            columns: [
              {
                table: {
                  widths: ["50%", "*"],
                  body: [
                    [
                      {
                        text: "VP01 Status Date: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: getFormattedDateStringNoTime(
                          element?.POST_STATUS_DATE
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP02 Posting Status: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.POSTING_STATUS,
                          element?.POST_STATUS
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP02a Posting Type: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.POSTING_TYPE,
                          element?.SNBI_POST_STATUS
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP02b Posting Condition: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.POSTING_CONDITION,
                          element?.SNBI_POST_CONDITION
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP03 Special Restr. Posting: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.SPECIAL_RESTRICT_POST,
                          element?.SPEC_RESTRICT_POST
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP04 Posted Wt. Limit: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text:
                          configStore.getMetricToEnglishConversions(
                            PAIR_CODE.METRICTON_TO_TON,
                            element?.POST_LIMIT_WEIGHT,
                            ROUNDING_FACTOR.ROUND,
                            null,
                            [METRIC_CONVERSION_TYPES.L3]
                          ) + " Tons",
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP05 Posted Limit Comb.: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text:
                          configStore.getMetricToEnglishConversions(
                            PAIR_CODE.METRICTON_TO_TON,
                            element?.POST_LIMIT_COMB,
                            ROUNDING_FACTOR.ROUND,
                            null,
                            [METRIC_CONVERSION_TYPES.L3]
                          ) + " Tons",
                        margin: [0, 3, 0, 0],
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
              {
                table: {
                  widths: ["50%", "*"],
                  body: [
                    [
                      {
                        text: "VP06 Posting Reason: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.POST_REASON,
                          element?.POST_REASON
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP07 Field Conditions: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.FIELD_COND,
                          element?.FIELD_COND
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP08 Special Conditions: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.SPECIAL_CONDITIONS,
                          element?.SPEC_COND
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP09 AASHTO Impact Code: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.AASHTO_IMPACT_CODE,
                          element?.IMPACT
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "VP12 Active: ",
                        bold: true,
                        margin: [3, 3, 0, 0],
                      },
                      {
                        text: inspectionStore.getReportCheckbox(
                          element?.ACTIVE
                        ),
                        margin: [0, 5, 0, 0],
                        style: "customFontAwesome",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          },
        ],
      },
    ]);
  }
  return postingHistoryTable;
}
function updateLoadRatings(
  loadRatings,
  element,
  configStore,
  getFormattedDateStringNoTime
) {
  loadRatings.push([
    {
      text: element?.LOAD_TYPE
        ? configStore.getReferenceValue(
            REFERENCE_TABLE.LOAD_TYPE,
            element?.LOAD_TYPE
          )
        : "",
      style: ["tableBody"],
    },

    {
      text: element?.NBI_RATING_IND
        ? configStore.getReferenceValue(
            REFERENCE_TABLE.NBI_RATING,
            element?.NBI_RATING_IND
          )
        : "",
      style: ["tableBody"],
    },
    {
      text: element?.RATING_ANALYSIS_METHOD
        ? configStore.getReferenceValue(
            REFERENCE_TABLE.LOAD_RATING_METHOD,
            element?.RATING_ANALYSIS_METHOD
          )
        : "",
      style: ["tableBody"],
    },
    {
      text: configStore.getMetricToEnglishConversions(
        PAIR_CODE.METRICTON_TO_TON,
        element?.IRLOAD,
        ROUNDING_FACTOR.ROUND
      ),
      style: ["tableBody"],
    },
    {
      text: configStore.getMetricToEnglishConversions(
        PAIR_CODE.METRICTON_TO_TON,
        element?.ORLOAD,
        ROUNDING_FACTOR.ROUND
      ),
      style: ["tableBody"],
    },
    {
      text: configStore.getMetricToEnglishConversions(
        PAIR_CODE.METRICTON_TO_TON,
        element?.SLC_RATING,
        ROUNDING_FACTOR.ROUND,
        null,
        [METRIC_CONVERSION_TYPES.W1]
      ),
      style: ["tableBody"],
    },
    {
      text: parseFloat(element?.IR_LOAD_FACTOR)
        ? parseFloat(element?.IR_LOAD_FACTOR)?.toFixed(2)
        : element?.IR_LOAD_FACTOR,
      style: ["tableBody"],
    },
    {
      text: parseFloat(element?.OR_LOAD_FACTOR)
        ? parseFloat(element?.OR_LOAD_FACTOR)?.toFixed(2)
        : element?.OR_LOAD_FACTOR,
      style: ["tableBody"],
    },
    {
      text: element?.SUPPORT_DATASET,
      style: ["tableBody"],
    },
  ]);
  loadRatings.push([
    {
      colSpan: 9,
      stack: [
        {
          columns: [
            {
              table: {
                body: [
                  [
                    {
                      text: "IR03 Calculation Date: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: getFormattedDateStringNoTime(element?.RATING_DATE),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR04 Load Type: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.LOAD_TYPE,
                        element?.LOAD_TYPE
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR05 NBI: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.NBI_RATING,
                        element?.NBI_RATING_IND
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR06 Load Rating Method: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.LOAD_RATING_METHOD,
                        element?.RATING_ANALYSIS_METHOD
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR07 Ctrl Member Type: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.CONTROLLING_MEMBER_TYPE,
                        element?.CONTROL_MEM_TYPE
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR08 Fatigue Stress Category: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.CONTROLLING_MEMBER_FATIGUE_STRESS_CATEGORY,
                        element?.FATIG_STRESS_CAT
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR09 Fatigue Load Type: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.FATIGUE_LOAD_TYPE,
                        element?.FATIG_LOAD_TYPE
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR14 AASHTO Man Yr: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: element?.AASHTO_MANUAL_YEAR,
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR15 AASHTO Spec Yr: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: element?.AASHTO_SPEC_YEAR,
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR16 Engineer: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: element?.ANALYSIS_ENGINEER,
                      margin: [0, 3, 0, 0],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              table: {
                widths: ["60%", "*"],
                body: [
                  [
                    {
                      text: "IR10 Inventory Rating: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text:
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          element?.IRLOAD,
                          ROUNDING_FACTOR.ROUND,
                          null,
                          [METRIC_CONVERSION_TYPES.L3]
                        ) + " Tons",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR11 Operating Rating: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text:
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          element?.ORLOAD,
                          ROUNDING_FACTOR.ROUND,
                          null,
                          [METRIC_CONVERSION_TYPES.L3]
                        ) + " Tons",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR11a SLC Rating: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text:
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          element?.SLC_RATING,
                          ROUNDING_FACTOR.ROUND,
                          null,
                          [METRIC_CONVERSION_TYPES.L3]
                        ) + " Tons",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR20 IR Rating Factor: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: element?.IR_LOAD_FACTOR
                        ? parseFloat(element?.IR_LOAD_FACTOR)?.toFixed(2)
                        : "",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR21 OR Rating Factor: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: element?.OR_LOAD_FACTOR
                        ? parseFloat(element?.OR_LOAD_FACTOR)?.toFixed(2)
                        : "",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR12 Govern Crit Inventory: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.GOVERNING_CRITERIA,
                        element?.INV_RATING_STRESS_METHOD
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR13 Govern Crit Operating: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.GOVERNING_CRITERIA,
                        element?.OPR_RATING_STRESS_METHOD
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR18 Stress Range: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text:
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.MPA_TO_KSI,
                          element?.STRESS_RANGE,
                          ROUNDING_FACTOR.TENTH,
                          null,
                          [METRIC_CONVERSION_TYPES.L3]
                        ) + " ksi",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "IR22 Legal Load Rating Factor: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: element?.LEGAL_LOAD_RF
                        ? parseFloat(element?.LEGAL_LOAD_RF)?.toFixed(2)
                        : "",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "B.EP.03 Vehicle Posting Type: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.VEHICLE_POSTING_TYPE,
                        element?.VEH_POST_TYPE
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "B.EP.04 Vehicle Posting Value: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text:
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METRICTON_TO_TON,
                          element?.VEH_POST_WEIGHT,
                          ROUNDING_FACTOR.ROUND,
                          null,
                          [METRIC_CONVERSION_TYPES.L3]
                        ) + " Tons",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
        },
        {
          colSpan: 9,
          columns: [
            [
              {
                text: "IR17 Rating Dataset: ",
                bold: true,
                margin: [0, 3, 0, 0],
              },
              {
                text: element?.SUPPORT_DATASET,
              },
              {
                text: "IR19 Notes: ",
                bold: true,
                margin: [0, 3, 0, 0],
              },
              {
                text: element?.NOTES,
              },
            ],
          ],
        },
      ],
    },
  ]);
  return loadRatings;
}
export { setLoadRatingsDocDefinition, setLoadRatingsTunnelDocDefinition };
