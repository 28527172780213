export const OTHER_LINKS_PAGES = {
  BULLETIN_BOARD: "Bulletin Board",
  LINK_MAINTENANCE: "Link Maintenance",
  SEARCH_STRUCTURE: "Structure Search",
  INSPECTION_REPORT_GENERATOR: "Inspection Rpt Generator",
  REPORTS: "Reports",
  RISK_SCORE_SEARCH: "Risk Score Search",
};
export const OTHER_LINKS_PAGE_PARAMS = {
  BULLETIN_BOARD: "bulletinBoard",
  LINK_MAINTENANCE: "linkMaintenance",
  SEARCH_STRUCTURE: "structureSearch",
  INSPECTION_REPORT_GENERATOR: "inspectionReportGenerator",
  REPORTS: "reports",
  RISK_SCORE_SEARCH: "riskScoreSearch",
};

export const DOCUMENT_TYPES = {
  LOADING_RATING_ANALYSIS: 55,
  INSPECTION_SUMMARY: 56,
  COMPLETED_REPORT: 57,
  CORRESPONDANCE: 59,
  IFROMS_BMS3_REPORT: 62,
  D491: 63,
  INSPECTION_PHOTO: 66,
  DEFECT_SKETCHES: 67,
  LOCATION_MAP: 69,
  FATIGUE_ANALYSIS: 77,
  PRIORITY_LETTER: 83,
  ENGINEERING_JUDGEMENT_RATING_FORM: 85,
  FATIGUE_AND_FRACTURE_PLAN: 94,
  ASSEMBLED_PLAN_SET: 235,
  AS_BUILT_DRAWING: 236,
  EXITING_STRUCTURE_PLAN: 241,
  SHOP_DRAWING: 255,
  SCOUR_POA: 262,
  WATERWAY_SKETCHES: 263,
  WATERWAY_CROSS_SECTION: 264,
  ASSIGNED_LOAD_RATING_APPROVAL_FORM: 269,
  EXECUTIVE_SUMMARY: 270,
  GENERAL_CONDITIONS: 271,
  RECOMMENDATIONS: 272,
  WATERWAY_PROBES_AND_SOUNDINGS: 273,
  WATERWAY_COUTOUR_MAPS: 274,
  WATERWAY_TEMPLATES_XLS: 275,
  BEARING_MEASUREMENTS: 276,
  BEARING_TEMPLATES_XLS: 277,
  TEST_RESULTS: 278,
};
export const OTHER_LINKS_ROUTES = {
  INSPECTION_REPORT_GENERATOR: "/otherLinks/inspectionReportGenerator",
  BULLETIN_BOARD_ROUTE: "/otherLinks/bulletinBoard",
  LINK_MAINTENANCE_ROUTE: "/otherLinks/linkMaintenance",
  SEARCH_STRUCTURE_ROUTE: "/otherLinks/structureSearch",
  REPORTS_ROUTE: "/otherLinks/reports",
  RISK_SCORE_SEARCH_ROUTE: "/otherLinks/riskScoreSearch",
};
