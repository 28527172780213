<template>
  <div v-if="!substructureFatigueTable" class="mt-4">
    <v-row v-if="$vuetify?.display?.xlAndUp">
      <v-col lg="4">
        <LabelSelect
          noGrid
          id="fatigueDetail"
          label="IF07 Fatigue Details"
          v-model="fatigueDetail"
          :refTable="REFERENCE_TABLE.FATIGUE_DETAILS"
      /></v-col>
      <v-col lg="4">
        <LabelSelect
          noGrid
          id="nstms"
          :showColon="false"
          label="IF08 NSTM's?"
          v-model="structure.UserInsp.NSTM_PRESENT"
          :refTable="REFERENCE_TABLE.NSTM_PRESENT"
      /></v-col>
      <v-col lg="4">
        <LabelSelect
          noGrid
          id="complex"
          :showColon="false"
          label="B.IR.04 Complex Feature?"
          v-model="structure.UserInsp.COMPLEX_FEATURE_PRESENT"
          :refTable="REFERENCE_TABLE.COMPLEX_FEATURE"
      /></v-col>
    </v-row>
    <v-row class="denseRow" v-else>
      <LabelSelect
        noGrid
        id="fatigueDetail"
        label="IF07 Fatigue Details"
        v-model="fatigueDetail"
        :refTable="REFERENCE_TABLE.FATIGUE_DETAILS"
      />&nbsp;
      <LabelSelect
        noGrid
        id="nstms"
        :showColon="false"
        label="IF08 NSTM's?"
        v-model="structure.UserInsp.NSTM_PRESENT"
        :refTable="REFERENCE_TABLE.NSTM_PRESENT"
      />&nbsp;
      <LabelSelect
        noGrid
        id="complex"
        :showColon="false"
        label="B.IR.04 Complex Feature?"
        v-model="structure.UserInsp.COMPLEX_FEATURE_PRESENT"
        :refTable="REFERENCE_TABLE.COMPLEX_FEATURE"
      />
    </v-row>
    <v-row>
      <v-col class="px-0">
        <v-btn
          :disabled="
            !isEditable || expandedRows?.length > 0 || locations?.length <= 0
          "
          id="btn_addDetails"
          variant="outlined"
          class="mb-2"
          @click="addDetailsFunction()"
          >Add Details</v-btn
        >
        <IconTooltip
          id="icon_noLocationsAvailable"
          icon="fas fa-circle-info"
          iconclass="infoIcon"
          :tooltip="INSPECTION_MESSAGES.NO_FATIGUE_LOCATIONS_AVAILABLE_TO_ADD"
          v-if="isEditable && locations?.length <= 0"
        />
      </v-col>
    </v-row>
  </div>
  <v-row v-if="$vuetify?.display?.mdAndDown">
    <v-data-table
      id="nstm-data-table"
      v-model:page="pageInspection.page"
      :items-per-page="pageInspection.itemsPerPage"
      v-model:expanded="expandedRows"
      :headers="headersSmall"
      :items="fcDetails"
      class="compactTable padding-xs detailTable"
      v-model:sort-by="sortBy"
      hide-default-footer
      must-sort
      show-expand
      return-object
      @update:sort-by="nstmFooter.setPageValue(1)"
    >
      <template v-slot:[`header.data-table-expand`]="{ column }">
        {{ column.title }}
      </template>
      <template v-slot:[`header.FC_KEY`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="3" />
      </template>
      <template v-slot:[`header.STRUNITKEY`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="3" />
      </template>
      <template v-slot:[`header.FC_MEM_TYPE`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="3" />
      </template>
      <template
        v-if="!substructureFatigueTable"
        v-slot:[`header.FC_MEM`]="{ column }"
      >
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="3" />
      </template>
      <template v-slot:[`header.FC_DETAIL`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="3" />
      </template>
      <template v-slot:[`header.FATIG_STRESS_CAT`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="3" />
      </template>
      <template v-slot:[`header.NSTM_DETAIL_PRESENT`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="3" />
      </template>
      <template v-slot:[`header.FC_DESC`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="3" />
      </template>
      <template
        v-if="!substructureFatigueTable"
        v-slot:[`header.action`]="{ column }"
      >
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="3" />
      </template>

      <template v-slot:expanded-row="{ item }">
        <td :colspan="headers.length">
          <v-form
            ref="fcDetailForm"
            v-model="validDetails"
            class="mt-2"
            @change="changesDetected = true"
          >
            <v-row>
              <v-col cols="12">
                <LabelText
                  labelXl="4"
                  labelLg="4"
                  labelMd="6"
                  labelsm="6"
                  textXl="6"
                  textLg="6"
                  textMd="6"
                  textSm="6"
                  id="fcKey"
                  label="IF00 Key"
                  v-model="item.FC_KEY"
                  type="number" />
                <LabelSelect
                  labelXl="4"
                  labelLg="4"
                  labelMd="6"
                  labelsm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="fcLocation"
                  label="IF01 Location"
                  :isEditable="isEditable && item.isAdd"
                  v-model="item.STRUNITKEY"
                  :options="locations"
                  isRequired
                  @update:modelValue="changesDetected = true" />
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  selectXl="7"
                  selectLg="7"
                  selectMd="6"
                  selectSm="6"
                  id="fcMemberType"
                  label="IF02 Member Type"
                  :isEditable="isEditable && !item.readOnly"
                  :refTable="REFERENCE_TABLE.FRACTURE_CRITICAL_MEMBER_TYPE"
                  v-model="item.FC_MEM_TYPE"
                  isRequired
                  @update:modelValue="changesDetected = true" />
                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  textXl="7"
                  textLg="7"
                  textMd="6"
                  textSm="6"
                  id="memberDetail"
                  label="IF04 Member Detail"
                  v-model="item.FC_DETAIL"
                  type="textarea"
                  counter="50"
                  maxlength="50"
                  persistentCounter
                  isRequired
                  :isEditable="isEditable && !item.readOnly" />
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  selectXl="7"
                  selectLg="7"
                  selectMd="6"
                  selectSm="6"
                  id="memberStressCat"
                  label="IF05 Member Stress Category"
                  :isEditable="isEditable && !item.readOnly"
                  :options="fractureCriticalFatigueStressCategory"
                  v-model="item.FATIG_STRESS_CAT"
                  isRequired
                  @update:modelValue="changesDetected = true" />
                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  textXl="7"
                  textLg="7"
                  textMd="6"
                  textSm="6"
                  id="member"
                  label="IF03 Member"
                  v-model="item.FC_MEM"
                  type="textarea"
                  counter="255"
                  maxlength="255"
                  persistentCounter
                  isRequired
                  :isEditable="isEditable && !item.readOnly" />
                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  textXl="7"
                  textLg="7"
                  textMd="6"
                  textSm="6"
                  id="memberDetailCondition"
                  label="IF06 Member Detail Condition"
                  v-model="item.FC_DESC"
                  type="textarea"
                  counter="255"
                  maxlength="255"
                  persistentCounter
                  :isEditable="isEditable && !item.readOnly" />
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  selectXl="7"
                  selectLg="7"
                  selectMd="6"
                  selectSm="6"
                  :showColon="false"
                  id="nstm"
                  label="IF09 Detail Type"
                  :isEditable="isEditable && !item.readOnly"
                  :refTable="REFERENCE_TABLE.NSTM_DETAIL_PRESENT"
                  v-model="item.NSTM_DETAIL_PRESENT"
                  isRequired
                  @update:modelValue="changesDetected = true" />
                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  textXl="7"
                  textLg="7"
                  textMd="6"
                  textSm="6"
                  id="memberInspectionProcedures"
                  label="IF10 Member Inspection Procedures"
                  v-model="item.DETAIL_INSP_PROC"
                  isRequired
                  type="textarea"
                  counter="2000"
                  maxlength="2000"
                  persistentCounter
                  :isEditable="isEditable && !item.readOnly"
              /></v-col>
            </v-row>
          </v-form>
        </td>
      </template>
      <template v-slot:item="{ item }">
        <tr :class="rowClass(item)">
          <td class="text-center">
            <template v-if="expandedRows?.length == 0 || !anyDetailEditable">
              <button
                v-if="!expandedRows?.includes(item)"
                @click="toggleRow(item)"
              >
                <v-icon
                  :id="`icon_Chevron_Right_${item.UNIQUE_KEY}`"
                  icon="fa-solid fa-chevron-right"
                ></v-icon>
              </button>

              <button
                v-if="expandedRows?.includes(item)"
                @click="toggleRow(item)"
              >
                <v-icon
                  :id="`icon_Chevron_Down_${item.UNIQUE_KEY}`"
                  icon="fa-solid fa-chevron-down"
                ></v-icon>
              </button>
            </template>
          </td>
          <td class="text-center">
            {{ item.FC_KEY }}
          </td>
          <td class="text-center">
            {{ locations?.find((a) => a.value === item?.STRUNITKEY)?.title }}
          </td>
          <td class="text-center" style="width: 18%">
            {{
              item?.FC_MEM_TYPE
                ? configStore.getReferenceValue(
                    REFERENCE_TABLE.FRACTURE_CRITICAL_MEMBER_TYPE,
                    item?.FC_MEM_TYPE
                  )
                : ""
            }}
          </td>
          <td v-if="!substructureFatigueTable" class="text-center">
            <div class="ellipsisText">
              <span>{{ item.FC_MEM }}</span>
            </div>
          </td>
          <td class="text-center">
            <div class="ellipsisText">
              <span>{{ item.FC_DETAIL }}</span>
            </div>
          </td>
          <td class="text-center">
            {{
              item?.FATIG_STRESS_CAT
                ? configStore.getReferenceValue(
                    REFERENCE_TABLE.FRACTURE_CRITICAL_FATIGUE_STRESS_CATEGORY,
                    item?.FATIG_STRESS_CAT,
                    true
                  )
                : ""
            }}
          </td>
          <td class="text-center" style="width: 18%">
            {{
              item.columns?.NSTM_DETAIL_PRESENT
                ? configStore.getReferenceValue(
                    REFERENCE_TABLE.NSTM_DETAIL_PRESENT,
                    item.columns?.NSTM_DETAIL_PRESENT
                  )
                : ""
            }}
          </td>
          <td class="text-center">
            <div class="ellipsisText">
              <span>{{ item.FC_DESC }}</span>
            </div>
          </td>
          <td v-if="!substructureFatigueTable" class="text-center">
            <div
              style="white-space: nowrap"
              v-if="!item.readOnly && isEditable"
            >
              <IconTooltip
                id="icon_add"
                icon="fa-solid fa-check"
                :tooltip="item?.isAdd ? 'Add' : 'Update'"
                @click="saveDetail(item)"
              />
              <IconTooltip
                id="icon_close"
                icon="fa-solid fa-xmark"
                tooltip="Close"
                @click="closeDetail"
              />
            </div>
            <div
              style="white-space: nowrap"
              v-else-if="
                isEditable && (expandedRows?.length == 0 || !anyDetailEditable)
              "
            >
              <IconTooltip
                id="icon_edit"
                icon="fa-solid fa-pencil"
                tooltip="Edit"
                @click="editDetail(item)"
              />
              <IconTooltip
                id="icon_delete"
                icon="fa-solid fa-trash"
                tooltip="Delete"
                @click="deleteDetail(item)"
              />
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:bottom>
        <DataTableFooter
          ref="nstmFooter"
          id="inspection_nstm_footer"
          :items="fcDetails"
          v-model="pageInspection"
        ></DataTableFooter>
      </template>
    </v-data-table>
  </v-row>
  <v-row v-else>
    <v-data-table
      id="nstm-data-table"
      v-model:page="pageInspection.page"
      :items-per-page="pageInspection.itemsPerPage"
      v-model:expanded="expandedRows"
      :headers="headers"
      :items="fcDetails"
      class="compactTable padding-xs detailTable"
      v-model:sort-by="sortBy"
      hide-default-footer
      must-sort
      show-expand
      return-object
      @update:sort-by="nstmFooter.setPageValue(1)"
    >
      <template v-slot:[`header.data-table-expand`]="{ column }">
        {{ column.title }}
      </template>
      <template v-slot:[`header.FC_KEY`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="2" />
      </template>
      <template v-slot:[`header.STRUNITKEY`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="2" />
      </template>
      <template v-slot:[`header.FC_MEM_TYPE`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="2" />
      </template>
      <template
        v-if="!substructureFatigueTable"
        v-slot:[`header.FC_MEM`]="{ column }"
      >
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="2" />
      </template>
      <template v-slot:[`header.FC_DETAIL`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="2" />
      </template>
      <template v-slot:[`header.FATIG_STRESS_CAT`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="2" />
      </template>
      <template v-slot:[`header.NSTM_DETAIL_PRESENT`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="2" />
      </template>
      <template v-slot:[`header.FC_DESC`]="{ column }">
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="2" />
      </template>
      <template
        v-if="!substructureFatigueTable"
        v-slot:[`header.action`]="{ column }"
      >
        <DataTableHeader :column="column" :sortBy="sortBy" :lines="2" />
      </template>

      <template v-slot:expanded-row="{ item }">
        <td :colspan="headers.length">
          <v-form
            ref="fcDetailForm"
            v-model="validDetails"
            class="mt-2"
            @change="changesDetected = true"
          >
            <v-row>
              <v-col cols="6">
                <LabelText
                  labelXl="4"
                  labelLg="4"
                  labelMd="6"
                  labelsm="6"
                  textXl="6"
                  textLg="6"
                  textMd="6"
                  textSm="6"
                  id="fcKey"
                  label="IF00 Key"
                  v-model="item.FC_KEY"
                  type="number"
                />
              </v-col>
              <v-col>
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  selectXl="7"
                  selectLg="7"
                  selectMd="6"
                  selectSm="6"
                  id="fcMemberType"
                  label="IF02 Member Type"
                  :isEditable="isEditable && !item.readOnly"
                  :refTable="REFERENCE_TABLE.FRACTURE_CRITICAL_MEMBER_TYPE"
                  v-model="item.FC_MEM_TYPE"
                  isRequired
                  @update:modelValue="changesDetected = true"
                /> </v-col
            ></v-row>

            <v-row>
              <v-col cols="6">
                <LabelSelect
                  labelXl="4"
                  labelLg="4"
                  labelMd="6"
                  labelsm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="fcLocation"
                  label="IF01 Location"
                  :isEditable="isEditable && item.isAdd"
                  v-model="item.STRUNITKEY"
                  :options="locations"
                  isRequired
                  @update:modelValue="changesDetected = true"
                />
              </v-col>
              <v-col>
                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  textXl="7"
                  textLg="7"
                  textMd="6"
                  textSm="6"
                  id="memberDetail"
                  label="IF04 Member Detail"
                  v-model="item.FC_DETAIL"
                  type="textarea"
                  counter="50"
                  maxlength="50"
                  persistentCounter
                  isRequired
                  :isEditable="isEditable && !item.readOnly"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <LabelSelect
                  labelXl="4"
                  labelLg="4"
                  labelMd="6"
                  labelsm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="memberStressCat"
                  label="IF05 Member Stress Category"
                  :isEditable="isEditable && !item.readOnly"
                  :options="fractureCriticalFatigueStressCategory"
                  v-model="item.FATIG_STRESS_CAT"
                  isRequired
                  @update:modelValue="changesDetected = true"
                />
              </v-col>
              <v-col cols="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelsm="6"
                  selectXl="7"
                  selectLg="7"
                  selectMd="6"
                  selectSm="6"
                  :showColon="false"
                  id="nstm"
                  label="IF09 Detail Type"
                  :isEditable="isEditable && !item.readOnly"
                  :refTable="REFERENCE_TABLE.NSTM_DETAIL_PRESENT"
                  v-model="item.NSTM_DETAIL_PRESENT"
                  isRequired
                  @update:modelValue="changesDetected = true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <LabelText
                  labelXl="2"
                  labelLg="2"
                  labelMd="3"
                  labelsm="3"
                  textXl="10"
                  textLg="10"
                  textMd="9"
                  textSm="9"
                  id="member"
                  label="IF03 Member"
                  v-model="item.FC_MEM"
                  type="textarea"
                  counter="255"
                  maxlength="255"
                  persistentCounter
                  isRequired
                  :isEditable="isEditable && !item.readOnly"
                />
              </v-col>
            </v-row>
            <v-row
              ><v-col>
                <LabelText
                  labelXl="2"
                  labelLg="2"
                  labelMd="3"
                  labelsm="3"
                  textXl="10"
                  textLg="10"
                  textMd="9"
                  textSm="9"
                  id="memberDetailCondition"
                  label="IF06 Member Detail Condition"
                  v-model="item.FC_DESC"
                  type="textarea"
                  counter="255"
                  maxlength="255"
                  persistentCounter
                  :isEditable="isEditable && !item.readOnly"
                />
              </v-col>
            </v-row>
            <v-row
              ><v-col>
                <LabelText
                  labelXl="2"
                  labelLg="2"
                  labelMd="3"
                  labelsm="3"
                  textXl="10"
                  textLg="10"
                  textMd="9"
                  textSm="9"
                  id="memberInspectionProcedures"
                  label="IF10 Member Inspection Procedures"
                  v-model="item.DETAIL_INSP_PROC"
                  isRequired
                  type="textarea"
                  counter="2000"
                  maxlength="2000"
                  persistentCounter
                  :isEditable="isEditable && !item.readOnly"
              /></v-col>
            </v-row>
          </v-form>
        </td>
      </template>
      <template v-slot:item="{ item }">
        <tr :class="rowClass(item)">
          <td class="text-center">
            <template v-if="expandedRows?.length == 0 || !anyDetailEditable">
              <button
                v-if="!expandedRows?.includes(item)"
                @click="toggleRow(item)"
              >
                <v-icon
                  :id="`icon_Chevron_Right_${item.UNIQUE_KEY}`"
                  icon="fa-solid fa-chevron-right"
                ></v-icon>
              </button>

              <button
                v-if="expandedRows?.includes(item)"
                @click="toggleRow(item)"
              >
                <v-icon
                  :id="`icon_Chevron_Down_${item.UNIQUE_KEY}`"
                  icon="fa-solid fa-chevron-down"
                ></v-icon>
              </button>
            </template>
          </td>
          <td class="text-center">
            {{ item.FC_KEY }}
          </td>
          <td class="text-center">
            {{ locations?.find((a) => a.value === item?.STRUNITKEY)?.title }}
          </td>
          <td class="text-center" style="width: 18%">
            {{
              item?.FC_MEM_TYPE
                ? configStore.getReferenceValue(
                    REFERENCE_TABLE.FRACTURE_CRITICAL_MEMBER_TYPE,
                    item?.FC_MEM_TYPE
                  )
                : ""
            }}
          </td>
          <td v-if="!substructureFatigueTable" class="text-center">
            <div class="ellipsisText">
              <span>{{ item.FC_MEM }}</span>
            </div>
          </td>
          <td class="text-center">
            <div class="ellipsisText">
              <span>{{ item.FC_DETAIL }}</span>
            </div>
          </td>
          <td class="text-center">
            {{
              item?.FATIG_STRESS_CAT
                ? configStore.getReferenceValue(
                    REFERENCE_TABLE.FRACTURE_CRITICAL_FATIGUE_STRESS_CATEGORY,
                    item?.FATIG_STRESS_CAT,
                    true
                  )
                : ""
            }}
          </td>
          <td class="text-center" style="width: 18%">
            {{
              item?.NSTM_DETAIL_PRESENT
                ? configStore.getReferenceValue(
                    REFERENCE_TABLE.NSTM_DETAIL_PRESENT,
                    item?.NSTM_DETAIL_PRESENT
                  )
                : ""
            }}
          </td>
          <td class="text-center">
            <div class="ellipsisText">
              <span>{{ item.FC_DESC }}</span>
            </div>
          </td>
          <td v-if="!substructureFatigueTable" class="text-center">
            <div
              style="white-space: nowrap"
              v-if="!item.readOnly && isEditable"
            >
              <IconTooltip
                id="icon_add"
                icon="fa-solid fa-check"
                :tooltip="item?.isAdd ? 'Add' : 'Update'"
                @click="saveDetail(item)"
              />
              <IconTooltip
                id="icon_close"
                icon="fa-solid fa-xmark"
                tooltip="Close"
                @click="closeDetail"
              />
            </div>
            <div
              style="white-space: nowrap"
              v-else-if="
                isEditable && (expandedRows?.length == 0 || !anyDetailEditable)
              "
            >
              <IconTooltip
                id="icon_edit"
                icon="fa-solid fa-pencil"
                tooltip="Edit"
                @click="editDetail(item)"
              />
              <IconTooltip
                id="icon_delete"
                icon="fa-solid fa-trash"
                tooltip="Delete"
                @click="deleteDetail(item)"
              />
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:bottom>
        <DataTableFooter
          ref="nstmFooter"
          id="inspection_nstm_footer"
          :items="fcDetails"
          v-model="pageInspection"
        ></DataTableFooter>
      </template>
    </v-data-table>
  </v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>
<script>
import { computed, ref, nextTick, onMounted } from "vue";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { requiredRule } from "@/composables/validationRules";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import IconTooltip from "@/components/common/IconTooltip.vue";
import { LOGGER } from "@/util/logger";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import { BUTTONS } from "@/constants/CommonWebConstants";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { scrollToDataTable } from "@/util/scrollToElement";
import useNSTMFatigueData from "@/composables/NSTMFatigueData";
import { STRUCTURE_UNIT_TYPE } from "@/constants/Inspections";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "NSTMFatigueTable",
  props: {
    id: { default: "" },
    substructureFatigueTable: { type: Boolean, default: false },
    unitKey: { default: null },
  },
  setup(props) {
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    const snackbarStore = useSnackbarStore();
    let valid = ref(true);
    let headersSmall = ref(
      props.substructureFatigueTable
        ? [
            {
              key: "data-table-expand",
              width: "2%",
            },
            {
              line1: "IF00",
              line2: "Key",
              line3: null,
              key: "FC_KEY",
              width: "10%",
              sortable: true,
            },
            {
              line1: "IF01",
              line2: "Location",
              line3: null,
              key: "STRUNITKEY",
              width: "13%",
              sortable: true,
            },
            {
              line1: "IF02",
              line2: "Member",
              line3: "Type",
              key: "FC_MEM_TYPE",
              width: "8%",
              sortable: true,
            },
            {
              line1: "IF04",
              line2: "Member",
              line3: "Detail",
              key: "FC_DETAIL",
              width: "16%",
              sortable: true,
            },
            {
              line1: "IF05",
              line2: "Member",
              line3: "Stress Category",
              key: "FATIG_STRESS_CAT",
              width: "18%",
              sortable: true,
            },
            {
              line1: "IF09",
              line2: "Detail Type",
              line3: null,
              key: "NSTM_DETAIL_PRESENT",
              width: "8%",
              sortable: true,
            },
            {
              line1: "IF06",
              line2: "Member",
              line3: "Detail Condition",
              key: "FC_DESC",
              width: "18%",
              sortable: true,
            },
          ]
        : [
            {
              key: "data-table-expand",
              width: "2%",
            },
            {
              line1: "IF00",
              line2: "Key",
              line3: null,
              key: "FC_KEY",
              width: "10%",
              sortable: true,
            },
            {
              line1: "IF01",
              line2: "Location",
              line3: null,
              key: "STRUNITKEY",
              width: "8%",
              sortable: true,
            },
            {
              line1: "IF02",
              line2: "Member",
              line3: "Type",
              key: "FC_MEM_TYPE",
              width: "6%",
              sortable: true,
            },
            {
              line1: "IF03",
              line2: "Member",
              line3: null,
              key: "FC_MEM",
              width: "12%",
              sortable: true,
            },
            {
              line1: "IF04",
              line2: "Member",
              line3: "Detail",
              key: "FC_DETAIL",
              width: "15%",
              sortable: true,
            },
            {
              line1: "IF05",
              line2: "Member",
              line3: "Stress Category",
              key: "FATIG_STRESS_CAT",
              width: "17%",
              sortable: true,
            },
            {
              line1: "IF09",
              line2: "Detail Type",
              line3: null,
              key: "NSTM_DETAIL_PRESENT",
              width: "12%",
              sortable: true,
            },
            {
              line1: "IF06",
              line2: "Member",
              line3: "Detail Condition",
              key: "FC_DESC",
              width: "17%",
              sortable: true,
            },
            {
              line1: null,
              line2: "Action",
              line3: null,
              key: "action",
              width: "5%",
              sortable: false,
            },
          ]
    );
    let headers = ref(
      props.substructureFatigueTable
        ? [
            {
              key: "data-table-expand",
              width: "3%",
            },
            {
              line1: "IF00",
              line2: "Key",
              line3: null,
              key: "FC_KEY",
              width: "7%",
              sortable: true,
            },
            {
              line1: "IF01",
              line2: "Location",
              line3: null,
              key: "STRUNITKEY",
              width: "10%",
              sortable: true,
            },
            {
              line1: "IF02",
              line2: "Member Type",
              line3: null,
              key: "FC_MEM_TYPE",
              width: "10%",
              sortable: true,
            },
            {
              line1: "IF04",
              line2: "Member Detail",
              line3: null,
              key: "FC_DETAIL",
              width: "15%",
              sortable: true,
            },
            {
              line1: "IF05",
              line2: "Member Stress Category",
              line3: null,
              key: "FATIG_STRESS_CAT",
              width: "18%",
              sortable: true,
            },
            {
              line1: "IF09",
              line2: "Detail Type",
              line3: null,
              key: "NSTM_DETAIL_PRESENT",
              width: "12%",
              sortable: true,
            },
            {
              line1: "IF06",
              line2: "Member Detail Condition",
              line3: null,
              key: "FC_DESC",
              width: "20%",
              sortable: true,
            },
          ]
        : [
            {
              key: "data-table-expand",
              width: "3%",
            },
            {
              line1: "IF00",
              line2: "Key",
              line3: null,
              key: "FC_KEY",
              width: "7%",
              sortable: true,
            },
            {
              line1: "IF01",
              line2: "Location",
              line3: null,
              key: "STRUNITKEY",
              width: "8%",
              sortable: true,
            },
            {
              line1: "IF02",
              line2: "Member Type",
              line3: null,
              key: "FC_MEM_TYPE",
              width: "10%",
              sortable: true,
            },
            {
              line1: "IF03",
              line2: "Member",
              line3: null,
              key: "FC_MEM",
              width: "10%",
              sortable: true,
            },
            {
              line1: "IF04",
              line2: "Member Detail",
              line3: null,
              key: "FC_DETAIL",
              width: "15%",
              sortable: true,
            },
            {
              line1: "IF05",
              line2: "Member Stress Category",
              line3: null,
              key: "FATIG_STRESS_CAT",
              width: "18%",
              sortable: true,
            },
            {
              line1: "IF09",
              line2: "Detail Type",
              line3: null,
              key: "NSTM_DETAIL_PRESENT",
              width: "8%",
              sortable: true,
            },
            {
              line1: "IF06",
              line2: "Member Detail Condition",
              line3: null,
              key: "FC_DESC",
              width: "20%",
              sortable: true,
            },
            {
              line1: null,
              line2: "Action",
              line3: null,
              key: "action",
              width: "8%",
              sortable: false,
            },
          ]
    );
    onMounted(() => {
      updateNSTMPresent();
      updateComplexStructurePresent();
    });

    const structure = computed(() => inspectionStore.selectedInspection);
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    const locations = inspectionStore
      .getFilteredStructureUnits(
        [
          STRUCTURE_UNIT_TYPE.MAIN,
          STRUCTURE_UNIT_TYPE.APPROACH,
          STRUCTURE_UNIT_TYPE.PIER,
        ],
        true
      )
      ?.map((b) => ({
        title: `${b?.STRUNITKEY} - ${b?.STRUNITLABEL}`,
        value: b?.STRUNITKEY,
      }))
      .sort((a, b) => a?.value - b?.value);

    const { fatigueDetail, getFcDetails } = useNSTMFatigueData();
    const updateNSTMPresent = () => {
      const isNSTMPresent = fcDetails.value.findIndex(
        (a) => a?.NSTM_DETAIL_PRESENT === "Y" || a?.NSTM_DETAIL_PRESENT === "B"
      );
      if (isNSTMPresent == -1) {
        structure.value.UserInsp.NSTM_PRESENT = "N";
      } else {
        structure.value.UserInsp.NSTM_PRESENT = "Y";
      }
    };

    const getNewFcInsp = () => {
      return {
        BRKEY: structure.value.Bridge.BRKEY,
        STRUNITKEY: null,
        INSPKEY: structure.value.InspEvnt.INSPKEY,
        FC_KEY: null,
        FATIG_STRESS_CAT: "",
        FC_DESC: "",
        FC_DETAIL: "",
        FC_MEM: "",
        FC_MEM_TYPE: "",
        NSTM_DETAIL_PRESENT: "Y",
        MODTIME: new Date().toISOString(),
        USERKEY: "",
        INSPEVNT: null,
      };
    };
    let fcDetails = ref(getFcDetails(props.unitKey));
    let expandedRows = ref([]);
    let fcDetailForm = ref(null);
    let validDetails = ref(false);
    let changesDetected = ref(false);
    const closeConfirm = ref(null);
    const pageInspection = ref({
      itemsPerPage: 30,
      page: 1,
    });
    let nstmFooter = ref(null);
    let previousSort = ref([{ key: "FC_KEY", order: "asc" }]);
    let sortBy = ref([{ key: "FC_KEY", order: "asc" }]);
    let snackBarId = ref("");

    function resetSnackbar() {
      snackbarStore.removeMessage(snackBarId.value);
    }
    function addDetailsFunction() {
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.NSTM_FATIGUE_DATATABLE,
      });
      let newFcInsp = getNewFcInsp();
      newFcInsp.isAdd = true;
      newFcInsp.readOnly = false;
      fcDetails.value.unshift(newFcInsp);
      previousSort.value = sortBy.value.map((x) => x);
      sortBy.value = [];
      expandedRows.value = [newFcInsp];
      scrollToDataTable("nstm-data-table");
      changesDetected.value = false;
      nstmFooter.value.setPageValue(1);
      nextTick(() => {
        fcDetailForm.value?.validate();
      });
    }

    const getMaxFCKey = (strUnitKey) => {
      const keys = structure.value.T_FC_Insp
        ? structure.value.T_FC_Insp.filter(
            (a) => a?.STRUNITKEY === strUnitKey
          ).map((fcInsp) => fcInsp?.FC_KEY)
        : [];

      let maxKey = Math.max(...keys);
      return Number.isFinite(maxKey) ? maxKey : -1;
    };

    function editDetail(detail) {
      previousSort.value = sortBy.value.map((x) => x);

      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.NSTM_FATIGUE_DATATABLE,
      });
      detail.readOnly = false;
      expandedRows.value = [detail];
      changesDetected.value = false;
      nextTick(() => {
        fcDetailForm.value.validate();
      });
      sortBy.value = [];
      moveEditableElementToTop(detail);
      nstmFooter.value.setPageValue(1);
    }

    const moveEditableElementToTop = (detail) => {
      const index = fcDetails.value.findIndex(
        (a) =>
          a?.STRUNITKEY === detail.STRUNITKEY && a?.FC_KEY === detail.FC_KEY
      );
      let editableElement = fcDetails.value[index];
      //then remove that element and use unshift
      fcDetails.value.splice(index, 1);
      fcDetails.value.unshift(editableElement);
    };

    function deleteDetail(detail) {
      const deleteIndex = structure.value.T_FC_Insp.findIndex(
        (a) =>
          a?.FC_KEY === detail.FC_KEY && a?.STRUNITKEY === detail.STRUNITKEY
      );
      if (deleteIndex > -1) {
        structure.value.T_FC_Insp.splice(deleteIndex, 1);
        expandedRows.value = [];
        fcDetails.value = getFcDetails(props.unitKey);
        updateNSTMPresent();
        updateComplexStructurePresent();
      }
    }

    function saveDetail(detail) {
      fcDetailForm.value
        .validate()
        .then(() => {
          if (validDetails.value) {
            inspectionStore.setShowValidationSnackbar(false);
            if (!structure.value?.T_FC_Insp?.[0]) {
              //remove null values from array(if any)
              structure.value.T_FC_Insp = structure.value.T_FC_Insp
                ? structure.value.T_FC_Insp.filter((a) => !!a)
                : [];
            }
            if (detail.isAdd) {
              detail.FC_KEY = getMaxFCKey(detail.STRUNITKEY) + 1;
              detail.UNIQUE_KEY = detail.STRUNITKEY + "-" + detail.FC_KEY;
              structure.value.T_FC_Insp.push(convertToFCInspObject(detail));
            } else {
              //update existing detail
              const updateIndex = structure.value.T_FC_Insp.findIndex(
                (a) =>
                  a?.STRUNITKEY === detail.STRUNITKEY &&
                  a?.FC_KEY === detail.FC_KEY
              );
              structure.value.T_FC_Insp.splice(
                updateIndex,
                1,
                convertToFCInspObject(detail)
              );
            }
            updateNSTMPresent();
            updateComplexStructurePresent();
            //if all required fields entered - close the expanded row
            expandedRows.value = [];
            fcDetails.value = getFcDetails(props.unitKey);
            changesDetected.value = false;
            inspectionStore.setAddingOrEditing(false);
            resetSnackbar();
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
          sortBy.value = previousSort.value?.map((x) => x);
        })
        .catch((err) => LOGGER.logException(err));
    }

    const convertToFCInspObject = (details) => {
      let fcInspObject = getNewFcInsp();
      fcInspObject.BRKEY = details.BRKEY;
      fcInspObject.STRUNITKEY = details.STRUNITKEY;
      fcInspObject.INSPKEY = details.INSPKEY;
      fcInspObject.FC_KEY = details.FC_KEY;
      fcInspObject.FATIG_STRESS_CAT = details.FATIG_STRESS_CAT;
      fcInspObject.FC_DESC = details.FC_DESC;
      fcInspObject.FC_DETAIL = details.FC_DETAIL;
      fcInspObject.FC_MEM = details.FC_MEM;
      fcInspObject.FC_MEM_TYPE = details.FC_MEM_TYPE;
      fcInspObject.NSTM_DETAIL_PRESENT = details.NSTM_DETAIL_PRESENT;
      fcInspObject.DETAIL_INSP_PROC = details.DETAIL_INSP_PROC;
      fcInspObject.MODTIME = new Date().toISOString();
      fcInspObject.USERKEY = details.USERKEY;
      fcInspObject.INSPEVNT = details.INSPEVNT;
      return fcInspObject;
    };

    const rowClass = (data) => {
      return expandedRows.value?.find((a) => a.UNIQUE_KEY === data.UNIQUE_KEY)
        ? "rowSelect"
        : "rowUnselect";
    };

    const anyDetailEditable = computed(() => {
      return expandedRows.value?.find((element) => !element.readOnly);
    });

    const fractureCriticalFatigueStressCategory = computed(() => {
      return configStore.getReferenceValues(
        REFERENCE_TABLE.FRACTURE_CRITICAL_FATIGUE_STRESS_CATEGORY,
        null,
        null,
        true
      );
    });

    const closeDetail = () => {
      if (isEditable.value && changesDetected.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              fcDetails.value = getFcDetails(props.unitKey);

              expandedRows.value = [];
              changesDetected.value = false;
              inspectionStore.setAddingOrEditing(false);
              resetSnackbar();
              sortBy.value = previousSort.value?.map((x) => x);
            }
          });
      } else {
        fcDetails.value = getFcDetails(props.unitKey);
        expandedRows.value = [];
        inspectionStore.setAddingOrEditing(false);
        resetSnackbar();
        sortBy.value = previousSort.value?.map((x) => x);
      }
    };
    const toggleRow = (detail) => {
      if (expandedRows.value.includes(detail)) {
        const index = expandedRows.value.findIndex(
          (d) => d.UNIQUE_KEY == detail.UNIQUE_KEY
        );
        expandedRows.value.splice(index, 1);
      } else {
        expandedRows.value.push(detail);
      }
    };

    const getAllDetails = () => {
      expandedRows.value = [];
      fcDetails.value = getFcDetails(props.unitKey);
    };

    const updateComplexStructurePresent = () => {
      const isComplexStructurePresent = fcDetails.value.some(
        (a) => a?.NSTM_DETAIL_PRESENT === "C" || a?.NSTM_DETAIL_PRESENT === "B"
      );
      if (isComplexStructurePresent) {
        structure.value.UserInsp.COMPLEX_FEATURE_PRESENT = "Y";
      } else {
        structure.value.UserInsp.COMPLEX_FEATURE_PRESENT = "N";
      }
    };

    return {
      headersSmall,
      headers,
      fatigueDetail,
      fractureCriticalFatigueStressCategory,
      editDetail,
      deleteDetail,
      saveDetail,
      addDetailsFunction,
      locations,
      REFERENCE_TABLE,
      configStore,
      structure,
      requiredRule,
      valid,
      fcDetails,
      expandedRows,
      rowClass,
      anyDetailEditable,
      isEditable,
      closeDetail,
      fcDetailForm,
      validDetails,
      changesDetected,
      closeConfirm,
      BUTTONS,
      getFcDetails,
      inspectionStore,
      INSPECTION_MESSAGES,
      toggleRow,
      pageInspection,
      nstmFooter,
      sortBy,
      getAllDetails,
    };
  },
  components: {
    LabelSelect,
    LabelText,
    IconTooltip,
    ConfirmDialog,
    DataTableHeader,
    DataTableFooter,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.detailTable :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.detailTable :deep(button:focus) {
  outline: none !important;
}
.alignCenter {
  text-align: center;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}
</style>
