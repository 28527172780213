import { PERMISSIONS } from "@/rbac/permissions.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_PERMISSIONS = [
  PERMISSIONS.ROLE_BMS_BROWSER,
  PERMISSIONS.ROLE_BMS_BROWSER_INSPECTION,
  PERMISSIONS.ROLE_BMS_BROWSER_INVENTORY,
  PERMISSIONS.ROLE_BMS_INSPECTOR,
  PERMISSIONS.ROLE_BMS_INSPECTOR_SUPERVISOR,
  PERMISSIONS.ROLE_BMS_POWERUSER,
  PERMISSIONS.ROLE_BMS_SUPERUSER,
];

const ADD_PERMISSIONS = [PERMISSIONS.EDMSEDIT];
const EDIT_PERMISSIONS = [PERMISSIONS.EDMSEDIT];
const DRAFT_PERMISSIONS = [PERMISSIONS.ROLE_BMS_SUPERUSER];
const DELETE_PERMISSIONS = [PERMISSIONS.ROLE_BMS_SUPERUSER];
const CRITICAL_FINDING_PERMISSIONS = [PERMISSIONS.ROLE_BMS_SUPERUSER];
const BPR_EDIT_IN_NON_DRAFT_STATE = [PERMISSIONS.ROLE_BMS_SUPERUSER];

export const BRIDGE_PROBLEM_REPORT_PERMISSIONS = {
  [ACCESS_LEVELS.VIEW]: VIEW_PERMISSIONS,
  [ACCESS_LEVELS.EDIT]: EDIT_PERMISSIONS,
  [ACCESS_LEVELS.ADD]: ADD_PERMISSIONS,
  [ACCESS_LEVELS.DRAFT]: DRAFT_PERMISSIONS,
  [ACCESS_LEVELS.DELETE]: DELETE_PERMISSIONS,
  [ACCESS_LEVELS.CUSTOM.BPR_CRITICAL_FINDING]: CRITICAL_FINDING_PERMISSIONS,
  [ACCESS_LEVELS.CUSTOM.BPR_EDIT_IN_NON_DRAFT_STATE]:
    BPR_EDIT_IN_NON_DRAFT_STATE,
};
