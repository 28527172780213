import { styles } from "@/reports/reportStyles";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import useApproachData from "@/composables/approachData";
import { sortStructure } from "@/util/sortStructure";

function setApproachDocDefinition(structure) {
  const configStore = useConfigStore();
  const { pavementReliefJointsPresent, bridgeRailingDesc, transitionDesc } =
    useApproachData();
  const signsTable = [];
  signsTable.push(getSignsTableHeader());
  sortStructure(structure, "T_Signing", "SIGN_TYPE");
  getSignsOpeningTable(signsTable, structure);

  let docDefinition = {
    header: function () {
      return getReportHeader("Approach Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Approach Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Approach Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "22%",
            text: "B.AP.01 Approach Roadway Alignment: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL,
              structure?.InspEvnt?.APPRALIGN
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "Alignment Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.APPROACH_ALIGNMENT
        )?.NOTES,
      },
      {
        columns: [
          {
            width: "22%",
            text: "6B39 Approach Roadway: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.APPR_ROAD_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "18%",
            text: "6B38 Approach Slab: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.APPR_SLAB_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },

      {
        columns: [
          {
            width: "22%",
            text: "B.C.05 Railing: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.RAILING_RATING,
              structure?.InspEvnt?.RAILRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "18%",
            text: "B.C.06 Transition: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.TRANSITION_RATING,
              structure?.InspEvnt?.TRANSRATIN
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Approach Details and Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "28%",
            text: "6A43 Approach Pavement Width: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                structure?.UserBrdg?.APPR_PAVEMENT_WIDTH,
                ROUNDING_FACTOR.ROUND
              ) + " ft",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "Pavement: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.APPROACH_PAVEMENT
        )?.NOTES,
      },
      {
        text: "Drainage: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.APPROACH_DRAINAGE
        )?.NOTES,
      },
      {
        text: "Shoulders: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.APPROACH_SHOULDERS
        )?.NOTES,
      },
      {
        text: "Approach Slab: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.APPROACH_SLAB
        )?.NOTES,
      },
      {
        columns: [
          {
            width: "32%",
            text: "6A39 Pavement Relief Joints Present? ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: pavementReliefJointsPresent.value,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.PAVEMENT_RELIEF_JOINTS
        )?.NOTES,
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Railings: ",
                bold: true,
                decoration: "underline",
              },
            ],
            [
              {
                text: [
                  {
                    text: "B.C.05 Condition: ",
                    bold: true,
                  },
                  configStore.getReferenceValue(
                    REFERENCE_TABLE.RAILING_RATING,
                    structure.InspEvnt.RAILRATING
                  ),
                ],
              },
            ],
            [
              {
                text: [
                  {
                    text: "B.RH.01 Crash Level: ",
                    bold: true,
                  },
                  structure.InspEvnt.railingsCrashLevel,
                ],
              },
            ],
            [
              {
                width: "auto",
                text: [
                  { text: "Description: ", bold: true },
                  bridgeRailingDesc.value,
                ],
                margin: [0, 0, 0, 0],
              },
            ],
            [
              {
                text: "Notes: ",
                bold: true,
                margin: [0, 0, 0, 0],
              },
            ],
            [
              {
                text: structure?.T_Insp_Comment?.find(
                  (element) =>
                    element?.COMMENT_TYPE == INSPECTION_COMMENTS.BRIDGE_RAILING
                )?.NOTES,
                margin: [0, 0, 0, 0],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Transitions: ",
                bold: true,
                decoration: "underline",
              },
            ],
            [
              {
                text: [
                  {
                    text: "B.C.06 Condition: ",
                    bold: true,
                  },
                  configStore.getReferenceValue(
                    REFERENCE_TABLE.TRANSITION_RATING,
                    structure.InspEvnt.TRANSRATIN
                  ),
                ],
              },
            ],
            [
              {
                text: [
                  {
                    text: "B.RH.02 Crash Level: ",
                    bold: true,
                  },
                  structure.InspEvnt.transitionsCrashLevel,
                ],
              },
            ],
            [
              {
                width: "auto",
                text: [
                  { text: "Description: ", bold: true },
                  transitionDesc.value,
                ],
                margin: [0, 0, 0, 0],
              },
            ],
            [
              {
                text: "Notes: ",
                bold: true,
                margin: [0, 0, 0, 0],
              },
            ],
            [
              {
                text: structure?.T_Insp_Comment?.find(
                  (element) =>
                    element?.COMMENT_TYPE == INSPECTION_COMMENTS.TRANSITION
                )?.NOTES,
                margin: [0, 0, 0, 0],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        stack: [
          {
            table: {
              headerRows: 0,
              widths: ["100%"],
              body: [
                [
                  {
                    text: "Signs",
                    fillColor: "#ebebeb",
                    alignment: "center",
                    style: ["sectionHeader"],
                  },
                ],
              ],
            },
            layout: "noBorders",
            margin: [0, 5, 0, 5],
          },
          {
            table: {
              headerRows: 1,
              widths: ["25%", "15%", "9%", "9%", "9%", "9%", "*"],
              body: signsTable,
            },
          },
        ],
      },
    ],
    unbreakable: true,
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

function getSignsTableHeader() {
  return [
    {
      text: "ID01\n Type of Sign",
      style: ["tableHeader"],
    },
    {
      text: "ID03\n Sign Message",
      style: ["tableHeader"],
    },
    {
      text: "ID04\n Near\n Advance",
      style: ["tableHeader"],
    },
    {
      text: "ID06\n Bridge Site\n Near",
      style: ["tableHeader"],
    },
    {
      text: "ID07\n Bridge\n Site Far",
      style: ["tableHeader"],
    },
    {
      text: "ID05\n Far\n Advance",
      style: ["tableHeader"],
    },
    {
      text: "ID08\n Signing Notes",
      style: ["tableHeader"],
    },
  ];
}

function getSignsOpeningTable(signingDetails, structure) {
  const configStore = useConfigStore();

  for (const openingDetail of structure?.T_Signing || []) {
    signingDetails.push(
      [
        {
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.SIGN_TYPE,
            openingDetail?.SIGN_TYPE
          ),
          style: ["tableBody"],
        },
        {
          text: openingDetail?.SIGN_MESSAGE,
          style: ["tableBody"],
        },
        {
          text: openingDetail?.NEAR_ADVANCE_COND,
          style: ["tableBody"],
        },
        {
          text: openingDetail?.NEAR_SITE_COND,
          style: ["tableBody"],
        },
        {
          text: openingDetail?.FAR_SITE_COND,
          style: ["tableBody"],
        },
        {
          text: openingDetail?.FAR_ADVANCE_COND,
          style: ["tableBody"],
        },
        {
          text: openingDetail?.NOTES,
          style: ["tableBody"],
        },
      ].map((d) => {
        return {
          text: d,
          alignment: "center",
        };
      })
    );
  }
}

export { setApproachDocDefinition };
