import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import useCoordinates from "@/composables/coordinates.js";
import { convertToInt } from "@/composables/util";
import useDateField from "@/composables/dateField.js";
import { CUSTOM_ICONS, SYMBOLS } from "@/constants/Unicode";
import { sortStructure } from "@/util/sortStructure";

function setGeneralDocDefinition(structure) {
  const configStore = useConfigStore();
  const coordinates = useCoordinates();
  const { getFormattedDateStringNoTime } = useDateField();
  const spanDetails = [];
  spanDetails.push(getDetailsHeader());
  sortStructure(structure, "Structure_Unit", "STRUNITKEY");
  getSpecificSpanDetails(structure, spanDetails, configStore);

  let docDefinition = {
    header: function () {
      return getReportHeader("General Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "General Page" },
      {
        columns: [
          [
            {
              table: {
                headerRows: 1,
                widths: ["45%", "*"],
                body: [
                  [
                    {
                      colSpan: 2,
                      text: "Structure Identification",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["sectionHeader"],
                    },
                    {},
                  ],
                  [
                    {
                      text: "5A01 BMS ID:",
                      bold: true,
                    },
                    {
                      text: structure.Bridge?.BRIDGE_ID,
                    },
                  ],
                  [
                    {
                      text: "5A02 Structure Name:",
                      bold: true,
                    },
                    {
                      text: structure.Bridge?.STRUCNAME,
                    },
                  ],
                  [
                    {
                      text: "5A03 NBI Structure No:",
                      bold: true,
                    },
                    {
                      text: structure.Bridge?.STRUCT_NUM,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              table: {
                headerRows: 1,
                widths: ["45%", "*"],
                body: [
                  [
                    {
                      colSpan: 2,
                      text: "Location",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["sectionHeader"],
                    },
                    {},
                  ],
                  [
                    {
                      text: "5A04 District:",
                      bold: true,
                    },
                    {
                      text: getDistrict(
                        configStore,
                        structure.Bridge?.DISTRICT
                      ),
                    },
                  ],
                  [
                    {
                      text: "5A05 County:",
                      bold: true,
                    },
                    {
                      text: getCounty(configStore, structure.Bridge?.COUNTY),
                    },
                  ],
                  [
                    {
                      text: "5A06 City/Town/Place:",
                      bold: true,
                    },
                    {
                      text: getMunicipality(
                        configStore,
                        structure.Bridge?.PLACECODE
                      ),
                    },
                  ],
                  [
                    {
                      text: "5A07 Feature Intersected:",
                      bold: true,
                    },
                    {
                      text: structure.Bridge?.FEATINT,
                    },
                  ],
                  [
                    {
                      text: "5A08 Facility Carried:",
                      bold: true,
                    },
                    {
                      text: structure.Bridge?.FACILITY,
                    },
                  ],
                  [
                    {
                      text: "5A09 Location:",
                      bold: true,
                    },
                    {
                      text: structure.Bridge?.LOCATION,
                    },
                  ],
                  [
                    {
                      text: "5A10 Latitude:",
                      bold: true,
                    },
                    {
                      text: coordinates.parseBMSLatLongToDMS(
                        structure.Bridge?.LATITUDE,
                        "N"
                      ).decimal,
                    },
                  ],
                  [
                    {
                      text: "5A11 Longitude:",
                      bold: true,
                    },
                    {
                      text: coordinates.parseBMSLatLongToDMS(
                        structure.Bridge?.LONGITUDE,
                        "W"
                      ).decimal,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              table: {
                headerRows: 1,
                widths: ["45%", "*"],
                body: [
                  [
                    {
                      colSpan: 2,
                      text: "Age and Service",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["sectionHeader"],
                    },
                    {},
                  ],
                  [
                    {
                      text: "5A15 Year Built:",
                      bold: true,
                    },
                    {
                      text: structure?.Bridge?.YEARBUILT,
                    },
                  ],
                  [
                    {
                      text: "5A16 Year Reconstruction:",
                      bold: true,
                    },
                    {
                      text: structure?.Bridge?.YEARRECON,
                    },
                  ],
                  [
                    {
                      text: "5A17 Service Type On:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.SERVICE_TYPE_ON,
                        structure?.Bridge?.SERVTYPON
                      ),
                    },
                  ],
                  [
                    {
                      text: "5A18 Service Type Under:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.SERVICE_TYPE_UNDER,
                        structure?.Bridge?.SERVTYPUND
                      ),
                    },
                  ],
                  [
                    {
                      text: "5A19 # of Lanes Under:",
                      bold: true,
                    },
                    {
                      text: structure?.Bridge?.SUMLANES,
                    },
                  ],
                  [
                    {
                      text: "5A20 Maint Resp:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.MAINTENENCE_RESPONSIBILITY,
                        structure?.Bridge?.CUSTODIAN
                      ),
                    },
                  ],
                  [
                    {
                      text: "5A21 Owner Code:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.OWNER,
                        structure?.Bridge?.OWNER
                      ),
                    },
                  ],
                  [
                    {
                      text: "6A06 Submitting Agency:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.SUB_AGENCY,
                        structure?.UserBrdg?.SUB_AGENCY
                      ),
                    },
                  ],
                  [
                    {
                      text: "6A23 Owner Description:",
                      bold: true,
                    },
                    {
                      text: structure?.UserBrdg?.OWNER_DESC,
                    },
                  ],
                  [
                    {
                      text: "6A19 BPN:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.BUS_PLAN_NETWORK,
                        structure?.UserBrdg?.BUS_PLAN_NETWORK
                      ),
                    },
                  ],
                  [
                    {
                      text: "VP02 Posting Status:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.POSTING_STATUS,
                        structure?.T_Posting?.find(
                          (posting) => posting.ACTIVE == "1"
                        )?.POST_STATUS
                      ),
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
          [
            {
              table: {
                headerRows: 1,
                widths: ["52%", "*"],
                body: [
                  [
                    {
                      colSpan: 2,
                      text: "Deck Information",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["sectionHeader"],
                    },
                    {},
                  ],
                  [
                    { text: "5B01 Deck Structure Type:", bold: true },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.DECK_STRUCTURE_TYPE,
                        structure.Bridge?.DKSTRUCTYP
                      ),
                    },
                  ],
                  [
                    { text: "5B02 Deck Surface Type:", bold: true },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.DECK_SURFACE_TYPE,
                        structure.Bridge?.DKSURFTYPE
                      ),
                    },
                  ],
                  [
                    { text: "5B03 Deck Membrane Type:", bold: true },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.DECK_MEMBRANE_TYPE,
                        structure.Bridge?.DKMEMBTYPE
                      ),
                    },
                  ],
                  [
                    { text: "5B04 Deck Protection:", bold: true },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.DECK_PROTECTION_TYPE,
                        structure.Bridge?.DKPROTECT
                      ),
                    },
                  ],
                  [
                    { text: "5B05 Left Curb/S'walk Width:", bold: true },
                    {
                      text: configStore.getMetricToEnglishConversions(
                        PAIR_CODE.METERS_TO_FEET,
                        structure.Bridge?.LFTCURBSW,
                        ROUNDING_FACTOR.TENTH,
                        true
                      ),
                    },
                  ],
                  [
                    { text: "5B06 Right Curb/S'walk Width:", bold: true },
                    {
                      text: configStore.getMetricToEnglishConversions(
                        PAIR_CODE.METERS_TO_FEET,
                        structure.Bridge?.RTCURBSW,
                        ROUNDING_FACTOR.TENTH,
                        true
                      ),
                    },
                  ],
                  [
                    { text: "5B07 Deck Width (O/O):", bold: true },
                    {
                      text: configStore.getMetricToEnglishConversions(
                        PAIR_CODE.METERS_TO_FEET,
                        structure.Bridge?.DECKWIDTH,
                        ROUNDING_FACTOR.TENTH,
                        true
                      ),
                    },
                  ],
                  [
                    { text: "5B08 Median Type:", bold: true },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.BRIDGE_MEDIAN,
                        structure.Bridge?.BRIDGEMED,
                        true
                      ),
                    },
                  ],
                  [
                    { text: "5B09 Skew:", bold: true },
                    {
                      text: structure.Bridge?.SKEW
                        ? structure.Bridge?.SKEW + SYMBOLS.DEGREE
                        : "",
                    },
                  ],
                  [
                    { text: "5B10 Structure Flare:", bold: true },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.STRUCTURE_FLARED,
                        structure.Bridge?.STRFLARED
                      ),
                    },
                  ],
                  [
                    { text: "6A38 PennDOT Deck Type:", bold: true },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.BRIDGE_DECK_TYPE,
                        structure?.UserBrdg?.DEPT_DKSTRUCTYP
                      ),
                    },
                  ],
                  [
                    { text: "6A39 Relief Joints?:", bold: true },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.RELIEF_JOINT,
                        structure?.UserBrdg?.RELIEF_JOINT
                      ),
                    },
                  ],
                  [
                    { text: "6A40 Form Type:", bold: true },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.DECK_FORM_TYPE,
                        structure?.UserBrdg?.DECK_FORM_TYPE
                      ),
                    },
                  ],
                  [
                    { text: "6A41 No. of Joints:", bold: true },
                    {
                      text: structure?.UserBrdg?.DECK_JOINTS,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              table: {
                headerRows: 1,
                widths: ["52%", "*"],
                body: [
                  [
                    {
                      colSpan: 2,
                      text: "Span Information",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["sectionHeader"],
                    },
                    {},
                  ],
                  [
                    {
                      text: "5B11 No. of Main Spans:",
                      bold: true,
                    },
                    {
                      text: structure?.Bridge?.MAINSPANS,
                    },
                  ],
                  [
                    {
                      text: "5B12 Main Span Mat'l Type:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.MAIN_SPAN_MATERIAL,
                        structure?.Bridge?.MATERIALMAIN
                      ),
                    },
                  ],
                  [
                    {
                      text: "5B13 Main Span Design Type:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.MAIN_SPAN_DESIGN,
                        structure?.Bridge?.DESIGNMAIN
                      ),
                    },
                  ],
                  [
                    {
                      text: "5B14 No. of Approach Spans:",
                      bold: true,
                    },
                    {
                      text: structure.Bridge?.APPSPANS,
                    },
                  ],
                  [
                    {
                      text: "5B15 Appr Span Mat'l Type:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.APPROACH_SPAN_MATERIAL,
                        structure?.Bridge?.MATERIALAPPR
                      ),
                    },
                  ],
                  [
                    {
                      text: "5B16 Appr Span Design Type:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.APPROACH_SPAN_DESIGN,
                        structure?.Bridge?.DESIGNAPPR
                      ),
                    },
                  ],
                  [
                    {
                      text: "5B18 Structure Length:",
                      bold: true,
                    },
                    {
                      text: configStore.getMetricToEnglishConversions(
                        PAIR_CODE.METERS_TO_FEET,
                        structure?.Bridge?.LENGTH,
                        ROUNDING_FACTOR.ROUND,
                        true
                      ),
                    },
                  ],
                  [
                    {
                      text: "VD19 Culvert Length:",
                      bold: true,
                    },
                    {
                      text: getCulvertLength(configStore, structure),
                    },
                  ],
                  [
                    {
                      text: "5B19 Deck Area:",
                      bold: true,
                    },
                    {
                      text: configStore.getMetricToEnglishConversions(
                        PAIR_CODE.SQUAREMETERS_TO_SQUAREFEET,
                        structure?.Bridge?.DECK_AREA,
                        ROUNDING_FACTOR.ROUND,
                        true
                      ),
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              table: {
                headerRows: 1,
                widths: ["52%", "*"],
                body: [
                  [
                    {
                      colSpan: 2,
                      text: "Classification Items",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["sectionHeader"],
                    },
                    {},
                  ],
                  [
                    {
                      text: "5E01 NBIS Bridge Length:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.NBIS_BRIDGE_LENGTH,
                        structure?.Bridge?.NBISLEN
                      ),
                    },
                  ],
                  [
                    {
                      text: "5E03 Temporary Structure:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.TEMPORARY_STRUCTURE,
                        structure?.Bridge?.TEMPSTRUC
                      ),
                    },
                  ],
                  [
                    {
                      text: "5E04 Historical Significance:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.HISTORICAL_SIGNIFIGANCE,
                        structure?.Bridge?.HISTSIGN
                      ),
                    },
                  ],
                  [
                    {
                      text: "5A24 Report Group:",
                      bold: true,
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.REPORTING_GROUP,
                        structure?.Bridge?.REPORTGROUP
                      ),
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "2A01 Structure Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      { text: structure.Bridge?.NOTES },
      {
        stack: [
          {
            table: {
              headerRows: 0,
              widths: ["100%"],
              body: [
                [
                  {
                    text: "Structure Type",
                    fillColor: "#ebebeb",
                    alignment: "center",
                    style: ["sectionHeader"],
                  },
                ],
              ],
            },
            layout: "noBorders",
          },
          {
            table: {
              headerRows: 1,
              widths: ["40%", "30%", "30%"],
              body: [
                [
                  { text: "", style: ["tableHeader"] },
                  { text: "Main", style: ["tableHeader"] },
                  { text: "Approach", style: ["tableHeader"] },
                ],
                [
                  { text: "6A26 Material" },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.MATERIAL_MAKEUP,
                      structure?.UserBrdg?.DEPT_MAIN_MATERIAL_TYPE
                    ),
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.MATERIAL_MAKEUP,
                      structure?.UserBrdg?.DEPT_APPR_MATERIAL_TYPE
                    ),
                  },
                ],
                [
                  { text: "6A27 Physical Span Make-Up" },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.PHYSICAL_MAKEUP,
                      structure?.UserBrdg?.DEPT_MAIN_PHYSICAL_TYPE
                    ),
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.PHYSICAL_MAKEUP,
                      structure?.UserBrdg?.DEPT_APPR_PHYSICAL_TYPE
                    ),
                  },
                ],
                [
                  { text: "6A28 Span Interaction" },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.SPAN_INTERACTION,
                      structure?.UserBrdg?.DEPT_MAIN_SPAN_INTERACTION
                    ),
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.SPAN_INTERACTION,
                      structure?.UserBrdg?.DEPT_APPR_SPAN_INTERACTION
                    ),
                  },
                ],
                [
                  { text: "6A29 Structure Type" },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
                      structure?.UserBrdg?.DEPT_MAIN_STRUC_CONFIG
                    ),
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
                      structure?.UserBrdg?.DEPT_APPR_STRUC_CONFIG
                    ),
                  },
                ],
                [
                  { text: "6A33 Wearing Surface Thickness" },
                  {
                    text: configStore.getMetricToEnglishConversions(
                      PAIR_CODE.MILLIMETERS_TO_INCHES,
                      structure?.UserBrdg?.MAIN_WS_THICKNESS,
                      ROUNDING_FACTOR.TENTH,
                      true
                    ),
                  },
                  {
                    text: configStore.getMetricToEnglishConversions(
                      PAIR_CODE.MILLIMETERS_TO_INCHES,
                      structure?.UserBrdg?.APPR_WS_THICKNESS,
                      ROUNDING_FACTOR.TENTH,
                      true
                    ),
                  },
                ],
                [
                  { text: "6A34 WS Thickness Date" },
                  {
                    text: getFormattedDateStringNoTime(
                      structure?.UserBrdg?.MAIN_WS_THICK_DATE
                    ),
                  },
                  {
                    text: getFormattedDateStringNoTime(
                      structure?.UserBrdg?.APPR_WS_THICK_DATE
                    ),
                  },
                ],
                [
                  { text: "6A44 FC Group No." },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.FC_GROUP_NUMBER,
                      structure?.UserBrdg?.MAIN_FC_GROUP_NUM
                    ),
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.FC_GROUP_NUMBER,
                      structure?.UserBrdg?.APPR_FC_GROUP_NUM
                    ),
                  },
                ],
                [
                  { text: "6A45 FC Member Type" },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.MEMBER_TYPE,
                      structure?.UserBrdg?.MAIN_CRF_MEM_TYPE
                    ),
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.MEMBER_TYPE,
                      structure?.UserBrdg?.APPR_CRF_MEM_TYPE
                    ),
                  },
                ],
                [
                  { text: "6A46 FC Fatigue Susceptibility" },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.CRF_FATIGUE_SUSCEPTIBILITY,
                      structure?.UserBrdg?.MAIN_CRF_FATIG_SUS
                    ),
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.CRF_FATIGUE_SUSCEPTIBILITY,
                      structure?.UserBrdg?.APPR_CRF_FATIG_SUS
                    ),
                  },
                ],
                [
                  { text: "6A47 FC Material Type" },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.CRF_MATERIAL,
                      structure?.UserBrdg?.MAIN_CRF_MATERIAL
                    ),
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.CRF_MATERIAL,
                      structure?.UserBrdg?.APPR_CRF_MATERIAL
                    ),
                  },
                ],
                [
                  { text: "6A48 FC ADTT" },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.CUMULATIVE_AVERAGE_DAILY_TRUCK_TRAFFIC,
                      structure?.UserBrdg?.MAIN_CRF_CUM_ADTT
                    ),
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.CUMULATIVE_AVERAGE_DAILY_TRUCK_TRAFFIC,
                      structure?.UserBrdg?.APPR_CRF_CUM_ADTT
                    ),
                  },
                ],
                [
                  { text: "6A49 FC Total CRF" },
                  {
                    text:
                      convertToInt(structure?.UserBrdg?.MAIN_CRF_MEM_TYPE) +
                      convertToInt(structure?.UserBrdg?.MAIN_CRF_FATIG_SUS) +
                      convertToInt(structure?.UserBrdg?.MAIN_CRF_MATERIAL) +
                      convertToInt(structure?.UserBrdg?.MAIN_CRF_CUM_ADTT),
                  },
                  {
                    text:
                      convertToInt(structure?.UserBrdg?.APPR_CRF_MEM_TYPE) +
                      convertToInt(structure?.UserBrdg?.APPR_CRF_FATIG_SUS) +
                      convertToInt(structure?.UserBrdg?.APPR_CRF_MATERIAL) +
                      convertToInt(structure?.UserBrdg?.APPR_CRF_CUM_ADTT),
                  },
                ],
              ],
              margin: [0, 10, 0, 0],
            },
          },
        ],
        unbreakable: true,
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Span-Specific Information",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 10, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["5%", "15%", "10%", "10%", "10%", "10%", "10%", "30%"],
          body: spanDetails,
          margin: [0, 10, 0, 0],
        },
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

function getMunicipality(configStore, code) {
  const m = configStore.getMunicipalityByPlaceCode(code);
  return `${m.countyCode}/${m.municipalityNum} - ${m.municipalityName}`;
}

function getCounty(configStore, code) {
  const c = configStore.getCountyByFips(code);
  return `${c.countyCode} - ${c.countyName}`;
}

function getDistrict(configStore, code) {
  const d = configStore.getDistrictByDistrictNum(code);
  return `${d.title}`;
}

function getCulvertLength(configStore, structure) {
  let structureCulvertLength = "";
  for (let idx in structure?.T_Culvert_Opening) {
    let structureCulvertLengthPlaceHolder =
      configStore.getMetricToEnglishConversions(
        PAIR_CODE.METERS_TO_FEET,
        structure?.T_Culvert_Opening[idx]?.CULVERT_LENGTH,
        ROUNDING_FACTOR.ROUND,
        true
      );
    if (
      idx != structure?.T_Culvert_Opening?.length - 1 &&
      structure?.T_Culvert_Opening[idx]?.CULVERT_LENGTH
    ) {
      structureCulvertLength +=
        structureCulvertLengthPlaceHolder.toString() + ", ";
    } else if (structure?.T_Culvert_Opening[idx]?.CULVERT_LENGTH) {
      structureCulvertLength += structureCulvertLengthPlaceHolder.toString();
    }
  }
  return structureCulvertLength;
}

function getSpecificSpanDetails(structure, specificSpans, configStore) {
  const structureUnits = structure?.Structure_Unit?.filter(
    (x) => x.STRUNITTYPE != "X"
  ).map((s) => {
    return {
      ...s,
      userStrUnit: structure?.UserStrUnit?.find(
        (u) => u.STRUNITKEY === s.STRUNITKEY
      ),
    };
  });
  structureUnits?.forEach((specificSpan) => {
    specificSpans.push(
      [
        specificSpan.STRUNITKEY,
        configStore.getReferenceValue(
          REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
          specificSpan.STRUNITTYPE
        ),
        specificSpan.STRUNITLABEL,
        configStore.getMetricToEnglishConversions(
          PAIR_CODE.METERS_TO_FEET,
          specificSpan.userStrUnit?.SPAN_LENGTH,
          ROUNDING_FACTOR.TENTH
        ),
        configStore.getMetricToEnglishConversions(
          PAIR_CODE.METERS_TO_FEET,
          specificSpan.userStrUnit?.SPAN_DECK_WIDTH,
          ROUNDING_FACTOR.TENTH
        ),
        {
          text:
            specificSpan.userStrUnit?.SPAN_FLARED === "1"
              ? CUSTOM_ICONS.SQUARE_CHECK
              : CUSTOM_ICONS.SQUARE,
          style: "customFontAwesome",
          margin: [0, 10, 0, 0],
        },
        (specificSpan.userStrUnit?.DEPT_MATERIAL_TYPE ?? "") +
          (specificSpan.userStrUnit?.DEPT_PHYSICAL_TYPE ?? "") +
          (specificSpan.userStrUnit?.DEPT_SPAN_INTERACTION ?? "") +
          (specificSpan.userStrUnit?.DEPT_STRUC_CONFIG ?? ""),
        configStore.getDepartmentStructureTypeDescription(
          specificSpan.userStrUnit?.DEPT_MATERIAL_TYPE +
            specificSpan.userStrUnit?.DEPT_PHYSICAL_TYPE +
            specificSpan.userStrUnit?.DEPT_SPAN_INTERACTION +
            specificSpan.userStrUnit?.DEPT_STRUC_CONFIG
        ),
      ].map((d) => {
        return {
          text: d,
          alignment: "center",
        };
      })
    );
  });
}

function getDetailsHeader() {
  return [
    {
      text: "5D01\nUnit Key",
      style: ["tableHeader"],
    },
    {
      text: "5D04/SP01\nType",
      style: ["tableHeader"],
    },
    {
      text: "5D02/SP02\nUnit ID",
      style: ["tableHeader"],
    },
    {
      text: "SP03\nSpan Length",
      style: ["tableHeader"],
    },
    {
      text: "SP04\nDeck Width",
      style: ["tableHeader"],
    },
    {
      text: "SP05\nFlared?",
      style: ["tableHeader"],
    },
    {
      text: "SP07 to SP10\nStructural Config",
      style: ["tableHeader"],
    },
    {
      text: "Structure Type Description",
      style: ["tableHeader"],
    },
  ];
}

export { setGeneralDocDefinition };
