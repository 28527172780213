import { defineStore } from "pinia";
import { ref } from "vue";
import { INSPECTION_LINKS_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { clone } from "@/util/clone";
import { MODULE_TYPES } from "@/constants/InspectionConstants";

export const useCulvertStore = defineStore("culvert", () => {
  //culvert data is loaded either from idb (pouch db for inspection module) or service (for management module)
  let culvertData = ref({ inspection: {}, management: {} });
  let isSearching = ref(false);

  const loadCulvertObject = async (
    moduleType,
    brkey,
    inspectionId,
    structure
  ) => {
    if (moduleType === MODULE_TYPES.INSPECTION) {
      //if moduleType is inspection, load data from pouchDB
      convertToInspModuleObject(structure);
    } else if (moduleType === MODULE_TYPES.MANAGEMENT) {
      //if moduleType is management, load data from service call
      await getInspLinkCulvert(brkey, inspectionId);
    }
  };

  const getCulvertObject = (moduleType) => {
    return clone(culvertData.value[moduleType]);
  };

  const saveCulvert = async (moduleType, culvertValue, structure) => {
    let isSuccess = false;
    if (moduleType === MODULE_TYPES.INSPECTION) {
      convertToSaveInspModuleObject(culvertValue, structure);
      isSuccess = true;
    } else if (moduleType === MODULE_TYPES.MANAGEMENT) {
      isSuccess = await updateCulvert(
        convertToSaveInspLinkObject(culvertValue)
      );
    }
    return isSuccess;
  };

  const updateCulvert = async (culvert) => {
    let isSuccess = true;
    const url = INSPECTION_LINKS_ENDPOINT.UPDATE_CULVERT;
    await axios
      .put(url, culvert)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update Culvert",
          response
        );
        convertToInspLinkObject(response.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const convertToSaveInspLinkObject = (culvert) => {
    return {
      brkey: culvert.BRKEY,
      inspectionId: culvert.INSPECTIONID,
      culvRating: culvert.CULVRATING,
      inspectionComments: culvert.inspectionComments,
      matStoreUnd: culvert.MAT_STORE_UND,
      newPaint: culvert.NEW_PAINT,
      paintCondRate: culvert.PAINT_COND_RATE,
      paintExtentRate: culvert.PAINT_EXTENT_RATE,
      culvertStructureSpanSets: culvert.culvertStructureSpanSets,
      totalNumberOfSpans: culvert.totalNumberOfSpans,

      culvDuration: culvert.CULV_DURATION,
      paintDuration: culvert.PANIT_DURATION,
      paintExtDuration: culvert.PAINT_EXT_DURATION,
    };
  };

  const convertToSaveInspModuleObject = (culvertValue, structure) => {
    structure.InspEvnt.CULVRATING = culvertValue.CULVRATING;
    structure.UserInsp.MAT_STORE_UND = culvertValue.MAT_STORE_UND;
    structure.UserInsp.NEW_PAINT = culvertValue.NEW_PAINT;
    structure.UserInsp.PAINT_COND_RATE = culvertValue.PAINT_COND_RATE;
    structure.UserInsp.PAINT_EXTENT_RATE = culvertValue.PAINT_EXTENT_RATE;
    structure.Bridge.MAINSPANS = culvertValue.MAINSPANS;
    structure.Bridge.APPSPANS = culvertValue.APPSPANS;
  };

  const convertToInspModuleObject = (structure) => {
    culvertData.value.management = {};
    culvertData.value.inspection = {
      CULVRATING: structure.InspEvnt.CULVRATING,
      MAT_STORE_UND: structure.UserInsp.MAT_STORE_UND,
      NEW_PAINT: structure.UserInsp.NEW_PAINT,
      PAINT_COND_RATE: structure.UserInsp.PAINT_COND_RATE,
      PAINT_EXTENT_RATE: structure.UserInsp.PAINT_EXTENT_RATE,
      MAINSPANS: structure.Bridge.MAINSPANS,
      APPSPANS: structure.Bridge.APPSPANS,

      CULV_DURATION: structure.T_Insp_Condition_Hist?.CULV_DURATION,
      CULV_DURATION_CHANGES: structure.T_Insp_Condition_Hist?.CULV_DURATION_CHANGES,
      PANIT_DURATION: structure.T_Insp_Condition_Hist?.PANIT_DURATION,
      PANIT_DURATION_CHANGES: structure.T_Insp_Condition_Hist?.PANIT_DURATION_CHANGES,
      PAINT_EXT_DURATION: structure.T_Insp_Condition_Hist?.PAINT_EXT_DURATION,
      PAINT_EXT_DURATION_CHANGES: structure.T_Insp_Condition_Hist?.PAINT_EXT_DURATION_CHANGES
    };
  };

  const getInspLinkCulvert = async (brkey, inspectionId) => {
    //clear idb object
    culvertData.value.inspection = {};

    let url = INSPECTION_LINKS_ENDPOINT.GET_CULVERT.replace(
      "{brkey}",
      brkey
    ).replace("{inspectionId}", inspectionId);

    isSearching.value = true;
    await axios
      .get(url)
      .then((resp) => {
        isSearching.value = false;
        convertToInspLinkObject(resp.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSearching.value = false;
      });
  };

  const convertToInspLinkObject = (culvert) => {
    culvertData.value.inspection = {};
    culvertData.value.management = {
      BRKEY: culvert.brkey,
      INSPECTIONID: culvert.inspectionId,
      CULVRATING: culvert.culvRating,
      inspectionComments: culvert.inspectionComments,
      MAT_STORE_UND: culvert.matStoreUnd,
      NEW_PAINT: culvert.newPaint,
      PAINT_COND_RATE: culvert.paintCondRate,
      PAINT_EXTENT_RATE: culvert.paintExtentRate,
      culvertStructureSpanSets: culvert.culvertStructureSpanSets,
      totalNumberOfSpans: culvert.totalNumberOfSpans,

      CULV_DURATION: culvert.culvDuration,
      PANIT_DURATION: culvert.paintDuration,
      PAINT_EXT_DURATION: culvert.paintExtDuration,
    };
  };

  return {
    culvertData,
    getCulvertObject,
    saveCulvert,
    loadCulvertObject,
  };
});
