<template>
  <v-row>
    <ExpandCollapseToggle
      id="approachPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel style="margin-top: 20px">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Approach Conditions</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="valid" ref="modifyApproachValidation">
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Approach_Alignment"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      approachValue?.APPRALIGN_DURATION,
                      approachValue?.APPRALIGN_DURATION_CHANGES
                    )
                  "
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  label="B.AP.01 Approach Roadway Alignment"
                  :refTable="
                    REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL
                  "
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachAlignmentUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="approachValue.APPRALIGN"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="12" class="pt-4">
                <CommentTypeTextField
                  v-if="moduleType == MODULE_TYPES.INSPECTION"
                  label="Alignment Notes"
                  id="field_alignment_notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.APPROACH_ALIGNMENT
                  "
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                  @changesDetected="inspectionStore.setDirtyFlag(true)"
                />
                <ManagementCommentTypeTextField
                  v-else
                  label="Alignment Notes"
                  id="field_alignment_notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.APPROACH_ALIGNMENT
                  "
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                  :comments="approachValue.inspectionComments"
                  :brkey="approachValue.brkey"
                  :inspectionId="approachValue.inspectionId"
                  @updateComment="
                    (comment) =>
                      setUpdatedComment(
                        comment,
                        INSPECTION_COMMENTS.APPROACH_ALIGNMENT
                      )
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Approach_Roadway"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      approachValue?.APPR_ROADWAY_DURATION,
                      approachValue?.APPR_ROADWAY_DURATION_CHANGES
                    )
                  "
                  label="6B39 Approach Roadway"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachRoadwayUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="approachValue.APPR_ROAD_COND_RATE"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Approach_Slab"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      approachValue?.APPR_SLAB_DURATION,
                      approachValue?.APPR_SLAB_DURATION_CHANGES
                    )
                  "
                  label="6B38 Approach Slab"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachSlabUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="approachValue.APPR_SLAB_COND_RATE"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Railing"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      approachValue?.RAILRATING_DURATION,
                      approachValue?.RAILRATING_DURATION_CHANGES
                    )
                  "
                  label="B.C.05 Railing"
                  :refTable="REFERENCE_TABLE.RAILING_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachRailingUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="approachValue.RAILRATING"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Transition"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      approachValue?.TRANSRATIN_DURATION,
                      approachValue?.TRANSRATIN_DURATION_CHANGES
                    )
                  "
                  label="B.C.06 Transition"
                  :refTable="REFERENCE_TABLE.TRANSITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachTransitionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="approachValue.TRANSRATIN"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect
              ></v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />
          <h3>Approach Details and Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <LabelText
            id="field_Pavement_Width"
            label="6A43 Approach Pavement Width"
            v-model="approachValue.APPR_PAVEMENT_WIDTH"
            appendWith=" ft"
            type="number"
            :pairCode="PAIR_CODE.METERS_TO_FEET"
            :roundingFactor="ROUNDING_FACTOR.ROUND"
            noGrid
            class="pt-4"
          >
          </LabelText>
          <div>
            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="Pavement"
              id="field_Pavement"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_PAVEMENT"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              @changesDetected="inspectionStore.setDirtyFlag(true)"
            />
            <ManagementCommentTypeTextField
              v-else
              label="Pavement"
              id="field_Pavement"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_PAVEMENT"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              :comments="approachValue.inspectionComments"
              :brkey="approachValue.brkey"
              :inspectionId="approachValue.inspectionId"
              @updateComment="
                (comment) =>
                  setUpdatedComment(
                    comment,
                    INSPECTION_COMMENTS.APPROACH_PAVEMENT
                  )
              "
            />
          </div>

          <div>
            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="Drainage"
              id="field_Drainage"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_DRAINAGE"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              @changesDetected="inspectionStore.setDirtyFlag(true)"
            />
            <ManagementCommentTypeTextField
              v-else
              label="Drainage"
              id="field_Drainage"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_DRAINAGE"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              :comments="approachValue.inspectionComments"
              :brkey="approachValue.brkey"
              :inspectionId="approachValue.inspectionId"
              @updateComment="
                (comment) =>
                  setUpdatedComment(
                    comment,
                    INSPECTION_COMMENTS.APPROACH_DRAINAGE
                  )
              "
            />
          </div>

          <div>
            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="Shoulders"
              id="field_Shoulders"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_SHOULDERS"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              @changesDetected="inspectionStore.setDirtyFlag(true)"
            />
            <ManagementCommentTypeTextField
              v-else
              label="Shoulders"
              id="field_Shoulders"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_SHOULDERS"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              :comments="approachValue.inspectionComments"
              :brkey="approachValue.brkey"
              :inspectionId="approachValue.inspectionId"
              @updateComment="
                (comment) =>
                  setUpdatedComment(
                    comment,
                    INSPECTION_COMMENTS.APPROACH_SHOULDERS
                  )
              "
            />
          </div>

          <div>
            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="Approach Slab"
              id="field_Approach_Slab"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_SLAB"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              @changesDetected="inspectionStore.setDirtyFlag(true)"
            />
            <ManagementCommentTypeTextField
              v-else
              label="Approach Slab"
              id="field_Approach_Slab"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_SLAB"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              :comments="approachValue.inspectionComments"
              :brkey="approachValue.brkey"
              :inspectionId="approachValue.inspectionId"
              @updateComment="
                (comment) =>
                  setUpdatedComment(comment, INSPECTION_COMMENTS.APPROACH_SLAB)
              "
            />
          </div>
          <div>
            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="6A39 Pavement Relief Joints Present?"
              :showColon="false"
              :labelValue="pavementReliefJointsPresent"
              id="field_Pavement_Relief_Joints_Present"
              :inspectionCommentType="
                INSPECTION_COMMENTS.PAVEMENT_RELIEF_JOINTS
              "
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              @changesDetected="inspectionStore.setDirtyFlag(true)"
            />
            <ManagementCommentTypeTextField
              v-else
              label="6A39 Pavement Relief Joints Present?"
              id="field_Pavement_Relief_Joints_Present"
              :inspectionCommentType="
                INSPECTION_COMMENTS.PAVEMENT_RELIEF_JOINTS
              "
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              :comments="approachValue.inspectionComments"
              :brkey="approachValue.brkey"
              :inspectionId="approachValue.inspectionId"
              @updateComment="
                (comment) =>
                  setUpdatedComment(
                    comment,
                    INSPECTION_COMMENTS.PAVEMENT_RELIEF_JOINTS
                  )
              "
            />
          </div>
          <div>
            <v-row>
              <v-label class="bold text-decoration-underline"
                >Railings:</v-label
              >
            </v-row>
            <v-row>
              <v-col class="pa-0">
                <LabelSelect
                  labelXl="2"
                  labelLg="2"
                  labelMd="2"
                  labelSm="2"
                  id="notes_Sel_Bridge_Railing"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      approachValue?.RAILRATING_DURATION,
                      approachValue?.RAILRATING_DURATION_CHANGES
                    )
                  "
                  label="B.C.05 Condition"
                  :refTable="REFERENCE_TABLE.RAILING_RATING"
                  v-model="approachValue.RAILRATING"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0">
                <LabelText
                  labelXl="2"
                  labelLg="2"
                  labelMd="2"
                  labelSm="2"
                  id="crash_level"
                  label="B.RH.01 Crash Level"
                  :model-value="approachValue.CRASH_LEVEL_BRIDGE_RAILINGS"
                  class="pb-0"
                >
                </LabelText>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0">
                <LabelText
                  v-if="moduleType == MODULE_TYPES.INSPECTION"
                  labelXl="2"
                  labelLg="2"
                  labelMd="2"
                  labelSm="2"
                  id="text_Bridge_Railing_desc"
                  label="Description"
                  :isEditable="isEditable"
                  v-model="bridgeRailingDesc"
                  maxlength="50"
                  type="textarea"
                  counter
                  persistentCounter
                  class="pb-0"
                />
                <LabelText
                  v-else
                  labelXl="2"
                  labelLg="2"
                  labelMd="2"
                  labelSm="2"
                  id="text_Bridge_Railing_desc"
                  label="Description"
                  :isEditable="isEditable"
                  v-model="approachValue.BRIDGE_RAILING_DESC"
                  maxlength="50"
                  type="textarea"
                  counter
                  persistentCounter
                  class="pb-0"
                />
              </v-col>
            </v-row>

            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="Notes"
              id="field_Bridge_Railing_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.BRIDGE_RAILING"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              @changesDetected="inspectionStore.setDirtyFlag(true)"
            />
            <ManagementCommentTypeTextField
              v-else
              label="Notes"
              id="field_Bridge_Railing_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.BRIDGE_RAILING"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              :comments="approachValue.inspectionComments"
              :brkey="approachValue.brkey"
              :inspectionId="approachValue.inspectionId"
              @updateComment="
                (comment) =>
                  setUpdatedComment(comment, INSPECTION_COMMENTS.BRIDGE_RAILING)
              "
            />
          </div>
          <div class="pt-1">
            <v-row>
              <v-label class="bold text-decoration-underline"
                >Transitions:</v-label
              >
            </v-row>
            <v-row>
              <v-col class="pa-0">
                <LabelSelect
                  labelXl="2"
                  labelLg="2"
                  labelMd="2"
                  labelSm="2"
                  id="notes_Sel_transitions"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      approachValue?.TRANSRATIN_DURATION,
                      approachValue?.TRANSRATIN_DURATION_CHANGES
                    )
                  "
                  label="B.C.06 Condition"
                  :refTable="REFERENCE_TABLE.TRANSITION_RATING"
                  v-model="approachValue.TRANSRATIN"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0">
                <LabelText
                  labelXl="2"
                  labelLg="2"
                  labelMd="2"
                  labelSm="2"
                  id="transitions_crash_level"
                  label="B.RH.02 Crash Level"
                  :model-value="approachValue.CRASH_LEVEL_TRANSITIONS"
                  maxlength="4"
                >
                </LabelText>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0">
                <LabelText
                  v-if="moduleType == MODULE_TYPES.INSPECTION"
                  labelXl="2"
                  labelLg="2"
                  labelMd="2"
                  labelSm="2"
                  id="text_Transition_desc"
                  label="Description"
                  :isEditable="isEditable"
                  v-model="transitionDesc"
                  maxlength="50"
                  type="textarea"
                  counter
                  persistentCounter
                  class="pb-0"
                />
                <LabelText
                  v-else
                  labelXl="2"
                  labelLg="2"
                  labelMd="2"
                  labelSm="2"
                  id="text_Transition_desc"
                  label="Description"
                  :isEditable="isEditable"
                  v-model="approachValue.TRANSITION_DESC"
                  maxlength="50"
                  type="textarea"
                  counter
                  persistentCounter
                  class="pb-0"
                />
              </v-col>
            </v-row>

            <CommentTypeTextField
              v-if="moduleType == MODULE_TYPES.INSPECTION"
              label="Notes"
              id="field_Transition_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.TRANSITION"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              @changesDetected="inspectionStore.setDirtyFlag(true)"
            />
            <ManagementCommentTypeTextField
              v-else
              label="Notes"
              id="field_Transition_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.TRANSITION"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              :comments="approachValue.inspectionComments"
              :brkey="approachValue.brkey"
              :inspectionId="approachValue.inspectionId"
              @updateComment="
                (comment) =>
                  setUpdatedComment(comment, INSPECTION_COMMENTS.TRANSITION)
              "
            />
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel @group:selected="checkValidation">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(2)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(2)" icon="fa-solid fa-minus" />
          <h3>Signs</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-row>
            <v-col>
              <v-row class="d-flex align-center mx-0 mt-2 mb-4"
                ><v-btn
                  v-if="isEditable"
                  id="btn_addSign"
                  variant="outlined"
                  @click="addNewSign"
                  :disabled="
                    !isEditable ||
                    expandedRows?.length > 0 ||
                    availableSignTypes?.length <= 0
                  "
                  >Add Sign</v-btn
                >
                <IconTooltip
                  id="icon_noSignAvailable"
                  icon="fas fa-circle-info"
                  iconclass="infoIcon"
                  :tooltip="INSPECTION_MESSAGES.NO_SIGN_TYPES_AVAILABLE_TO_ADD"
                  v-if="isEditable && availableSignTypes?.length == 0"
              /></v-row>

              <v-data-table
                id="approach-data-table"
                v-model:expanded="expandedRows"
                :headers="headers"
                :items="signs"
                class="compactTable lessPaddingTableColumns"
                v-model:sort-by="sortBy"
                hide-default-footer
                must-sort
                show-expand
                return-object
              >
                <template v-slot:[`header.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`header.SIGN_TYPE`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`header.SIGN_MESSAGE`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`header.NEAR_ADVANCE_COND`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`header.FAR_ADVANCE_COND`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`header.NEAR_SITE_COND`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`header.FAR_SITE_COND`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`header.action`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>

                <template v-slot:expanded-row="{ item }">
                  <td :colspan="headers.length">
                    <v-form
                      ref="signDetailForm"
                      v-model="validDetails"
                      class="mt-2"
                      @change="changesDetected = true"
                    >
                      <v-row>
                        <v-col sm="6" xs="12" class="pa-0">
                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_sign_type"
                            label="ID01 Type of Sign"
                            :options="
                              item?.isAdd ? availableSignTypes : allSignTypes
                            "
                            :isEditable="isEditable && item.isAdd"
                            isRequired
                            v-model="item.SIGN_TYPE"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>

                          <LabelText
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            textXl="6"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="sel_sign_message"
                            :isEditable="isEditable && !item.readOnly"
                            label="ID03 Sign Message"
                            v-model="item.SIGN_MESSAGE"
                            maxlength="10"
                            counter="10"
                          >
                          </LabelText>

                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_near_advance"
                            label="ID04 Near Advance"
                            :refTable="REFERENCE_TABLE.SIGN_CONDITION_RAITING"
                            :isEditable="isEditable && !item.readOnly"
                            isRequired
                            v-model="item.NEAR_ADVANCE_COND"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>
                        </v-col>

                        <v-col sm="6" xs="12" class="pa-0">
                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_far_advance"
                            label="ID05 Far Advance"
                            :refTable="REFERENCE_TABLE.SIGN_CONDITION_RAITING"
                            :isEditable="isEditable && !item.readOnly"
                            isRequired
                            v-model="item.FAR_ADVANCE_COND"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_near_bridge_site"
                            label="ID06 Near Bridge Site"
                            :refTable="REFERENCE_TABLE.SIGN_CONDITION_RAITING"
                            :isEditable="isEditable && !item.readOnly"
                            isRequired
                            v-model="item.NEAR_SITE_COND"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_far_bridge_site"
                            label="ID07 Far Bridge Site"
                            :refTable="REFERENCE_TABLE.SIGN_CONDITION_RAITING"
                            :isEditable="isEditable && !item.readOnly"
                            isRequired
                            v-model="item.FAR_SITE_COND"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <LabelText
                            labelXl="12"
                            labelLg="12"
                            labelMd="12"
                            labelSm="12"
                            textXl="12"
                            textLg="12"
                            textMd="12"
                            textSm="12"
                            id="sel_signing_notes"
                            type="textarea"
                            :isEditable="isEditable && !item.readOnly"
                            label="ID08 Signing Notes"
                            v-model="item.NOTES"
                            maxlength="255"
                          >
                          </LabelText>
                        </v-col>
                      </v-row>
                    </v-form>
                  </td>
                </template>

                <template v-slot:item="{ item }">
                  <tr :class="rowClass(item)">
                    <td class="alignCenter">
                      <template
                        v-if="
                          expandedRows?.length == 0 || !anySignDetailEditable
                        "
                      >
                        <button
                          v-if="!expandedRows.includes(item)"
                          @click="toggleRow(item)"
                          aria-label="toggleRowButtonOpen"
                        >
                          <v-icon
                            :id="`icon_Chevron_Right_${item.SIGN_TYPE}`"
                            icon="fa-solid fa-chevron-right"
                          ></v-icon>
                        </button>

                        <button
                          v-if="expandedRows.includes(item)"
                          @click="toggleRow(item)"
                          aria-label="toggleRowButtonClosed"
                        >
                          <v-icon
                            :id="`icon_Chevron_Down_${item.SIGN_TYPE}`"
                            icon="fa-solid fa-chevron-down"
                          ></v-icon>
                        </button>
                      </template>
                    </td>
                    <td class="alignCenter">
                      {{
                        item.SIGN_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_TYPE,
                              item.SIGN_TYPE
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">{{ item.SIGN_MESSAGE }}</td>
                    <td class="alignCenter">
                      {{
                        item.NEAR_ADVANCE_COND
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_CONDITION_RAITING,
                              item.NEAR_ADVANCE_COND
                            )
                          : ""
                      }}
                    </td>

                    <td class="alignCenter">
                      {{
                        item.FAR_ADVANCE_COND
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_CONDITION_RAITING,
                              item.FAR_ADVANCE_COND
                            )
                          : ""
                      }}
                    </td>

                    <td class="alignCenter">
                      {{
                        item.NEAR_SITE_COND
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_CONDITION_RAITING,
                              item.NEAR_SITE_COND
                            )
                          : ""
                      }}
                    </td>

                    <td class="alignCenter">
                      {{
                        item.FAR_SITE_COND
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_CONDITION_RAITING,
                              item.FAR_SITE_COND
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      <div
                        style="white-space: nowrap"
                        v-if="!item.readOnly && isEditable"
                      >
                        <IconTooltip
                          id="icon_add"
                          icon="fa-solid fa-check"
                          :tooltip="item?.isAdd ? 'Add Sign' : 'Update Sign'"
                          @click="saveDetail(item)"
                        />
                        <IconTooltip
                          id="icon_close"
                          icon="fa-solid fa-xmark"
                          tooltip="Close"
                          @click="closeDetail"
                        />
                      </div>
                      <div
                        style="white-space: nowrap"
                        v-else-if="
                          isEditable &&
                          (expandedRows?.length == 0 || !anySignDetailEditable)
                        "
                      >
                        <IconTooltip
                          id="icon_edit"
                          icon="fa-solid fa-pencil"
                          tooltip="Edit"
                          @click="editDetail(item)"
                        />
                        <IconTooltip
                          id="icon_delete"
                          icon="fa-solid fa-trash"
                          tooltip="Delete"
                          @click="deleteDetail(item)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:bottom> </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>

<script>
import { computed, ref, watch, nextTick, onMounted } from "vue";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import { getHistDuration } from "@/composables/util";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { clone } from "@/util/clone";
import IconTooltip from "@/components/common/IconTooltip.vue";
import sign from "@/data/sign";
import { LOGGER } from "@/util/logger";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import {
  INSPECTION_MESSAGES,
  MODULE_TYPES,
} from "@/constants/InspectionConstants";
import { BUTTONS } from "@/constants/CommonWebConstants";
import safetyFeature from "@/data/safetyFeature";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import { scrollToDataTable } from "@/util/scrollToElement";
import useApproachData from "@/composables/approachData";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { useApproachStore } from "@/components/inspection/store/approach";
import { useQuickSearchStore } from "@/stores/quickSearch";
import { useInspectionLinksStore } from "@/stores/inspectionLinks";
import ManagementCommentTypeTextField from "@/components/common/ManagementCommentTypeTextField.vue";
import { INSPECTION_LINKS_APPROACH_PERMISSIONS } from "@/rbac";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";
import { useUserPermissionStore } from "@/stores/userPermission";
import { SNACKBAR_MARKER } from "@/constants/GlobalSnackbar";
import { storeToRefs } from "pinia";

export default {
  name: "InspectionApproach",
  data() {
    return {
      headers: [
        {
          key: "data-table-expand",
        },
        {
          line1: "ID01",
          line2: "Type of Sign",
          line3: null,
          key: "SIGN_TYPE",
          sortable: true,
        },
        {
          line1: "ID03",
          line2: "Sign Message",
          line3: null,
          key: "SIGN_MESSAGE",
          sortable: true,
        },
        {
          line1: "ID04",
          line2: "Near Advance",
          line3: null,
          key: "NEAR_ADVANCE_COND",
          sortable: true,
        },
        {
          line1: "ID05",
          line2: "Far Advance",
          line3: null,
          key: "FAR_ADVANCE_COND",
          sortable: true,
        },
        {
          line1: "ID06",
          line2: "Bridge Site Near",
          line3: null,
          key: "NEAR_SITE_COND",
          sortable: true,
        },
        {
          line1: "ID07",
          line2: "Bridge Site Far",
          line3: null,
          key: "FAR_SITE_COND",
          sortable: true,
        },
        {
          line1: null,
          line2: "Action",
          line3: null,
          key: "action",
          sortable: false,
        },
      ],
    };
  },
  props: {
    moduleType: { default: MODULE_TYPES.INSPECTION },
  },
  emits: ["showMessage"],
  setup(props, ctx) {
    const configStore = useConfigStore();
    const snackbarStore = useSnackbarStore();
    const inspectionStore = useInspectionStore();
    const isEditable = computed(() => {
      if (props.moduleType == MODULE_TYPES.MANAGEMENT) {
        return (
          !quickSearchStore.getErrorMessage() &&
          approachValue.value?.BRKEY &&
          hasAccessToEdit.value
        );
      } else {
        return inspectionStore.isEditable;
      }
    });
    let structure = computed(() => inspectionStore.selectedInspection);
    const allPanels = [0, 1, 2];
    const panel = ref(allPanels);
    const { pavementReliefJointsPresent, bridgeRailingDesc, transitionDesc } =
      useApproachData();

    let valid = ref(true);
    let modifyApproachValidation = ref(null);
    async function validate() {
      await modifyApproachValidation.value.validate();
    }
    let approachAlignmentUpdated = ref(false);
    let conditionUpdated = ref(false);
    let approachRoadwayUpdated = ref(false);
    let approachSlabUpdated = ref(false);
    let approachRailingUpdated = ref(false);
    let approachTransitionUpdated = ref(false);
    let snackBarId = ref("");
    let previousSort = ref([{ key: "SIGN_TYPE", order: "asc" }]);
    let sortBy = ref([{ key: "SIGN_TYPE", order: "asc" }]);
    let approachValue = ref({});
    const approachStore = useApproachStore();
    const quickSearchStore = useQuickSearchStore();
    const { hasQuickSearchSucceeded } = storeToRefs(quickSearchStore);

    const structureHeader = computed(() => quickSearchStore.getStructure());
    let resetDirty = ref(false);
    const inspectionLinksStore = useInspectionLinksStore();
    const userPermissionStore = useUserPermissionStore();
    let brkey = ref("");
    let inspectionId = ref("");
    let signs = ref([]);

    onMounted(async () => {
      await readData();
    });

    const hasAccessToEdit = computed(() =>
      userPermissionStore.checkUserPermission(
        INSPECTION_LINKS_APPROACH_PERMISSIONS[ACCESS_LEVELS.EDIT],
        userPermissionStore.getUserPermissionByBrkeyFromCache(
          approachValue.value.BRKEY
        )
      )
    );

    const readData = async () => {
      approachValue.value = {};
      brkey.value = "";
      inspectionId.value = "";
      if (
        props.moduleType == MODULE_TYPES.MANAGEMENT &&
        structureHeader.value?.BRKEY
      ) {
        if (!hasQuickSearchSucceeded.value) {
          resetDirty.value = true;
          return;
        }
        brkey.value = structureHeader.value.BRKEY;
        inspectionId.value = structureHeader.value.INSPECTIONID;
        await approachStore.loadDataObject(
          props.moduleType,
          structureHeader.value.BRKEY,
          structureHeader.value.INSPECTIONID
        );
        approachValue.value = approachStore.getApproachObject(props.moduleType);
        checkIfError();
      } else if (props.moduleType == MODULE_TYPES.INSPECTION) {
        const structure = computed(() => inspectionStore.selectedInspection);
        brkey.value = structure.value.Bridge.BRKEY;
        inspectionId.value = structure.value.InspEvnt.INSPKEY;
        await approachStore.loadDataObject(
          props.moduleType,
          null,
          null,
          structure.value
        );
        approachValue.value = approachStore.getApproachObject(props.moduleType);
        defaultEmptySafetyFeature();
      }
      resetDirty.value = true;
      signs.value = getSignDetails();
      await validate();
    };

    const updateDuration = () => {
      if (approachAlignmentUpdated.value) {
        approachStore.APPRALIGN_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            approachStore.APPRALIGN_DURATION_CHANGES
          );
        approachStore.APPRALIGN_DURATION = inspectionStore.setDuration(
          approachStore.SUB_DURATION,
          approachStore.APPRALIGN_DURATION_CHANGES
        );
      }
      if (approachRoadwayUpdated.value) {
        approachStore.APPR_ROADWAY_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            approachStore.APPR_ROADWAY_DURATION_CHANGES
          );
        approachStore.APPR_ROADWAY_DURATION = inspectionStore.setDuration(
          approachStore.APPR_ROADWAY_DURATION,
          approachStore.APPR_ROADWAY_DURATION_CHANGES
        );
      }
      if (approachSlabUpdated.value) {
        approachStore.APPR_SLAB_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            approachStore.APPR_SLAB_DURATION_CHANGES
          );
        approachStore.APPR_SLAB_DURATION = inspectionStore.setDuration(
          approachStore.APPR_SLAB_DURATION,
          approachStore.APPR_SLAB_DURATION_CHANGES
        );
      }
      if (approachRailingUpdated.value) {
        approachStore.RAILRATING_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            approachStore.RAILRATING_DURATION_CHANGES
          );
        approachStore.RAILRATING_DURATION = inspectionStore.setDuration(
          approachStore.RAILRATING_DURATION,
          approachStore.RAILRATING_DURATION_CHANGES
        );
      }
      if (approachTransitionUpdated.value) {
        approachStore.TRANSRATIN_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            approachStore.TRANSRATIN_DURATION_CHANGES
          );
        approachStore.TRANSRATIN_DURATION = inspectionStore.setDuration(
          approachStore.TRANSRATIN_DURATION,
          approachStore.TRANSRATIN_DURATION_CHANGES
        );
      }

      approachTransitionUpdated.value = false;
      approachAlignmentUpdated.value = false;
      approachRailingUpdated.value = false;
      approachRoadwayUpdated.value = false;
      approachSlabUpdated.value = false;
      conditionUpdated.value = false;
    };
    let signDetailForm = ref(null);
    let validDetails = ref(false);
    let changesDetected = ref(false);
    const closeConfirm = ref(null);
    const allSignTypes = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.SIGN_TYPE)
    );

    if (!isEditable.value) {
      panel.value = allPanels;
    }

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    let expandedRows = ref([]);

    const getNewSign = () => {
      return clone(sign);
    };

    const availableSignTypes = computed(() =>
      allSignTypes.value.filter(
        (t) =>
          !signs.value
            ?.filter((s) => !s.isAdd)
            ?.map((s) => s.SIGN_TYPE)
            .includes(t.value.toString())
      )
    );

    const getSignDetails = () =>
      approachValue.value.SIGNS?.filter((p) => !p?.deleted)
        ?.map((i) => {
          return { ...i, readOnly: true };
        })
        ?.sort((a, b) => a?.SIGN_TYPE - b?.SIGN_TYPE);

    const closeDetail = () => {
      if (isEditable.value && changesDetected.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              signs.value = getSignDetails();
              expandedRows.value = [];
              inspectionStore.setAddingOrEditing(false);
              snackbarStore.removeMessage(snackBarId.value);
              changesDetected.value = false;
              sortBy.value = previousSort.value?.map((x) => x);
            }
          });
      } else {
        signs.value = getSignDetails();
        expandedRows.value = [];
        inspectionStore.setAddingOrEditing(false);
        snackbarStore.removeMessage(snackBarId.value);
        sortBy.value = previousSort.value?.map((x) => x);
      }
    };

    const saveDetail = (detail) => {
      signDetailForm.value
        .validate()
        .then(() => {
          if (validDetails.value) {
            inspectionStore.setShowValidationSnackbar(false);
            if (detail.isAdd) {
              approachValue.value?.SIGNS.push(
                convertToSignDetailsObject(detail)
              );
            } else {
              const updateIndex = approachValue.value?.SIGNS.findIndex(
                (object) => {
                  return object.SIGN_TYPE === detail.SIGN_TYPE;
                }
              );
              approachValue.value?.SIGNS.splice(
                updateIndex,
                1,
                convertToSignDetailsObject(detail)
              );
            }
            //if all required fields entered - close the expanded row
            expandedRows.value = [];
            changesDetected.value = false;
            detail.readOnly = true;
            detail.isAdd = false;
            inspectionStore.setAddingOrEditing(false);
            snackbarStore.removeMessage(snackBarId.value);
            sortBy.value = previousSort.value?.map((x) => x);
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => LOGGER.logException(err));
    };

    const convertToSignDetailsObject = (details) => {
      let signDetail = getNewSign();
      signDetail.BRKEY = details.BRKEY;
      signDetail.INSPKEY = details.INSPKEY;

      signDetail.SIGN_TYPE = details.SIGN_TYPE;
      signDetail.SIGN_MESSAGE = details.SIGN_MESSAGE;
      signDetail.NEAR_ADVANCE_COND = details.NEAR_ADVANCE_COND;
      signDetail.FAR_ADVANCE_COND = details.FAR_ADVANCE_COND;
      signDetail.NEAR_SITE_COND = details.NEAR_SITE_COND;
      signDetail.FAR_SITE_COND = details.FAR_SITE_COND;
      signDetail.USERKEY = details.USERKEY;
      signDetail.NOTES = details.NOTES;
      signDetail.SIGN_NEEDED = "1";

      return signDetail;
    };

    const editDetail = (detail) => {
      previousSort.value = sortBy.value.map((x) => x);
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.SIGNS_DATATABLE,
        marker: SNACKBAR_MARKER.INSPECTION_LINK,
      });
      detail.readOnly = false;
      detail.isAdd = false;
      expandedRows.value.push(detail);
      changesDetected.value = false;
      sortBy.value = [];
      nextTick(() => {
        signDetailForm.value?.validate();
      });
    };

    const toggleRow = (detail) => {
      if (expandedRows.value.includes(detail)) {
        const index = expandedRows.value.findIndex(
          (d) => d.SIGN_TYPE == detail.SIGN_TYPE
        );
        expandedRows.value.splice(index, 1);
      } else {
        expandedRows.value.push(detail);
      }
    };

    const deleteDetail = (detail) => {
      //remove from the signs array
      const index = signs.value.findIndex(
        (d) => d.SIGN_TYPE == detail.SIGN_TYPE
      );

      if (index > -1) {
        signs.value?.splice(index, 1);
      }

      const indexFromMainApproachObject = approachValue.value?.SIGNS?.findIndex(
        (d) => d.SIGN_TYPE == detail.SIGN_TYPE
      );

      if (indexFromMainApproachObject > -1) {
        approachValue.value.SIGNS[indexFromMainApproachObject].deleted = true;
      }
    };

    const rowClass = (detail) => {
      return expandedRows.value?.includes(detail) ? "rowSelect" : "rowUnselect";
    };

    const anySignDetailEditable = computed(() => {
      return expandedRows.value?.find((element) => !element.readOnly);
    });

    const addNewSign = () => {
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.SIGNS_DATATABLE,
        marker: SNACKBAR_MARKER.INSPECTION_LINK,
      });
      let newSign = getNewSign();
      newSign.isAdd = true;
      newSign.BRKEY = brkey.value;
      newSign.INSPKEY = inspectionId.value;
      newSign.UNIQUE_KEY = newSign.SIGN_TYPE;
      newSign.readOnly = false;
      signs.value?.unshift(newSign);
      previousSort.value = sortBy.value.map((x) => x);
      sortBy.value = [];
      expandedRows.value = [newSign];
      scrollToDataTable("approach-data-table");
      changesDetected.value = false;
      nextTick(() => {
        signDetailForm.value?.validate();
      });
    };

    const setUpdatedComment = (comment, commentType) => {
      approachValue.value.inspectionComments.map((c) => {
        if (c.commentType == commentType) {
          c.notes = comment.notes;
          c.updateUserId = comment.updateUserId;
          c.updateDateTime = comment.updateDateTime;
        }
        return c;
      });
    };

    const checkIfError = () => {
      if (!approachValue.value || JSON.stringify(approachValue.value) == "{}")
        ctx.emit("showMessage");
    };

    const saveChanges = async () => {
      const structure = inspectionStore.selectedInspection;
      //set deleted flag on the approachValue.SIGNS based on the signs array
      setSignsDeletedFlag();
      const isSuccess = await approachStore.saveData(
        props.moduleType,
        approachValue.value,
        structure
      );
      if (isSuccess) {
        inspectionLinksStore.isDirty = false;
        if (props.moduleType == MODULE_TYPES.INSPECTION) {
          const structure = computed(() => inspectionStore.selectedInspection);
          await approachStore.loadDataObject(
            props.moduleType,
            null,
            null,
            structure.value
          );
        }
        approachValue.value = approachStore.getApproachObject(props.moduleType);
        // Using this flag to not set the dirty flag in watch
        resetDirty.value = true;
      }
      return isSuccess;
    };

    const cancelChanges = async () => {
      //only called from Inspection Links
      approachValue.value = approachStore.getApproachObject(props.moduleType);
      signs.value = getSignDetails();
      // Using this flag to not set the dirty flag in watch
      resetDirty.value = true;
      inspectionLinksStore.isDirty = false;
    };

    const defaultEmptySafetyFeature = () => {
      if (!structure.value?.T_Safety_Feature) {
        safetyFeature.map((item) => {
          item.BRKEY = structure.value.Bridge.BRKEY;
          item.INSPKEY = structure.value.InspEvnt.INSPKEY;
        });
        structure.value.T_Safety_Feature = safetyFeature;
      }
    };

    const setSignsDeletedFlag = () => {
      //set the deleted flag to true if any sign_type is missing from the signs.value
      const signTypes = signs.value?.map((s) => s.SIGN_TYPE);
      // //set deleted=true approachValue.SIGNS array
      approachValue.value.SIGNS?.forEach((sign) => {
        sign.deleted = !signTypes.includes(sign.SIGN_TYPE);
      });
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      [approachValue, signs],
      () => {
        // This watch will NOT be triggered during initial onMounted()
        if (props.moduleType === MODULE_TYPES.MANAGEMENT) {
          // for management module
          if (resetDirty.value) {
            inspectionLinksStore.isDirty = false;
            resetDirty.value = false;
          } else {
            inspectionLinksStore.isDirty = true;
          }
        } else if (props.moduleType === MODULE_TYPES.INSPECTION) {
          // for inspection  module
          if (resetDirty.value) {
            inspectionStore.setDirtyFlag(false);
            resetDirty.value = false;
          } else {
            inspectionStore.setDirtyFlag(true);
          }
        }
      },
      { deep: true }
    );

    watch(
      () => [props],
      () => {
        readData();
      },
      { deep: true }
    );

    const checkValidation = (value) => {
      //add validation only when the expansion panel is expanded(value = true)
      if (value) {
        nextTick(() => {
          signDetailForm.value?.validate();
        });
      }
    };
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    return {
      updateDuration,
      approachSlabUpdated,
      conditionUpdated,
      approachTransitionUpdated,
      approachRailingUpdated,
      approachRoadwayUpdated,
      approachAlignmentUpdated,
      REFERENCE_TABLE,
      configStore,
      pavementReliefJointsPresent,
      commentsMaxLength,
      structure,
      getHistDuration,
      panel,
      isEditable,
      INSPECTION_COMMENTS,
      changesDetected,
      PAIR_CODE,
      ROUNDING_FACTOR,
      validDetails,
      anySignDetailEditable,
      closeDetail,
      saveDetail,
      editDetail,
      deleteDetail,
      rowClass,
      addNewSign,
      expandedRows,
      signDetailForm,
      closeConfirm,
      BUTTONS,
      availableSignTypes,
      INSPECTION_MESSAGES,
      allSignTypes,
      bridgeRailingDesc,
      transitionDesc,
      inspectionStore,
      toggleRow,
      checkValidation,
      valid,
      modifyApproachValidation,
      toggleAllPanels,
      sortBy,
      approachValue,
      MODULE_TYPES,
      readData,
      setUpdatedComment,
      saveChanges,
      cancelChanges,
      getSignDetails,
      signs,
      validate,
    };
  },
  components: {
    DataTableHeader,
    CommentTypeTextField,
    LabelSelect,
    LabelText,
    IconTooltip,
    ConfirmDialog,
    ExpandCollapseToggle,
    ManagementCommentTypeTextField,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  order: 1;
  color: c.$p-white !important;
}

.v-expansion-panel-title__icon {
  order: 0;
}

.v-label.v-field-label {
  color: c.$p-black !important;
  opacity: 1 !important;
  font-weight: bold !important;
}
.v-input--density-default {
  --v-input-control-height: 25px !important;
}

.v-checkbox {
  flex: inherit !important;
}
.signTable :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.signTable :deep(button:focus) {
  outline: none !important;
}
.alignCenter {
  text-align: center;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}
</style>
