import { computed } from "vue";
import { styles } from "@/reports/reportStyles";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { useInspectionStore } from "@/stores/inspection";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { METRIC_CONVERSION_TYPES } from "@/constants/InspectionConstants";
import getFeaturesHighway from "@/composables/featuresHighway";
import getFeaturesRailroad from "@/composables/featuresRailroad";
import getFeaturesWaterway from "@/composables/featuresWaterway";
import { sortStructure } from "@/util/sortStructure";

function setFeaturesDocDefinition(structure) {
  const configStore = useConfigStore();

  const FeaturesDetails = [];
  sortStructure(structure, "Roadway", "ON_UNDER"); 
  getFeaturesInspectionDetails(FeaturesDetails, structure);

  let docDefinition = {
    header: function () {
      return getReportHeader("Features Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Features Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Clearance Information",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        table: {
          headerRows: 0,
          widths: ["48%", "2%", "50%"],

          body: [
            [
              {
                text: "Minimum Vert Clr",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
              {
                text: "",
                fillColor: "#ffffff",
                alignment: "center",
                style: ["sectionHeader"],
              },
              {
                text: "Minimum Lateral Clr",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 5, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["24%", "26%", "26%", "*"],
          body: [
            [
              { text: "4A15 Over Structure: ", bold: true },
              {
                text:
                  configStore.getMetricToEnglishConversions(
                    PAIR_CODE.METERS_TO_FEET,
                    structure?.Bridge?.VCLROVER,
                    ROUNDING_FACTOR.HUNDRETH,
                    null,
                    [METRIC_CONVERSION_TYPES.L3]
                  ) + " ft",
              },
              { text: "4A18 Reference Feature: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.MINIMUM_UNDERCLEARANCE,
                  structure?.Bridge?.REFHUC
                ),
              },
            ],
            [
              { text: "4A16 Under (Reference): ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.MINIMUM_UNDERCLEARANCE,
                  structure?.Bridge?.REFVUC
                ),
              },
              { text: "4A19 Under Right: ", bold: true },
              {
                text:
                  configStore.getMetricToEnglishConversions(
                    PAIR_CODE.METERS_TO_FEET,
                    structure?.Bridge?.HCLRURT,
                    ROUNDING_FACTOR.TENTH,
                    null,
                    [METRIC_CONVERSION_TYPES.L4]
                  ) + " ft",
              },
            ],
            [
              { text: "4A17 Under Clr: ", bold: true },
              {
                text:
                  configStore.getMetricToEnglishConversions(
                    PAIR_CODE.METERS_TO_FEET,
                    structure?.Bridge?.VCLRUNDER,
                    ROUNDING_FACTOR.HUNDRETH
                  ) + " ft",
              },
              { text: "4A20 Under Left: ", bold: true },
              {
                text:
                  configStore.getMetricToEnglishConversions(
                    PAIR_CODE.METERS_TO_FEET,
                    structure?.Bridge?.HCLRULT,
                    ROUNDING_FACTOR.TENTH
                  ) + " ft",
              },
            ],
          ],
        },
        margin: [20, 0, 20, 5],
        layout: "noBorders",
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Navigation Control",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 0, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["24%", "26%", "26%", "*"],
          body: [
            [
              { text: "4A21 Nav Control Exists: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.NAVIGATION_CONTROL,
                  structure?.Bridge?.NAVCNTROL
                ),
              },
              { text: "4A22 Nav Vert Clr: ", bold: true },
              {
                text:
                  configStore.getMetricToEnglishConversions(
                    PAIR_CODE.METERS_TO_FEET,
                    structure?.Bridge?.NAVVC,
                    ROUNDING_FACTOR.HUNDRETH
                  ) + " ft",
              },
            ],
            [
              { text: "4A23 Nav Horiz Clr: ", bold: true },
              {
                text:
                  configStore.getMetricToEnglishConversions(
                    PAIR_CODE.METERS_TO_FEET,
                    structure?.Bridge?.NAVHC,
                    ROUNDING_FACTOR.HUNDRETH
                  ) + " ft",
              },

              { text: "4A24 Min Vert Lift Clr: ", bold: true },
              {
                text:
                  configStore.getMetricToEnglishConversions(
                    PAIR_CODE.METERS_TO_FEET,
                    structure?.Bridge?.LFTBRNAVCL,
                    ROUNDING_FACTOR.HUNDRETH
                  ) + " ft",
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 0, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Feature Intersection Details",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 5],
      },
      FeaturesDetails?.map((a) => a),
    ],
    styles: styles,

    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

function getFeaturesInspectionDetails(FeaturesDetails, structure) {
  const inspectionStore = useInspectionStore();
  let mergedTableData = computed(() =>
    inspectionStore.getFeatureIntersectionTableData()
  );

  for (const detail of mergedTableData.value) {
    if (detail?.FEATURE_TYPE === "1" || detail?.FEATURE_TYPE === "4") {
      FeaturesDetails.push(getFeaturesHighway(detail, structure));
    } else if (detail?.FEATURE_TYPE === "2") {
      FeaturesDetails.push(getFeaturesRailroad(detail, structure));
    } else if (detail?.FEATURE_TYPE === "3") {
      FeaturesDetails.push(getFeaturesWaterway(detail));
    }
  }
}

export { setFeaturesDocDefinition };
