import { defineStore } from "pinia";
import { ref } from "vue";
import { INSPECTION_LINKS_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { clone } from "@/util/clone";
import { MODULE_TYPES } from "@/constants/InspectionConstants";

export const useRatingsStore = defineStore("ratings", () => {
  let pageData = ref({ inspection: {}, management: {} });
  let isSearching = ref(false);

  const loadDataObject = async (moduleType, brkey, inspectionId, structure) => {
    if (moduleType === MODULE_TYPES.INSPECTION) {
      //if moduleType is inspection, load data from pouchDB
      convertToInspModuleObject(structure);
    } else if (
      moduleType === MODULE_TYPES.MANAGEMENT &&
      brkey &&
      inspectionId
    ) {
      //if moduleType is management, load data from service call
      await getInspLinkDataObject(brkey, inspectionId);
    }
  };

  const getRatingsObject = (moduleType) => {
    return clone(pageData.value[moduleType]);
  };

  const saveData = async (moduleType, value, structure) => {
    let isSuccess = false;
    if (moduleType === MODULE_TYPES.INSPECTION) {
      convertToSaveInspModuleObject(value, structure);
      isSuccess = true;
    } else if (moduleType === MODULE_TYPES.MANAGEMENT) {
      isSuccess = await updateData(convertToSaveInspLinkObject(value));
    }
    return isSuccess;
  };

  const updateData = async (payload) => {
    let isSuccess = true;
    const url = INSPECTION_LINKS_ENDPOINT.UPDATE_RATINGS;
    await axios
      .put(url, payload)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update Ratings",
          response
        );
        convertToInspLinkObject(response.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const convertToSaveInspLinkObject = (ratingsValues) => {
    return {
      brkey: ratingsValues.BRKEY,
      inspectionId: ratingsValues.INSPECTIONID,
      inspectionStatus: ratingsValues.INSPSTAT,
      // Condition Ratings section
      dkRating: ratingsValues.DKRATING,
      subRating: ratingsValues.SUBRATING,
      culvRating: ratingsValues.CULVRATING,
      scourCondRating: ratingsValues.SCOUR_COND_RATING,
      underwaterRating: ratingsValues.UNDERWATER_RATING,
      nstmInspectionCondition: ratingsValues.NSTM_INSPECTION_CONDITION,
      supRating: ratingsValues.SUPRATING,
      chanRating: ratingsValues.CHANRATING,
      chanProtRating: ratingsValues.CHAN_PROT_RATING,
      overallBearingsRating: ratingsValues.OVERALL_BEARINGS_RATING,
      overallJointsRating: ratingsValues.OVERALL_JOINTS_RATING,
      lowestConditionRating: ratingsValues.LOWEST_CONDITION_RATING,
      inspectionNotes: ratingsValues.INSPECTION_NOTES,
      // Appraisal Ratings section
      railRating: ratingsValues.RAILRATING,
      transRating: ratingsValues.TRANSRATIN,
      pierProtection: ratingsValues.PIER_PROTECTION,
      scourVulnerability: ratingsValues.SCOUR_VULNERABILITY,
      newPaint: ratingsValues.NEW_PAINT,
      paintConditionRating: ratingsValues.PAINT_COND_RATE,
      paintExtentRating: ratingsValues.PAINT_EXTENT_RATING,
      seismicVulnerability: ratingsValues.SEISMIC_VULNERABILITY,
      apprAlign: ratingsValues.APPRALIGN,
      apprSlabConditionRating: ratingsValues.APPR_SLAB_COND_RATE,
      apprRoadConditionRating: ratingsValues.APPR_ROAD_COND_RATE,
      deckWearingConditionRating: ratingsValues.DECK_WS_COND_RATE,
      scourCrit: ratingsValues.SCOURCRIT,
      scourCritCategory: ratingsValues.SCOUR_CRIT_CTGRY,
      bridgeConditionRating: ratingsValues.BRIDGE_CONDITION_RATING,
      maintenanceDeficiencyRating: ratingsValues.MAINT_DEF_RATE,
    };
  };

  const convertToSaveInspModuleObject = (ratingsValues, structure) => {
    structure.InspEvnt.INSPSTAT = ratingsValues.INSPSTAT;
    structure.InspEvnt.DKRATING = ratingsValues.DKRATING;
    structure.InspEvnt.SUBRATING = ratingsValues.SUBRATING;
    structure.InspEvnt.CULVRATING = ratingsValues.CULVRATING;
    structure.UserInsp.SCOUR_COND_RATING = ratingsValues.SCOUR_COND_RATING;
    structure.UserInsp.UNDERWATER_RATING = ratingsValues.UNDERWATER_RATING;
    structure.UserInsp.NSTM_CONDITION = ratingsValues.NSTM_INSPECTION_CONDITION;
    structure.InspEvnt.SUPRATING = ratingsValues.SUPRATING;
    structure.InspEvnt.CHANRATING = ratingsValues.CHANRATING;
    structure.UserInsp.CHAN_PROT_RATING = ratingsValues.CHAN_PROT_RATING;
    structure.T_Bearings[0].BEARING_OVERALL_COND_RATE =
      ratingsValues.OVERALL_BEARINGS_RATING;
    structure.T_Joints[0].OVERALL_COND_RATING =
      ratingsValues.OVERALL_JOINTS_RATING;
    structure.value.UserInsp.LOWEST_CONDITION =
      ratingsValues.LOWEST_CONDITION_RATING;
    structure.InspEvnt.NOTES = ratingsValues.INSPECTION_NOTES;

    // Appraisal Ratings section
    structure.InspEvnt.RAILRATING = ratingsValues.RAILRATING;
    structure.InspEvnt.TRANSRATIN = ratingsValues.TRANSRATIN;
    structure.InspEvnt.PIERPROT = ratingsValues.PIER_PROTECTION;
    structure.value.UserInsp.SCOUR_VULNERABILITY =
      ratingsValues.SCOUR_VULNERABILITY;
    structure.UserInsp.NEW_PAINT = ratingsValues.NEW_PAINT;
    structure.UserInsp.PAINT_COND_RATE = ratingsValues.PAINT_COND_RATE;
    structure.UserInsp.PAINT_EXTENT_RATE = ratingsValues.PAINT_EXTENT_RATING;
    structure.UserBrdg.SEISMIC_VULN = ratingsValues.SEISMIC_VULNERABILITY;
    structure.UserInsp.APPR_SLAB_COND_RATE = ratingsValues.APPRALIGN;
    structure.UserInsp.APPR_SLAB_COND_RATE = ratingsValues.APPR_SLAB_COND_RATE;
    structure.UserInsp.APPR_ROAD_COND_RATE = ratingsValues.APPR_ROAD_COND_RATE;
    structure.UserInsp.DECK_WS_COND_RATE = ratingsValues.DECK_WS_COND_RATE;
    structure.InspEvnt.SCOURCRIT = ratingsValues.SCOURCRIT;
    structure.InspEvnt.SCOUR_CRIT_CTGRY = ratingsValues.SCOUR_CRIT_CTGRY;
    structure.value.UserInsp.LOWEST_CONDITION =
      ratingsValues.BRIDGE_CONDITION_RATING;
    structure.UserInsp.MAINT_DEF_RATE = ratingsValues.MAINT_DEF_RATE;
  };

  const getInspLinkDataObject = async (brkey, inspectionId) => {
    //clear idb object
    pageData.value.inspection = {};
    isSearching.value = true;
    let url = INSPECTION_LINKS_ENDPOINT.GET_RATINGS.replace(
      "{brkey}",
      brkey
    ).replace("{inspectionId}", inspectionId);

    await axios
      .get(url)
      .then((resp) => {
        isSearching.value = false;
        convertToInspLinkObject(resp.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSearching.value = false;
      });
    isSearching.value = false;
  };

  const convertToInspModuleObject = (structure) => {
    pageData.value.management = {};
    pageData.value.inspection = {
      INSPSTAT: structure?.InspEvnt?.INSPSTAT,
      DK_DURATION: structure?.T_Insp_Condition_Hist?.DK_DURATION,
      DK_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.DK_DURATION_CHANGES,
      DKRATING: structure?.InspEvnt?.DKRATING,
      SUB_DURATION: structure?.T_Insp_Condition_Hist?.SUB_DURATION,
      SUB_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.SUB_DURATION_CHANGES,
      SUBRATING: structure?.InspEvnt?.SUBRATING,
      CULV_DURATION: structure?.T_Insp_Condition_Hist?.CULV_DURATION,
      CULV_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.CULV_DURATION_CHANGES,
      CULVRATING: structure?.InspEvnt?.CULVRATING,
      SCOUR_COND_RATING: structure?.UserInsp?.SCOUR_COND_RATING,
      //scour hole duration currently unavailable
      UNDERWATER_RATING: structure?.UserInsp?.UNDERWATER_RATING,
      //underwater rating duration currently unavailable
      NSTM_INSPECTION_CONDITION: structure?.UserInsp?.NSTM_CONDITION,
      //nstm condition duration currently unavailable
      SUP_DURATION: structure?.T_Insp_Condition_Hist?.SUP_DURATION,
      SUP_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.SUP_DURATION_CHANGES,
      SUPRATING: structure?.InspEvnt?.SUPRATING,
      CHANRATE_DURATION: structure?.T_Insp_Condition_Hist?.CHANRATE_DURATION,
      CHANRATE_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.CHANRATE_DURATION_CHANGES,
      CHANRATING: structure?.InspEvnt?.CHANRATING,
      CHAN_PROT_RATING: structure?.UserInsp.CHAN_PROT_RATING,
      //channel protection rating duration not currently unavailable
      OVERALL_BEARINGS_RATING:
        structure?.T_Bearings?.[0]?.BEARING_OVERALL_COND_RATE,
      //overall bearings duration not currently unavailable
      OVERALL_JOINTS_RATING: structure?.T_Joints?.[0]?.OVERALL_COND_RATING,
      //overall joints duration not currently unavailable
      LOWEST_CONDITION_RATING: structure?.UserInsp?.LOWEST_CONDITION,
      INSPECTION_NOTES: structure?.InspEvnt?.NOTES,

      // Appraisal Ratings section
      RAILRATING_DURATION:
        structure?.T_Insp_Condition_Hist?.RAILRATING_DURATION,
      RAILRATING_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.RAILRATING_DURATION_CHANGES,
      RAILRATING: structure?.InspEvnt?.RAILRATING,
      TRANSRATIN_DURATION:
        structure?.T_Insp_Condition_Hist?.TRANSRATIN_DURATION,
      TRANSRATIN_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.TRANSRATIN_DURATION_CHANGES,
      TRANSRATIN: structure?.InspEvnt?.TRANSRATIN,
      PIER_PROTECTION: structure?.InspEvnt?.PIERPROT,
      //pier protection duration currently unavailable
      SCOUR_VULNERABILITY: structure?.UserInsp?.SCOUR_VULNERABILITY,
      //scour vulnerability duration currently unavailable
      NEW_PAINT: structure?.UserInsp?.NEW_PAINT,
      PAINT_DURATION: structure?.T_Insp_Condition_Hist?.PANIT_DURATION,
      PAINT_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.PANIT_DURATION_CHANGES,
      PAINT_COND_RATE: structure?.UserInsp?.PAINT_COND_RATE,
      PAINT_EXT_DURATION: structure?.T_Insp_Condition_Hist?.PAINT_EXT_DURATION,
      PAINT_EXT_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.PAINT_EXT_DURATION_CHANGES,
      PAINT_EXTENT_RATING: structure?.UserInsp?.PAINT_EXTENT_RATE,
      SEISMIC_VULNERABILITY: structure?.UserBrdg?.SEISMIC_VULN,
      // seismic vulnerability duration currently unavailable
      APPRALIGN_DURATION: structure?.T_Insp_Condition_Hist?.APPR_SLAB_DURATION,
      APPRALIGN_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.APPR_SLAB_DURATION_CHANGES,
      APPRALIGN: structure?.InspEvnt?.APPRALIGN,
      APPR_SLAB_DURATION: structure?.T_Insp_Condition_Hist?.APPR_SLAB_DURATION,
      APPR_SLAB_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.APPR_SLAB_DURATION_CHANGES,
      APPR_SLAB_COND_RATE: structure?.UserInsp?.APPR_SLAB_COND_RATE,
      APPR_ROADWAY_DURATION:
        structure?.T_Insp_Condition_Hist?.APPR_ROADWAY_DURATION,
      APPR_ROADWAY_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.APPR_ROADWAY_DURATION_CHANGES,
      APPR_ROAD_COND_RATE: structure?.UserInsp?.APPR_ROAD_COND_RATE,
      DECK_WS_SURF_DURATION:
        structure?.T_Insp_Condition_Hist?.DECK_WS_SURF_DURATION,
      DECK_WS_SURF_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.DECK_WS_SURF_DURATION_CHANGES,
      DECK_WS_COND_RATE: structure?.UserInsp?.DECK_WS_COND_RATE,
      SCOUTCRIT_DURATION: structure?.T_Insp_Condition_Hist?.SCOUTCRIT_DURATION,
      SCOUTCRIT_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.SCOUTCRIT_DURATION_CHANGES,
      SCOURCRIT: structure?.InspEvnt?.SCOURCRIT,
      SCOUR_CRIT_CTGRY: structure?.InspEvnt?.SCOUR_CRIT_CTGRY,
      BRGCOND_DURATION: structure?.T_Insp_Condition_Hist?.BRGCOND_DURATION,
      BRGCOND_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.BRGCOND_DURATION_CHANGES,
      BRIDGE_CONDITION_RATING: structure?.UserInsp?.LOWEST_CONDITION,
      MAINT_DEF_RATE: structure?.UserInsp?.MAINT_DEF_RATE,
      MAIN_FC_GROUP_NAME: structure?.UserBrdg.MAIN_FC_GROUP_NUM,
      APPR_FC_GROUP_NUM: structure?.UserBrdg.APPR_FC_GROUP_NUM,
    };
  };

  const convertToInspLinkObject = (response) => {
    pageData.value.inspection = {};
    pageData.value.management = {
      BRKEY: response.brkey,
      INSPECTIONID: response.inspectionId,
      INSPSTAT: response.inspectionStatus,

      // ***still need the Key field comparison***

      // Condition Ratings section
      DK_DURATION: response.dkDuration,
      DK_DURATION_CHANGES: "",
      DKRATING: response.dkRating,
      SUB_DURATION: response.subDuration,
      SUB_DURATION_CHANGES: "",
      SUBRATING: response.subRating,
      CULV_DURATION: response.culvDuration,
      CULV_DURATION_CHANGES: "",
      CULVRATING: response.culvRating,
      SCOUR_COND_RATING: response.scourCondRating,
      //scour hole duration currently unavailable
      UNDERWATER_RATING: response.underwaterRating,
      //underwater rating duration currently unavailable
      NSTM_INSPECTION_CONDITION: response.nstmInspectionCondition,
      //nstm condition duration currently unavailable
      SUP_DURATION: response.supDuration,
      SUP_DURATION_CHANGES: "",
      SUPRATING: response.supRating,
      CHANRATE_DURATION: response.chanRateDuration,
      CHANRATE_DURATION_CHANGES: "",
      CHANRATING: response.chanRating,
      CHAN_PROT_RATING: response.chanProtRating,
      //channel protection rating duration not currently unavailable
      OVERALL_BEARINGS_RATING: response.overallBearingsRating,
      //overall bearings duration not currently unavailable
      OVERALL_JOINTS_RATING: response.overallJointsRating,
      //overall joints duration not currently unavailable
      LOWEST_CONDITION_RATING: response.lowestConditionRating,
      INSPECTION_NOTES: response.inspectionNotes,

      // Appraisal Ratings section
      RAILRATING_DURATION: response.railRatingDuration,
      RAILRATING_DURATION_CHANGES: "",
      RAILRATING: response.railRating,
      TRANSRATIN_DURATION: response.transRatingDuration,
      TRANSRATIN_DURATION_CHANGES: "",
      TRANSRATIN: response.transRating,
      PIER_PROTECTION: response.pierProtection,
      //pier protection duration currently unavailable
      SCOUR_VULNERABILITY: response.scourVulnerability,
      //scour vulnerability duration currently unavailable
      NEW_PAINT: response.newPaint,
      PAINT_DURATION: response.paintConditionDuration,
      PAINT_DURATION_CHANGES: "",
      PAINT_COND_RATE: response.paintConditionRating,
      PAINT_EXT_DURATION: response.paintExtentDuration,
      PAINT_EXT_DURATION_CHANGES: "",
      PAINT_EXTENT_RATING: response.paintExtentRating,
      SEISMIC_VULNERABILITY: response.seismicVulnerability,
      // seismic vulnerability duration currently unavailable
      APPRALIGN_DURATION: response.apprAlignmentDuration,
      APPRALIGN_DURATION_CHANGES: "",
      APPRALIGN: response.apprAlign,
      APPR_SLAB_DURATION: response.apprSlabDuration,
      APPR_SLAB_DURATION_CHANGES: "",
      APPR_SLAB_COND_RATE: response.apprSlabConditionRating,
      APPR_ROADWAY_DURATION: response.apprRoadwayDuration,
      APPR_ROADWAY_DURATION_CHANGES: "",
      APPR_ROAD_COND_RATE: response.apprRoadConditionRating,
      DECK_WS_SURF_DURATION: response.deckWearingSurfaceDuration,
      DECK_WS_SURF_DURATION_CHANGES: "",
      DECK_WS_COND_RATE: response.deckWearingConditionRating,
      SCOUTCRIT_DURATION: response.scourCritDuration,
      SCOUTCRIT_DURATION_CHANGES: "",
      SCOURCRIT: response.scourCrit,
      SCOUR_CRIT_CTGRY: response.scourCritCategory,
      BRGCOND_DURATION: response.bridgeConditionRatingDuration,
      BRGCOND_DURATION_CHANGES: "",
      BRIDGE_CONDITION_RATING: response.bridgeConditionRating,
      MAINT_DEF_RATE: response.maintenanceDeficiencyRating,
      MAIN_FC_GROUP_NAME: response.mainFCGroupNum,
      APPR_FC_GROUP_NUM: response.apprFCGroupNum,
    };
  };

  return {
    getRatingsObject,
    saveData,
    loadDataObject,
  };
});
