<template>
  <v-container fluid>
    <ServiceStatus />
  </v-container>
</template>

<script>
import ServiceStatus from "@/components/support/ServiceStatus.vue";
import { onMounted } from "vue";
import { useInspectionStore } from "@/stores/inspection";
export default {
  components: {
    ServiceStatus,
  },
  name: "ViewServiceStatus",
  setup() {
    const inspectionStore = useInspectionStore();
    onMounted(async () => {
      await inspectionStore.setUnsavedChangesWarning(false);
      await inspectionStore.setDirtyFlag(false);
    });
  },
};
</script>
