/* eslint-disable vue/valid-v-slot */
<template>
  <v-row>
    <ExpandCollapseToggle
      id="notesPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-col sm="12" md="12" lg="12" xl="12" class="pa-0">
      <v-expansion-panels v-model="panel" multiple color="#fff">
        <v-expansion-panel
          style="margin-top: 20px"
          @group:selected="checkFormValidation"
        >
          <v-expansion-panel-title
            class="d-flex justify-start"
            hide-actions
            expand-icon="fa-solid fa-plus"
            collapse-icon="fa-solid fa-minus"
          >
            <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />

            <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />

            <h3>Inspection Notes</h3>
          </v-expansion-panel-title>

          <v-expansion-panel-text>
            <v-row>
              <v-col class="px-0">
                <v-btn
                  :disabled="!isEditable || anyEditableInspectionNotes"
                  id="btn_add_Inspection_Note"
                  variant="outlined"
                  class="my-2 ml-1"
                  @click="addInspectionNotes()"
                  >Add Note</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-form v-model="validInspection" ref="inspectionNotesForm">
                  <v-data-table
                    id="inspection_Table"
                    v-model:page="pageInspection.page"
                    :items="inspectionNotes"
                    :headers="inspectionHeaders"
                    :items-per-page="pageInspection.itemsPerPage"
                    hide-default-footer
                  >
                    <template v-slot:[`header.COMMENT_TYPE`]="{ column }">
                      <v-row align="center">
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[0] }}
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[1] }}
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[2] }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`header.NOTES`]="{ column }">
                      <v-row class="pb-6" align="center">
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[0] }}
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[1] }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`header.Action`]="{ column }">
                      {{ column.title }}
                    </template>
                    <template v-slot:[`item.COMMENT_TYPE`]="{ item }">
                      <LabelSelect
                        v-if="!item.readOnly && item.isAdd"
                        :style="validInspection ? 'padding-bottom: 22px' : ''"
                        :selectXl="12"
                        :selectLg="12"
                        :selectMd="12"
                        :selectSm="12"
                        id="Note_Type"
                        :options="getInspectionNotesCommentTypes"
                        :isEditable="true"
                        isRequired
                        v-model="item.TEMP_COMMENT_TYPE"
                        @update:modelValue="
                          changesDetectedInspectionNotes = true
                        "
                      ></LabelSelect>
                      <div v-else :class="!item.readOnly ? 'pb-5' : 'pb-0'">
                        {{ noteTypeIC01(item.COMMENT_TYPE) }}
                      </div>
                    </template>
                    <template v-slot:[`item.NOTES`]="{ item }">
                      <LabelText
                        v-if="!item.readOnly"
                        :textXl="12"
                        :textLg="12"
                        :textMd="12"
                        :textSm="12"
                        id="textarea_Inspection_Notes"
                        v-model="item.NOTES"
                        @update:modelValue="
                          changesDetectedInspectionNotes = true
                        "
                        :isEditable="true"
                        :counter="commentsMaxLength"
                        :maxlength="commentsMaxLength"
                        persistent-counter
                        type="textarea"
                        auto-grow
                        rows="1"
                      >
                      </LabelText>
                      <div style="overflow-wrap: anywhere" v-else>
                        {{ item.NOTES }}
                      </div>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <div
                        style="white-space: nowrap"
                        v-if="!item.readOnly && isEditable"
                      >
                        <IconTooltip
                          id="icon_Inspection_Update"
                          icon="fa-solid fa-check"
                          :tooltip="item.isAdd ? 'Add' : 'Update'"
                          @click="saveInspectionNotes(item)"
                        />
                        <IconTooltip
                          id="icon_Inspection_Close"
                          icon="fa-solid fa-xmark"
                          tooltip="Close"
                          @click="closeInspectionNotes"
                        />
                      </div>
                      <div
                        style="white-space: nowrap"
                        v-else-if="isEditable && !anyEditableInspectionNotes"
                      >
                        <IconTooltip
                          id="icon_Inspection_Edit"
                          icon="fa-solid fa-pencil"
                          tooltip="Edit"
                          @click="editInspectionNotes(item)"
                        />
                        <IconTooltip
                          id="icon_Inspection_Delete"
                          icon="fa-solid fa-trash"
                          tooltip="Delete"
                          @click="deleteInspectionNotes(item)"
                        />
                      </div>
                    </template>
                    <template v-slot:bottom>
                      <DataTableFooter
                        id="inspection_Notes_DataTable_Footer"
                        :items="inspectionNotes"
                        v-model="pageInspection"
                        ref="updateInspectionPage"
                        @update:modelValue="
                          scrollToDataTable('inspection_Table')
                        "
                      ></DataTableFooter>
                    </template>
                  </v-data-table>
                </v-form>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel @group:selected="checkSubFormValidation">
          <v-expansion-panel-title
            class="d-flex justify-start"
            hide-actions
            expand-icon="fa-solid fa-plus"
            collapse-icon="fa-solid fa-minus"
          >
            <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />

            <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />

            <h3>Substructure Notes</h3>
          </v-expansion-panel-title>

          <v-expansion-panel-text>
            <v-row>
              <v-col class="px-0">
                <v-btn
                  :disabled="!isEditable || anyEditableSubInspectionNotes"
                  id="btn_add_Sub_Inspection_Note"
                  variant="outlined"
                  class="my-2 ml-1"
                  @click="addSubInspectionNotes()"
                  >Add Note</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-form
                  v-model="validSubInspection"
                  ref="subInspectionNotesForm"
                >
                  <v-data-table
                    id="sub_Table"
                    v-model:page="subPageInspection.page"
                    :items="subInspectionNotes"
                    :headers="subInspectionHeaders"
                    :items-per-page="subPageInspection.itemsPerPage"
                    hide-default-footer
                  >
                    <template v-slot:[`header.STRUNITKEY`]="{ column }">
                      <v-row align="center">
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[0] }}
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[1] }}
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[2] }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`header.COMMENT_TYPE`]="{ column }">
                      <v-row align="center">
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[0] }}
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[1] }}
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[2] }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`header.NOTES`]="{ column }">
                      <v-row class="pb-6" align="center">
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[0] }}
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ column.title.split(" ")[1] }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`header.Action`]="{ column }">
                      {{ column.title }}
                    </template>
                    <template v-slot:[`item.STRUNITKEY`]="{ item }">
                      <LabelSelect
                        v-if="item.isAdd && !item.readOnly"
                        :style="
                          validSubInspection ? 'padding-bottom: 22px' : ''
                        "
                        :selectXl="12"
                        :selectLg="12"
                        :selectMd="12"
                        :selectSm="12"
                        isRequired
                        id="New_SubStructure_Type"
                        :options="getSubInspectionNotesUnitKeys"
                        :isEditable="true"
                        v-model="item.TEMP_STRUNITKEY"
                        @update:modelValue="
                          changesDetectedSubInspectionNotes = true
                        "
                      ></LabelSelect>
                      <div
                        v-else-if="item.STRUNITKEY"
                        :class="!item.readOnly ? 'pb-5' : 'pb-0'"
                      >
                        {{ substuctureUnitIC03(item.STRUNITKEY) }}
                      </div>
                      <div
                        v-else
                        :class="!item.readOnly ? 'pb-5' : 'pb-0'"
                      ></div>
                    </template>
                    <template v-slot:[`item.COMMENT_TYPE`]="{ item }">
                      <LabelSelect
                        :style="
                          validSubInspection ? 'padding-bottom: 22px' : ''
                        "
                        v-if="item.isAdd && !item.readOnly"
                        :selectXl="12"
                        :selectLg="12"
                        :selectMd="12"
                        :selectSm="12"
                        isRequired
                        id="selected_Sub_Note_Type"
                        :options="getSubInspectionNotesCommentTypes"
                        :isEditable="true"
                        v-model="item.TEMP_COMMENT_TYPE"
                        @update:modelValue="
                          changesDetectedSubInspectionNotes = true
                        "
                      ></LabelSelect>
                      <div v-else :class="!item.readOnly ? 'pb-5' : 'pb-0'">
                        {{ noteTypeIC04(item.COMMENT_TYPE) }}
                      </div>
                    </template>
                    <template v-slot:[`item.NOTES`]="{ item }">
                      <LabelText
                        v-if="!item.readOnly"
                        :textXl="12"
                        :textLg="12"
                        :textMd="12"
                        :textSm="12"
                        id="textarea_Sub_Inspection_Notes"
                        v-model="item.NOTES"
                        @update:modelValue="
                          changesDetectedSubInspectionNotes = true
                        "
                        :isEditable="true"
                        :counter="commentsMaxLength"
                        :maxlength="commentsMaxLength"
                        persistent-counter
                        type="textarea"
                        auto-grow
                        rows="1"
                      >
                      </LabelText>
                      <div v-else>
                        {{ item.NOTES }}
                      </div>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <div
                        style="white-space: nowrap"
                        v-if="!item.readOnly && isEditable"
                      >
                        <IconTooltip
                          id="icon_Sub_Inspection_Update"
                          icon="fa-solid fa-check"
                          :tooltip="item.isAdd ? 'Add' : 'Update'"
                          @click="saveSubInspectionNotes(item)"
                        />
                        <IconTooltip
                          id="icon_Sub_Inspection_Close"
                          icon="fa-solid fa-xmark"
                          tooltip="Close"
                          @click="closeSubInspectionNotes"
                        />
                      </div>
                      <div
                        style="white-space: nowrap"
                        v-else-if="isEditable && !anyEditableSubInspectionNotes"
                      >
                        <IconTooltip
                          id="icon_Sub_Inspection_Edit"
                          icon="fa-solid fa-pencil"
                          tooltip="Edit"
                          @click="editSubInspectionNotes(item)"
                        />
                        <IconTooltip
                          id="icon_Sub_Inspection_Delete"
                          icon="fa-solid fa-trash"
                          tooltip="Delete"
                          @click="deleteSubInspectionNotes(item)"
                        />
                      </div>
                    </template>
                    <template v-slot:bottom>
                      <DataTableFooter
                        id="sub_Inspection_Notes_DataTable_Footer"
                        :items="subInspectionNotes"
                        v-model="subPageInspection"
                        ref="updateSubPage"
                        @update:modelValue="scrollToDataTable('sub_Table')"
                      ></DataTableFooter>
                    </template>
                  </v-data-table>
                </v-form>
              </v-col> </v-row
          ></v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>

<script>
import { defineComponent, ref, computed, watch, nextTick } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { BUTTONS } from "@/constants/CommonWebConstants";
import { clone } from "@/util/clone";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import IconTooltip from "@/components/common/IconTooltip.vue";
import LabelText from "@/components/common/LabelText.vue";
import LabelSelect from "@/components/common/LabelSelect.vue";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { LOGGER } from "@/util/logger";
import { scrollToDataTable } from "@/util/scrollToElement";
import useNotesData from "@/composables/notesData";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { useSnackbarStore } from "@/stores/snackbar";

export default defineComponent({
  name: "InspectionNotes",
  data() {
    return {
      inspectionHeaders: [
        {
          title: "IC01 Note Type",
          sortable: false,
          key: "COMMENT_TYPE",
          width: "25%",
        },
        {
          title: "IC02 Note",
          sortable: false,
          key: "NOTES",
        },
        {
          title: "Action",
          sortable: false,
          key: "action",
          width: "5%",
        },
      ],
      subInspectionHeaders: [
        {
          title: "IC03 Substructure Unit",
          sortable: false,
          key: "STRUNITKEY",
          width: "10%",
        },
        {
          title: "IC04 Note Type",
          sortable: false,
          key: "COMMENT_TYPE",
          width: "25%",
        },
        {
          title: "IC05 Note",
          sortable: false,
          key: "NOTES",
        },
        {
          title: "Action",
          sortable: false,
          key: "action",
          width: "5%",
        },
      ],
    };
  },
  setup() {
    const {
      noteTypeIC01,
      substuctureUnitIC03,
      noteTypeIC04,
      sortSubInspectionNotes,
    } = useNotesData();
    const allPanels = [0, 1];
    const panel = ref(allPanels);

    const inspectionStore = useInspectionStore();

    const configStore = useConfigStore();
    const snackbarStore = useSnackbarStore();

    const structure = computed(() => inspectionStore.selectedInspection);

    const isEditable = computed(() => inspectionStore.isEditable);

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    let addingAndEditingInspectionNotes = false;

    let addingAndEditingSubNotes = false;

    function setAddingOrEditing() {
      if (!addingAndEditingInspectionNotes && !addingAndEditingSubNotes) {
        inspectionStore.setAddingOrEditing(false);
      }
    }

    const closeConfirm = ref(null);

    let inspectionNotesSnackbarId = ref("");
    let substructureNotesSnackbarId = ref("");
    //Inspection Notes

    let changesDetectedInspectionNotes = ref(false);

    let inspectionNotes = ref([]);

    let newInspectionNote = null;

    let updateInspectionPage = ref(null);

    let pageInspection = ref({
      itemsPerPage: 30,
      page: 1,
    });

    let validInspection = ref(false);

    let inspectionNotesForm = ref(null);

    const convertToInspectionNotesObject = (detail) => {
      if (detail.isAdd) {
        return {
          BRKEY: detail.BRKEY,
          INSPKEY: detail.INSPKEY,
          COMMENT_TYPE: detail.TEMP_COMMENT_TYPE,
          MODTIME: detail.MODTIME,
          NOTES: detail.NOTES,
          USERKEY: detail.USERKEY,
        };
      } else {
        return {
          BRKEY: detail.BRKEY,
          INSPKEY: detail.INSPKEY,
          COMMENT_TYPE: detail.COMMENT_TYPE,
          MODTIME: detail.MODTIME,
          NOTES: detail.NOTES,
          USERKEY: detail.USERKEY,
        };
      }
    };

    const sortInspectionNotes = () => {
      inspectionNotes.value?.sort((a, b) => {
        if (parseInt(a.COMMENT_TYPE) < parseInt(b.COMMENT_TYPE)) {
          return -1;
        } else if (parseInt(a.COMMENT_TYPE) > parseInt(b.COMMENT_TYP)) {
          return 1;
        } else return 0;
      });
    };

    const getInspectionNotes = () => {
      inspectionNotes.value = clone(structure.value.T_Insp_Comment);

      if (!inspectionNotes.value) {
        //remove null values from array(if any)
        inspectionNotes.value = inspectionNotes.value?.filter((a) => !!a);
      }
      if (inspectionNotes.value) {
        inspectionNotes.value?.forEach((i) => {
          i.readOnly = true;
          i.isAdd = false;
        });
      }

      sortInspectionNotes();
    };
    getInspectionNotes();

    const closeInspectionNotes = () => {
      if (isEditable.value && changesDetectedInspectionNotes.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              getInspectionNotes();
              newInspectionNote = null;
              changesDetectedInspectionNotes.value = false;
              addingAndEditingInspectionNotes = false;
              setAddingOrEditing();
              snackbarStore.removeMessage(inspectionNotesSnackbarId.value);
            }
          });
      } else {
        newInspectionNote = null;
        snackbarStore.removeMessage(inspectionNotesSnackbarId.value);
        addingAndEditingInspectionNotes = false;
        setAddingOrEditing();
        getInspectionNotes();
      }
    };

    const getInspectionNotesCommentTypes = computed(() => {
      let commentTypes = configStore.getReferenceValues(
        REFERENCE_TABLE.INSPECTION_COMMENT_TYPE,
        null,
        null,
        null,
        null,
        true
      );

      const noteTypes = [];
      inspectionNotes.value.forEach((note) => {
        noteTypes.push(note.COMMENT_TYPE);
      });

      return commentTypes
        .filter((commentType) => !noteTypes.includes(commentType.value))
        .sort((a, b) => {
          if (parseInt(a.value) < parseInt(b.value)) {
            return -1;
          } else if (parseInt(a.value) > parseInt(b.value)) {
            return 1;
          }
          return 0;
        });
    });

    const editInspectionNotes = (detail) => {
      addingAndEditingInspectionNotes = true;
      inspectionStore.setAddingOrEditing(true);
      inspectionNotesSnackbarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.INSPECTION_NOTES_SAVE_MESSAGE,
      });
      detail.readOnly = false;
      changesDetectedInspectionNotes.value = false;
    };

    const deleteInspectionNotes = (detail) => {
      const deleteIndex = structure.value.T_Insp_Comment.findIndex(
        (a) => a?.COMMENT_TYPE == detail?.COMMENT_TYPE
      );
      if (deleteIndex > -1) {
        structure.value.T_Insp_Comment.splice(deleteIndex, 1);
        getInspectionNotes();
      }
    };

    const anyEditableInspectionNotes = computed(() => {
      return inspectionNotes.value?.some((element) => !element.readOnly);
    });

    const addInspectionNotes = () => {
      addingAndEditingInspectionNotes = true;
      inspectionStore.setAddingOrEditing(true);
      newInspectionNote = {
        BRKEY: structure.value.Bridge.BRKEY,
        INSPKEY: structure.value.InspEvnt.INSPKEY,
        TEMP_COMMENT_TYPE: "",
        isAdd: true,
        readOnly: false,
        COMMENT_TYPE: "",
        MODTIME: structure.value.InspEvnt.MODTIME,
        NOTES: "",
        USERKEY: structure.value.InspEvnt.USERKEY,
      };
      inspectionNotes.value.unshift(newInspectionNote);
      inspectionNotesSnackbarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.INSPECTION_NOTES_SAVE_MESSAGE,
      });
      changesDetectedInspectionNotes.value = false;
      nextTick(() => {
        updateInspectionPage.value?.setPageValue(1);
        inspectionNotesForm.value.validate();
        scrollToDataTable("select_Note_Type");
      });
    };

    const saveInspectionNotes = (detail) => {
      inspectionNotesForm.value
        .validate()
        .then(() => {
          if (validInspection.value) {
            inspectionStore.setShowValidationSnackbar(false);
            if (!structure.value?.T_Insp_Comment?.[0]) {
              //remove null values from array(if any)
              structure.value.T_Insp_Comment =
                structure.value.T_Insp_Comment?.filter((a) => !!a);
            }
            if (detail.isAdd) {
              structure.value.T_Insp_Comment.push(
                convertToInspectionNotesObject(newInspectionNote)
              );
            } else {
              //update existing
              const updateIndex = structure.value.T_Insp_Comment.findIndex(
                (a) => a?.COMMENT_TYPE == detail?.COMMENT_TYPE
              );
              structure.value.T_Insp_Comment.splice(
                updateIndex,
                1,
                convertToInspectionNotesObject(detail)
              );
            }
            //if all required fields entered - close the expanded row
            newInspectionNote = null;
            changesDetectedInspectionNotes.value = false;
            snackbarStore.removeMessage(inspectionNotesSnackbarId.value);
            getInspectionNotes();
            addingAndEditingInspectionNotes = false;
            setAddingOrEditing();
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => {
          LOGGER.logException(err);
          newInspectionNote = null;
          changesDetectedInspectionNotes.value = false;
          snackbarStore.removeMessage(inspectionNotesSnackbarId.value);
          getInspectionNotes();
          addingAndEditingInspectionNotes = false;
          setAddingOrEditing();
        });
    };

    //Sub Inspection Notes

    let changesDetectedSubInspectionNotes = ref(false);

    let subInspectionNotes = ref([]);

    let newSubInspectionNote = null;

    let subPageInspection = ref({
      itemsPerPage: 30,
      page: 1,
    });
    let updateSubPage = ref(null);

    let validSubInspection = ref(false);

    let subInspectionNotesForm = ref(null);

    const convertToSubInspectionNotesObject = (detail) => {
      if (detail.isAdd) {
        return {
          BRKEY: detail.BRKEY,
          INSPKEY: detail.INSPKEY,
          STRUNITKEY: detail.TEMP_STRUNITKEY,
          COMMENT_TYPE: detail.TEMP_COMMENT_TYPE,
          MODTIME: detail.MODTIME,
          NOTES: detail.NOTES,
          USERKEY: detail.USERKEY,
        };
      } else {
        return {
          BRKEY: detail.BRKEY,
          INSPKEY: detail.INSPKEY,
          COMMENT_TYPE: detail.COMMENT_TYPE,
          STRUNITKEY: detail.STRUNITKEY,
          MODTIME: detail.MODTIME,
          NOTES: detail.NOTES,
          USERKEY: detail.USERKEY,
        };
      }
    };

    const getSubInspectionNotes = () => {
      subInspectionNotes.value = clone(
        structure.value.T_Struc_Unit_Insp_Comment
      );

      if (!subInspectionNotes.value?.[0]) {
        //remove null values from array(if any)
        subInspectionNotes.value = subInspectionNotes.value?.filter((a) => !!a);
      }
      for (let idx = 1; idx <= subInspectionNotes.value?.length; idx++) {
        subInspectionNotes.value[idx - 1].readOnly = true;
        subInspectionNotes.value[idx - 1].isAdd = false;
      }
      sortSubInspectionNotes(subInspectionNotes.value);
    };
    getSubInspectionNotes();

    const getSubInspectionNotesCommentTypes = computed(() => {
      const addNote = subInspectionNotes.value.find((note) => note.isAdd);
      if (addNote) {
        let subCommentTypes = configStore.getReferenceValues(
          REFERENCE_TABLE.SUBSTRUCTURE_COMMENT_TYPE
        );

        let subInspectionNoteTypes = [];

        subInspectionNotes.value.forEach((note) => {
          if (note.STRUNITKEY == addNote.TEMP_STRUNITKEY) {
            subInspectionNoteTypes.push(note.COMMENT_TYPE);
          }
        });

        return subCommentTypes
          .filter(
            (subCommentType) =>
              !subInspectionNoteTypes.includes(subCommentType.value)
          )
          .sort();
      } else {
        return configStore.getReferenceValues(
          REFERENCE_TABLE.SUBSTRUCTURE_COMMENT_TYPE
        );
      }
    });

    const getSubInspectionNotesUnitKeys = computed(() => {
      const addNote = subInspectionNotes.value.find((note) => note.isAdd);
      if (addNote) {
        let subUnits = inspectionStore.getAllSubUnits();

        let subInspectionKeys = [];

        subInspectionNotes.value.forEach((note) => {
          if (note.COMMENT_TYPE == addNote.TEMP_COMMENT_TYPE) {
            subInspectionKeys.push(note.STRUNITKEY);
          }
        });

        return subUnits
          .filter((subKey) => !subInspectionKeys.includes(subKey.value))
          .sort();
      } else {
        return inspectionStore.getAllSubUnits();
      }
    });

    const closeSubInspectionNotes = () => {
      if (isEditable.value && changesDetectedSubInspectionNotes.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              addingAndEditingSubNotes = false;
              setAddingOrEditing();
              getSubInspectionNotes();
              newSubInspectionNote = null;
              snackbarStore.removeMessage(substructureNotesSnackbarId.value);
              changesDetectedSubInspectionNotes.value = false;
            }
          });
      } else {
        newSubInspectionNote = null;
        snackbarStore.removeMessage(substructureNotesSnackbarId.value);
        addingAndEditingSubNotes = false;
        setAddingOrEditing();
        getSubInspectionNotes();
      }
    };

    const editSubInspectionNotes = (detail) => {
      addingAndEditingSubNotes = true;
      inspectionStore.setAddingOrEditing(true);
      detail.readOnly = false;
      substructureNotesSnackbarId.value = snackbarStore.showMessage({
        displayText:
          INSPECTION_MESSAGES.SUBSTRUCTURE_INSPECTION_NOTES_SAVE_MESSAGE,
      });
      changesDetectedSubInspectionNotes.value = false;
    };

    const deleteSubInspectionNotes = (detail) => {
      const deleteIndex = structure.value.T_Struc_Unit_Insp_Comment.findIndex(
        (a) =>
          a?.COMMENT_TYPE == detail?.COMMENT_TYPE &&
          a?.STRUNITKEY == detail?.STRUNITKEY
      );
      if (deleteIndex > -1) {
        structure.value.T_Struc_Unit_Insp_Comment.splice(deleteIndex, 1);
        getSubInspectionNotes();
      }
    };

    const anyEditableSubInspectionNotes = computed(() => {
      return subInspectionNotes.value?.some((element) => !element.readOnly);
    });

    const addSubInspectionNotes = () => {
      addingAndEditingSubNotes = true;
      inspectionStore.setAddingOrEditing(true);
      newSubInspectionNote = {
        BRKEY: structure.value.Bridge.BRKEY,
        INSPKEY: structure.value.InspEvnt.INSPKEY,
        STRUNITKEY: "",
        isAdd: true,
        readOnly: false,
        TEMP_STRUNITKEY: "",
        COMMENT_TYPE: "",
        TEMP_COMMENT_TYPE: "",
        MODTIME: structure.value.InspEvnt.MODTIME,
        NOTES: "",
        USERKEY: structure.value.InspEvnt.USERKEY,
      };
      subInspectionNotes.value.unshift(newSubInspectionNote);
      substructureNotesSnackbarId.value = snackbarStore.showMessage({
        displayText:
          INSPECTION_MESSAGES.SUBSTRUCTURE_INSPECTION_NOTES_SAVE_MESSAGE,
      });
      changesDetectedSubInspectionNotes.value = false;
      nextTick(() => {
        updateSubPage.value?.setPageValue(1);
        subInspectionNotesForm.value.validate();
        scrollToDataTable("select_New_SubStructure_Type");
      });
    };

    const saveSubInspectionNotes = (detail) => {
      subInspectionNotesForm.value
        .validate()
        .then(() => {
          if (validSubInspection.value) {
            inspectionStore.setShowValidationSnackbar(false);
            if (!structure.value?.T_Struc_Unit_Insp_Comment?.[0]) {
              //remove null values from array(if any)
              structure.value.T_Struc_Unit_Insp_Comment =
                structure.value.T_Struc_Unit_Insp_Comment?.filter((a) => !!a);
            }
            if (detail.isAdd) {
              structure.value.T_Struc_Unit_Insp_Comment.push(
                convertToSubInspectionNotesObject(newSubInspectionNote)
              );
            } else {
              //update existing
              const updateIndex =
                structure.value.T_Struc_Unit_Insp_Comment.findIndex(
                  (a) =>
                    a?.COMMENT_TYPE == detail?.COMMENT_TYPE &&
                    a?.STRUNITKEY == detail?.STRUNITKEY
                );
              structure.value.T_Struc_Unit_Insp_Comment.splice(
                updateIndex,
                1,
                convertToSubInspectionNotesObject(detail)
              );
            }
            //if all required fields entered - close the expanded row
            newSubInspectionNote = null;
            changesDetectedSubInspectionNotes.value = false;
            snackbarStore.removeMessage(substructureNotesSnackbarId.value);
            getSubInspectionNotes();
            addingAndEditingSubNotes = false;
            setAddingOrEditing();
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => {
          LOGGER.logException(err);
          newSubInspectionNote = null;
          changesDetectedSubInspectionNotes.value = false;
          snackbarStore.removeMessage(substructureNotesSnackbarId.value);
          getSubInspectionNotes();
          addingAndEditingSubNotes = false;
          setAddingOrEditing();
        });
    };

    const checkFormValidation = (value) => {
      //add validation only when the expansion panel is expanded(value = true)
      if (value) {
        nextTick(() => {
          inspectionNotesForm.value.validate();
        });
      }
    };

    const checkSubFormValidation = (value) => {
      //add validation only when the expansion panel is expanded(value = true)
      if (value) {
        nextTick(() => {
          subInspectionNotesForm.value.validate();
        });
      }
    };

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.T_Insp_Comment,
        structure.value.T_Struc_Unit_Insp_Comment,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      getSubInspectionNotes,
      getInspectionNotes,
      updateInspectionPage,
      updateSubPage,
      newSubInspectionNote,
      newInspectionNote,
      structure,
      subPageInspection,
      pageInspection,
      changesDetectedSubInspectionNotes,
      changesDetectedInspectionNotes,
      getSubInspectionNotesUnitKeys,
      getSubInspectionNotesCommentTypes,
      getInspectionNotesCommentTypes,
      subInspectionNotes,
      saveSubInspectionNotes,
      addSubInspectionNotes,
      anyEditableSubInspectionNotes,
      deleteSubInspectionNotes,
      editSubInspectionNotes,
      closeSubInspectionNotes,
      inspectionNotes,
      saveInspectionNotes,
      addInspectionNotes,
      anyEditableInspectionNotes,
      deleteInspectionNotes,
      editInspectionNotes,
      closeInspectionNotes,
      configStore,
      commentsMaxLength,
      closeConfirm,
      BUTTONS,
      validSubInspection,
      subInspectionNotesForm,
      validInspection,
      inspectionNotesForm,
      isEditable,
      panel,
      REFERENCE_TABLE,
      inspectionStore,
      scrollToDataTable,
      checkFormValidation,
      checkSubFormValidation,
      noteTypeIC01,
      substuctureUnitIC03,
      noteTypeIC04,
      toggleAllPanels,
    };
  },
  components: {
    ConfirmDialog,
    IconTooltip,
    LabelText,
    LabelSelect,
    DataTableFooter,
    ExpandCollapseToggle,
  },
});
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

.v-expansion-panel-title,
.v-expansion-panel-title:hover {
  background-color: c.$p-pa-light-blue !important;
}

h3 {
  order: 1;
  color: c.$p-white !important;
}

.v-expansion-panel-title__icon {
  order: 0;
}
</style>
