<template>
  <v-row>
    <ExpandCollapseToggle
      id="eDocsPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panels"
    />
    <v-expansion-panels v-model="panels" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panels.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panels.includes(0)" icon="fa-solid fa-minus" />
          <h3>Electronic Documents</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col>
              <v-btn
                variant="outlined"
                class="mx-5"
                @click="download('allSelected')"
                :disabled="!docSelected || !connectivity.getisOnline"
              >
                Download E-Docs
              </v-btn>
              <v-btn
                @click="downloadZip"
                variant="outlined"
                class="mx-5"
                :disabled="
                  (!connectivity.getisOnline && !downloadedDocSelected) ||
                  !docSelected
                "
              >
                Save to .zip file
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panels.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panels.includes(1)" icon="fa-solid fa-minus" />
          <h3>Inventory</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <EDocsTable
            :eDocsList="inventoryDocs"
            id="inventory"
            ref="inventoryDocsTable"
          />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panels.includes(2)" icon="fa-solid fa-plus" />
          <v-icon v-if="panels.includes(2)" icon="fa-solid fa-minus" />
          <h3>Inspection</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <EDocsTable
            :eDocsList="inspectionDocs"
            id="inspection"
            ref="inspectionDocsTable"
        /></v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panels.includes(3)" icon="fa-solid fa-plus" />
          <v-icon v-if="panels.includes(3)" icon="fa-solid fa-minus" />
          <h3>Drawings</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          ><DrawingsTable ref="drawingsTableRef" />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel v-if="otherDocs.length > 0">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panels.includes(4)" icon="fa-solid fa-plus" />
          <v-icon v-if="panels.includes(4)" icon="fa-solid fa-minus" />
          <h3>Other</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          ><EDocsTable :eDocsList="otherDocs" id="other" ref="otherDocsTable" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    scroll-strategy="none"
  >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
  <v-dialog v-model="downloadErrorMessage.show" :width="800">
    <v-card class="pa-3 warning-dialog">
      <p class="h3 mb-6"><strong>&nbsp;</strong></p>
      <p class="mb-2">{{ downloadErrorMessage.message }}</p>

      <v-table class="message-table-responsive">
        <thead>
          <tr>
            <th id="th_docLabel">Description/Document Label</th>
            <th id="th_docType">Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in downloadErrorMessage?.errorDocs" :key="d">
            <td>{{ d.label }}</td>
            <td>{{ d.type }}</td>
          </tr>
        </tbody>
      </v-table>

      <button
        @click="downloadErrorMessage.show = false"
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon size="x-large" icon="fas fa-xmark mx-2" />
        <small>CLOSE</small>
      </button>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref, onMounted, watch } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { useConfigStore } from "@/stores/config";
import EDocsTable from "@/components/shared/EDocsTable.vue";
import JSZip from "jszip";
import {
  base64ToArrayBuffer,
  getEDocDownloadErrorDesc,
  scrubCharFromStart,
} from "@/composables/util";
import { DOCUMENT_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import mime from "mime";
import { useStructureSearchStore } from "@/stores/structureSearch";
import { HTTP_STATUS_CODE } from "@/constants/CommonWebCodes";
import {
  RESPONSE_ERROR_MESSAGE,
  BUTTONS,
  EDOC_ERROR_DESC,
} from "@/constants/CommonWebConstants";
import { DOWNLOAD_PROGRESS, EDOC_CATEGORY } from "@/constants/Inspections";
import { useEdocStore } from "@/stores/edoc";
import { useConnectivityStore } from "@/stores/connectivity";
import { RECORD_MANAGEMENT_TYPES } from "@/constants/StructureSearches";
import rateLimit from "axios-rate-limit";
import auth from "@/auth";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useEDocsIDBStore } from "@/stores/eDocsIDB";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import DrawingsTable from "@/components/shared/DrawingsTable.vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

export default {
  name: "InspectionEDocs",
  setup() {
    let inventoryDocsTable = ref(null);
    let inspectionDocsTable = ref(null);
    let otherDocsTable = ref(null);
    let drawingsTableRef = ref(null);
    let snackBarId = ref("");
    const inspectionStore = useInspectionStore();
    const configStore = useConfigStore();
    const eDocStore = useEdocStore();
    const snackbarStore = useSnackbarStore();
    const allPanels = [0, 1, 2, 3, 4];
    const panels = ref(allPanels);
    const structureSearchStore = useStructureSearchStore();
    let isLoading = ref(true);
    const eDocsIDBStore = useEDocsIDBStore();

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    let structure = computed(() => inspectionStore.selectedInspection);
    let inventoryDocs = ref([]);
    let inspectionDocs = ref([]);
    let otherDocs = ref([]);
    const structureIDBStore = useStructureIDBStore();
    const attachmentsInIndexDB = ref(null);
    const connectivity = useConnectivityStore();
    // contains value to either download all selected docs (Download EDocs button) or selected undownloaded docs (Save to Zip file)
    let downloadDocs = ref();
    let downloadErrorMessage = ref({
      show: false,
      header: "Documents",
      value: null,
      errorDocs: [],
      message: "The following documents were unable to be downloaded:",
    });

    onMounted(async () => {
      await structureIDBStore.initDB(false);
      await eDocsIDBStore.initDB();
      await init();
    });

    const init = async () => {
      inventoryDocs.value = [];
      inspectionDocs.value = [];
      otherDocs.value = [];
      let attachmentsTable = await eDocsIDBStore.getAttachmentsFromIDB(
        structure.value.Bridge.BRKEY
      );
      eDocStore.downloads[structure.value.Bridge.BRKEY] =
        attachmentsTable.EDocs;
      attachmentsInIndexDB.value = attachmentsTable._attachments;
      //array contains null value when empty hence filtering null values first
      const allAttachments = structure.value.T_Edms_Doc?.filter(
        (a) => !!a
      ).filter((a) => {
        const docContainer = getDocContainerFromSeqNum(a.DOC_CONTAINER_SEQ_NUM);
        const docType = configStore.getDocType(docContainer.DOC_TYPE_KEY);

        return (
          docContainer &&
          (docType.iFormsDownloadFromEdocs == "1" ||
            docType.iFormsDownloadInitial == "1")
        );
      });

      allAttachments?.forEach((doc) => {
        const docContainer = getDocContainerFromSeqNum(
          doc.DOC_CONTAINER_SEQ_NUM
        );
        const docType = configStore.getDocType(docContainer.DOC_TYPE_KEY);
        let docDetails = {
          docId: doc?.EDMS_DOC_ID,
          label: docContainer.DOC_LABEL,
          fileName: null,
          source: docContainer.SOURCE_DESC,
          type: docType.documentType,
          content: null,
          contentType: null,
          category: docType.docCategory,
          docContainerSeqNum: docContainer.DOC_CONTAINER_SEQ_NUM,
          repository: doc?.EDMS_CS_REPOSITORY,
          documentVersion: doc?.EDMS_DOC_VERSION,
          download: null,
          inspectionDate: null,
          lastModDate: docContainer.LAST_MOD_DATE,
        };

        if (attachmentsInIndexDB.value) {
          for (const attachKey of Object.keys(attachmentsInIndexDB.value)) {
            const attachmentKey = attachKey.split("^");
            const docId = attachmentKey?.[1];
            if (doc?.EDMS_DOC_ID === docId) {
              docDetails.fileName = attachmentKey?.[0];
              const dbAttachment = attachmentsInIndexDB.value[attachKey];
              docDetails.content = dbAttachment?.data;
              docDetails.contentType = dbAttachment?.content_type;
              break;
            }
          }
        }
        const docProgress = getDocProgressFromStore(
          doc.DOC_CONTAINER_SEQ_NUM,
          doc.EDMS_DOC_ID
        );
        docDetails.download = docProgress?.download;
        docDetails.error = docProgress?.error;

        if (docDetails.category === EDOC_CATEGORY.INVENTORY) {
          inventoryDocs.value.push(docDetails);
        } else if (docDetails.category === EDOC_CATEGORY.INSPECTION) {
          docDetails.inspectionDate = getInspectionDate(docContainer);
          inspectionDocs.value.push(docDetails);
        } else {
          otherDocs.value.push(docDetails);
        }
      });
      sort();
      isLoading.value = false;
    };

    const sort = () => {
      inventoryDocs.value?.sort((a, b) => a.type.localeCompare(b.type));
      otherDocs.value?.sort((a, b) => a.type.localeCompare(b.type));
      let sortByDate;
      inspectionDocs.value.sort((a, b) => {
        // nulls sort after anything else
        if (!a.inspectionDate && !b.inspectionDate) {
          // If both dates are null, sort by type
          return a.type.localeCompare(b.type);
        } else if (!a.inspectionDate) {
          sortByDate = 1;
        } else if (!b.inspectionDate) {
          sortByDate = -1;
        } else {
          sortByDate = new Date(b.inspectionDate) - new Date(a.inspectionDate);
        }
        return sortByDate;
      });
    };

    const getInspectionDate = (docContainer) => {
      return inspectionStore.inspections[structure.value.Bridge.BRKEY]?.find(
        (i) => i.inspectionId == docContainer?.INSPKEY
      )?.inspectionDate;
    };

    const getDocProgressFromStore = (docContainerSeqNum, docId) => {
      const storedDownloadProgress =
        eDocStore.downloads[structure.value.Bridge.BRKEY];
      if (storedDownloadProgress) {
        let storeDocProgress = storedDownloadProgress.find(
          (d) => d.docId == docId && d.docContainerSeqNum == docContainerSeqNum
        );
        return storeDocProgress || null;
      }
    };

    const getDocContainerFromSeqNum = (docContainerSeqNum) => {
      return structure.value.T_Doc_Container?.find(
        (d) => d.DOC_CONTAINER_SEQ_NUM == docContainerSeqNum
      );
    };

    const download = async (docsToDownload) => {
      downloadDocs.value = docsToDownload;
      structureSearchStore.userAuthenticationErrors = {};
      const selectedInvDocs = inventoryDocsTable.value?.selectedDocs || [];
      const selectedInspDocs = inspectionDocsTable.value?.selectedDocs || [];
      const selectedOtherDocs = otherDocsTable.value?.selectedDocs || [];
      const selectedDocs = selectedInvDocs.concat(
        selectedInspDocs,
        selectedOtherDocs
      );
      if (downloadDocs.value == "allSelected") {
        downloadDocuments(selectedDocs);
      } else {
        //only download selected undownloaded docs
        await downloadDocuments(selectedDocs.filter((d) => !d.content));
      }
    };

    const addInterceptor = () => {
      http.interceptors.request.use(async (config) => {
        const user = await auth.getUser();
        config.headers.Authorization = `Bearer ${user.id_token}`;
        return config;
      });
    };

    const maxRequests = parseInt(
      configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.EDOC_SERVICE_MAX_REQUESTS
      )
    );

    const http = rateLimit(axios.create(), {
      maxRequests: maxRequests,
      perMilliseconds: parseInt(
        configStore.getEnvConfigValue(
          ENV_CONFIG_PROPERTY.EDOC_SERVICE_PER_MILLISECONDS
        )
      ),
      maxRPS: maxRequests,
    });
    addInterceptor();

    const retryDownloadDocs = async (selectedDocs, retryDocIds) => {
      let retryDocs;
      if (retryDocIds?.length > 0) {
        //get doc object from docid
        retryDocs = selectedDocs.filter((a) => retryDocIds.includes(a.docId));
      }
      if (retryDocs?.length > 0) {
        await auth.silentLogin();
        await downloadDocuments(retryDocs);
      }
    };

    const downloadDocuments = async (selectedDocs) => {
      selectedDocs.map((d) => {
        d.download = DOWNLOAD_PROGRESS.START;
        setDownloadProgress(
          d.docContainerSeqNum,
          d.docId,
          DOWNLOAD_PROGRESS.START
        );
        return d;
      });

      let abortController = new AbortController();
      const promises = getEDocs(selectedDocs, abortController);
      let failedDocsToRetry = [];
      await callDownloadService(
        promises,
        abortController,
        selectedDocs,
        failedDocsToRetry
      );
      await retryDownloadDocs(selectedDocs, failedDocsToRetry);
    };

    const checkTokenExpiry = (e, failedDocsToRetry) => {
      //Contains Invalid JWT.
      const statusCode = e.response?.data?.statusCode;
      const message = e.response?.data?.message;
      if (
        statusCode == HTTP_STATUS_CODE.NOT_AUTHORIZED &&
        message?.includes(RESPONSE_ERROR_MESSAGE.INVALID_JWT)
      ) {
        //the error is because of access token expiry
        //store the failed calls for retrying with new token
        failedDocsToRetry.push(e?.response?.config?.headers.docId);
      } else if (e?.code == "ERR_CANCELED") {
        const id = e?.config?.headers.docId;
        if (id) {
          failedDocsToRetry.push(id);
        }
      }
    };

    const callDownloadService = async (
      promises,
      abortController,
      selectedDocs,
      failedDocsToRetry
    ) => {
      await Promise.allSettled(
        promises.map((p) =>
          p
            .then((result) => {
              if (result.data?.data) {
                const docId = result.config.headers.docId;
                const docContainerSeqNum = result.config.headers.docContainer;

                const docContainer =
                  getDocContainerFromSeqNum(docContainerSeqNum);
                const docType = configStore.getDocType(
                  docContainer?.DOC_TYPE_KEY
                );
                const fileName = scrubCharFromStart(
                  result.data.data.filename,
                  "_"
                );
                let attachmentName = fileName + "^" + docId;
                let attachment = {
                  content_type: mime.getType(fileName),
                  data: result.data.data.content,
                };
                saveAttachment(
                  fileName,
                  attachmentName,
                  attachment,
                  docType,
                  docContainerSeqNum,
                  docId
                );
              }
              inventoryDocsTable.value?.clearSelections();
              inspectionDocsTable.value?.clearSelections();
              otherDocsTable.value?.clearSelections();
              return result;
            })
            .catch(async (e) => {
              LOGGER.logException(e);
              const docId = e?.response?.config?.headers.docId;
              const docContainerSeqNum =
                e?.response?.config?.headers.docContainer;
              //check if token expired
              checkTokenExpiry(e, failedDocsToRetry);
              if (
                e.response.status === HTTP_STATUS_CODE.NOT_AUTHORIZED ||
                e.response.status === HTTP_STATUS_CODE.FORBIDDEN
              ) {
                abortController.abort("Unauthorized");
                selectedDocs.map((d) => (d.download = null));
                setErrors(e, docContainerSeqNum, docId);
              } else {
                await setDownloadProgress(
                  docContainerSeqNum,
                  docId,
                  DOWNLOAD_PROGRESS.FAIL,
                  getEDocDownloadErrorDesc(e.response.status)
                );
                inventoryDocsTable.value?.clearSelections();
                inspectionDocsTable.value?.clearSelections();
                otherDocsTable.value?.clearSelections();
              }
              throw e;
            })
        )
      ).then(async () => {
        //set download statuses
        structure.value.EDocs =
          eDocStore.downloads[structure.value.Bridge.BRKEY];
        //set attachments
        if (eDocStore.attachments && !structure.value._attachments) {
          structure.value._attachments = {};
        }
        for (const attachKey of Object.keys(eDocStore.attachments)) {
          structure.value._attachments[attachKey] =
            eDocStore.attachments[attachKey];
        }
        //set the record types to documents (saveStructure will take care of updating the record type to BOTH if applicable)
        structure.value.recordType = getRecordType(
          structure.value.recordType,
          RECORD_MANAGEMENT_TYPES.DOCUMENTS
        );
        //save to DB
        structureIDBStore.saveStructure(structure.value);
        //clear the attachments from store after being saved
        eDocStore.attachments = {};
      });
    };

    const getRecordType = (savedRecordType, newRecordType) => {
      return savedRecordType != newRecordType
        ? RECORD_MANAGEMENT_TYPES.BOTH
        : newRecordType;
    };

    const setErrors = (e, docContainerSeqNum, docId) => {
      if (structureSearchStore.userAuthenticationErrors[e.response.status]) {
        structureSearchStore.userAuthenticationErrors[e.response.status].push(
          docContainerSeqNum,
          docId
        );
      } else {
        structureSearchStore.userAuthenticationErrors[e.response.status] = [
          docContainerSeqNum,
          docId,
        ];
      }
    };

    const saveAttachment = async (
      fileName,
      attachmentName,
      attachment,
      docType,
      docContainerSeqNum,
      docId
    ) => {
      let downloadStatus = "";
      let errorDesc = "";
      if (attachment.data) {
        saveAttachmentToStore(attachmentName, attachment);
        downloadStatus = DOWNLOAD_PROGRESS.SUCCESS;
      } else {
        downloadStatus = DOWNLOAD_PROGRESS.FAIL;
        errorDesc = EDOC_ERROR_DESC.NO_CONTENT;
      }
      //documents returned from ecs that have a filename but no content, set the status to fail
      await setDownloadProgress(
        docContainerSeqNum,
        docId,
        downloadStatus,
        errorDesc
      );

      if (docType.docCategory === EDOC_CATEGORY.INVENTORY) {
        inventoryDocs.value.forEach((a) => {
          setfileContent(a, docContainerSeqNum, docId, attachment, fileName);
        });
      } else if (docType.docCategory === EDOC_CATEGORY.INSPECTION) {
        inspectionDocs.value.forEach((a) => {
          setfileContent(a, docContainerSeqNum, docId, attachment, fileName);
        });
      } else {
        otherDocs.value.forEach((a) => {
          setfileContent(a, docContainerSeqNum, docId, attachment, fileName);
        });
      }
    };

    const setfileContent = (
      file,
      docContainerSeqNum,
      docId,
      attachment,
      fileName
    ) => {
      if (
        file.docContainerSeqNum == docContainerSeqNum &&
        file.docId == docId
      ) {
        file.content = attachment.data;
        file.contentType = attachment.content_type;
        file.fileName = fileName;
      }
    };

    const getEDocs = (docs, abortController) => {
      return docs?.map(async (doc) => {
        return http.get(DOCUMENT_ENDPOINT + `/${doc.docId}`, {
          params: {
            brkey: structure.value.Bridge.BRKEY,
            repository: doc.repository,
            documentVersion: doc.documentVersion,
          },
          headers: {
            docId: doc.docId,
            docContainer: doc.docContainerSeqNum,
          },
          signal: abortController.signal,
        });
      });
    };

    const setDownloadProgress = async (
      docContainerSeqNum,
      docId,
      status,
      error
    ) => {
      await eDocStore.saveDownloadProgress(
        structure.value.Bridge.BRKEY,
        docContainerSeqNum,
        docId,
        status,
        error
      );
    };
    const saveAttachmentToStore = (attachmentName, attachment) => {
      eDocStore.saveAttachment(attachmentName, attachment);
    };

    const downloadZip = async () => {
      try {
        const writable = await selectZipFileLocation();
        const invDocs = ref(inventoryDocsTable.value?.selectedDocs || []);
        const inspDocs = ref(inspectionDocsTable.value?.selectedDocs || []);
        const otherDocs = ref(otherDocsTable.value?.selectedDocs || []);
        const selectedDocs = invDocs.value.concat(
          inspDocs.value,
          otherDocs.value
        );
        if (selectedDocs.some((d) => !d.content)) {
          downloadDocs.value = "undownloaded";
          await download();
        }

        if (selectedDocs.length > 0) {
          const errors = getErrors(selectedDocs);
          let fileStatuses = [];
          let zip = null;
          if (errors?.length != selectedDocs.length) {
            zip = getZipFileObject(selectedDocs, fileStatuses);
          } else {
            //no files can be saved to zip
            setFileStatuses(selectedDocs, fileStatuses, "Failed");
            zip = new JSZip();
            snackBarId.value = snackbarStore.showMessage({
              displayText: "No files were saved to the zip file.",
              timeout: 10000,
              messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
            });
          }
          zip.file("MANIFEST.TXT", getFileStatusString(fileStatuses));
          if (writable) {
            await writable.write(await zip.generateAsync({ type: "blob" }));
            await writable.close();
          } else {
            const blob = await zip.generateAsync({ type: "blob" });
            const blobURL = URL.createObjectURL(blob);
            // Create the `<a download>` element and append it invisibly.
            const a = document.createElement("a");
            a.href = blobURL;
            a.download = "downloads.zip";
            a.style.display = "none";
            document.body.append(a);
            // Programmatically click the element.
            a.click();
            // Revoke the blob URL and remove the element.
            setTimeout(() => {
              URL.revokeObjectURL(blobURL);
              a.remove();
            }, 1000);
          }
          showHideErrorDialog();
          inventoryDocsTable.value?.clearSelections();
          inspectionDocsTable.value?.clearSelections();
          otherDocsTable.value?.clearSelections();
        }
      } catch (err) {
        isLoading.value = false;
        LOGGER.logException(err);
      }
    };

    const setFileStatuses = (selectedDocs, fileStatuses, status) => {
      for (const doc of selectedDocs) {
        fileStatuses.push({
          filename: doc.fileName || doc.label || doc.type,
          status: status,
        });
      }
    };

    const getFileStatusString = (fileStatuses) => {
      return fileStatuses
        ?.map((f, i) => i + 1 + ". " + f.filename + ":" + f.status)
        .join("\r\n");
    };

    const showHideErrorDialog = () => {
      if (downloadErrorMessage.value.errorDocs?.length > 0) {
        downloadErrorMessage.value.show = true;
      } else {
        snackBarId.value = snackbarStore.showMessage({
          displayText: "E-docs .zip file created successfully.",
          timeout: 10000,
          messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
        });
      }
    };

    const getErrors = (docs) => {
      downloadErrorMessage.value.errorDocs = docs
        .filter((d) => !!d.error)
        .map((d) => ({ label: d.label, type: d.type }));
      return downloadErrorMessage.value.errorDocs;
    };

    const getZipFileObject = (selectedDocs, fileStatuses) => {
      let zip = new JSZip();
      for (const [i, doc] of selectedDocs.entries()) {
        const fileInZip = zip.files[doc.fileName];
        if (doc.content) {
          let fileName = doc.fileName;
          if (!fileInZip) {
            zip.file(fileName, base64ToArrayBuffer(doc.content), {
              binary: true,
            });
          } else {
            fileName = doc.fileName.replace(".", `_${i}.`);
            zip.file(fileName, base64ToArrayBuffer(doc.content), {
              binary: true,
            });
          }
          fileStatuses.push({ filename: fileName, status: "Success" });
        } else {
          fileStatuses.push({
            filename: doc.fileName ?? (doc.label || doc.type),
            status: "Failed",
          });
        }
      }
      return zip;
    };

    async function selectZipFileLocation() {
      if (window.showSaveFilePicker) {
        isLoading.value = true;
        const fhandle = await window.showSaveFilePicker({
          suggestedName: "downloads.zip",
          startIn: "downloads",
          types: [
            {
              description: "Zip folder",
              accept: {
                "application/zip": [".zip"],
              },
            },
          ],
        });
        isLoading.value = false;
        return await fhandle?.createWritable();
      } else {
        return null;
      }
    }

    const docSelected = computed(() => {
      const selectedInvDocs = inventoryDocsTable.value?.selectedDocs || [];
      const selectedInspDocs = inspectionDocsTable.value?.selectedDocs || [];
      const selectedOtherDocs = otherDocsTable.value?.selectedDocs || [];
      return (
        selectedInvDocs.concat(selectedInspDocs, selectedOtherDocs).length > 0
      );
    });

    const downloadedDocSelected = computed(() => {
      const selectedInvDocs = inventoryDocsTable.value?.selectedDocs || [];
      const selectedInspDocs = inspectionDocsTable.value?.selectedDocs || [];
      const selectedOtherDocs = otherDocsTable.value?.selectedDocs || [];
      const selectedDocs = selectedInvDocs.concat(
        selectedInspDocs,
        selectedOtherDocs
      );

      //check if any selected doc is downloaded to enable "Save to Zip"
      return selectedDocs.some(
        (doc) => doc.download == DOWNLOAD_PROGRESS.SUCCESS
      );
    });

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panels.value = allPanels;
      } else {
        panels.value = [];
      }
    };

    const getDrawings = () => {
      drawingsTableRef.value.getDrawings();
    };

    //watch changes in store for download progress of docs.
    watch(
      eDocStore.downloads,
      () => {
        inventoryDocsTable.value?.setDownloadProgress(
          eDocStore.downloads[structure.value.Bridge.BRKEY]
        );
        inspectionDocsTable.value?.setDownloadProgress(
          eDocStore.downloads[structure.value.Bridge.BRKEY]
        );
        otherDocsTable.value?.setDownloadProgress(
          eDocStore.downloads[structure.value.Bridge.BRKEY]
        );
      },
      { deep: true }
    );

    const resetSnackBarMessage = () => {
      snackbarStore.removeMessage(snackBarId.value);
    };

    return {
      drawingsTableRef,
      getDrawings,
      INSPECTION_MESSAGES,
      BUTTONS,
      REFERENCE_TABLE,
      panels,
      isEditable,
      structure,
      download,
      downloadZip,
      inventoryDocs,
      inspectionDocs,
      otherDocs,
      inventoryDocsTable,
      inspectionDocsTable,
      otherDocsTable,
      isLoading,
      connectivity,
      docSelected,
      downloadedDocSelected,
      downloadErrorMessage,
      init,
      toggleAllPanels,
      resetSnackBarMessage,
    };
  },
  components: {
    DrawingsTable,
    EDocsTable,
    ExpandCollapseToggle,
  },
};
</script>
