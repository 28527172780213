export const ACCESS_LEVELS = {
  VIEW: "view",
  EDIT: "edit",
  EXPORT: "export",
  ADD: "add",
  DRAFT: "draft",
  DELETE: "delete",
  APPROVE: "approve",
  CUSTOM: {
    BPR_CRITICAL_FINDING: "bprCriticalFinding",
    BPR_EDIT_IN_NON_DRAFT_STATE: "bprEditInNonDraftState"
  },
};
