import { PERMISSIONS } from "@/rbac/permissions.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_PERMISSIONS = [PERMISSIONS.INSPVIEW];

const EDIT_PERMISSIONS = [PERMISSIONS.INSPEDIT];

const EDIT_APPROVED_INSPECTION_PERMISSIONS = [
  PERMISSIONS.INSPEDIT,
  PERMISSIONS.INSPAPPREDIT,
];

const VIEW_APPROVED_INSPECTION_PERMISSIONS = [
  PERMISSIONS.INSPVIEW,
  PERMISSIONS.INSPAPPRVIEW,
];

export const INSPECTION_LINKS_PERMISSIONS = {
  [ACCESS_LEVELS.VIEW]: VIEW_PERMISSIONS,
  [ACCESS_LEVELS.EDIT]: EDIT_PERMISSIONS,
  [ACCESS_LEVELS.CUSTOM.VIEW_APPROVED_INSPECTION]:
    VIEW_APPROVED_INSPECTION_PERMISSIONS,
  [ACCESS_LEVELS.CUSTOM.EDIT_APPROVED_INSPECTION]:
    EDIT_APPROVED_INSPECTION_PERMISSIONS,
};
