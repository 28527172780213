<template>
  <v-btn
    :class="styleClass"
    :id="`btn_${id}`"
    :aria-describedby="`btn_${id}`"
    aria-label="SubmitButton"
    style="min-width: 100px"
    variant="outlined"
    :disabled="isDisabled"
    @click.stop.prevent="$emit('clicked')"
    :style="{
      'font-size': connectivity.getOnlineServiceStatus ? '.875rem' : '.720rem',
    }"
    >{{
      connectivity.getOnlineServiceStatus ? "Submit" : "Submit Later"
    }}</v-btn
  >
</template>
<script setup>
import { useConnectivityStore } from "@/stores/connectivity";

defineProps({
  isDisabled: { default: false, type: Boolean },
  styleClass: { default: "" },
  id: { default: "" },
});

const connectivity = useConnectivityStore();
</script>
