<template>
  <v-form v-model="validFutureForm" ref="futureScheduleForm">
    <v-table class="compactTable moreCompact">
      <thead>
        <tr>
          <th
            id="th_inspType"
            class="align-center"
            :style="$vuetify?.display?.smAndDown ? 'width: 24% !important' : ''"
          >
            Inspection Type
          </th>
          <th
            id="th_7A57"
            class="align-center"
            :style="$vuetify?.display?.smAndDown ? 'width: 12% !important' : ''"
          >
            7A57<br />Required
          </th>
          <th
            id="th_7A58"
            class="align-center"
            :style="$vuetify?.display?.smAndDown ? 'width: 15% !important' : ''"
          >
            7A58<br />Last Date
          </th>
          <th
            id="th_7A59"
            class="align-center"
            :style="$vuetify?.display?.smAndDown ? 'width: 24% !important' : ''"
          >
            7A59<br />Interval
          </th>
          <th
            id="th_7A60"
            class="align-center"
            :style="$vuetify?.display?.smAndDown ? 'width: 25% !important' : ''"
          >
            7A60<br />Next Inspection Date
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, idx) in futureScheduleData" :key="idx">
          <td class="align-center">
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.SNBI_INSPECTION_TYPE,
                row.inspType
              )
            }}
          </td>
          <td class="align-center">
            <v-checkbox
              v-if="row.show7A57Checkbox"
              :id="'checkbox_snbi_required' + idx"
              :aria-describedby="'checkbox_snbi_required' + idx"
              :aria-label="'checkbox_snbi_required' + idx"
              class="noGridCheckbox justify-center"
              :isEditable="isEditable"
              true-value="1"
              false-value="0"
              v-model="row.is7A57RequiredValue"
              @update:model-value="updateFieldDependencies(row)"
              hide-details
              :disabled="!isEditable"
            >
            </v-checkbox>
            <span v-else>N/A</span>
          </td>
          <td class="align-center">
            <LabelDatefield
              textXl="12"
              textLg="12"
              textMd="12"
              textSm="12"
              :id="'txt_snbi_last_inspection_date_' + idx"
              v-model="row.lastInspectionDate"
            >
            </LabelDatefield>
          </td>
          <td
            :style="
              $vuetify?.display?.mdAndDown
                ? 'width: 24% !important'
                : 'width: 19% !important'
            "
          >
            <div class="align-center" v-if="row.showInterval">
              <LabelText
                textXl="12"
                textLg="12"
                textMd="12"
                textSm="12"
                :id="'txt_snbi_inspection_interval_' + idx"
                :isEditable="isEditable"
                :disabled="true"
                v-model="row.inspectionInterval"
                appendWith=" mos"
                type="number"
                maxlength="2"
                textStyle="width:80%"
                allowNegativeInput
                :rules="
                  isEditable
                    ? [
                        (value) => {
                          return valueInRangeRule(value, 0, 99);
                        },
                      ]
                    : []
                "
                :isRequired="isEditable && row.is7A57RequiredValue == '1'"
                @update:modelValue="updateFieldDependencies(row)"
              >
              </LabelText>
            </div>
            <div
              class="align-right"
              v-else-if="!row.showInterval && isEditable"
              style="width: 80%"
            >
              <span class="pr-8">0</span>
              <span style="font-size: 1rem; float: right">mos</span>
            </div>
            <div v-else style="width: 92%" class="align-center">
              <span>0 mos</span>
            </div>
          </td>

          <td class="align-center">
            <LabelDatefield
              v-if="row.showInspDueDate"
              textXl="8"
              textLg="10"
              textMd="12"
              textSm="12"
              :id="'txt_snbi_next_inspection_date_' + idx"
              v-model="row.inspectionDueDate"
              :isEditable="isEditable"
              :isRequired="isEditable && row.is7A57RequiredValue == '1'"
              @update:modelValue="updateInspTypeNextDate(row)"
              class="date_center"
            >
            </LabelDatefield>
            <span v-else>N/A</span>
          </td>
        </tr>
      </tbody></v-table
    >
  </v-form>
</template>
<script>
import { valueInRangeRule } from "@/composables/validationRules";
import LabelText from "@/components/common/LabelText.vue";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { computed, ref, defineComponent, watch } from "vue";
import { useInspectionStore } from "@/stores/inspection";

export default defineComponent({
  name: "FutureScheduleTable",
  emits: ["updateInterval", "updateNextDate"],
  setup(_, ctx) {
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    const structure = computed(() => inspectionStore.selectedInspection);
    const isEditable = computed(() => inspectionStore.isEditable);
    let futureScheduleForm = ref(null);
    let validFutureForm = ref(false);
    let snbiScheduleChanged = ref(false);

    const futureScheduleData = ref([
      {
        inspType: "1",
        show7A57Checkbox: false,
        showInterval: false,
        showInspDueDate: false,
        is7A57RequiredValue: null,
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule?.[0]?.FT_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].FT_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: 0,
        inspectionDueDate: null,
      },
      {
        inspType: "2",
        show7A57Checkbox: true,
        showInterval: true,
        showInspDueDate: true,
        is7A57RequiredValue: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule?.[0]?.ROU_REQUIRED;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].ROU_REQUIRED = param;
          },
        }),
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule?.[0]?.ROU_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].ROU_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule?.[0]?.ROU_INTERVAL;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].ROU_INTERVAL = param;
          },
        }),
        inspectionDueDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule?.[0]?.ROU_NEXT_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].ROU_NEXT_DATE = param;
          },
        }),
      },
      {
        inspType: "3",
        show7A57Checkbox: true,
        showInterval: true,
        showInspDueDate: true,
        is7A57RequiredValue: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.UW_REQUIRED;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].UW_REQUIRED = param;
          },
        }),
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.UW_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].UW_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.UW_INTERVAL;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].UW_INTERVAL = param;
          },
        }),
        inspectionDueDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.UW_NEXT_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].UW_NEXT_DATE = param;
          },
        }),
      },
      {
        inspType: "4",
        show7A57Checkbox: true,
        showInterval: true,
        showInspDueDate: true,
        is7A57RequiredValue: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.NSTM_REQUIRED;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].NSTM_REQUIRED = param;
          },
        }),
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.NSTM_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].NSTM_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.NSTM_INTERVAL;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].NSTM_INTERVAL = param;
          },
        }),
        inspectionDueDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.NSTM_NEXT_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].NSTM_NEXT_DATE = param;
          },
        }),
      },
      {
        inspType: "5",
        show7A57Checkbox: false,
        showInterval: false,
        showInspDueDate: false,
        is7A57RequiredValue: null,
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.DMG_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].DMG_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: 0,
        inspectionDueDate: null,
      },
      {
        inspType: "6",
        show7A57Checkbox: true,
        showInterval: true,
        showInspDueDate: true,
        is7A57RequiredValue: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.IND_REQUIRED;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].IND_REQUIRED = param;
          },
        }),
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.IND_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].IND_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.IND_INTERVAL;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].IND_INTERVAL = param;
          },
        }),
        inspectionDueDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.IND_NEXT_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].IND_NEXT_DATE = param;
          },
        }),
      },
      {
        inspType: "7",
        show7A57Checkbox: true,
        showInterval: true,
        showInspDueDate: true,
        is7A57RequiredValue: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.OS_REQUIRED;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].OS_REQUIRED = param;
          },
        }),
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.OS_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].OS_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.OS_INTERVAL;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].OS_INTERVAL = param;
          },
        }),
        inspectionDueDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.OS_NEXT_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].OS_NEXT_DATE = param;
          },
        }),
      },
      {
        inspType: "8",
        show7A57Checkbox: true,
        showInterval: true,
        showInspDueDate: true,
        is7A57RequiredValue: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.SERV_REQUIRED;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].SERV_REQUIRED = param;
          },
        }),
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.SERV_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].SERV_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.SERV_INTERVAL;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].SERV_INTERVAL = param;
          },
        }),
        inspectionDueDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.SERV_NEXT_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].SERV_NEXT_DATE = param;
          },
        }),
      },
      {
        inspType: "9",
        show7A57Checkbox: false,
        showInterval: false,
        showInspDueDate: false,
        is7A57RequiredValue: null,
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.SCM_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].SCM_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: 0,
        inspectionDueDate: null,
      },
      {
        inspType: "E",
        show7A57Checkbox: true,
        showInterval: true,
        showInspDueDate: true,
        is7A57RequiredValue: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.ELEM_REQUIRED;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].ELEM_REQUIRED = param;
          },
        }),
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.ELEM_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].ELEM_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.ELEM_INTERVAL;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].ELEM_INTERVAL = param;
          },
        }),
        inspectionDueDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.ELEM_NEXT_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].ELEM_NEXT_DATE = param;
          },
        }),
      },
      {
        inspType: "P",
        show7A57Checkbox: false,
        showInterval: false,
        showInspDueDate: false,
        is7A57RequiredValue: null,
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.PROB_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].PROB_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: 0,
        inspectionDueDate: null,
      },
      {
        inspType: "Q",
        show7A57Checkbox: false,
        showInterval: false,
        showInspDueDate: false,
        is7A57RequiredValue: null,
        lastInspectionDate: computed({
          get() {
            return structure.value?.Snbi_Insp_Schedule[0]?.QA_PREVIOUS_DATE;
          },
          set(param) {
            structure.value.Snbi_Insp_Schedule[0].QA_PREVIOUS_DATE = param;
          },
        }),
        inspectionInterval: 0,
        inspectionDueDate: null,
      },
    ]);

    //Update legacy schedule on entire page save
    const updateLegacySchedule = () => {
      // Get Routine insp type
      const inspTypeRoutine = futureScheduleData.value?.find(
        (inspTypeDetail) =>
          "2" == inspTypeDetail.inspType &&
          inspTypeDetail.is7A57RequiredValue == "1"
      );

      // Get Service insp type
      const inspTypeService = futureScheduleData.value?.find(
        (inspTypeDetail) =>
          "8" == inspTypeDetail.inspType &&
          inspTypeDetail.is7A57RequiredValue == "1"
      );

      let inspDetail = {
        lastInspectionDate: null,
        inspectionInterval: 0,
        inspectionDueDate: null,
      };

      if (inspTypeRoutine) {
        inspDetail = inspTypeRoutine;
      } else if (inspTypeService) {
        inspDetail = inspTypeService;
      }
      //NBI
      //update 7A08
      structure.value.InspEvnt.LASTINSP = inspDetail.lastInspectionDate;
      //update 7A09
      structure.value.InspEvnt.BRINSPFREQ = getInterval(inspDetail);
      //update 7A10
      structure.value.InspEvnt.NEXTINSP = inspDetail.inspectionDueDate;

      futureScheduleData.value?.forEach((inspTypeDetail) => {
        switch (inspTypeDetail.inspType) {
          case "3":
            //update 7A07
            structure.value.InspEvnt.UWINSPREQ =
              inspTypeDetail.is7A57RequiredValue;
            //update 7A08
            structure.value.InspEvnt.UWLASTINSP =
              inspTypeDetail.lastInspectionDate;
            //update 7A09
            structure.value.InspEvnt.UWINSPFREQ = getInterval(inspTypeDetail);
            //update 7A10
            structure.value.InspEvnt.UWNEXTDATE =
              inspTypeDetail.inspectionDueDate;
            break;
          case "4":
            structure.value.InspEvnt.FCINSPREQ =
              inspTypeDetail.is7A57RequiredValue;
            //update 7A08
            structure.value.InspEvnt.FCLASTINSP =
              inspTypeDetail.lastInspectionDate;
            structure.value.InspEvnt.FCINSPFREQ = getInterval(inspTypeDetail);
            //update 7A10
            structure.value.InspEvnt.FCNEXTDATE =
              inspTypeDetail.inspectionDueDate;
            break;
          case "E":
            structure.value.InspEvnt.ELINSPREQ =
              inspTypeDetail.is7A57RequiredValue;
            //update 7A08
            structure.value.InspEvnt.ELLASTINSP =
              inspTypeDetail.lastInspectionDate;
            structure.value.InspEvnt.ELINSPFREQ = getInterval(inspTypeDetail);
            //update 7A10
            structure.value.InspEvnt.ELNEXTDATE =
              inspTypeDetail.inspectionDueDate;
            break;
          case "7":
            structure.value.InspEvnt.OSINSPREQ =
              inspTypeDetail.is7A57RequiredValue;
            structure.value.InspEvnt.OSLASTINSP =
              inspTypeDetail.lastInspectionDate;
            structure.value.InspEvnt.OSINSPFREQ = getInterval(inspTypeDetail);
            //update 7A10
            structure.value.InspEvnt.OSNEXTDATE =
              inspTypeDetail.inspectionDueDate;
        }
      });
    };

    const updateFieldDependencies = async (inspTypeDetail) => {
      await validateForm();
      switch (inspTypeDetail.inspType) {
        case "Z":
        case "1":
        case "2":
        case "5":
        case "6":
        case "8":
        case "9":
        case "P":
        case "Q":
        case "3":
        case "4":
        case "E":
        case "7":
          //update 7A59
          inspTypeDetail.inspectionInterval = getInterval(inspTypeDetail);
          //update B.IE.05
          updateInspTypeInterval(inspTypeDetail);
          break;
      }
    };

    const getInterval = (inspTypeDetail) => {
      if (inspTypeDetail.is7A57RequiredValue == "1") {
        return inspTypeDetail.inspectionInterval;
      } else {
        //checkbox is not set, reset the interval
        return 0;
      }
    };

    const updateInspTypeInterval = (inspTypeDetail) => {
      ctx.emit("updateInterval", inspTypeDetail);
    };
    const updateInspTypeNextDate = (inspTypeDetail) => {
      ctx.emit("updateNextDate", inspTypeDetail);
    };

    const validateForm = async () => {
      await futureScheduleForm.value?.validate();
      let validDates = true;
      futureScheduleData.value.forEach((d) => {
        if (d.is7A57RequiredValue && d.is7A57RequiredValue == "1") {
          //check if Next insp date is set
          if (!d.inspectionDueDate) {
            validDates = false;
            return;
          }
        }
      });
      return validDates && validFutureForm.value;
    };

    const resetFormValidation = () => {
      futureScheduleForm.value?.resetValidation();
    };

    watch(
      () => [futureScheduleData],
      () => {
        snbiScheduleChanged.value = true;
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      futureScheduleData,
      configStore,
      REFERENCE_TABLE,
      valueInRangeRule,
      isEditable,
      validFutureForm,
      updateFieldDependencies,
      updateLegacySchedule,
      validateForm,
      futureScheduleForm,
      updateInspTypeNextDate,
      resetFormValidation,
    };
  },
  components: { LabelText, LabelDatefield },
});
</script>
<style scoped lang="scss">
td.align-center :deep(.v-checkbox) {
  padding-left: 8px !important;
}
td.align-center :deep(.v-row) {
  justify-content: center;
}

.date_center :deep(.v-field__field input) {
  align-items: center !important;
  text-align: center !important;
}
</style>
