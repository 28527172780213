<template>
  <div v-if="!readonly || readOnlyValidation" :style="textStyle">
    <v-text-field
      class="verticalAlignment"
      v-if="showSpinner"
      :id="id"
      :aria-describedby="id"
      :aria-label="ariaLabel"
      :variant="readonly ? 'plain' : 'filled'"
      :readonly="readonly"
      v-model="computedValue"
      hide-details="auto"
      :required="isRequired"
      :disabled="isDisabled"
      @keypress="handler"
      @blur="convertToString"
      :rules="computedRules"
      type="number"
      min="0"
      step="any"
      :max="max"
      reverse
    >
      <template v-if="appendWith" v-slot:append> {{ appendWith }}</template>
    </v-text-field>
    <v-text-field
      v-else
      class="verticalAlignment"
      :id="id"
      :aria-describedby="id"
      :aria-label="ariaLabel"
      :variant="readonly ? 'plain' : 'filled'"
      :readonly="readonly"
      v-model="computedValue"
      hide-details="auto"
      :required="isRequired"
      :disabled="isDisabled"
      @keypress="handler"
      @blur="convertToString"
      @focus="
        {
          showCommas = false;
          isFieldFocused = true;
        }
      "
      :decimalPlaces="decimalPlaces"
      :rules="computedRules"
      :maxlength="maxlength"
      :min="min"
      :max="max"
      reverse
    >
      <template v-if="appendWith" v-slot:append>{{ appendWith }}</template>
    </v-text-field>
  </div>
  <div
    v-else-if="
      (readonly || !readOnlyValidation) && !computedValue && showPlaceholder
    "
  >
    <span>{{ "--" }}</span>
  </div>
  <div v-else :class="alignRight ? 'alignRight' : ''" :style="textStyle">
    <span>
      {{ computedValue }}
    </span>
    <span v-if="appendWith">{{ appendWith }}</span>
  </div>
</template>
<script>
import { requiredRule } from "@/composables/validationRules";
import useFieldEntry from "@/composables/fieldEntry";
import { computed, ref } from "vue";
import { useConfigStore } from "@/stores/config";
import { REGEX_PATTERNS } from "@/constants/RegexPatterns";

export default {
  name: "NumberField",
  props: {
    id: {},
    rules: { default: [] },
    readonly: { type: Boolean, default: false },
    modelValue: { default: "" },
    showSpinner: { type: Boolean, default: false },
    isRequired: { default: false },
    decimalPlaces: { default: null },
    maxlength: { default: 7 },
    maxValue: { default: null },
    min: { default: null },
    appendWith: { default: null },
    allowCommas: { type: Boolean, default: true },
    pairCode: { default: null },
    roundingFactor: { default: null },
    showComma: { type: Boolean, default: true },
    showPlaceholder: { type: Boolean, default: false },
    alignRight: { type: Boolean, default: false },
    readOnlyValidation: { type: Boolean, default: false },
    noNegativeDisplayed: { type: Boolean, default: false },
    showMax: { type: Boolean, default: true },
    allowDecimalInput: { type: Boolean, default: false },
    allowNegativeInput: { type: Boolean, default: false },
    ariaLabel: { default: null },
    conversionTypes: { default: [] },
    textStyle: { default: "width: 50%" },
    isDisabled: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      isDecimalNumber,
      isNumber,
      isNumberWithNegative,
      isDecimalNumberWithNegative,
    } = useFieldEntry();
    let max = ref(props.maxValue);
    const configStore = useConfigStore();
    let isFieldFocused = ref(false);

    let showCommas = ref();
    if (props.showComma) {
      showCommas.value = true;
    } else {
      showCommas.value = false;
    }

    //get the max value from max length, maxlength = 7 will return 9999999
    function setMaxValue() {
      let result = "";
      if (!max.value) {
        for (let i = 0; i < props.maxlength; i++) {
          result += "9";
        }
        max.value = parseInt(result);
      }
    }
    setMaxValue();

    const valueInLimitsRule = (value) => {
      if (props.showMax) {
        if (parseInt(value) >= props.min && parseInt(value) <= max.value) {
          return true;
        } else if (value) {
          return `Valid range ${props.min} to ${max.value}`;
        }
      } else if (parseInt(value) >= props.min) {
        return true;
      } else if (value) {
        return `Must be greater than ${props.min - 1}`;
      }
    };

    const isInteger =
      !props.decimalPlaces ||
      props.decimalPlaces == null ||
      props.decimalPlaces == 0;

    const getFloatValue = (value) => {
      //replace commas in the pasted value so parsefloat will work
      value = value?.toString()?.replace(/,/g, "");
      return parseFloat(value) || parseFloat(value) === 0
        ? parseFloat(value)
        : null;
    };

    const getEnglishValue = (value) => {
      value = getFloatValue(value);
      if (props.pairCode && !props.noNegativeDisplayed) {
        return value != null
          ? getFloatValue(
              configStore.getMetricToEnglishConversions(
                props.pairCode,
                value,
                props.roundingFactor,
                false,
                props.conversionTypes
              )
            )?.toFixed(props.decimalPlaces)
          : "";
      } else if (props.pairCode && props.noNegativeDisplayed) {
        return value != null && value != -1
          ? getFloatValue(
              configStore.getMetricToEnglishConversions(
                props.pairCode,
                value,
                props.roundingFactor,
                false,
                props.conversionTypes
              )
            )?.toFixed(props.decimalPlaces)
          : "";
      } else if (props.decimalPlaces) {
        return value != null ? value.toFixed(props.decimalPlaces) : null;
      } else {
        return value;
      }
    };

    let enteredValue = getEnglishValue(props.modelValue);

    const computedValue = computed({
      get: () => {
        // if field is not focused, return the value based on commas or decimals.
        let value;
        if (props.allowDecimalInput) {
          value = String(props.modelValue) != "NaN" ? props.modelValue : null;
        } else {
          value = getEnglishValue(props.modelValue);
        }

        if (isFieldFocused.value) {
          value = enteredValue;
        } else {
          enteredValue = value;
        }

        if (props.showSpinner) {
          return value;
        }
        if (showCommas.value) {
          //add commas
          return value
            ?.toString()
            ?.replace(
              REGEX_PATTERNS.NUMBER_WITH_COMMAS.FIRST_HALF,
              REGEX_PATTERNS.NUMBER_WITH_COMMAS.SECOND_HALF
            );
        } else {
          return value?.toString() || "";
        }
      },

      set: (value) => {
        enteredValue = value;
        //if user ends a number with period, don't update the model value until a number afer decimal is entered.
        if (value.toString().endsWith(".")) {
          //if .5 is entered set it to 0.5
          if (value.toString().length === 1) {
            value = 0;
          }
        }

        value = getFloatValue(value);

        if (!props.pairCode) {
          context.emit("update:modelValue", value);
        } else {
          let metricValue = getMetricValue(value);
          context.emit("update:modelValue", metricValue);
        }
      },
    });

    const isValidDecimals = (value) => {
      if (props.decimalPlaces) {
        const afterDot = value.toString().split(".")[1];
        if (afterDot != undefined) {
          return afterDot.length <= props.decimalPlaces;
        } else {
          return true;
        }
      }
      return true;
    };

    const getMetricValue = (value) => {
      if (props.pairCode) {
        return configStore.getEnglishToMetricConversions(
          props.pairCode,
          value,
          props.conversionTypes
        );
      } else {
        return value;
      }
    };

    const convertToString = (e) => {
      decimalHandler(e);
      isFieldFocused.value = false;
      if (props.showComma) {
        showCommas.value = true;
      }
    };

    const computedRules = computed(() => {
      let validationRules = [];
      if (props.rules && props.rules.length > 0) {
        validationRules = validationRules.concat(props.rules);
      }
      if (props.isRequired) {
        validationRules.push(requiredRule);
      }
      if (
        computedValue.value &&
        (props.min || props.min == 0 || props.showSpinner)
      ) {
        validationRules.push(valueInLimitsRule);
      }
      return validationRules;
    });

    function handler(e) {
      if (props.allowNegativeInput) {
        return isInteger && !props.allowDecimalInput
          ? isNumberWithNegative(e, props.allowCommas)
          : isDecimalNumberWithNegative(e);
      } else {
        return isInteger && !props.allowDecimalInput
          ? isNumber(e, props.allowCommas)
          : isDecimalNumber(e);
      }
    }

    const setDecimalLength = (value) => {
      if (props.decimalPlaces) {
        const multiplier = Math.pow(10, props.decimalPlaces);
        const [beforeDot, afterDot] = (
          Math.round(parseFloat(value) * multiplier) / multiplier
        )
          .toFixed(props.decimalPlaces)
          .split(".");
        if (afterDot != undefined) {
          let afterDotTrimmed;

          if (afterDot.length > props.decimalPlaces) {
            afterDotTrimmed = afterDot.slice(0, props.decimalPlaces);
          } else {
            afterDotTrimmed = afterDot;
          }
          return beforeDot + "." + afterDotTrimmed;
        } else {
          return beforeDot;
        }
      } else {
        return value;
      }
    };

    function decimalHandler(e) {
      if (!isValidDecimals(e.target.value.toString())) {
        computedValue.value = setDecimalLength(e.target.value.toString());
      }
    }

    return {
      computedRules,
      isDecimalNumber,
      convertToString,
      isNumber,
      handler,
      computedValue,
      max,
      showCommas,
      isFieldFocused,
    };
  },
};
</script>

<style scoped lang="scss">
.verticalAlignment {
  align-items: center;
}
.alignRight {
  text-align: right;
}
</style>
