<template>
  <v-row class="navigationRow" align="center">
    <v-col class="pa-0" cols="12">
      <v-select
        :items="dropdownInspectionPages"
        v-model="selectedPage"
        id="navigation_dropdown_select"
      ></v-select>
    </v-col>
  </v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>

<script>
import { defineComponent, ref, toRefs, computed } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import router from "@/router";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { BUTTONS } from "@/constants/CommonWebConstants";
import { scrollToElement } from "@/util/scrollToElement";

export default defineComponent({
  props: ["brkey", "inspkey"],
  emits: ["selectedPage"],
  setup(props, ctx) {
    const { brkey, inspkey } = toRefs(props);
    const brKey = brkey?.value;
    const closeConfirm = ref(null);
    const inspectionKey = inspkey?.value;
    const selectedPage = computed({
      get() {
        return inspectionStore.getSelectedPage;
      },
      set(page) {
        selectPage(page);
      },
    });

    const selectPage = (page) => {
      scrollToElement("inspections_container");
      inspectionStore.setNextPage(page);
      if (inspectionStore.getDirtyFlag || inspectionStore.getAddingOrEditing) {
        inspectionStore.setNextRoute("");
        inspectionStore.setUnsavedChangesWarning(true);
      } else {
        inspectionStore.setSelectedPage(page);
        ctx.emit("selectedPage", selectedPage.value);
        setUri();
      }
    };

    const setUri = () => {
      if (brKey && structure.value?.InspEvnt?.INSPKEY) {
        router.push({
          name: "SpecificInspection",
          params: { inspkey: structure.value.InspEvnt.INSPKEY },
        });
      } else if (brKey) {
        router.push({ name: "LatestInspection" });
      }
    };

    const inspectionStore = useInspectionStore();
    const structure = computed(() => inspectionStore.selectedInspection);
    let dropdownInspectionPages = ref([]);
    function setDropdownInspectionPages() {
      dropdownInspectionPages.value = inspectionStore.getActiveNavigation;
    }
    setDropdownInspectionPages();
    return {
      closeConfirm,
      setDropdownInspectionPages,
      brKey,
      inspectionKey,
      dropdownInspectionPages,
      structure,
      selectedPage,
      selectPage,
      BUTTONS,
    };
  },
  components: { ConfirmDialog },
});
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.navigationRow {
  padding-top: 12px;
}
</style>
