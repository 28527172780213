import { ref } from "vue";
import { defineStore } from "pinia";
import { INSPECTION_LINKS_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { clone } from "@/util/clone";
import { MODULE_TYPES } from "@/constants/InspectionConstants";
import { useInspectionLinksStore } from "@/stores/inspectionLinks";

export const useLoadRatingStore = defineStore("loadRating", () => {
  //loadRating data is loaded either from idb(pouch db for inspection module) or service(for management module)
  const inspectionLinksStore = useInspectionLinksStore();
  let loadRatingData = ref({ inspection: {}, management: {} });

  const loadLoadRatingObject = async (
    moduleType,
    brkey,
    inspectionId,
    structure
  ) => {
    if (moduleType === MODULE_TYPES.INSPECTION) {
      //if moduleType is inspection, load data from pouchDB
      convertToInspModuleObject(structure);
    } else if (moduleType === MODULE_TYPES.MANAGEMENT) {
      //if moduleType is management, load data from service call
      await getInspLinkLoadRating(brkey, inspectionId);
    }
  };

  const getLoadRatingObject = (moduleType) => {
    return clone(loadRatingData.value[moduleType]);
  };

  const saveLoadRating = async (moduleType, loadRatingValue, structure) => {
    let isSuccess = false;
    if (moduleType === MODULE_TYPES.INSPECTION) {
      convertToSaveInspModuleObject(loadRatingValue, structure);
      isSuccess = true;
    } else if (moduleType === MODULE_TYPES.MANAGEMENT) {
      isSuccess = await updateLoadRating(
        convertToSaveInspLinkObject(loadRatingValue)
      );
    }
    return isSuccess;
  };

  const getInspLinkLoadRating = async (brkey, inspectionId) => {
    //clear idb object
    loadRatingData.value.inspection = {};

    let url = INSPECTION_LINKS_ENDPOINT.GET_LOAD_RATING_AND_POSTING.replace(
      "{brkey}",
      brkey
    ).replace("{inspectionId}", inspectionId);

    inspectionLinksStore.isSearching = true;
    await axios
      .get(url)
      .then((response) => {
        inspectionLinksStore.isSearching = false;
        convertToInspLinkObject(response.data.data);
      })
      .catch((e) => {
        LOGGER.logException(e);
        inspectionLinksStore.isSearching = false;
      });
  };

  const getAssignedRatingSet = async (brkey, calculationDate) => {
    let url = INSPECTION_LINKS_ENDPOINT.GET_ASSIGNED_RATING_SET.replace(
      "{brkey}",
      brkey
    );
    inspectionLinksStore.isSearching = true;
    return await axios
      .post(url, {
        date: calculationDate,
      })
      .then((response) => {
        inspectionLinksStore.isSearching = false;
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
        inspectionLinksStore.isSearching = false;
      });
  };
  const updateLoadRating = async (loadRating) => {
    let isSuccess = true;
    await axios
      .put(INSPECTION_LINKS_ENDPOINT.UPDATE_LOAD_RATING_AND_POSTING, loadRating)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update LoadRating",
          response
        );
        convertToInspLinkObject(response.data.data);
        isSuccess = true;
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const convertToSaveInspLinkObject = (loadRating) => {
    let postings = [];
    loadRating?.T_Posting?.forEach((tPosting) => {
      postings.push({
        brkey: tPosting.BRKEY,
        postingKey: tPosting.POSTING_KEY,
        active: tPosting.ACTIVE,
        fieldCond: tPosting.FIELD_COND,
        impact: tPosting.IMPACT,
        permanentlyClosedStruc: tPosting.PERMANENTLY_CLOSED_STRUC,
        postLimitComb: tPosting.POST_LIMIT_COMB,
        postLimitWeight: tPosting.POST_LIMIT_WEIGHT,
        postReason: tPosting.POST_REASON,
        postStatus: tPosting.POST_STATUS,
        postStatusDate: tPosting.POST_STATUS_DATE,
        snbiPostCondition: tPosting.SNBI_POST_CONDITION,
        snbiPostStatus: tPosting.SNBI_POST_STATUS,
        specCond: tPosting.SPEC_COND,
        specRestrictPost: tPosting.SPEC_RESTRICT_POST,
        updateDateTime: tPosting.MODTIME,
        updateUserId: tPosting.USERKEY,
        vehPostType: tPosting.VEH_POST_TYPE,
      });
    });
    let ratingLoads = [];
    loadRating?.T_Rating_Load?.forEach((tRatingLoad) => {
      ratingLoads.push({
        brkey: tRatingLoad.BRKEY,
        ratingDate: tRatingLoad.RATING_DATE,
        ratingKey: tRatingLoad.RATING_KEY,
        aashtoManualYear: tRatingLoad.AASHTO_MANUAL_YEAR,
        aashtoSpecYear: tRatingLoad.AASHTO_SPEC_YEAR,
        analysisEngineer: tRatingLoad.ANALYSIS_ENGINEER,
        controlMemType: tRatingLoad.CONTROL_MEM_TYPE,
        createdDateTime: tRatingLoad.CREATEDATETIME,
        createdUserId: tRatingLoad.CREATEUSERKEY,
        fatigLoadType: tRatingLoad.FATIG_LOAD_TYPE,
        fatigStressCat: tRatingLoad.FATIG_STRESS_CAT,
        invRatingStressMethod: tRatingLoad.INV_RATING_STRESS_METHOD,
        irLoad: tRatingLoad.IRLOAD,
        irLoadFactor: tRatingLoad.IR_LOAD_FACTOR,
        legalLoadRF: tRatingLoad.LEGAL_LOAD_RF,
        loadType: tRatingLoad.LOAD_TYPE,
        nbiRatingInd: tRatingLoad.NBI_RATING_IND,
        notes: tRatingLoad.NOTES,
        oprRatingStressMethod: tRatingLoad.OPR_RATING_STRESS_METHOD,
        orLoad: tRatingLoad.ORLOAD,
        orLoadFactor: tRatingLoad.OR_LOAD_FACTOR,
        ratingAnalysisMethod: tRatingLoad.RATING_ANALYSIS_METHOD,
        slcRating: tRatingLoad.SLC_RATING,
        stressRange: tRatingLoad.STRESS_RANGE,
        supportDataset: tRatingLoad.SUPPORT_DATASET,
        updateDateTime: tRatingLoad.MODTIME,
        updateUserId: tRatingLoad.USERKEY,
        vehPostWeight: tRatingLoad.VEH_POST_WEIGHT,
        vehPostType: tRatingLoad.VEH_POST_TYPE,
      });
    });
    let tunnelLoadRating = null;
    if (loadRating.T_Tunnel_Load_Rating) {
      tunnelLoadRating = {
        brkey: loadRating.T_Tunnel_Load_Rating.BRKEY,
        axlePostLoad: loadRating.T_Tunnel_Load_Rating.AXLE_POST_LOAD,
        grossPostLoad: loadRating.T_Tunnel_Load_Rating.GROSS_POST_LOAD,
        hazmatRestr: loadRating.T_Tunnel_Load_Rating.HAZMAT_RESTR,
        heightRestr: loadRating.T_Tunnel_Load_Rating.HEIGHT_RESTR,
        invLrFactor: loadRating.T_Tunnel_Load_Rating.INV_LR_FACTOR,
        opLrFactor: loadRating.T_Tunnel_Load_Rating.OP_LR_FACTOR,
        otherRestr: loadRating.T_Tunnel_Load_Rating.OTHER_RESTR,
        ratingMethod: loadRating.T_Tunnel_Load_Rating.RATING_METHOD,
        type3S2PostLoad: loadRating.T_Tunnel_Load_Rating.TYPE3S2_POST_LOAD,
        type33PostLoad: loadRating.T_Tunnel_Load_Rating.TYPE3_3_POST_LOAD,
        type3PostLoad: loadRating.T_Tunnel_Load_Rating.TYPE3_POST_LOAD,
        updateDateTime: loadRating.T_Tunnel_Load_Rating.MODTIME,
        updateUserId: loadRating.T_Tunnel_Load_Rating.USERKEY,
      };
    }
    return {
      brkey: loadRating.BRKEY,
      inspectionId: loadRating.INSPECTIONID,
      recommendRatingReview: loadRating.RECOMMEND_RATING_REVIEW,
      lrReviewAction: loadRating.LR_REVIEW_ACTION,
      postReviewRec: loadRating.POST_REVIEW_REC,
      calculationDate: loadRating.RATING_DATE,
      ratingApprDate: loadRating.RATING_APPR_DATE,
      approverName: loadRating.APPROVER_NAME,
      designLoad: loadRating.DESIGNLOAD,
      servTypOn: loadRating.SERVTYPON,
      designMethod: loadRating.DESIGN_METHOD,
      routinePermitLoads: loadRating.ROUTINE_PERMIT_LOADS,
      postings: postings,
      ratingLoads: ratingLoads,
      tunnelLoadRating: tunnelLoadRating,
      calculationDates: loadRating.calculationDates,
      inspectionComments: loadRating.inspectionComments,
      nbiAndAlternateRating: loadRating.nbiAndAlternateRating,
      previousInspectionComments: loadRating.previousInspectionComments,
    };
  };

  const convertToSaveInspModuleObject = (loadRatingValue, structure) => {
    structure.UserInsp.RECOMMEND_RATING_REVIEW =
      loadRatingValue.RECOMMEND_RATING_REVIEW;
    structure.UserInsp.LR_REVIEW_ACTION = loadRatingValue.LR_REVIEW_ACTION;
    structure.UserInsp.POST_REVIEW_REC = loadRatingValue.POST_REVIEW_REC;
    structure.UserInsp.RATING_DATE = loadRatingValue.RATING_DATE;
    structure.UserInsp.RATING_APPR_DATE = loadRatingValue.RATING_APPR_DATE;
    structure.UserInsp.APPROVER_NAME = loadRatingValue.APPROVER_NAME;
    structure.Bridge.DESIGNLOAD = loadRatingValue.DESIGNLOAD;
    structure.Bridge.SERVTYPON = loadRatingValue.SERVTYPON;
    structure.Bridge.DESIGN_METHOD = loadRatingValue.DESIGN_METHOD;
    structure.Bridge.ROUTINE_PERMIT_LOADS =
      loadRatingValue.ROUTINE_PERMIT_LOADS;
    structure.T_Posting = loadRatingValue.T_Posting;
    structure.T_Rating_Load = loadRatingValue.T_Rating_Load;
    structure.T_Tunnel_Load_Rating = loadRatingValue.T_Tunnel_Load_Rating;
    structure.nbiAndAlternateRating = loadRatingValue.nbiAndAlternateRating;
  };

  const convertToInspModuleObject = (structure) => {
    loadRatingData.value.management = {};
    loadRatingData.value.inspection = {
      RECOMMEND_RATING_REVIEW: structure.UserInsp.RECOMMEND_RATING_REVIEW,
      LR_REVIEW_ACTION: structure.UserInsp.LR_REVIEW_ACTION,
      POST_REVIEW_REC: structure.UserInsp.POST_REVIEW_REC,
      RATING_DATE: structure.UserInsp.RATING_DATE,
      RATING_APPR_DATE: structure.UserInsp.RATING_APPR_DATE,
      APPROVER_NAME: structure.UserInsp.APPROVER_NAME,
      DESIGNLOAD: structure.Bridge.DESIGNLOAD,
      SERVTYPON: structure.Bridge.SERVTYPON,
      DESIGN_METHOD: structure.Bridge.DESIGN_METHOD,
      ROUTINE_PERMIT_LOADS: structure.Bridge.ROUTINE_PERMIT_LOADS,
      T_Posting: structure.T_Posting,
      T_Rating_Load: structure.T_Rating_Load,
      T_Tunnel_Load_Rating: structure.T_Tunnel_Load_Rating,
      nbiAndAlternateRating: structure.nbiAndAlternateRating,
    };
  };

  const convertToInspLinkObject = (loadRating) => {
    let T_Posting = [];
    loadRating?.postings?.forEach((posting) => {
      T_Posting.push({
        BRKEY: posting.brkey,
        POSTING_KEY: posting.postingKey,
        ACTIVE: posting.active,
        FIELD_COND: posting.fieldCond,
        IMPACT: posting.impact,
        PERMANENTLY_CLOSED_STRUC: posting.permanentlyClosedStruc,
        POST_LIMIT_COMB: posting.postLimitComb,
        POST_LIMIT_WEIGHT: posting.postLimitWeight,
        POST_REASON: posting.postReason,
        POST_STATUS: posting.postStatus,
        POST_STATUS_DATE: posting.postStatusDate,
        SNBI_POST_CONDITION: posting.snbiPostCondition,
        SNBI_POST_STATUS: posting.snbiPostStatus,
        SPEC_COND: posting.specCond,
        SPEC_RESTRICT_POST: posting.specRestrictPost,
        MODTIME: posting.updateDateTime,
        USERKEY: posting.updateUserId,
        VEH_POST_TYPE: posting.vehPostType,
      });
    });
    let T_Rating_Load = [];
    loadRating?.ratingLoads?.forEach((ratingLoad) => {
      T_Rating_Load.push({
        BRKEY: ratingLoad.brkey,
        RATING_DATE: ratingLoad.ratingDate,
        RATING_KEY: ratingLoad.ratingKey,
        AASHTO_MANUAL_YEAR: ratingLoad.aashtoManualYear,
        AASHTO_SPEC_YEAR: ratingLoad.aashtoSpecYear,
        ANALYSIS_ENGINEER: ratingLoad.analysisEngineer,
        CONTROL_MEM_TYPE: ratingLoad.controlMemType,
        CREATEDATETIME: ratingLoad.createdDateTime,
        CREATEUSERKEY: ratingLoad.createdUserId,
        FATIG_LOAD_TYPE: ratingLoad.fatigLoadType,
        FATIG_STRESS_CAT: ratingLoad.fatigStressCat,
        INV_RATING_STRESS_METHOD: ratingLoad.invRatingStressMethod,
        IRLOAD: ratingLoad.irLoad,
        IR_LOAD_FACTOR: ratingLoad.irLoadFactor,
        LEGAL_LOAD_RF: ratingLoad.legalLoadRF,
        LOAD_TYPE: ratingLoad.loadType,
        NBI_RATING_IND: ratingLoad.nbiRatingInd,
        NOTES: ratingLoad.notes,
        OPR_RATING_STRESS_METHOD: ratingLoad.oprRatingStressMethod,
        ORLOAD: ratingLoad.orLoad,
        OR_LOAD_FACTOR: ratingLoad.orLoadFactor,
        RATING_ANALYSIS_METHOD: ratingLoad.ratingAnalysisMethod,
        SLC_RATING: ratingLoad.slcRating,
        STRESS_RANGE: ratingLoad.stressRange,
        SUPPORT_DATASET: ratingLoad.supportDataset,
        MODTIME: ratingLoad.updateDateTime,
        USERKEY: ratingLoad.updateUserId,
        VEH_POST_WEIGHT: ratingLoad.vehPostWeight,
        VEH_POST_TYPE: ratingLoad.vehPostType,
      });
    });
    let T_Tunnel_Load_Rating = null;
    if (loadRating.tunnelLoadRating) {
      T_Tunnel_Load_Rating = {
        BRKEY: loadRating.tunnelLoadRating.brkey,
        AXLE_POST_LOAD: loadRating.tunnelLoadRating.axlePostLoad,
        GROSS_POST_LOAD: loadRating.tunnelLoadRating.grossPostLoad,
        HAZMAT_RESTR: loadRating.tunnelLoadRating.hazmatRestr,
        HEIGHT_RESTR: loadRating.tunnelLoadRating.heightRestr,
        INV_LR_FACTOR: loadRating.tunnelLoadRating.invLrFactor,
        OP_LR_FACTOR: loadRating.tunnelLoadRating.opLrFactor,
        OTHER_RESTR: loadRating.tunnelLoadRating.otherRestr,
        RATING_METHOD: loadRating.tunnelLoadRating.ratingMethod,
        TYPE3S2_POST_LOAD: loadRating.tunnelLoadRating.type3S2PostLoad,
        TYPE3_3_POST_LOAD: loadRating.tunnelLoadRating.type33PostLoad,
        TYPE3_POST_LOAD: loadRating.tunnelLoadRating.type3PostLoad,
        MODTIME: loadRating.tunnelLoadRating.updateDateTime,
        USERKEY: loadRating.tunnelLoadRating.updateUserId,
      };
    }
    loadRatingData.value.inspection = {};
    loadRatingData.value.management = {
      BRKEY: loadRating.brkey,
      INSPECTIONID: loadRating.inspectionId,
      RECOMMEND_RATING_REVIEW: loadRating.recommendRatingReview,
      LR_REVIEW_ACTION: loadRating.lrReviewAction,
      POST_REVIEW_REC: loadRating.postReviewRec,
      RATING_DATE: loadRating.calculationDate,
      RATING_APPR_DATE: loadRating.ratingApprDate,
      APPROVER_NAME: loadRating.approverName,
      DESIGNLOAD: loadRating.designLoad,
      SERVTYPON: loadRating.servTypOn,
      DESIGN_METHOD: loadRating.designMethod,
      ROUTINE_PERMIT_LOADS: loadRating.routinePermitLoads,
      T_Posting: T_Posting,
      T_Rating_Load: T_Rating_Load,
      T_Tunnel_Load_Rating: T_Tunnel_Load_Rating,
      calculationDates: loadRating.calculationDates,
      inspectionComments: loadRating.inspectionComments,
      nbiAndAlternateRating: loadRating.nbiAndAlternateRating,
      previousInspectionComments: loadRating.previousInspectionComments,
    };
  };

  return {
    getAssignedRatingSet,
    getLoadRatingObject,
    saveLoadRating,
    loadLoadRatingObject,
  };
});
