import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import { clone } from "@/util/clone";
import { MAINTENANCE_CANDIDATE_TYPE } from "@/constants/InspectionConstants";
import useDateField from "@/composables/dateField.js";
import { convertToInt } from "@/composables/util";

function setMaintenanceDocDefinition(structure, includeCompleted) {
  const configStore = useConfigStore();
  const { getFormattedDateStringNoTime } = useDateField();
  let proposed = loadCandidates(MAINTENANCE_CANDIDATE_TYPE.PROPOSED, structure);
  let completed = loadCandidates(
    MAINTENANCE_CANDIDATE_TYPE.COMPLETED,
    structure
  );
  proposed?.sort((a, b) => {
    if (convertToInt(a?.AGENCY_PRIORITY) == convertToInt(b?.AGENCY_PRIORITY)) {
      return new Date(a?.WORKRECDATE) - new Date(b?.WORKRECDATE);
    } else {
      return (
        convertToInt(a?.AGENCY_PRIORITY) - convertToInt(b?.AGENCY_PRIORITY)
      );
    }
  });
  completed?.sort((a, b) => {
    return convertToInt(a?.AGENCY_PRIORITY) - convertToInt(b?.AGENCY_PRIORITY);
  });
  const proposedTable = getProposedCandidates(
    configStore,
    getFormattedDateStringNoTime,
    proposed
  );
  const completedTable = getCompletedCandidates(
    configStore,
    getFormattedDateStringNoTime,
    completed
  );
  let docDefinition = {
    header: function () {
      return getReportHeader("Maintenance  Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Maintenance  Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Proposed Candidates",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 5],
      },
      {
        table: {
          widths: ["35%", "35%", "5%", "5%", "7%", "13%"],
          headerRows: 1,
          body: proposedTable,
          margin: [0, 0, 0, 5],
        },
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  if (includeCompleted) {
    docDefinition.content.push(
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Completed Candidates",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 5],
      },
      {
        table: {
          widths: ["27%", "28%", "5%", "5%", "7%", "13%", "15%"],
          headerRows: 1,
          body: completedTable,
          margin: [0, 0, 0, 5],
        },
      }
    );
  }
  return docDefinition;
}
const loadCandidates = (candidateType, structure) => {
  if (candidateType == MAINTENANCE_CANDIDATE_TYPE.PROPOSED) {
    return getCandidates(MAINTENANCE_CANDIDATE_TYPE.PROPOSED, structure);
  } else {
    return getCandidates(MAINTENANCE_CANDIDATE_TYPE.COMPLETED, structure);
  }
};
const getStatuses = (candidateType) => {
  if (candidateType == MAINTENANCE_CANDIDATE_TYPE.PROPOSED) {
    return ["0", "1", "2", "3", "4", "D"];
  } else {
    return ["5", "6", "7"];
  }
};
const getUOM = (item, configStore) => {
  if (item?.ACTKIND !== "1") {
    return configStore.getFlexAction(item?.ACTCODE)?.uom;
  } else {
    return "";
  }
};
const getCandidates = (candidateType, structure) => {
  let proposed = [];
  let completed = [];
  let cloneWCand = clone(structure?.Insp_WCand);
  if (!cloneWCand?.[0]) {
    //remove null values from array(if any)
    cloneWCand = cloneWCand?.filter((a) => !!a);
  }
  let statuses = getStatuses(candidateType);

  cloneWCand?.forEach((c) => {
    if (statuses?.includes(c?.AGENCY_STATUS)) {
      const userWcand = structure?.T_UserInsp_WCand?.find(
        (element) => element?.WCKEY == c?.WCKEY
      );
      c.LOCATION = userWcand?.LOCATION;
      c.DEFERRED_ACTION_DESC = userWcand?.DEFERRED_ACTION_DESC;

      if (candidateType == MAINTENANCE_CANDIDATE_TYPE.COMPLETED) {
        c.COMPLETED_DATE = userWcand?.COMPLETED_DATE;
      }
      c.readOnly = true;
      c.isAdd = false;
      if (candidateType == MAINTENANCE_CANDIDATE_TYPE.PROPOSED) {
        proposed.push(c);
      } else {
        completed.push(c);
      }
    }
  });
  if (candidateType == MAINTENANCE_CANDIDATE_TYPE.PROPOSED) {
    return proposed;
  } else {
    return completed;
  }
};
function getProposedCandidates(
  configStore,
  getFormattedDateStringNoTime,
  proposed
) {
  let proposedTable = [];
  proposedTable.push([
    {
      text: "3A02\n  Candidate ID\n",
      style: ["tableHeader"],
    },
    {
      text: "IM03\n Action\n",
      style: ["tableHeader"],
    },
    {
      text: "IM04\n Est\n Qty",
      style: ["tableHeader"],
    },
    {
      text: "\n UOM\n",
      style: ["tableHeader"],
    },
    {
      text: "IM05\n Priority\n",
      style: ["tableHeader"],
    },
    {
      text: "IM06\n Initial\n Recom'd Date",
      style: ["tableHeader"],
    },
  ]);
  for (const element of proposed) {
    proposedTable.push([
      {
        text: element?.WC_ID,
        style: ["tableBody"],
      },
      {
        text:
          element?.ACTKIND == "1"
            ? configStore.getBridgeActionTitle(element?.ACTCODE)
            : configStore.getFlexActionTitle(element?.ACTCODE),
        style: ["tableBody"],
      },
      {
        text: element?.ESTIMQUANTITY,
        style: ["tableBody"],
      },
      {
        text: getUOM(element, configStore),
        style: ["tableBody"],
      },
      {
        text: element?.AGENCY_PRIORITY,
        style: ["tableBody"],
      },
      {
        text: getFormattedDateStringNoTime(element?.WORKRECDATE),
        style: ["tableBody"],
      },
    ]);
    proposedTable.push([
      {
        colSpan: 6,
        stack: [
          {
            columns: [
              {
                width: "20%",
                stack: [
                  {
                    text: "IM07 Status: ",
                    bold: true,
                    width: "20%",
                    margin: [0, 5, 0, 0],
                  },
                  {
                    text: "IM08 Target Year: ",
                    bold: true,
                    width: "20%",
                    margin: [0, 5, 0, 0],
                  },
                  {
                    text: "IM11 Work Assign: ",
                    bold: true,
                    width: "20%",
                    margin: [0, 5, 0, 0],
                  },
                ],
              },

              {
                width: "25%",
                stack: [
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.AGENCY_STATUS,
                      element?.AGENCY_STATUS
                    ),
                    margin: [0, 5, 0, 0],
                  },
                  {
                    text: element?.TARGETYEAR,
                    margin: [0, 5, 0, 0],
                  },
                  {
                    text: configStore.getReferenceValue(
                      REFERENCE_TABLE.WORK_ASSIGNMENT,
                      element?.WORK_ASSIGNMENT
                    ),
                    margin: [0, 5, 0, 0],
                  },
                ],
              },
              {
                width: "55%",
                stack: [
                  {
                    text: "IM09 Location: ",
                    bold: true,
                    margin: [0, 5, 0, 0],
                  },
                  {
                    text: element?.LOCATION,
                  },
                ],
              },
            ],
          },
          {
            colSpan: 6,
            text: "IM15a Work Candidate Notes: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            colSpan: 6,
            text: element?.NOTES,
          },
          {
            colSpan: 6,
            text: "IM15b Deferred Notes: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            colSpan: 6,
            text: element?.DEFERRED_ACTION_DESC,
          },
        ],
      },
    ]);
  }
  return proposedTable;
}
function getCompletedCandidates(
  configStore,
  getFormattedDateStringNoTime,
  completed
) {
  let completedTable = [];
  completedTable.push([
    {
      text: "3A02\n  Candidate ID\n",
      style: ["tableHeader"],
    },
    {
      text: "IM03\n Action\n",
      style: ["tableHeader"],
    },
    {
      text: "IM04\n Est\n Qty",
      style: ["tableHeader"],
    },
    {
      text: "\n UOM\n",
      style: ["tableHeader"],
    },
    {
      text: "IM05\n Priority\n",
      style: ["tableHeader"],
    },
    {
      text: "IM06\n Initial\n Recom'd Date",
      style: ["tableHeader"],
    },
    {
      text: "IM14a\n Completed Date\n",
      style: ["tableHeader"],
    },
  ]);
  for (const element of completed) {
    completedTable.push([
      {
        text: element?.WC_ID,
        style: ["tableBody"],
      },
      {
        text:
          element?.ACTKIND == "1"
            ? configStore.getBridgeActionTitle(element?.ACTCODE)
            : configStore.getFlexActionTitle(element?.ACTCODE),
        style: ["tableBody"],
      },
      {
        text: element?.ESTIMQUANTITY,
        style: ["tableBody"],
      },
      {
        text: getUOM(element, configStore),
        style: ["tableBody"],
      },
      {
        text: element?.AGENCY_PRIORITY,
        style: ["tableBody"],
      },
      {
        text: getFormattedDateStringNoTime(element?.WORKRECDATE),
        style: ["tableBody"],
      },
      {
        text: getFormattedDateStringNoTime(element?.COMPLETED_DATE),
        style: ["tableBody"],
      },
    ]);
    completedTable.push([
      {
        colSpan: 7,
        columns: [
          {
            width: "20%",
            stack: [
              {
                text: "IM07 Status: ",
                bold: true,
                width: "20%",
                margin: [0, 5, 0, 0],
              },
              {
                text: "IM08 Target Year: ",
                bold: true,
                width: "20%",
                margin: [0, 5, 0, 0],
              },
              {
                text: "IM11 Work Assign: ",
                bold: true,
                width: "20%",
                margin: [0, 5, 0, 0],
              },
            ],
          },

          {
            width: "25%",
            stack: [
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.AGENCY_STATUS,
                  element?.AGENCY_STATUS
                ),
                margin: [0, 5, 0, 0],
              },
              {
                text: element?.TARGETYEAR,
                margin: [0, 5, 0, 0],
              },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.WORK_ASSIGNMENT,
                  element?.WORK_ASSIGNMENT
                ),
                margin: [0, 5, 0, 0],
              },
            ],
          },
          {
            width: "55%",
            stack: [
              {
                text: "IM09 Location: ",
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: element?.LOCATION,
              },
              {
                text: "IM15a Work Candidate Notes: ",
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: element?.NOTES,
              },
            ],
          },
        ],
      },
    ]);
  }
  return completedTable;
}

export { setMaintenanceDocDefinition };
