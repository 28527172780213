import { clone } from "@/util/clone";
import useStructureType from "@/composables/structureType";
import {
  STRUCTURE_TYPE,
  FUNCTIONAL_SYSTEM,
} from "@/constants/InspectionConstants";
import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { FA_SOLID_ICONS } from "@/constants/Unicode";

let functionalSystemElements = {};
let structure = {};

function getElementReport(structureValue) {
  const configStore = useConfigStore();
  let arr = [];
  arr.push({ text: "", pageHeaderText: "Elements Page" });
  const structureUnitGroups = convertToHierarchy(structureValue);
  for (let key in structureUnitGroups) {
    const structureUnit = structureUnitGroups?.[key];

    arr.push(
      {
        table: {
          headerRows: 0,
          widths: ["30%", "*", "20%"],
          body: [
            [
              {
                text: "SP02 Structure Unit ID: " + structureUnit?.STRUNITLABEL,
                fillColor: "#ebebeb",
                alignment: "left",
                style: ["sectionHeader"],
              },
              {
                text:
                  "SP01 Span Type: " +
                  configStore.getReferenceValue(
                    REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
                    structureUnit?.STRUNITTYPE
                  ),
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
              {
                text: "5D01 Unit Key: " + structureUnit?.STRUNITKEY,
                fillColor: "#ebebeb",
                alignment: "right",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 10, 0, 0],
      },
      getElements(structureUnit)?.map((a) => a)
    );
  }
  return arr;
}

function getElements(structureUnit) {
  if (!structureUnit?.elements || structureUnit?.elements?.length == 0) {
    return [
      {
        text: "This structure unit currently contains no elements.",
        bold: true,
        color: "#ad2624",
      },
    ];
  } else {
    let elements = [];
    elements.push(getElementsHeader());
    structureUnit?.elements?.forEach((e) => {
      elements.push(getElementRow(e));
      getChildren(e, elements);
      elements.push(getElementCondition(e));
    });
    return [
      {
        table: {
          headerRows: 1,
          widths: ["5%", "*", "7%", "9%", "5%", "9%", "9%", "9%", "9%"],
          body: elements,
        },
      },
    ];
  }
}
function getChildren(element, elementArray) {
  if (!element?.children || element?.children?.length == 0) {
    return;
  }
  if (element?.children?.length > 0) {
    element?.children?.forEach((e) => {
      elementArray.push(getElementRow(e));
      getChildren(e, elementArray);
    });
  }
  if (element?.SUB_COMPS?.length > 0) {
    element?.SUB_COMPS?.forEach((e) => elementArray.push(getElementRow(e)));
  }
}

function getElementCondition(element) {
  return [
    {
      colSpan: 9,
      columns: [
        { text: "Description: ", bold: true, width: "11%" },
        { text: element?.ELEM_DESC },
        { text: "Condition: ", bold: true, width: "10%" },
        { text: element?.ELEM_NOTES },
      ],
    },
  ];
}

function getElementRow(e) {
  const configStore = useConfigStore();
  const { icon, color } = getIcon(configStore.getElementDefinition(e.ELEM_KEY));
  return [
    {
      text: icon,
      style: "fontawesome",
      fillColor: color,
      alignment: "center",
    },
    {
      text: `${e?.ELEM_KEY}-${
        configStore.getElementDefinition(e?.ELEM_KEY)?.elemLongName
      }`,
      fillColor: color,
    },
    {
      text: (parseFloat(e?.ELEM_SCALE_FACTOR) || 0)?.toLocaleString("en-US", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }),
      fillColor: color,
      alignment: "center",
    },
    {
      text: getQuantityRounded(e?.ELEM_KEY, e?.ELEM_QUANTITY),
      fillColor: color,
      alignment: "center",
    },
    {
      text: isDefect(configStore.getElementDefinition(e?.ELEM_KEY))
        ? configStore.getMetricConversionObject(
            configStore.getElementDefinition(e?.ELEM_PARENT_KEY)?.elemPairCode
          )?.englishUnit
        : configStore.getMetricConversionObject(
            configStore.getElementDefinition(e?.ELEM_KEY)?.elemPairCode
          )?.englishUnit,
      fillColor: color,
      alignment: "center",
    },
    {
      text: getQuantityRounded(e?.ELEM_KEY, e?.ELEM_QTYSTATE1),
      fillColor: color,
      alignment: "center",
    },
    {
      text: getQuantityRounded(e?.ELEM_KEY, e?.ELEM_QTYSTATE2),
      fillColor: color,
      alignment: "center",
    },
    {
      text: getQuantityRounded(e?.ELEM_KEY, e?.ELEM_QTYSTATE3),
      fillColor: color,
      alignment: "center",
    },
    {
      text: getQuantityRounded(e?.ELEM_KEY, e?.ELEM_QTYSTATE4),
      fillColor: color,
      alignment: "center",
    },
  ];
}

function getQuantityRounded(elemKey, value) {
  const configStore = useConfigStore();

  let quantity = configStore.getMetricToEnglishConversions(
    configStore.getElementDefinition(elemKey)?.elemPairCode,
    value,
    ROUNDING_FACTOR.ROUND
  );
  if (quantity === "") {
    return "0";
  }
  return quantity;
}

function getElementsHeader() {
  return [
    {
      text: "",
      style: ["tableHeader"],
    },
    {
      text: "1B01\nElement Description",
      style: ["tableHeader"],
    },
    {
      text: "1B05\nSF",
      style: ["tableHeader"],
    },
    {
      text: "1A10\nTotal\nQty",
      style: ["tableHeader"],
    },
    {
      text: "UOM",
      style: ["tableHeader"],
    },
    {
      text: "1A11\nCS1\nQty",
      style: ["tableHeader"],
    },
    {
      text: "1A11\nCS2\nQty",
      style: ["tableHeader"],
    },
    {
      text: "1A11\nCS3\nQty",
      style: ["tableHeader"],
    },
    {
      text: "1A11\nCS4\nQty",
      style: ["tableHeader"],
    },
  ];
}

function convertToHierarchy(structureValue) {
  const configStore = useConfigStore();

  const structureUnitGroups = {};
  functionalSystemElements = {};
  structure = structureValue;
  let structureUnitsToAddElems = getStructureUnits();
  //Add all structure units that can have elements to the hashtable
  structureUnitsToAddElems?.forEach(
    (a) => (structureUnitGroups[a.STRUNITKEY] = clone(a))
  );
  let elems = clone(structure?.Pon_Elem_Insp)?.sort(
    (a, b) => a?.STRUNITKEY - b?.STRUNITKEY || a?.ELEM_KEY - b?.ELEM_KEY
  );
  //structureUnitGroups is a hashtable with StructureUnit as key and list of elements as value
  elems?.forEach((item) => {
    if (item && item != null) {
      const list = structureUnitGroups?.[item?.STRUNITKEY]?.tempElements;
      if (list) {
        list.push(item);
      } else if (structureUnitGroups[item?.STRUNITKEY]) {
        structureUnitGroups[item?.STRUNITKEY].tempElements = [item];
      }
    }
    const fnSystem = getFunctionalSystem(item.ELEM_KEY);
    if (!fnSystem) {
      //handled below
    } else if (functionalSystemElements[fnSystem.elemId]) {
      functionalSystemElements[fnSystem.elemId].push(item);
    } else {
      functionalSystemElements[fnSystem.elemId] = [item];
    }
  });
  for (let key in structureUnitGroups) {
    let structureUnitElements = structureUnitGroups?.[key];
    //build elements hierarchy for each structure unit
    structureUnitElements.elements = buildElements(
      structureUnitElements?.tempElements
    ).sort((a, b) => a?.ELEM_KEY - b?.ELEM_KEY);
    sortElements(structureUnitElements?.elements, configStore);
  }
  return structureUnitGroups;
}

function sortElements(elements, configStore) {
  elements?.forEach((element) => {
    element?.children?.sort((a, b) => {
      const elemA = configStore.getElementDefinition(a?.ELEM_KEY);
      const elemB = configStore.getElementDefinition(b?.ELEM_KEY);

      const compareProtectSys =
        (elemA?.elemProtectSys === "Y") - (elemB?.elemProtectSys === "Y");
      if (compareProtectSys !== 0) {
        return compareProtectSys;
      }

      const compareSubComp =
        (elemA?.elemSubComp === "Y") - (elemB?.elemSubComp === "Y");
      if (compareSubComp !== 0) {
        return compareSubComp;
      }
      return a?.ELEM_KEY - b?.ELEM_KEY;
    });
    // // If Functional system array has elements for the elem_key add them to the children list
    // //adding the elements at the end of the list
    if (functionalSystemElements?.[element.ELEM_KEY]) {
      element.children = element.children.concat(
        functionalSystemElements[element.ELEM_KEY]
      );
    }

    element?.children?.forEach((child) => {
      child?.children?.sort((a, b) => {
        return a?.ELEM_KEY - b?.ELEM_KEY;
      });
    });
  });
}

function getStructureUnits() {
  const { getStructureType } = useStructureType();
  return structure?.Structure_Unit?.filter((a) => {
    if (
      getStructureType(structure?.Bridge?.SERVTYPON) === STRUCTURE_TYPE.TUNNEL
    ) {
      return (
        a?.STRUNITTYPE == "N" ||
        a?.STRUNITTYPE == "R" ||
        a?.STRUNITTYPE == "S" ||
        a?.STRUNITTYPE == "Z"
      );
    } else if (
      getStructureType(structure?.Bridge?.SERVTYPON) === STRUCTURE_TYPE.BRIDGE
    ) {
      return (
        a?.STRUNITTYPE == "A" || a?.STRUNITTYPE == "M" || a?.STRUNITTYPE == "C"
      );
    }
  });
}

function getFunctionalSystem(elementKey) {
  const configStore = useConfigStore();
  const { getStructureType } = useStructureType();

  //get the functional system for an element key(if exists)
  //get elemCat and elemType keys for elementKey
  const elemDef = configStore.getElementDefinition(elementKey);
  //get matching functional system
  return configStore
    .getAddElementDefinitions(getStructureType(structure?.Bridge?.SERVTYPON))
    ?.find(
      (elem) =>
        elem?.elemCatKey === elemDef?.elemCatKey &&
        elem?.elemTypeKey === elemDef?.elemTypeKey &&
        elem?.elemMatKey != elemDef?.elemMatKey &&
        elem?.elemMatKey === FUNCTIONAL_SYSTEM.MAT_KEY
    );
}

//build the hierarchical view of elements/defects/protective systems
function buildElements(inspElements) {
  let tree = [];
  let mappedArr = {};
  // Build a hash table and map items to objects
  inspElements?.forEach(function (item) {
    //id is combination of keys (can't use elemKey as one defect with an elemKey can be part of multiple elements)
    let id =
      item?.ELEM_KEY +
      "-" +
      (item?.ELEM_PARENT_KEY || 0) +
      "-" +
      (item?.ELEM_GRANDPARENT_KEY || 0);
    mappedArr[id] = item;
    mappedArr[id].children = [];
  });

  buildChildElements(mappedArr, tree);
  buildProtectiveSystemChildElements(mappedArr, tree);

  return tree;
}
function buildChildElements(mappedArr, tree) {
  //loop through the hash table and add children(defects and protective systems) to elements
  for (let id in mappedArr) {
    let mappedElem = mappedArr?.[id];
    if (!mappedElem?.children) {
      mappedElem.children = [];
    }
    //add children
    if (
      (!mappedElem?.ELEM_GRANDPARENT_KEY ||
        mappedElem?.ELEM_GRANDPARENT_KEY == 0) &&
      mappedElem?.ELEM_PARENT_KEY > 0
    ) {
      mappedArr?.[mappedElem.ELEM_PARENT_KEY + "-0-0"]?.children.push(
        mappedElem
      );
    } else if (mappedElem?.ELEM_GRANDPARENT_KEY > 0) {
      //skip sub systems as they are taken care in the below loop
    } else {
      checkIfFunctionalSystems(mappedElem, tree);
    }
  }
}
function checkIfFunctionalSystems(mappedElem, tree) {
  const fnSystem = getFunctionalSystem(mappedElem?.ELEM_KEY);
  if (!fnSystem) {
    tree.push(mappedElem);
  }
}
function buildProtectiveSystemChildElements(mappedArr, tree) {
  //loop through the hash table and add children(sub components) to protective systems added to the tree above
  for (let id in mappedArr) {
    let mappedElem = mappedArr?.[id];
    if (mappedElem?.ELEM_GRANDPARENT_KEY > 0) {
      let element = tree?.find(
        (g) => g?.ELEM_KEY == mappedElem?.ELEM_GRANDPARENT_KEY
      );
      //if element is not found is specific structure, check if it is in the functional element list
      const fnSystem = getFunctionalSystem(mappedElem?.ELEM_GRANDPARENT_KEY);
      if (!element) {
        element = functionalSystemElements?.[fnSystem?.elemId]?.find(
          (a) => a?.ELEM_KEY == mappedElem?.ELEM_GRANDPARENT_KEY
        );
      }
      let protectiveSystem = element?.children?.find(
        (c) => c?.ELEM_KEY == mappedElem?.ELEM_PARENT_KEY
      );
      if (protectiveSystem?.children?.indexOf(mappedElem) == -1) {
        protectiveSystem?.children?.push(mappedElem);
      }
    }
  }
}

function isDefect(elemDef) {
  if (elemDef?.elemProtectSys === "Y") {
    return false;
  } else if (elemDef?.elemSubComp === "Y") {
    return false;
  } else if (elemDef?.elemSmartFlag === "Y") {
    return true;
  }
}

function getIcon(elemDef) {
  if (elemDef?.elemProtectSys === "Y") {
    return { icon: FA_SOLID_ICONS.SHIELD, color: "#e2efda" };
  } else if (elemDef?.elemSubComp === "Y") {
    return { icon: FA_SOLID_ICONS.PUZZLE_PIECE, color: "#f6f4a2" };
  } else if (elemDef?.elemSmartFlag === "Y") {
    return { icon: FA_SOLID_ICONS.BOLT_LIGHTNING, color: "#f0c6c2" };
  } else {
    return { icon: "", color: "#d9e1f2" };
  }
}

export { convertToHierarchy, getElementReport };
