<template>
  <v-row>
    <v-col class="px-0 pb-0">
      <h3 class="withBlueBackground px-2 py-1 d-sm-flex">
        <span class="mr-auto">Inspections &gt; {{ selectedPage }}</span>
        <ReportsButton styleClass="mx-3" id="reports" />
        <SubmitButton
          styleClass="mx-3"
          id="submit"
          @clicked="submitInspection"
          :isDisabled="
            !STATUSES_CAN_SUBMIT.includes(structure?.InspEvnt?.INSPSTAT) ||
            structure?.recordType === RECORD_MANAGEMENT_TYPES.DOCUMENTS ||
            isSavingValue ||
            isDirty ||
            inspectionStore.getAddingOrEditing
          "
        />

        <v-btn
          class="mx-3"
          :disabled="
            !isDirty || isSavingValue || inspectionStore.getAddingOrEditing
          "
          id="btn_saveChanges"
          variant="outlined"
          @click="save()"
          rounded="lg"
        >
          Save
        </v-btn>
        <v-btn
          class="mx-3"
          :disabled="!isDirty || isSavingValue"
          id="btn_cancelChanges"
          variant="outlined"
          @click="cancelChanges()"
          rounded="lg"
        >
          Cancel
        </v-btn>
      </h3>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="pa-0">
      <v-row class="ma-0 withLightBlueBackground" align="center">
        <v-col cols="4" sm="4" md="4" lg="2" xl="2" class="increaseContrast"
          >BRKEY: {{ structure?.Bridge?.BRKEY }}</v-col
        >
        <v-col cols="4" sm="4" md="4" lg="3" xl="3" class="increaseContrast"
          >BMS ID: {{ structure?.Bridge?.BRIDGE_ID }}</v-col
        >
        <v-col cols="4" sm="4" md="4" lg="2" xl="2" class="increaseContrast"
          >Report Group: {{ structure?.Bridge?.REPORTGROUP }}
        </v-col>
        <v-col v-if="$vuetify?.display?.mdAndDown" cols="2" />

        <v-col cols="8" sm="8" md="8" lg="5" xl="5">
          <v-row align="center">
            <v-col cols="12" sm="12" class="align-center">
              <div style="display: flex" class="align-center">
                <span id="inspectionRecordLabel" class="increaseContrast"
                  >Inspection Record:</span
                >&nbsp;
                <v-select
                  id="select_inspections"
                  :items="inspectionArr"
                  hide-details
                  aria-describedby="select_inspections"
                  aria-labelledby="inspectionRecordLabel"
                  :disabled="
                    !connectivity.getOnlineServiceStatus ||
                    structure?.recordType === RECORD_MANAGEMENT_TYPES.DOCUMENTS
                  "
                  style="background-color: white"
                  class="disabledSelectHeaderField border-black"
                  v-model="inspectionPlaceholder"
                  @update:modelValue="
                    inspectionStore.getDirtyFlag
                      ? (warningInspectionChange = true)
                      : loadInspectionAndReload(false)
                  "
                >
                </v-select>
              </div> </v-col></v-row
        ></v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    scroll-strategy="none"
  >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
  <ValidationPopup
    :show="showValidation"
    :brkey="structure?.Bridge?.BRKEY"
    @close="showValidation = false"
    @reloadNotesComments="reloadNotesComments"
  />
  <v-dialog v-model="warningInspectionChange" persistent :width="800">
    <v-card class="pa-3">
      <p class="h3 mb-6"><strong>&nbsp;</strong></p>

      <p class="mb-2">
        You made changes to this page, do you want to save before you continue?
      </p>

      <div class="d-flex justify-center">
        <v-btn
          id="btn_saveForInspectionChange"
          variant="outlined"
          @click="loadInspectionAndReload(true)"
        >
          Yes
        </v-btn>

        <v-btn
          class="ml-4"
          id="btn_noSaveForInspectionChange"
          variant="outlined"
          @click="loadInspectionAndReload(false)"
        >
          No
        </v-btn>
      </div>

      <button
        id="btn_stayAndCloseXInspectionChange"
        @click="closeWarningInspectionChange()"
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon size="x-large" icon="fas fa-xmark mx-2" />
        <small>CLOSE</small>
      </button>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent, toRefs, onMounted, ref, computed, watch } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import useDateField from "@/composables/dateField";
import { useConnectivityStore } from "@/stores/connectivity";
import { useStructureIDBStore } from "@/stores/structureIDB";
import router from "@/router";
import { RECORD_MANAGEMENT_TYPES } from "@/constants/StructureSearches";
import SubmitButton from "@/components/shared/SubmitButton.vue";
import ReportsButton from "@/components/shared/ReportsButton.vue";
import ValidationPopup from "@/components/shared/ValidationPopup.vue";
import { INSPECTION_PAGES, STATUSES_CAN_SUBMIT } from "@/constants/Inspections";
import { INSPECTION_STATUSES } from "@/constants/InspectionStatuses";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

export default defineComponent({
  name: "InspectionHeader",
  props: ["brkey", "inspkey", "selectedPage"],
  emits: [
    "cancelChanges",
    "getRef",
    "reload",
    "cancelSubmit",
    "saving",
    "reloadNotesComments",
  ],
  setup(props, ctx) {
    const inspectionStore = useInspectionStore();
    const configStore = useConfigStore();
    const snackbarStore = useSnackbarStore();
    const { brkey, inspkey, selectedPage } = toRefs(props);
    const brKeyValue = brkey.value;
    let saved = computed(() => inspectionStore.getSaved);
    let isSavingValue = ref(false);
    let inspectionkey = inspkey.value;
    const structure = computed(() => inspectionStore.selectedInspection);
    const pageRef = computed(() => inspectionStore.getPageReference);
    const referenceValue = (type) =>
      configStore.getReferenceValue(REFERENCE_TABLE.INSPECTION_TYPE, type);
    const { getFormattedDateStringNoTime } = useDateField();
    let validationSnackbar = computed({
      get() {
        return inspectionStore.showValidationSnackbar;
      },
    });
    let calculateSnackbar = computed({
      get() {
        return inspectionStore.getShowCalculate;
      },
    });

    let calculateSnackbarId = ref("");
    let saveSnackbarId = ref("");
    let snackBarId = ref("");

    let inspectionArr = computed(() => inspectionStore.inspectionArray);
    let selectedInspection = ref();
    let inspectionPlaceholder = ref();
    let warningInspectionChange = ref();
    const connectivity = useConnectivityStore();
    let isLoading = ref(false);
    const structureIDBStore = useStructureIDBStore();
    let showValidation = ref(false);

    const getInspections = async () => {
      inspectionStore.setInspectionArray([]);
      if (connectivity.getisOnline) {
        await getAllInspections();
      } else if (structure.value?.InspEvnt?.INSPKEY) {
        inspectionArr.value.push({
          value: structure.value?.InspEvnt?.INSPKEY,
          title: `${getFormattedDateStringNoTime(
            structure.value?.InspEvnt?.INSPDATE
          )}- ${getStructureInspectionTypes(
            structure.value?.InspEvnt?.INSPTYPE,
            structure.value?.InspEvnt?.INSPKEY,
            getSnbiTypes(structure.value)
          )}`,
        });
      }
      inspectionStore.setInspectionArray(inspectionArr.value);
    };

    const pushNewInspection = () => {
      inspectionArr.value.unshift({
        value: structure.value?.InspEvnt?.INSPKEY,
        title: `${getFormattedDateStringNoTime(
          structure.value?.InspEvnt?.INSPDATE
        )} - ${getStructureInspectionTypes(
          structure.value?.InspEvnt?.INSPTYPE,
          structure.value?.InspEvnt?.INSPKEY,
          getSnbiTypes(structure.value)
        )}`,
      });
      inspectionkey = structure.value?.InspEvnt?.INSPKEY;
      selectedInspection.value = structure.value?.InspEvnt?.INSPKEY;
      inspectionPlaceholder.value = selectedInspection.value;
      inspectionStore.setLatestInspKey(structure.value?.InspEvnt?.INSPKEY);
      router.push({
        name: "SpecificInspection",
        params: { inspkey: selectedInspection.value },
      });
    };
    const getAllInspections = async () => {
      await inspectionStore.getInspections(brKeyValue);

      if (inspectionStore.inspections[brKeyValue]) {
        for (const insp of inspectionStore.inspections[brKeyValue]) {
          let snbiTypes = insp?.snbiInspectionTypes;
          if (insp?.inspectionId == inspectionStore.latestInspKey) {
            snbiTypes = getSnbiTypes(structure.value);
          }
          inspectionArr.value.push({
            value: insp?.inspectionId,
            title: `${getFormattedDateStringNoTime(
              insp?.inspectionDate
            )} - ${getStructureInspectionTypes(
              insp?.inspectionType,
              insp?.inspectionId,
              snbiTypes
            )}`,
          });
        }
      }
      let inspectionRecord = inspectionArr.value.find(
        (i) => i.value == structure.value.InspEvnt.INSPKEY
      );
      if (
        !inspectionRecord &&
        structure.value.InspEvnt.INSPKEY != inspectionArr.value[0]?.value
      ) {
        inspectionArr.value.unshift({
          value: structure.value?.InspEvnt?.INSPKEY,
          title: `${getFormattedDateStringNoTime(
            structure.value?.InspEvnt?.INSPDATE
          )} - ${getStructureInspectionTypes(
            structure.value?.InspEvnt?.INSPTYPE,
            inspectionArr.value[0]?.value,
            getSnbiTypes(structure.value)
          )}`,
        });
      }
    };

    const getStructureInspectionTypes = (
      primaryInspType,
      inspId,
      snbiInspTypes
    ) => {
      if (snbiInspTypes?.length > 0) {
        return (
          " Type " +
          snbiInspTypes
            .map((e) => e)
            ?.sort()
            .join("")
        );
      } else {
        return referenceValue(primaryInspType);
      }
    };

    const isDirty = computed(() => inspectionStore.getDirtyFlag);

    const resetSaveSnackBar = () => {
      inspectionStore.setShowValidationSnackbar(false);
      inspectionStore.setSavingSnackbar(false);
      inspectionStore.setSaved(false);
      inspectionStore.setShowCalculate(false);
      snackbarStore.removeMessage(snackBarId.value);
      snackbarStore.removeMessage(calculateSnackbarId.value);
      snackbarStore.removeMessage(saveSnackbarId.value);
    };

    const saveLogic = async () => {
      const selectedInspection = inspectionStore.getSelectedInspection;
      selectedInspection.backedUp = "N";
      if (selectedPage.value == INSPECTION_PAGES.ELEMENTS) {
        const savedInspPerformedValue =
          selectedInspection?.InspEvnt?.ELINSPDONE;
        if (savedInspPerformedValue === "0") {
          selectedInspection.InspEvnt.ELINSPDONE = "1";
          //show message that the flag is set
          pageRef.value.showInspectionPerformedMessage();
        }
      }
      if (
        selectedPage.value == INSPECTION_PAGES.RATINGS ||
        selectedPage.value == INSPECTION_PAGES.NSTM_FATIGUE
      ) {
        await pageRef.value.saveConditionFields();
      }
      if (selectedPage.value == INSPECTION_PAGES.WATERWAY) {
        pageRef.value.calculateOnSave();
      }
      if (
        selectedPage.value == INSPECTION_PAGES.RATINGS ||
        selectedPage.value == INSPECTION_PAGES.DECK ||
        selectedPage.value == INSPECTION_PAGES.SUBSTRUCTURE ||
        selectedPage.value == INSPECTION_PAGES.CULVERT ||
        selectedPage.value == INSPECTION_PAGES.SUPERSTRUCTURE
      ) {
        await inspectionStore.getLowestConditionRating();
      }
      if (
        (selectedPage.value == INSPECTION_PAGES.DECK ||
          selectedPage.value == INSPECTION_PAGES.APPROACH ||
          selectedPage.value == INSPECTION_PAGES.RATINGS ||
          selectedPage.value == INSPECTION_PAGES.SUPERSTRUCTURE ||
          selectedPage.value == INSPECTION_PAGES.SUBSTRUCTURE ||
          selectedPage.value == INSPECTION_PAGES.CULVERT ||
          selectedPage.value == INSPECTION_PAGES.WATERWAY) &&
        pageRef.value?.conditionUpdated
      ) {
        pageRef.value.updateDuration();
      }
      if (selectedPage.value == INSPECTION_PAGES.SCHEDULE) {
        pageRef.value.updateFieldDependencies();
      }

      await structureIDBStore.saveStructure(selectedInspection);

      inspectionStore.setDirtyFlag(false);
      inspectionStore.setSaved(true);
      inspectionStore.setSavingSnackbar(true);
    };

    const validateScheduleForm = async () => {
      inspectionStore.setShowValidationSnackbar(false);
      await pageRef.value.validate();
      const isValidForm = await pageRef.value.validateFutureSchedule();
      if (
        pageRef.value.valid &&
        pageRef.value.isValidInspTypes() &&
        isValidForm
      ) {
        await saveLogic();
        //Add/Update newly added insptype/date to the header
        pageRef.value.updateInspectionArr();
      } else if (!pageRef.value.isValidInspTypes()) {
        //Don't show any snackbar as another snackbar is already shown
      } else {
        inspectionStore.setShowValidationSnackbar(true);
      }
    };

    const saveChanges = async () => {
      resetSaveSnackBar();
      if (!pageRef.value || selectedPage.value == INSPECTION_PAGES.ELEMENTS) {
        await saveLogic();
      } else if (selectedPage.value == INSPECTION_PAGES.SCHEDULE) {
        await validateScheduleForm();
      } else {
        if (pageRef.value.validate) {
          await pageRef.value.validate();
        }

        if (pageRef.value.valid) {
          await saveLogic();
        } else {
          inspectionStore.setShowValidationSnackbar(true);
        }
      }
    };
    const reloadNotesComments = () => {
      ctx.emit("reloadNotesComments");
    };
    const save = async () => {
      isSavingValue.value = true;
      ctx.emit("saving", true);
      ctx.emit("getRef", true);
      await saveChanges();
      ctx.emit("saving", false);
      isSavingValue.value = false;
    };

    const cancelChanges = () => {
      ctx.emit("cancelChanges", true);
    };

    const submitInspection = async () => {
      if (connectivity.getOnlineServiceStatus) {
        showValidation.value = true;
      } else {
        structure.value.InspEvnt.INSPSTAT = INSPECTION_STATUSES.READY_TO_SUBMIT;
        await structureIDBStore.updateStructures([structure.value]);
        inspectionStore.setDirtyFlag(false);
        inspectionStore.setUnsavedChangesWarning(false);
        inspectionStore.setShowSubmitLaterMessage(true);
        snackbarStore.showMessage({
          displayText: `The inspection has been marked '0 - Ready to Submit'. You can submit later
    from the Worklist when you are connected to the internet.`,
          timeout: 10000,
        });
      }
    };

    const updateKeyFields = async () => {
      //Prev Accepted inspection (for comparison on Ratings page)
      if (connectivity.getisOnline) {
        await inspectionStore.updateKeyFields(brKeyValue);
      }
    };

    onMounted(async () => {
      await structureIDBStore.initDB(false);
      //get latest insp key from IDB
      inspectionStore.setLatestInspKey(
        await structureIDBStore.getLatestInspectionKeyFromIDB(brKeyValue)
      );
      await getInspections();
      selectedInspection.value =
        inspectionkey || structure.value?.InspEvnt?.INSPKEY;
      inspectionPlaceholder.value = selectedInspection.value;
      await updateKeyFields();
      await connectivity.getServiceStatus();
    });
    const loadInspectionAndReload = async (saveFirst) => {
      warningInspectionChange.value = false;
      if (saveFirst) {
        await save();
      }
      selectedInspection.value = inspectionPlaceholder.value;
      await loadInspectionData();
      ctx.emit("reload");
    };

    const closeWarningInspectionChange = () => {
      inspectionPlaceholder.value = selectedInspection.value;
      warningInspectionChange.value = false;
    };

    const loadInspectionData = async () => {
      isLoading.value = true;
      if (selectedInspection.value == inspectionStore.latestInspKey) {
        inspectionStore.setSelectedInspection(
          await structureIDBStore.getLatestInspectionFromIDB(brKeyValue)
        );
      } else {
        await inspectionStore.loadInspection(
          brKeyValue,
          selectedInspection.value
        );
      }
      router.push({
        name: "SpecificInspection",
        params: { inspkey: selectedInspection.value },
      });
      isLoading.value = false;
      inspectionStore.setDirtyFlag(false);
    };

    const getSnbiTypes = (structureValue) => {
      return structureValue.Snbi_Insp_Event?.map((i) => i.INSP_TYPE);
    };

    //watch for validationSnackbar
    watch(
      () => [validationSnackbar.value],
      () => {
        if (validationSnackbar.value) {
          snackBarId.value = snackbarStore.showMessage({
            displayText: INSPECTION_MESSAGES.VALIDATION_FAILED_MESSAGE,
            timeout: 10000,
            messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
            callback: resetSaveSnackBar,
          });
        } else {
          snackbarStore.removeMessage(snackBarId.value);
        }
      },
      { deep: true }
    );

    //watch for calculateSnackbar
    watch(
      () => [calculateSnackbar.value],
      () => {
        if (calculateSnackbar.value) {
          calculateSnackbarId.value = snackbarStore.showMessage({
            displayText: "Scour Calculator Update Successful.",
            timeout: 10000,
            messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
            callback: resetSaveSnackBar,
          });
        } else {
          snackbarStore.removeMessage(calculateSnackbarId.value);
        }
      },
      { deep: true }
    );

    //watch for saved
    watch(
      () => [saved.value],
      () => {
        if (saved.value) {
          saveSnackbarId.value = snackbarStore.showMessage({
            displayText: "Page saved successfully.",
            timeout: 10000,
            messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
            callback: resetSaveSnackBar,
          });
        } else {
          snackbarStore.removeMessage(saveSnackbarId.value);
        }
      },
      { deep: true }
    );

    return {
      calculateSnackbar,
      resetSaveSnackBar,
      reloadNotesComments,
      INSPECTION_PAGES,
      saved,
      isSavingValue,
      closeWarningInspectionChange,
      inspectionStore,
      inspectionPlaceholder,
      warningInspectionChange,
      saveLogic,
      INSPECTION_MESSAGES,
      save,
      isDirty,
      submitInspection,
      pushNewInspection,
      brKeyValue,
      inspectionkey,
      inspectionArr,
      structure,
      selectedInspection,
      connectivity,
      cancelChanges,
      loadInspectionAndReload,
      isLoading,
      RECORD_MANAGEMENT_TYPES,
      showValidation,
      INSPECTION_STATUSES,
      STATUSES_CAN_SUBMIT,
    };
  },
  components: { SubmitButton, ValidationPopup, ReportsButton },
});
</script>
<style lang="scss">
@use "@/styles/colors" as c;
.increaseContrast {
  filter: contrast(1.5);
}
.disabledSelectHeaderField .fa-caret-down {
  color: c.$p-black;
}
.disabledSelectHeaderField .v-field__field {
  color: c.$p-black;
}
.withBlueBackground button {
  background-color: c.$p-white !important;
  color: c.$p-pa-blue !important;
  text-transform: capitalize;
}
.border-black .v-field--variant-filled .v-field__outline::after {
  border-color: c.$p-black;
}
</style>
