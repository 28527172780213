export const getScourPOA = (scourCriticalCategory) => {
  let scourPOARequired = null;
  if (scourCriticalCategory == "--") {
    scourPOARequired = "0";
  } else if (
    scourCriticalCategory == "A" ||
    scourCriticalCategory == "B" ||
    scourCriticalCategory == "C" ||
    scourCriticalCategory == "D"
  ) {
    scourPOARequired = "Y";
  } else {
    scourPOARequired = "N";
  }
  return scourPOARequired;
};
