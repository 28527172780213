<template>
  <v-row>
    <v-col sm="8" class="font-weight-bold">
      Records in Local Database:
      {{ structureIDBStore.getLocalStructures?.length }}/{{ clientMaxStorage
      }}<br />
      Records Selected: {{ selectedLocalStructures?.length }}
    </v-col>
    <v-col sm="4" class="d-flex">
      <v-spacer />
      <SubmitButton
        :isDisabled="!isLocalStructuresSelected || nonSubmittableStatus"
        id="submit"
        @clicked="handleSubmit"
      />
      &nbsp; &nbsp; &nbsp;
      <v-btn
        id="btn_delete"
        style="width: 100px"
        variant="outlined"
        @click="deleteLocalStructures"
        :disabled="!isLocalStructuresSelected"
        >Delete</v-btn
      >
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="12">
      <v-data-table
        v-model:page="pageInspection.page"
        v-model="selectedLocalStructures"
        v-model:sort-by="sortBy"
        :items-per-page="pageInspection.itemsPerPage"
        :headers="headers"
        :items="flatArrayData"
        @update:sort-by="worklistFooter.setPageValue(1)"
        item-value="_id"
        class="compactTable"
        hide-default-footer
        must-sort
        return-object
      >
        <template v-slot:[`header.selectAllCheckbox`]="{ column }">
          {{ column.title }}
          <v-checkbox
            id="checkbox_selectAllLocalStructures"
            aria-describedby="checkbox_selectAllLocalStructures"
            aria-label="checkbox_selectAllLocalStructures"
            @click="handleSelectAllLocalStructures"
            v-model="selectAllLocalStructures"
            hide-details
          />
        </template>

        <template v-slot:[`header.recordTypeInfo`]="{ column }">
          <div class="iconAlign">
            {{ column.title }}
            <v-tooltip>
              <template v-slot:activator="{ props }">
                <v-icon
                  id="icon_eDocsInfo"
                  class="my-1 infoIcon"
                  icon="fas fa-circle-info"
                  v-bind="props"
                />
              </template>
              <div class="infoDialog">
                <span class="blackText"
                  >E-Docs represent records that only consist of Inspection,
                  Inventory, or Other Electronic Documents</span
                >
              </div>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`header.backedUpInfo`]="{ column }">
          <div class="iconAlign">
            {{ column.title }}
            <v-tooltip>
              <template v-slot:activator="{ props }">
                <v-icon
                  id="icon_eDocsInfo"
                  class="my-1 infoIcon"
                  icon="fas fa-circle-info"
                  v-bind="props"
                />
              </template>
              <div class="infoDialog">
                <span class="blackText"
                  >Backed Up - Local record stored on cloud for recovery
                  only.<br />
                  Changes Submitted - Local record changes are saved to
                  BMS.</span
                >
              </div>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item="{ item }">
          <tr :class="rowClass(item._id)">
            <td class="text-center">
              <v-checkbox
                :id="'checkbox_selectLocalStructure_' + item._id"
                :aria-describedby="'checkbox_selectLocalStructure_' + item._id"
                :aria-label="'checkbox_selectLocalStructure_' + item._id"
                v-model="selectedLocalStructures"
                @click="updateSelectAllLocalStructures(item._id)"
                :value="item._id"
                hide-details
              ></v-checkbox>
            </td>
            <td>
              <router-link
                :id="'link_bridgeId' + item._id"
                :to="'/inspections/structures/' + item._id"
              >
                {{ item._id }}
              </router-link>
            </td>

            <td class="text-center">
              <span v-if="item.bridgeId">
                {{ item.bridgeId }}
              </span>
              <span v-else-if="item.bmsId">{{ item.bmsId }}</span>
              <span v-else>N/A</span>
            </td>

            <td class="text-center">{{ item.recordType }}</td>
            <td class="iconAlign"></td>
            <td class="text-center">
              <span v-if="item.inspstat">
                {{
                  configStore.getReferenceValue(
                    REFERENCE_TABLE.INSPECTION_STATUS,
                    item.inspstat
                  )
                }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td class="text-center">
              <span v-if="item.inspdate">
                <span>{{ getFormattedDateStringNoTime(item.inspdate) }}</span>
              </span>
              <span v-else>N/A</span>
            </td>
            <td class="text-center">
              <span v-if="item.downloadDate">
                <span>{{
                  getFormattedDateStringNoTime(item.downloadDate)
                }}</span>
              </span>
              <span v-else>N/A</span>
            </td>

            <td class="text-center">{{ item.backedUp }}</td>
            <td class="iconAlign"></td>
            <td class="text-center">
              {{ isStructureSubmitted(item) }}
            </td>
          </tr>
        </template>
        <template v-if="flatArrayData.length == 0" v-slot:body>
          <tr>
            <td colspan="12">
              <v-label class="noRecordsMessage d-flex justify-center"
                >There are currently no structures downloaded to the Worklist.
              </v-label>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <DataTableFooter
            ref="worklistFooter"
            id="inspection_Worklist_Footer"
            :items="flatArrayData"
            v-model="pageInspection"
            :rowsPerPageOptions="footerOptions"
          ></DataTableFooter>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
  <ConfirmDialog ref="deleteConfirm" />
  <LoginDialog ref="loginDialogRef" @login-clicked="checkLogin" />

  <v-dialog v-model="showLogViewer" persistent scrollable>
    <v-card>
      <v-card-title>
        <h3 class="withBackground">Log Viewer</h3>
      </v-card-title>
      <v-card-text>
        <div id="logDetails">
          <div style="white-space: pre-wrap">
            <strong>{{ startingAllSubmissionsMessage }} </strong>
          </div>
          <template v-for="eventlog in submissionResponses" :key="eventlog">
            <div class="pre-formatted">{{ eventlog }}</div>
          </template>

          <template v-if="submissionResponses?.length == totalSelectedInsps">
            <div>
              {{ getSuccessfullySubmittedMessage }}
            </div>
            <div>
              {{ getFailedSubmissionMessage }}
            </div>
          </template>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="align-center">
            <v-btn variant="outlined" @click="copyLog">Copy Log</v-btn>
            <v-btn variant="outlined" @click="closePopup">Close</v-btn>
          </v-col>
        </v-row>
      </v-card-actions></v-card
    >
  </v-dialog>
  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    scroll-strategy="none"
  >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { useInspectionStore } from "@/stores/inspection";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import useDateField from "@/composables/dateField";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { LOGGER } from "@/util/logger";
import LoginDialog from "@/components/common/LoginDialog.vue";
import {
  WORKLIST_DELETE_CONFIRM_MESSAGES,
  BUTTONS,
  DOWNLOAD_ERROR_MESSAGES,
  SUBMISSION_FAILURE,
} from "@/constants/CommonWebConstants";
import { syncAllDirtyRecords } from "@/util/syncInterval";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { HTTP_STATUS_CODE } from "@/constants/CommonWebCodes";
import SubmitButton from "@/components/shared/SubmitButton.vue";
import { useConnectivityStore } from "@/stores/connectivity";
import { INSPECTION_STATUSES } from "@/constants/InspectionStatuses";
import { clone } from "@/util/clone";
import {
  INSPECTION_COMMENTS,
  STATUSES_CAN_SUBMIT,
} from "@/constants/Inspections";
import { getMessage } from "@/composables/util";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

export default {
  name: "WorkList",
  setup() {
    const sortBy = ref([{ key: "_id", order: "asc" }]);
    const headers = ref([
      {
        title: "",
        align: "center",
        key: "selectAllCheckbox",
        sortable: false,
      },
      {
        title: "BRKEY",
        align: "start",
        key: "_id",
        sortable: true,
      },
      {
        title: "BMS ID",
        align: "center",
        key: "bridgeId",
        sortable: true,
      },

      {
        title: "Record Type",
        align: "center",
        key: "recordType",
        sortable: true,
      },
      {
        title: "",
        align: "center",
        key: "recordTypeInfo",
        sortable: false,
        width: 0,
      },

      {
        title: "Inspection Status",
        align: "center",
        key: "inspstat",
        sortable: true,
      },
      {
        title: "Inspection Date",
        align: "center",
        key: "inspdate",
        sortable: true,
      },
      {
        title: "Download Date",
        align: "center",
        key: "downloadDate",
        sortable: true,
      },

      {
        title: "Backed Up",
        align: "center",
        key: "backedUp",
        sortable: true,
      },
      {
        title: "",
        align: "center",
        key: "backedUpInfo",
        sortable: false,
        width: 0,
      },
      {
        title: "Changes Submitted",
        align: "center",
        key: "submittedTimestamp",
        sortable: true,
      },
    ]);

    const selectAllLocalStructures = ref(false);
    const selectedLocalStructures = ref([]);
    const flatArrayData = ref([]);
    const updatePage = ref(false);
    const deleteConfirm = ref(null);
    const loginDialogRef = ref(null);
    const pageInspection = ref({
      itemsPerPage: 25,
      page: 1,
    });

    const footerOptions = [
      { title: "25", value: 25 },
      { title: "50", value: 50 },
      { title: "75", value: 75 },
    ];

    const worklistFooter = ref(null);
    const structureIDBStore = useStructureIDBStore();
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    const snackbarStore = useSnackbarStore();
    const { getFormattedDateStringNoTime } = useDateField();
    const connectivity = useConnectivityStore();

    const showLogViewer = ref(false);
    const selectedBrkey = ref(null);
    const submissionResponses = ref([]);
    const submissionPassedInsps = ref(0);
    const totalSelectedInsps = ref(0);
    const startingAllSubmissionsMessage =
      "Starting the Multiple Inspection Submission process...\n";
    let isLoading = ref(false);
    let snackBarId = ref("");
    const warningSubmitFailMessageId = ref("");

    onMounted(async () => {
      isLoading.value = true;
      await structureIDBStore.initDB(false);
      //store IDB data into state
      await structureIDBStore.getStructuresFromIDB();
      isLoading.value = false;
    });

    const handleSelectAllLocalStructures = () => {
      selectedLocalStructures.value = [];
      if (!selectAllLocalStructures.value) {
        for (let i in structureIDBStore.localStructures) {
          selectedLocalStructures.value.push(
            structureIDBStore.localStructures[i]._id
          );
        }
      }
    };

    const isStructureSubmitted = (columns) => {
      if (columns?.submittedTimestamp && columns?.backedUpTimestamp) {
        return new Date(columns?.submittedTimestamp).getTime() >=
          new Date(columns?.backedUpTimestamp).getTime()
          ? "Y"
          : "N";
      } else if (columns?.submittedTimestamp) {
        return "Y";
      } else {
        return "N";
      }
    };

    function removeTags(str) {
      if (str === null || str === " ") return false;
      else str = str.toString();

      return str.replace(/<(?:(?!>).)*>/g, "");
    }

    function copyLog() {
      const copyText = document.getElementById("logDetails");
      navigator.clipboard.writeText(removeTags(copyText.innerHTML));
      snackBarId.value = snackbarStore.showMessage({
        displayText: "Copy Log Successful",
        timeout: 10000,
        messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
      });
    }

    const validateInspection = async (inspection, userName, password) => {
      await inspectionStore.submitInspection(
        inspection,
        userName,
        password,
        true
      );
    };

    const submitInspection = async (inspection, userName, password) => {
      const failures = getErrors.value.some((e) => e.severity == "1");
      const errors = getErrors.value.some((e) => e.severity == "2");
      if (failures) return;
      if (errors) {
        const submissionComment = inspection.T_Insp_Comment?.find(
          (c) =>
            c?.COMMENT_TYPE == INSPECTION_COMMENTS.SUBMISSION_VALIDATION_COMMENT
        )?.NOTES;
        if (!submissionComment || submissionComment.length < 50) {
          return;
        } else {
          inspection.Users = getMessage(submissionComment);
        }
      }
      await inspectionStore.submitInspection(
        inspection,
        userName,
        password,
        false
      );
    };

    const clearAllLocalStructureSelections = () => {
      selectedLocalStructures.value = [];
      selectAllLocalStructures.value = false;
    };

    const submitLocalStructures = async (loginRequest) => {
      inspectionStore.userAuthenticationErrors = {};
      submissionResponses.value = [];
      submissionPassedInsps.value = 0;
      //Sync All dirty records
      await syncAllDirtyRecords();
      let userName = window.btoa(loginRequest.userName);
      let password = window.btoa(loginRequest.password);

      //Submit local structures
      let isloginClosed = ref(false);
      let isSubmitted = false;
      totalSelectedInsps.value = selectedLocalStructures.value.length;
      try {
        for (const brKey of selectedLocalStructures.value) {
          isSubmitted = false;
          selectedBrkey.value = brKey;
          let inspection = await structureIDBStore.getLatestInspectionFromIDB(
            brKey
          );
          await validateInspection(clone(inspection), userName, password);
          loginDialogRef.value.loginProgressDialog = false;
          if (!isloginClosed.value) {
            isloginClosed.value = checkAuthentication();
          }

          if (isloginClosed.value) {
            let log = buildInspectionLog(inspection);
            if (getErrors.value?.length > 0) {
              //change status
              inspection.InspEvnt.INSPSTAT =
                INSPECTION_STATUSES.VALIDATION_ERROR;
              await submitInspection(clone(inspection), userName, password);
            } else {
              //set the isSubmitted flag when an insp is submitted in the validation call to prevent resubmission
              log += "Submission Successful\n";
              isSubmitted = true;
              submissionPassedInsps.value++;
              //change status
              inspection.InspEvnt.INSPSTAT = INSPECTION_STATUSES.SUBMITTED;
              inspection.submittedTimestamp = new Date();
            }

            if (getErrors.value?.length > 0) {
              log += "Submission Failed\n";
            } else if (!isSubmitted) {
              log += "Submission Successful\n";
              submissionPassedInsps.value++;
              //change status
              inspection.InspEvnt.INSPSTAT = INSPECTION_STATUSES.SUBMITTED;
              inspection.submittedTimestamp = new Date();
            }
            await structureIDBStore.updateStructures([inspection]);
            submissionResponses.value.push(log);
          }
        }
        return isloginClosed.value;
      } catch (e) {
        LOGGER.logException(e);
        isloginClosed.value = true;
        warningSubmitFailMessageId.value = snackbarStore.showMessage({
          displayText: SUBMISSION_FAILURE.INSPECTION_FAILURE,
          timeout: 10000,
          messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
        });
      }
    };

    const checkAuthentication = () => {
      if (
        loginDialogRef.value.dialog &&
        !inspectionStore?.userAuthenticationErrors[
          HTTP_STATUS_CODE.NOT_AUTHORIZED
        ]
      ) {
        loginDialogRef.value.closeDialog();
        showLogViewer.value = true;
        return true;
      } else if (
        inspectionStore?.userAuthenticationErrors[
          HTTP_STATUS_CODE.NOT_AUTHORIZED
        ]
      ) {
        loginDialogRef.value.setMessage(
          DOWNLOAD_ERROR_MESSAGES.INVALID_CREDENTIALS_MESSAGE
        );
        return false;
      }
    };

    const buildInspectionLog = (inspection) => {
      let log = "Submitting the following inspection\n";
      log += `BRKEY = ${inspection.Bridge.BRKEY}, BRIDGE_ID = ${inspection.Bridge?.BRIDGE_ID}, INSPKEY = ${inspection.InspEvnt?.INSPKEY}, INSPSTAT = ${inspection.InspEvnt?.INSPSTAT} \n`;
      log += "Starting Validation\n";
      if (
        getErrors.value?.length > 0 &&
        getErrors.value.findIndex((a) => a.severity == 1) != -1
      ) {
        log += "Failed Validation\n";
        log += "Starting Submission\n";
      } else if (
        getErrors.value?.length > 0 &&
        getErrors.value.findIndex((a) => a.severity == 1) == -1
      ) {
        log += "Passed Validation w/Comment\n";
        log += "Starting Submission\n";
      } else {
        log += "Passed Validation\n";
        log += "Starting Submission\n";
      }
      return log;
    };

    const checkLogin = async (request) => {
      const loginClosed = await submitLocalStructures(request);
      if (loginClosed) {
        clearAllLocalStructureSelections();
      }
    };

    const handleSubmit = async () => {
      if (connectivity.getOnlineServiceStatus) {
        loginDialogRef.value
          .open()
          .then((loginRequest) => checkLogin(loginRequest));
      } else {
        let inspectionsToSubmit = [];

        //when offline
        for (const brKey of selectedLocalStructures.value) {
          let insp = await structureIDBStore.getLatestInspectionFromIDB(brKey);
          //change status
          insp.InspEvnt.INSPSTAT = INSPECTION_STATUSES.READY_TO_SUBMIT;
          inspectionsToSubmit.push(insp);
        }
        await structureIDBStore.updateStructures(inspectionsToSubmit);
        clearAllLocalStructureSelections();
        snackBarId.value = snackbarStore.showMessage({
          displayText: `The inspection has been marked '0 - Ready to Submit'. You can submit later
    from the Worklist when you are connected to the internet.`,
          timeout: 10000,
        });
      }
    };

    const closePopup = () => {
      showLogViewer.value = false;
    };

    const getErrors = computed(() => {
      return inspectionStore?.submissionErrors?.get(selectedBrkey.value);
    });

    const getSuccessfullySubmittedMessage = computed(() => {
      return `${submissionPassedInsps.value} of ${totalSelectedInsps.value} of Inspections Submitted Successfully\n`;
    });

    const getFailedSubmissionMessage = computed(() => {
      return `${totalSelectedInsps.value - submissionPassedInsps.value} of ${
        totalSelectedInsps.value
      } of Inspections Submission Failed`;
    });
    const nonSubmittableStatus = computed(() => {
      return selectedLocalStructures.value.some((selectedStructure) =>
        structureIDBStore.localStructures.some(
          (localStructure) =>
            localStructure.Bridge.BRKEY === selectedStructure &&
            !STATUSES_CAN_SUBMIT.includes(localStructure.InspEvnt.INSPSTAT)
        )
      );
    });
    const isLocalStructuresSelected = computed(() => {
      return selectedLocalStructures.value?.length > 0;
    });
    const clientMaxStorage = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.CLIENT_STORAGE_MAX
      );
    });
    const updateSelectAllLocalStructures = (bridge) => {
      if (selectedLocalStructures.value.includes(bridge)) {
        selectAllLocalStructures.value = false;
      }
    };
    const deleteLocalStructures = () => {
      //check if any of the selected structures contains unsubmitted changes
      let selectedUnSubmittedRecord = selectedLocalStructures.value?.some(
        (selectedStructure) => {
          let structure = null;
          try {
            structure = structureIDBStore?.localStructures.find(
              (b) => b?.Bridge.BRKEY === selectedStructure
            );
          } catch (err) {
            LOGGER.logException(err);
            structure = structureIDBStore?.localStructures.find(
              (b) => b?.brKey === selectedStructure
            );
          }
          return (
            isStructureSubmitted(structure) === "N" ||
            structure?.backedUp === "N" ||
            false
          );
        }
      );
      //show confirmation dialog on deletion
      deleteConfirm.value
        .open(
          selectedUnSubmittedRecord
            ? WORKLIST_DELETE_CONFIRM_MESSAGES.UNSUBMITTED_STRUCTURE_DELETE_CONFIRM_MESSAGE
            : WORKLIST_DELETE_CONFIRM_MESSAGES.SUBMITTED_STRUCTURE_DELETE_CONFIRM_MESSAGE,
          BUTTONS.DELETE
        )
        .then(async (response) => {
          if (response && response !== "close") {
            await structureIDBStore.deleteStructureFromIDB(
              selectedLocalStructures.value
            );
            clearAllLocalStructureSelections();
            worklistFooter.value?.setPageValue(1);
          }
        });
    };
    const rowClass = (_id) => {
      return selectedLocalStructures.value.includes(_id)
        ? "rowSelect"
        : "rowUnselect";
    };

    watch(
      () => [structureIDBStore.getLocalStructures],
      () => {
        flatArrayData.value = structureIDBStore.getLocalStructures.map(
          (item) => ({
            _id: item._id,
            bridgeId: item.Bridge?.BRIDGE_ID,
            bmsId: item?.bmsId || null,
            recordType: item.recordType,
            backedUp: item.backedUp,
            inspstat: item.InspEvnt?.INSPSTAT,
            inspdate: item.InspEvnt?.INSPDATE,
            downloadDate: item.downloadDate,
            submittedTimestamp: item?.submittedTimestamp || null,
            backedUpTimestamp: item?.backedUpTimestamp,
          })
        );
      },
      { deep: true }
    );

    return {
      footerOptions,
      HTTP_STATUS_CODE,
      REFERENCE_TABLE,
      selectedLocalStructures,
      selectAllLocalStructures,
      structureIDBStore,
      configStore,
      inspectionStore,
      getFormattedDateStringNoTime,
      isStructureSubmitted,
      flatArrayData,
      pageInspection,
      updatePage,
      loginDialogRef,
      DOWNLOAD_ERROR_MESSAGES,
      connectivity,
      handleSubmit,
      copyLog,
      handleSelectAllLocalStructures,
      showLogViewer,
      closePopup,
      checkLogin,
      submissionResponses,
      submissionPassedInsps,
      totalSelectedInsps,
      startingAllSubmissionsMessage,
      getSuccessfullySubmittedMessage,
      getFailedSubmissionMessage,
      clearAllLocalStructureSelections,
      nonSubmittableStatus,
      isLocalStructuresSelected,
      clientMaxStorage,
      updateSelectAllLocalStructures,
      deleteLocalStructures,
      rowClass,
      deleteConfirm,
      sortBy,
      headers,
      worklistFooter,
      isLoading,
      SUBMISSION_FAILURE,
    };
  },
  components: { ConfirmDialog, DataTableFooter, LoginDialog, SubmitButton },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;

.blackText {
  color: c.$p-black;
}

.infoDialog {
  background-color: c.$p-white;
  width: 350px;
}

td {
  white-space: nowrap;
}

.fill-height {
  height: 600px;
}

.iconAlign {
  margin-left: -3em;
}

.pre-formatted {
  white-space: pre-wrap;
  border-bottom: 1px dotted;
}
</style>
