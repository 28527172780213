export const LOAD_RATING_AND_POSTING_DATA = {
  BRKEY: null,
  INSPECTIONID: null,
  DESIGNLOAD: null,
  SERVTYPON: null,
  LR_REVIEW_ACTION: null,
  POST_REVIEW_REC: null,
  RATING_DATE: null,
  RATING_APPR_DATE: null,
  APPROVER_NAME: null,
  RECOMMEND_RATING_REVIEW: null,
  DESIGN_METHOD: "",
  ROUTINE_PERMIT_LOADS: "",
  T_Posting: [],
  T_Rating_Load: [],
  T_Tunnel_Load_Rating: {
    BRKEY: null,
    AXLE_POST_LOAD: null,
    GROSS_POST_LOAD: null,
    HAZMAT_RESTR: "",
    HEIGHT_RESTR: "",
    INV_LR_FACTOR: null,
    OP_LR_FACTOR: null,
    OTHER_RESTR: "",
    RATING_METHOD: "",
    TYPE3S2_POST_LOAD: null,
    TYPE3_3_POST_LOAD: null,
    TYPE3_POST_LOAD: null,
    MODTIME: null,
    USERKEY: null,
  },
  nbiAndAlternateRating: {
    brkey: null,
    controllingLegalLoadRatingFactor: null,
    h20IRLoad: null,
    h20IRLoadFactor: null,
    h20LoadType: "",
    h20ORLoad: null,
    h20ORLoadFactor: null,
    h20RatingAnalysisMethod: "",
    inspectionDate: "",
    inspectionId: null,
    ml80IRLoad: null,
    ml80IRLoadFactor: null,
    ml80LoadType: "",
    ml80ORLoad: null,
    ml80ORLoadFactor: null,
    ml80RatingAnalysisMethod: "",
    nbi1IRLoad: null,
    nbi1IRLoadFactor: null,
    nbi1LoadType: "",
    nbi1OperatingRatingMethod: "",
    nbi1ORLoad: null,
    nbi1ORLoadFactor: null,
    nbi1RatingAnalysisMethod: ""
  },
  calculationDates: [],
  inspectionComments: [],
  previousInspectionComments: [],
};

export const POSTING = {
  BRKEY: null,
  POSTING_KEY: null,
  ACTIVE: "",
  FIELD_COND: "",
  IMPACT: "",
  PERMANENTLY_CLOSED_STRUC: null,
  POST_LIMIT_COMB: null,
  POST_LIMIT_WEIGHT: null,
  POST_REASON: "",
  POST_STATUS: "",
  POST_STATUS_DATE: "",
  SNBI_POST_CONDITION: "",
  SNBI_POST_STATUS: "",
  SPEC_COND: "",
  SPEC_RESTRICT_POST: "",
  MODTIME: "",
  USERKEY: "",
  VEH_POST_TYPE: null,
};

export const RATING_LOAD = {
  BRKEY: null,
  RATING_DATE: "",
  RATING_KEY: null,
  AASHTO_MANUAL_YEAR: null,
  AASHTO_SPEC_YEAR: null,
  ANALYSIS_ENGINEER: null,
  CONTROL_MEM_TYPE: "",
  CREATEDATETIME: "",
  CREATEUSERKEY: "",
  FATIG_LOAD_TYPE: "",
  FATIG_STRESS_CAT: "",
  INV_RATING_STRESS_METHOD: "",
  IRLOAD: null,
  IR_LOAD_FACTOR: null,
  LEGAL_LOAD_RF: null,
  LOAD_TYPE: "",
  NBI_RATING_IND: "",
  NOTES: null,
  OPR_RATING_STRESS_METHOD: "",
  ORLOAD: null,
  OR_LOAD_FACTOR: null,
  RATING_ANALYSIS_METHOD: "",
  SLC_RATING: null,
  STRESS_RANGE: null,
  SUPPORT_DATASET: "",
  MODTIME: "",
  USERKEY: "",
  VEH_POST_WEIGHT: null,
  VEH_POST_TYPE: null,
};
