import { SECTION_TYPES } from "@/constants/InspectionReportGenerator.js";
/**
 * Generates Power BI parameters based on the provided section type and other details.
 *
 * @param {Object} options - The options object.
 * @param {string} options.sectionTypeId - The section type identifier.
 * @param {string} options.brKey - The bridge key.
 * @param {string} options.inspectionDate - The inspection date.
 * @param {string} options.user - The user id.
 * @returns {Array<Object>} The array of Power BI parameters.
 */
export const getPowerBIParams = ({
  sectionTypeId,
  brKey,
  inspectionDate,
  user,
}) => {
  const params = [
    {
      name: "User",
      value: user,
    },
  ];
  switch (sectionTypeId) {
    case SECTION_TYPES.D491: {
      params.push({
        name: "BridgeKey",
        value: brKey,
      });
      break;
    }
    case SECTION_TYPES.SCOUR_POA: {
      params.push(
        {
          name: "BRKEY",
          value: brKey,
        },
        {
          name: "InspDate",
          value: inspectionDate,
        }
      );
      break;
    }
  }
  return params;
};
