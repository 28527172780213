/**
 *
 * @param {string[]} groups This represents list of group which a user is part of.
 * @param {string[]} roles This represents list of roles
 * @returns boolean
 */
export const isUserGroupHaveAccess = (groups, roles) => {
  if (!groups || !Array.isArray(groups) || groups.length === 0) {
    return false; // groups claim is missing or empty
  }

  if (!roles || !Array.isArray(roles) || roles.length === 0) {
    return false; // roles empty
  }

  // Check if any of the roles are present in the groups claim of the JWT
  for (let role of roles) {
    if (groups.includes(role)) {
      return true; // At least one role is present in the groups claim
    }
  }

  return false; // None of the roles are present in the groups claim
};
