import { defineStore } from "pinia";
import { ADMIN_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { ref } from "vue";
import { LOGGER } from "@/util/logger";
import { getQuillHTML } from "@/util/quillUtility";

export const useAdminStore = defineStore("admin", () => {
  let pageReference = ref(null);
  let messages = ref([]);
  let isDirty = ref(false);
  let links = ref([]);
  let showValidationSnackbar = ref(false);
  let addingOrEditing = ref(false);
  let passwordSet = ref(true);

  const getMessages = () => {
    return messages.value;
  };
  const getPageReference = () => {
    return pageReference.value;
  };
  const getIsDirty = () => {
    return isDirty.value;
  };
  const getShowValidationSnackbar = () => {
    return showValidationSnackbar.value;
  };
  const getPasswordSet = () => {
    return passwordSet.value;
  };

  const setPageReference = (value) => {
    pageReference.value = value;
  };
  const setIsDirty = (value) => {
    isDirty.value = value;
  };
  const setShowValidationSnackbar = (value) => {
    showValidationSnackbar.value = value;
  };
  const setMessages = (value) => {
    messages.value = value;
  };
  const getLinks = () => {
    return links.value;
  };
  const setLinks = (value) => {
    links.value = value;
  };
  const setPasswordSet = (value) => {
    passwordSet.value = value;
  };

  const saveUser = async (user) => {
    return axios
      .put(ADMIN_ENDPOINT.SAVE_POUCHDB_USER, user)
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const getUserInfo = async (userId) => {
    let url = ADMIN_ENDPOINT.GET_POUCHDB_USER.replace("{userId}", userId);
    return await axios
      .get(url)
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const getThinBBMessages = async (includeExpired) => {
    const url = ADMIN_ENDPOINT.GET_THIN_BB_MESSAGES;
    return await axios
      .get(url, {
        params: {
          includeExpired: includeExpired,
        },
      })
      .then((response) => {
        response.data.data?.forEach((element) => {
          element.readOnly = true;
          element.isDirty = false;
          element.isAdd = false;
        });
        messages.value = response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const getPublishedBBMessages = async () => {
    const url = ADMIN_ENDPOINT.GET_EXTERNAL_BB_MESSAGES;
    return await axios
      .get(url, {})
      .then((response) => {
        messages.value = response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const getPublishedBBMessage = async (bbMessageId) => {
    let url = ADMIN_ENDPOINT.EXTERNAL_BB_MESSAGE.replace(
      "{bbMessageId}",
      bbMessageId
    );
    return await axios
      .get(url)
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const getBBMessage = async (bbMessageId) => {
    let url = ADMIN_ENDPOINT.BB_MESSAGE.replace("{bbMessageId}", bbMessageId);
    return await axios
      .get(url)
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const deleteBBMessage = async (bbMessageId) => {
    let isSuccess = true;
    let url = ADMIN_ENDPOINT.BB_MESSAGE.replace("{bbMessageId}", bbMessageId);
    await axios
      .delete(url)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Delete,
          LOGGER.EventCat.RequestResponse,
          "Delete BBMessage",
          response
        );
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const updateBBMessage = async (bbMessage) => {
    let isSuccess = true;
    const url = ADMIN_ENDPOINT.SAVE_BB_MESSAGE;
    await axios
      .put(url, bbMessage)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Update,
          LOGGER.EventCat.RequestResponse,
          "Update BBMessage",
          response
        );
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const addBBMessage = async (bbMessage) => {
    let isSuccess = true;
    const url = ADMIN_ENDPOINT.SAVE_BB_MESSAGE;
    await axios
      .post(url, bbMessage)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Add,
          LOGGER.EventCat.RequestResponse,
          "Add BBMessage",
          response
        );
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const loadLinks = async (isExternal) => {
    let url;
    if (isExternal) {
      url = ADMIN_ENDPOINT.GET_LINKS_EXTERNAL;
    } else {
      url = ADMIN_ENDPOINT.GET_LINKS;
    }
    return await axios
      .get(url)
      .then((response) => {
        links.value = response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const updateLinks = async (allLinks) => {
    const url = ADMIN_ENDPOINT.UPDATE_LINKS;
    let isSuccess = true;
    await axios
      .post(url, allLinks)
      .then((response) => {
        links.value = response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  const updateMessages = (value) => {
    messages.value = [];
    value?.forEach((element) => {
      let message = {
        bbMessageId: element.bbMessageId,
        messageBody: getQuillHTML(element.messageBody),
        messageTitle: getQuillHTML(element.messageTitle),
        effectiveDate: element.effectiveDate,
        expirationDate: element.expirationDate,
        createdDateTime: element.createdDateTime,
        createdUserId: element.createdUserId,
        updateDateTime: element.updateDateTime,
        updatedUserId: element.updatedUserId,
        readOnly: element.readOnly,
        isDirty: element.isDirty,
        isAdd: element.isAdd,
        isEdit: element.isEdit,
      };
      messages.value.push(message);
    });
  };

  return {
    getPasswordSet,
    setPasswordSet,
    updateMessages,
    getShowValidationSnackbar,
    setShowValidationSnackbar,
    getPublishedBBMessage,
    getPublishedBBMessages,
    updateBBMessage,
    addBBMessage,
    getMessages,
    setMessages,
    isDirty,
    pageReference,
    setIsDirty,
    setPageReference,
    getIsDirty,
    getPageReference,
    saveUser,
    getUserInfo,
    getThinBBMessages,
    getBBMessage,
    deleteBBMessage,
    loadLinks,
    updateLinks,
    getLinks,
    setLinks,
    addingOrEditing,
  };
});
