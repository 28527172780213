<template>
  <v-data-table
    id="bulletin-board-data-table"
    :headers="headers"
    :items="thinMessages"
    v-model:expanded="expandedRows"
    class="compactTable compactHeader lessPaddingTableColumns no"
    hide-default-footer
    must-sort
    show-expand
    return-object
  >
    <template v-slot:[`header.data-table-expand`]="{ column }">
      {{ column.title }}
    </template>
    <template v-slot:[`header.MESSAGE_TITLE`]="{ column }">
      <DataTableHeader :column="column" :lines="1" />
    </template>

    <template v-slot:expanded-row="{ item }">
      <td :colspan="headers.length">
        <v-row>
          <v-col
            cols="12"
            class="hideToolbar pt-3 pl-6 pb-3 pr-6 increaseContrast"
          >
            <div
              class="quillMessageWrapper"
              v-html="
              item.messageBody
              "
            ></div>
          </v-col>
        </v-row>
      </td>
    </template>

    <template v-slot:item="{ item }">
      <tr :class="rowClass(item)">
        <td class="alignCenter">
          <button
            v-if="!expandedRows.includes(item)"
            @click="toggleRow(item)"
            aria-label="toggleRowButtonOpen"
            class="toggleRowButton"
          >
            <v-icon
              :id="`icon_Chevron_Right_${item.bbMessageId}`"
              icon="fa-solid fa-chevron-right"
            ></v-icon>
          </button>

          <button
            v-if="expandedRows.includes(item)"
            @click="toggleRow(item)"
            aria-label="toggleRowButtonClosed"
            class="toggleRowButton"
          >
            <v-icon
              :id="`icon_Chevron_Down_${item.bbMessageId}`"
              icon="fa-solid fa-chevron-down"
            ></v-icon>
          </button>
        </td>
        <td>
          <div
            class="pl-2 alignCenter increaseContrast"
            v-html="
            item.messageTitle
            "
          ></div>
        </td></tr
    ></template>
    <template v-slot:bottom> </template>
  </v-data-table>
</template>

<script>
import { ref, onMounted } from "vue";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import { useAdminStore } from "@/stores/admin";
import { clone } from "@/util/clone";
export default {
  name: "ExternalBulletinBoard",
  data() {
    return {
      headers: [
        {
          key: "data-table-expand",
          width: "3%",
        },
        {
          line1: "Message Title",
          line2: null,
          line3: null,
          key: "MESSAGE_TITLE",
          width: "97%",
          sortable: false,
        },
      ],
    };
  },
  setup() {
    const adminStore = useAdminStore();
    let thinMessages = ref([]);
    let expandedRows = ref([]);
    const loadThinMessages = () => {
      thinMessages.value = clone(adminStore.getMessages());
    };
    const rowClass = (detail) => {
      return expandedRows.value?.includes(detail) ? "rowSelect" : "rowUnselect";
    };

    const toggleRow = async (detail) => {
      if (expandedRows.value.includes(detail)) {
        const index = expandedRows.value.findIndex(
          (d) => d.bbMessageId == detail.bbMessageId
        );
        expandedRows.value.splice(index, 1);
      } else {
        let message = await adminStore.getPublishedBBMessage(
          detail.bbMessageId
        );
        detail.messageBody = message.messageBody;
        expandedRows.value.push(detail);
      }
    };

    onMounted(async () => {
      await adminStore.getPublishedBBMessages(false);
      loadThinMessages();
    });

    return {
      expandedRows,
      toggleRow,
      rowClass,
      thinMessages,
      loadThinMessages,
    };
  },
  components: {
    DataTableHeader,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.increaseContrast {
  filter: contrast(1.1);
}
.hideToolbar {
  :deep(.ql-toolbar.ql-snow) {
    display: none;
  }
  :deep(.ql-container.ql-snow.ql-disabled) {
    border-top: 1px solid #d1d5db;
  }
}
.alignCenter {
  text-align: center;
}

.quillMessageWrapper {
  border: 1px solid #d1d5db;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
