export const SNACKBAR_MESSAGE_TYPES = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  ERROR_CUSTOM: "error_custom",
};

export const SNACKBAR_MARKER = {
  INSPECTION_PLANNING: "inspectionPlanning",
  PERSERVATION_REHAB: "preservationRehab",
  INVENTORY_FEATURES: "invenbtoryFeatures",
  TUNNELS: "tunnel",
  WALLS: "walls",
  SIGN_AND_LIGHTS: "signAndLights",
  STRUCTURE_IDENTIFICATION: "structureIdentification",
  STRUCTURE_COMPOSITION: "StructureComposition",
  INSPECTION_LINK: "inspectionLink",
};
