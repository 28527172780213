<template>
  <v-container fluid class="blankSpace" id="inspections_container">
    <div
      :class="isOnline ? 'sticky-element' : 'sticky-element addBannerHeight'"
    >
      <InspectionHeader
        ref="inspectionHeader"
        :brkey="brkey"
        :inspkey="inspkey"
        :selectedPage="page"
        @cancel-changes="confirmCancelChanges"
        @get-ref="setPageRef"
        @saving="toggleSaving"
        @reload="reload"
        @reloadNotesComments="reloadNotesComments"
      />
      <InspectionNavigationDropdown
        :brkey="brkey"
        :inspkey="inspkey"
        v-if="$vuetify.display.mdAndDown"
        @selected-page="setPage"
      />
      <InspectionNavigation
        :brkey="brkey"
        :inspkey="inspkey"
        v-else
        @selected-page="setPage"
      />
    </div>

    <InspectionGeneral
      v-if="page == INSPECTION_PAGES.GENERAL"
      @start-new-inspection="startNewInspection"
    />
    <InspectionSchedule
      ref="scheduleRef"
      v-if="page == INSPECTION_PAGES.SCHEDULE"
    />
    <InspectionRatings
      ref="ratingsRef"
      v-if="page == INSPECTION_PAGES.RATINGS"
    />
    <InspectionElements
      v-if="page == INSPECTION_PAGES.ELEMENTS"
      ref="elementsRef"
      :inspectionPage="INSPECTION_PAGES.ELEMENTS"
    />
    <InspectionNSTMFatigue
      ref="nstmRef"
      v-if="page == INSPECTION_PAGES.NSTM_FATIGUE"
    />
    <InspectionApproach
      ref="approachRef"
      v-if="page == INSPECTION_PAGES.APPROACH"
    />
    <InspectionSuperstructure
      ref="superRef"
      v-if="page == INSPECTION_PAGES.SUPERSTRUCTURE"
    />
    <InspectionSubstructure
      ref="subRef"
      v-if="page == INSPECTION_PAGES.SUBSTRUCTURE"
    />
    <InspectionDeck ref="deckRef" v-if="page == INSPECTION_PAGES.DECK" />
    <InspectionJoints ref="jointsRef" v-if="page == INSPECTION_PAGES.JOINTS" />
    <InspectionWalls ref="wallsRef" v-if="page == INSPECTION_PAGES.WALLS" />
    <InspectionBearings
      ref="bearingsRef"
      v-if="page == INSPECTION_PAGES.BEARINGS"
    />
    <InspectionLoadRatings
      ref="loadRatingsRef"
      v-if="page == INSPECTION_PAGES.LOAD_RATING"
    />
    <InspectionCulvert
      ref="culvertRef"
      v-if="page == INSPECTION_PAGES.CULVERT"
    />
    <InspectionSignsLights
      ref="signsLightsRef"
      v-if="page == INSPECTION_PAGES.SIGNS_AND_LIGHTS"
    />
    <InspectionEDocs v-if="page == INSPECTION_PAGES.EDOCS" ref="eDocsRef" />
    <InspectionTunnels v-if="page == INSPECTION_PAGES.TUNNELS" />
    <InspectionMaintenance
      v-if="page == INSPECTION_PAGES.MAINTENANCE_ITEMS"
      ref="maintenanceRef"
    />
    <InspectionFeatures v-if="page == INSPECTION_PAGES.FEATURES" />
    <InspectionWaterway
      ref="waterwayRef"
      v-if="page == INSPECTION_PAGES.WATERWAY"
    />
    <InspectionNotes v-if="page == INSPECTION_PAGES.NOTES" ref="notesRef" />
  </v-container>

  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />

  <ConfirmDialog ref="closeConfirmRouter" :cancelButtonText="BUTTONS.NO" />

  <v-btn
    v-scroll="onScroll"
    v-show="offset > 230"
    class="toTop"
    color="primary"
    @click="scrollToTop"
    aria-label="Scroll To Top"
  >
    <v-icon size="large" icon="fa-solid fa-chevron-up" />
  </v-btn>
  <v-overlay
    v-model="isSaving"
    persistent
    eager
    class="align-center justify-center"
    scroll-strategy="none"
  >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import InspectionGeneral from "@/components/inspection/InspectionGeneral.vue";
import InspectionRatings from "@/components/inspection/InspectionRatings.vue";
import InspectionElements from "@/components/inspection/InspectionElements.vue";
import InspectionNSTMFatigue from "@/components/inspection/InspectionNSTMFatigue.vue";
import InspectionNavigation from "@/components/inspection/InspectionNavigation.vue";
import InspectionNavigationDropdown from "@/components/inspection/InspectionNavigationDropdown.vue";
import InspectionSchedule from "@/components/inspection/InspectionSchedule.vue";
import InspectionApproach from "@/components/inspection/InspectionApproach.vue";
import InspectionSuperstructure from "@/components/inspection/InspectionSuperstructure.vue";
import InspectionSubstructure from "@/components/inspection/InspectionSubstructure.vue";
import InspectionDeck from "@/components/inspection/InspectionDeck.vue";
import InspectionJoints from "@/components/inspection/InspectionJoints.vue";
import InspectionBearings from "@/components/inspection/InspectionBearings.vue";
import InspectionLoadRatings from "@/components/inspection/InspectionLoadRatings.vue";
import InspectionCulvert from "@/components/inspection/InspectionCulvert.vue";
import InspectionSignsLights from "@/components/inspection/InspectionSignsLights.vue";
import InspectionWalls from "@/components/inspection/InspectionWalls.vue";
import InspectionTunnels from "@/components/inspection/InspectionTunnels.vue";
import InspectionWaterway from "@/components/inspection/InspectionWaterway.vue";
import InspectionFeatures from "@/components/inspection/InspectionFeatures.vue";
import InspectionNotes from "@/components/inspection/InspectionNotes.vue";
import InspectionMaintenance from "@/components/inspection/InspectionMaintenance.vue";

import { useRoute, onBeforeRouteLeave } from "vue-router";
import InspectionHeader from "@/components/inspection/InspectionHeader.vue";
import { useInspectionStore } from "@/stores/inspection";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { computed, ref, nextTick, watch, onMounted } from "vue";
import router from "@/router";
import { INSPECTION_PAGES } from "@/constants/Inspections";
import InspectionEDocs from "@/components/inspection/InspectionEDocs.vue";
import { RECORD_MANAGEMENT_TYPES } from "@/constants/StructureSearches";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { BUTTONS } from "@/constants/CommonWebConstants";
import { useConnectivityStore } from "@/stores/connectivity";
import { scrollToElement } from "@/util/scrollToElement";
import { useQuickSearchStore } from "@/stores/quickSearch";
import { INVENTORY_ROUTES } from "@/constants/InventoryLinks";
import { OTHER_LINKS_ROUTES } from "@/constants/OtherLinks";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "StructureInspection",
  async setup(_, ctx) {
    const connectivity = useConnectivityStore();
    const quickSearchStore = useQuickSearchStore();
    const snackbarStore = useSnackbarStore();
    let isOnline = computed(() => connectivity.getOnlineServiceStatus);

    onBeforeRouteLeave((to) => {
      if (inspectionStore.getDirtyFlag || inspectionStore.getAddingOrEditing) {
        closeConfirmRouter.value
          .open(
            inspectionStore.getAddingOrEditing
              ? INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE
              : INSPECTION_MESSAGES.DETECT_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (response) {
              stayAndClose();
            } else if (!response) {
              snackbarStore.removeAllMessage();
              leavePage();
            }
          });

        nextTick(() =>
          document.getElementById("btn_saveUnsavedDataRouter")?.focus()
        );
        inspectionStore.setNextRoute(to.fullPath);
        return false;
      } else {
        if (
          to.fullPath == INVENTORY_ROUTES.RISK_SCORE_DETAILS_ROUTE ||
          to.fullPath == OTHER_LINKS_ROUTES.BRIDGE_PROBLEM_REPORTS_ROUTE ||
          to.fullPath == OTHER_LINKS_ROUTES.BP_ASSIGNMENT_ROUTE ||
          to.fullPath == INVENTORY_ROUTES.STRUCTURE_IDENTIFICATION_ROUTE ||
          to.fullPath == INVENTORY_ROUTES.STRUCTURE_COMPOSITION
        ) {
          quickSearchStore.setStructure({
            BRKEY: inspection.value.Bridge.BRKEY,
            BMSID: inspection.value.Bridge.BRIDGE_ID,
            REPORTGROUP: inspection.value.Bridge.REPORTGROUP,
          });
        }
        return true;
      }
    });
    const closeConfirm = ref(null);
    const closeConfirmRouter = ref(null);

    const route = useRoute();
    const inspectionStore = useInspectionStore();
    const structureIDBStore = useStructureIDBStore();

    const inspection = computed(() => inspectionStore.selectedInspection);

    const confirmCancel = ref(false);
    //cancel the changes on elements page when cancel is clicked
    let elementsRef = ref(null);
    let ratingsRef = ref(null);
    let scheduleRef = ref(null);
    let deckRef = ref(null);
    let subRef = ref(null);
    let superRef = ref(null);
    let offset = ref(0);
    let nstmRef = ref(null);
    let jointsRef = ref(null);
    let bearingsRef = ref(null);
    let signsLightsRef = ref(null);
    let approachRef = ref(null);
    let page = ref(null);
    let wallsRef = ref(null);
    let waterwayRef = ref(null);
    let eDocsRef = ref(null);
    let loadRatingsRef = ref(null);
    let culvertRef = ref(null);
    let inspectionHeader = ref(null);
    let notesRef = ref(null);
    let maintenanceRef = ref(null);
    let { brkey, inspkey } = route.params;

    let pageRef = ref(null);
    let isSaving = ref(false);
    const toggleSaving = (value) => {
      isSaving.value = value;
    };
    const setUri = () => {
      if (inspection.value?.InspEvnt?.INSPKEY) {
        router.push({
          name: "SpecificInspection",
          params: {
            inspkey: inspection.value?.InspEvnt?.INSPKEY,
          },
        });
      } else {
        router.push({
          name: "LatestInspection",
        });
      }
    };
    onMounted(async () => {
      await structureIDBStore.initDB(false);
    });
    watch(
      () => [inspectionStore.getNextPage],
      () => {
        if (
          inspectionStore.getUnsavedChangesWarning &&
          page.value != inspectionStore.getNextPage
        ) {
          closeConfirm.value
            .open(
              inspectionStore.getAddingOrEditing
                ? INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE
                : INSPECTION_MESSAGES.DETECT_CHANGE_CONFIRMATION_MESSAGE,
              BUTTONS.YES
            )
            .then((response) => {
              if (response) {
                stayAndClose();
                //set the next page to current page so user trying to navigate away will show unsaved changes warning
                inspectionStore.setNextPage(page.value);
              } else if (!response) {
                snackbarStore.removeAllMessage();
                cancelChanges();
                leavePage();
                setUri();
              }
            });
        }
      },
      { deep: true }
    );

    const setPage = (pageParam) => {
      page.value = pageParam;
      if (!inspectionStore.getDirtyFlag) {
        ctx.emit("selectedPage", pageParam);
      }
    };

    const getInspectionDetails = async () => {
      inspectionStore.setAddingOrEditing(false);
      if (brkey && inspkey) {
        const latestInspKey =
          await structureIDBStore.getLatestInspectionKeyFromIDB(brkey);
        if (inspkey == latestInspKey) {
          await getLatestInspection();
        } else {
          await inspectionStore.loadInspection(brkey, inspkey);
        }
      } else if (brkey && !inspkey) {
        //get latest inspection record for brkey
        await getLatestInspection();
      }
      setUri();
    };

    const getLatestInspection = async () => {
      await inspectionStore.setSelectedInspection(
        await structureIDBStore.getLatestInspectionFromIDB(brkey)
      );
      inspectionStore.setLatestInspKey(
        inspectionStore.selectedInspection?.InspEvnt?.INSPKEY
      );
    };

    const setLandingPage = () => {
      if (route.query?.page) {
        inspectionStore.setSelectedPage(route.query.page);
        setPage(route.query.page);
      } else {
        if (
          inspectionStore.selectedInspection?.recordType ===
          RECORD_MANAGEMENT_TYPES.DOCUMENTS
        ) {
          page.value = INSPECTION_PAGES.EDOCS;
        } else {
          page.value = INSPECTION_PAGES.GENERAL;
        }
        inspectionStore.setSelectedPage(page.value);
      }
    };
    await getInspectionDetails();
    setLandingPage();

    function onScroll() {
      offset.value = window.scrollY;
    }
    const leavePage = async () => {
      await getLatestInspection();
      inspectionStore.setDirtyFlag(false);
      inspectionStore.setAddingOrEditing(false);
      inspectionStore.setShowValidationSnackbar(false);
      inspectionStore.setUnsavedChangesWarning(false);

      if (inspectionStore.getNextRoute) {
        router.push(inspectionStore.getNextRoute);
      } else {
        inspectionStore.setSelectedPage(inspectionStore.getNextPage);
        page.value = inspectionStore.getNextPage;
      }
    };

    const stayAndClose = () => {
      inspectionStore.setSelectedPage(page.value);
      inspectionStore.setUnsavedChangesWarning(false);
      confirmCancel.value = false;
    };

    const confirmCancelChanges = () => {
      if (inspectionStore.getDirtyFlag) {
        confirmCancel.value = true;
        inspectionStore.setUnsavedChangesWarning(true);
      }
      if (inspectionStore.getUnsavedChangesWarning) {
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (response) {
              stayAndClose();
            } else if (!response) {
              cancelChanges();
            }
          });
      }
    };

    const cancelChanges = async () => {
      await getLatestInspection();
      await resetDataFromStore();
      inspectionStore.setAddingOrEditing(false);
      inspectionStore.setShowValidationSnackbar(false);
      if (page.value == INSPECTION_PAGES.RATINGS) {
        ratingsRef.value.resetChangedByUser();
      }
      if (page.value == INSPECTION_PAGES.WATERWAY) {
        waterwayRef.value.resetChangedByUser();
      }
    };

    const reload = async () => {
      await resetDataFromStore();
      scrollToElement("inspections_container");
    };

    const reloadNotesComments = () => {
      if (page.value == INSPECTION_PAGES.NOTES) {
        notesRef.value.getInspectionNotes();
      }
    };

    const resetDataFromStore = async () => {
      if (page.value == INSPECTION_PAGES.ELEMENTS) {
        elementsRef.value.convertToHierarchy();
      } else if (page.value == INSPECTION_PAGES.NSTM_FATIGUE) {
        nstmRef.value.resetNSTMData();
      } else if (page.value == INSPECTION_PAGES.JOINTS) {
        jointsRef.value.getJointDetails();
      } else if (page.value == INSPECTION_PAGES.BEARINGS) {
        bearingsRef.value.getBearingDetails();
      } else if (page.value == INSPECTION_PAGES.APPROACH) {
        approachRef.value.getSignDetails();
      } else if (page.value == INSPECTION_PAGES.EDOCS) {
        await eDocsRef.value.init();
        eDocsRef.value.getDrawings();
      } else if (page.value == INSPECTION_PAGES.NOTES) {
        notesRef.value.getInspectionNotes();
        notesRef.value.getSubInspectionNotes();
      } else if (page.value == INSPECTION_PAGES.WATERWAY) {
        waterwayRef.value.getFloodPlains();
        waterwayRef.value.getMeasuresPotential();
        waterwayRef.value.getMeasuresCurrent();
      } else if (page.value == INSPECTION_PAGES.MAINTENANCE_ITEMS) {
        maintenanceRef.value.loadCandidates();
      } else if (page.value == INSPECTION_PAGES.SCHEDULE) {
        scheduleRef.value.getAllInspectionTypes();
        scheduleRef.value.resetFutureScheduleFormValidation();
      }
      inspectionStore.setDirtyFlag(false);
      confirmCancel.value = false;
      inspectionStore.setUnsavedChangesWarning(false);
    };

    const setPageRef = () => {
      const pageRefs = {
        [INSPECTION_PAGES.SCHEDULE]: scheduleRef,
        [INSPECTION_PAGES.ELEMENTS]: elementsRef,
        [INSPECTION_PAGES.RATINGS]: ratingsRef,
        [INSPECTION_PAGES.DECK]: deckRef,
        [INSPECTION_PAGES.SIGNS_AND_LIGHTS]: signsLightsRef,
        [INSPECTION_PAGES.WALLS]: wallsRef,
        [INSPECTION_PAGES.SUBSTRUCTURE]: subRef,
        [INSPECTION_PAGES.WATERWAY]: waterwayRef,
        [INSPECTION_PAGES.NSTM_FATIGUE]: nstmRef,
        [INSPECTION_PAGES.LOAD_RATING]: loadRatingsRef,
        [INSPECTION_PAGES.APPROACH]: approachRef,
        [INSPECTION_PAGES.SUPERSTRUCTURE]: superRef,
        [INSPECTION_PAGES.CULVERT]: culvertRef,
        [INSPECTION_PAGES.BEARINGS]: bearingsRef,
        [INSPECTION_PAGES.JOINTS]: jointsRef,
      };
      inspectionStore.setPageReference(pageRefs[page.value]);
    };

    const startNewInspection = () => {
      inspectionHeader.value.saveLogic();
      inspectionHeader.value.pushNewInspection();
      inspectionStore.setSelectedPage(INSPECTION_PAGES.SCHEDULE);
      setPage(INSPECTION_PAGES.SCHEDULE);
      inspkey = inspectionStore.selectedInspection?.InspEvnt?.INSPKEY;
    };

    function scrollToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    return {
      reloadNotesComments,
      toggleSaving,
      isSaving,
      setPageRef,
      pageRef,
      inspectionStore,
      notesRef,
      ratingsRef,
      inspectionHeader,
      startNewInspection,
      waterwayRef,
      wallsRef,
      culvertRef,
      offset,
      onScroll,
      scrollToTop,
      brkey,
      inspkey,
      page,
      setPage,
      stayAndClose,
      leavePage,
      cancelChanges,
      confirmCancel,
      confirmCancelChanges,
      INSPECTION_PAGES,
      elementsRef,
      scheduleRef,
      deckRef,
      nstmRef,
      subRef,
      superRef,
      jointsRef,
      bearingsRef,
      signsLightsRef,
      approachRef,
      reload,
      eDocsRef,
      loadRatingsRef,
      maintenanceRef,
      INSPECTION_MESSAGES,
      closeConfirm,
      closeConfirmRouter,
      BUTTONS,
      isOnline,
    };
  },
  components: {
    InspectionNotes,
    InspectionApproach,
    InspectionNSTMFatigue,
    InspectionGeneral,
    InspectionRatings,
    InspectionNavigation,
    InspectionNavigationDropdown,
    InspectionSchedule,
    InspectionHeader,
    InspectionElements,
    InspectionSuperstructure,
    InspectionSubstructure,
    InspectionDeck,
    InspectionJoints,
    InspectionBearings,
    InspectionLoadRatings,
    InspectionCulvert,
    InspectionSignsLights,
    InspectionWalls,
    InspectionEDocs,
    InspectionTunnels,
    InspectionWaterway,
    InspectionFeatures,
    InspectionMaintenance,
    ConfirmDialog,
  },
};
</script>
<style scoped lang="scss">
.blankSpace {
  margin-bottom: 8rem;
}
</style>
