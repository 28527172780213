import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

function isValidDate(input) {
  dayjs.extend(customParseFormat);
  if (!input) {
    return { isValid: false, inputDate: "" };
  }
  const inputDate =
    typeof input === "object"
      ? dayjs(input)
      : dayjs(input, [
          "YYYY-MM-DD",
          "YYYY-MM-DDTHH:mm:ss",
          "MM/DD/YYYY h:mm A",
        ]);
  return { isValid: inputDate.isValid(), inputDate };
}

export default () => {
  function getFormattedDateStringNoTime(input) {
    const { isValid, inputDate } = isValidDate(input);
    if (!isValid) {
      return "";
    }
    return inputDate.format("MM/DD/YYYY");
  }

  function checkValidDate(input) {
    return dayjs(input, "MM/DD/YYYY").isValid();
  }

  // Month XX, XXXX
  function getLongFormattedDate(dateString) {
    if (!dateString) return "";
    return dayjs(dateString, "YYYY-MM-DD").format("MMMM DD, YYYY");
  }

  return {
    getFormattedDateStringNoTime,
    checkValidDate,
    getLongFormattedDate,
  };
};
