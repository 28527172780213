export const CWOPA_ROLES = {
  BMS: "$BMS",
  BMS_APPLICATION_SECURITY_ADMINISTRATION:
    "$BMS APPLICATION SECURITY ADMINISTRATOR",
  BMS_APRAS_BROWSER: "$BMS APRAS BROWSER",
  BMS_APRAS_EDIT: "$BMS APRAS EDIT",
  BMS_BROWSER: "$BMS BROWSER",
  BMS_BROWSER_INSPECTION: "$BMS BROWSER INSPECTION",
  BMS_BROWSER_INVENTORY: "$BMS BROWSER INVENTORY",
  BMS_LBI_USER: "$BMS LBI USER",
  BMS_POWERUSER: "$BMS POWERUSER",
  BMS_REGISTRAR: "$BMS REGISTRAR",
  BMS_SUPERUSER: "$BMS SUPERUSER",
  SPN_APPLICATION_SECURITY_ADMINISTRATOR:
    "$SPN APPLICATION SECURITY ADMINISTRATOR",
  SPN_APPROVE_DIST: "$SPN APPROVE DIST",
  SPN_APPROVE_STATE: "$SPN APPROVE STATE",
  SPN_EDIT: "$SPN EDIT",
  SPN_VIEW: "$SPN VIEW",
  BMS_INSPECTOR: "$BMS INSPECTOR",
  BMS_INSPECTOR_SUPERVISOR: "$BMS INSPECTOR SUPERVISOR",
};

export const BUSINESS_PARTNER_ROLES = {
  BMSBP_CONSULTANT_INSPECTOR: "$BMSBP CONSULTANT INSPECTOR",
  BMSBP_CONSULTANT_INSPECTOR_SUPERVISOR:
    "$BMSBP CONSULTANT INSPECTOR SUPERVISOR",
  BMSBP_ADMIN: "$BMSBP ADMIN",
  BMSBP_BROWSER: "$BMSBP BROWSER",
  BMSBP_BROWSER_INSPECTION: "$BMSBP BROWSER INSPECTION",
  BMSBP_BROWSER_INVENTORY: "$BMSBP BROWSER INVENTORY",
  BMSBP_CONSULTANT_OWNER_AGENT: "$BMSBP CONSULTANT OWNER AGENT",
  BMSBP_GOVT_BRIDGE_OWNER: "$BMSBP GOVT BRIDGE OWNER",
  BMSBP_GOVT_BRIDGE_OWNER_AGENT: "$BMSBP GOVT BRIDGE OWNER AGENT",
  BMSBP_GOVT_BRIDGE_OWNER_INSPECTOR: "$BMSBP GOVT BRIDGE OWNER INSPECTOR",
  BMSBP_GOVT_BRIDGE_OWNER_INSPECTOR_SUPERVISOR:
    "$BMSBP GOVT BRIDGE OWNER INSPECTOR SUPERVISOR",
  BMSBP_INSPECTOR_SUPERVISOR: "$BMSBP INSPECTOR SUPERVISOR",
  BMSBP_LBI_USER: "$BMSBP LBI USER",
  BMSBP_PLAN_PARTNER: "$BMSBP PLAN PARTNER",
  BMSBP_PRIV_BRIDGE_OWNER: "$BMSBP PRIV BRIDGE OWNER",
  BMSBP_PRIV_BRIDGE_OWNER_AGENT: "$BMSBP PRIV BRIDGE OWNER AGENT",
  BMSBP_PRIV_BRIDGE_OWNER_INSPECTOR: "$BMSBP PRIV BRIDGE OWNER INSPECTOR",
  BMSBP_PRIV_BRIDGE_OWNER_INSPECTOR_SUPERVISOR:
    "$BMSBP PRIV BRIDGE OWNER INSPECTOR SUPERVISOR",
  SPNBP_EDIT: "$SPNBP EDIT",
  SPNBP_VIEW: "$SPNBP VIEW",
};
