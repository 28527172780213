import { useGtag } from "vue-gtag-next";

export const LOGGER = {
  EventCat: {
    Button: "Button",
    Interval: "Interval",
    RequestResponse: "RequestResponse",
  },
  EventAction: {
    Add: "Add",
    Navigate: "Navigate",
    Validate: "Validate",
    Submit: "Submit",
    SubmitReport: "Submit Report",
    Save: "Save",
    Search: "Search",
    ExpandCollapse: "Expand/Collapse",
    Delete: "Delete",
    Download: "Download",
    Upload: "Upload",
    Sync: "Sync",
    Update: "Update",
    OfflinePassword: "Updating Offline Password",
  },
  logEvent: function logEvent(
    eventAction,
    eventCategory,
    eventLabel,
    eventValue
  ) {
    // Google Analytics
    useGtag().event(eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
    });
  },
  logException: function logException(error, fatal) {
    // Google Analytics
    useGtag().exception({ description: error.message, error, fatal });
  },
};
