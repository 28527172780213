export const TOKEN = {
  NUMBER_COMMA_SPACE: { A: { pattern: /^[0-9,\s]/, repeated: true } },
  NUMBER: { A: { pattern: /^\d/, repeated: true } },
};
export const MASK = {
  NUMBER_COMMA_SPACE_REPEATING: {
    mask: "A",
    tokens: TOKEN.NUMBER_COMMA_SPACE,
  },
  NUMBER: { mask: "A", tokens: TOKEN.NUMBER },
  LATLONG_SECONDS: { mask: "##.##" },
};
