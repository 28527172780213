import { BUSINESS_PARTNER_ROLES, CWOPA_ROLES } from "@/rbac/roles.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_ROLES = [
  CWOPA_ROLES.SPN_APPROVE_DIST,
  CWOPA_ROLES.SPN_APPROVE_STATE,
  CWOPA_ROLES.SPN_EDIT,
  CWOPA_ROLES.SPN_VIEW,
  BUSINESS_PARTNER_ROLES.SPNBP_EDIT,
  BUSINESS_PARTNER_ROLES.SPNBP_VIEW,
];
const EDIT_ROLES = [
  CWOPA_ROLES.SPN_APPROVE_DIST,
  CWOPA_ROLES.SPN_APPROVE_STATE,
  CWOPA_ROLES.SPN_EDIT,
  BUSINESS_PARTNER_ROLES.SPNBP_EDIT,
];
const APPROVE_ROLES = [
  CWOPA_ROLES.SPN_APPROVE_DIST,
  CWOPA_ROLES.SPN_APPROVE_STATE,
];

export const STRUCTURE_PLAN_PERMITTED_ROLES = {
  [ACCESS_LEVELS.VIEW]: [...VIEW_ROLES],
  [ACCESS_LEVELS.EDIT]: [...EDIT_ROLES],
  [ACCESS_LEVELS.APPROVE]: [...APPROVE_ROLES],
};
