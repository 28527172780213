<template>
  <v-dialog v-model="localShowValue" persistent scrollable>
    <v-card>
      <v-card-title>
        <h3 class="withBackground">
          Key Field Comparison since Last Accepted Inspection
        </h3>
      </v-card-title>
      <v-card-text>
        <FieldComparison showPerformedData :moduleType="inspection">
        </FieldComparison>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="align-center">
            <v-btn variant="outlined" @click="handleSubmit('VALIDATE')">{{
              connectivity.getOnlineServiceStatus ? "Submit" : "Submit Later"
            }}</v-btn>
            <v-btn variant="outlined" @click="closePopup">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-actions></v-card
    >
  </v-dialog>
  <LoginDialog ref="loginDialogRef" @login-clicked="checkLogin" />

  <ValidationResponse
    :show="showValidation"
    :validationErrors="getErrors"
    @close="showValidation = false"
    @resubmit="handleSubmit"
    @reloadNotesComments="reloadNotesComments"
  ></ValidationResponse>
</template>
<script setup>
import { syncAllDirtyRecords } from "@/util/syncInterval";
import { ref, toRefs, watch, computed, onMounted } from "vue";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { useInspectionStore } from "@/stores/inspection";
import LoginDialog from "@/components/common/LoginDialog.vue";
import { useConnectivityStore } from "@/stores/connectivity";
import FieldComparison from "@/components/shared/FieldComparison.vue";
import ValidationResponse from "@/components/shared/ValidationResponse.vue";
import { getMessage } from "@/composables/util";
import { clone } from "@/util/clone";
import { INSPECTION_STATUSES } from "@/constants/InspectionStatuses";
import {
  DOWNLOAD_ERROR_MESSAGES,
  SUBMISSION_FAILURE,
  SUBMISSION_SUCCESS,
} from "@/constants/CommonWebConstants";
import { HTTP_STATUS_CODE } from "@/constants/CommonWebCodes";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";
import { LOGGER } from "@/util/logger";
import { useUserPermissionStore } from "@/stores/userPermission";

const props = defineProps({
  show: { default: false },
  brkey: { default: {} },
});

const emit = defineEmits(["close", "reloadNotesComments"]);

let { show } = toRefs(props);
let localShowValue = ref(show.value);

const structureIDBStore = useStructureIDBStore();
const inspectionStore = useInspectionStore();
const snackbarStore = useSnackbarStore();
const userPermissionStore = useUserPermissionStore();
let loginDialogRef = ref(null);
const connectivity = useConnectivityStore();
let showValidation = ref(false);
const structure = computed(() => inspectionStore.selectedInspection);
let submissionMessage = ref("");
let showSubmitSuccessMessageId = ref("");
let showSubmitLaterMessageId = ref("");
let warningSubmitFailMessageId = ref("");

const resetSnackBarMessage = () => {
  showSubmitSuccessMessageId.value = "";
  showSubmitLaterMessageId.value = "";
  warningSubmitFailMessageId.value = "";
};

const handleSubmit = async (submissionMessageValue) => {
  submissionMessage.value = submissionMessageValue;
  closePopup();
  if (connectivity.getOnlineServiceStatus) {
    loginDialogRef.value
      .open()
      .then((loginRequest) => checkLogin(loginRequest));
  } else {
    await userPermissionStore.updateStructurePermission(structure.value);
    //change status
    structure.value.InspEvnt.INSPSTAT = INSPECTION_STATUSES.READY_TO_SUBMIT;
    //Save the submitted structure to DB
    await structureIDBStore.saveStructure(structure.value);
    inspectionStore.setDirtyFlag(false);
    inspectionStore.setUnsavedChangesWarning(false);
    inspectionStore.setShowSubmitLaterMessage(true);
    showSubmitLaterMessageId.value = snackbarStore.showMessage({
      displayText: `The inspection has been marked '0 - Ready to Submit'. You can submit later
    from the Worklist when you are connected to the internet.`,
      timeout: 10000,
      messageType: SNACKBAR_MESSAGE_TYPES.WARNING,
      callback: resetSnackBarMessage,
    });
  }
};

const submit = async (loginRequest) => {
  try {
    inspectionStore.userAuthenticationErrors = {};
    //Sync All dirty records
    await syncAllDirtyRecords();
    //Submit local structures
    const insp = await structureIDBStore.getLatestInspectionFromIDB(
      props.brkey
    );
    let userName = window.btoa(loginRequest.userName);
    let password = window.btoa(loginRequest.password);
    //add message to the insp object for submission/validation
    let submissionPayload = clone(insp);
    submissionPayload.Users = getMessage(submissionMessage.value);
    await inspectionStore.submitInspection(
      submissionPayload,
      userName,
      password
    );
    loginDialogRef.value.loginProgressDialog = false;
    if (
      inspectionStore?.userAuthenticationErrors[HTTP_STATUS_CODE.NOT_AUTHORIZED]
    ) {
      loginDialogRef.value.setMessage(
        DOWNLOAD_ERROR_MESSAGES.INVALID_CREDENTIALS_MESSAGE
      );
    } else if (getErrors.value?.length > 0) {
      //check if any errors
      if (
        getErrors.value.includes(HTTP_STATUS_CODE.BAD_REQUEST) ||
        getErrors.value.includes(HTTP_STATUS_CODE.SERVICE_UNAVAILABLE) ||
        getErrors.value.includes(HTTP_STATUS_CODE.SERVER_ERROR) ||
        getErrors.value.includes(
          HTTP_STATUS_CODE.GATEWAY_TIMEOUT ||
            getErrors.value.includes(HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY)
        )
      ) {
        loginDialogRef.value.closeDialog();
        warningSubmitFailMessageId.value = snackbarStore.showMessage({
          displayText: SUBMISSION_FAILURE.INSPECTION_FAILURE,
          timeout: 10000,
          messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
          callback: resetSnackBarMessage,
        });
      } else {
        showValidation.value = true;
        loginDialogRef.value.closeDialog();
        insp.InspEvnt.INSPSTAT = INSPECTION_STATUSES.VALIDATION_ERROR;
        await saveToIDB(insp);
        structure.value.InspEvnt.INSPSTAT = insp.InspEvnt.INSPSTAT;
      }
    } else {
      insp.submittedTimestamp = new Date().toISOString();
      insp.InspEvnt.INSPSTAT = INSPECTION_STATUSES.SUBMITTED;
      //Save the submitted structure to DB
      await saveToIDB(insp);
      structure.value.submittedTimestamp = insp.submittedTimestamp;
      structure.value.InspEvnt.INSPSTAT = insp.InspEvnt.INSPSTAT;

      //close the login if there is no authentication error in the first record
      loginDialogRef.value?.closeDialog();
      showSubmitSuccessMessageId.value = snackbarStore.showMessage({
        displayText: SUBMISSION_SUCCESS,
        timeout: 10000,
        messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
        callback: resetSnackBarMessage,
      });
    }
  } catch (e) {
    warningSubmitFailMessageId.value = snackbarStore.showMessage({
      displayText: SUBMISSION_FAILURE.INSPECTION_FAILURE,
      timeout: 10000,
      messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
      callback: resetSnackBarMessage,
    });
    LOGGER.logException(e);
    loginDialogRef.value?.closeDialog();
  }
};

const saveToIDB = async (inspection) => {
  await userPermissionStore.updateStructurePermission(inspection);
  await structureIDBStore.saveStructure(inspection);
  inspectionStore.setDirtyFlag(false);
  inspectionStore.setUnsavedChangesWarning(false);
};

const checkLogin = async (request) => {
  await submit(request).then(() => {
    inspectionStore.setDirtyFlag(false);
    inspectionStore.setUnsavedChangesWarning(false);
  });
};

const closePopup = () => {
  localShowValue.value = false;
  emit("close");
};
const reloadNotesComments = () => {
  emit("reloadNotesComments");
};

const getErrors = computed(() => {
  return inspectionStore?.submissionErrors?.get(props.brkey);
});
onMounted(async () => {
  await structureIDBStore.initDB(false);
});

watch(show, (newValue) => {
  localShowValue.value = newValue;
});
</script>
