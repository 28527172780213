import { MODULE_TYPES } from "@/constants/InspectionConstants";

const INSPECTION_PAGE_HEADER = [
  {
    line1: "VD18",
    line2: "Opening",
    line3: "Type",
    key: "OPENING_TYPE",
    sortable: false,
    width: "14%",
  },
  {
    line1: "VD19",
    line2: "Length of",
    line3: "Culvert Barrel",
    key: "CULVERT_LENGTH_FT",
    width: "14%",
    sortable: false,
  },
  {
    line1: "VD20",
    line2: "Min Fill",
    line3: "Height",
    key: "MIN_FILL_HEIGHT_FT",
    sortable: false,
    width: "14%",
  },
  {
    line1: "VD21",
    line2: "Max Fill",
    line3: "Height",
    key: "MAX_FILL_HEIGHT_FT",
    sortable: false,
    width: "14%",
  },
  {
    line1: "VD22",
    line2: "Eff Width",
    line3: null,
    key: "EFF_WIDTH_FT",
    sortable: false,
    width: "14%",
  },
  {
    line1: "VD23",
    line2: "Tie Type",
    line3: null,
    key: "TIE_TYPE",
    sortable: false,
    width: "14%",
  },
  {
    line1: "VD24",
    line2: "Floor Type",
    line3: null,
    key: "FLOOR_TYPE",
    width: "14%",
    sortable: false,
  },
];

const MANAGEMENT_PAGE_HEADER = [
    {
        line1: "B.SP.01",
        line2: "Span Configuration",
        line3: "Designation",
        key: "spanConfigDesignation",
        sortable: false,
        width: "15%",
      },
      {
        line1: "####",
        line2: "Floor Type",
        line3: null,
        key: "culvertFloorType",
        width: "14%",
        sortable: false,
      },
      {
        line1: "####",
        line2: "Opening",
        line3: "Type",
        key: "culvertOpeningType",
        sortable: false,
        width: "14%",
      },
      {
        line1: "####",
        line2: "Length Along",
        line3: "Centerline",
        key: "culvertLengthCenterline",
        sortable: false,
        width: "14%",
      },
      {
        line1: "####",
        line2: "Min Fill",
        line3: "Height",
        key: "minFillHeight",
        sortable: false,
        width: "13%",
      },
      {
        line1: "####",
        line2: "Max Fill",
        line3: "Height",
        key: "maxFillHeight",
        sortable: false,
        width: "13%",
      },
      {
        line1: "####",
        line2: "Hydraulic Opening",
        line3: "Effective Width",
        key: "hydraulicOpeningEffWidth",
        width: "16%",
        sortable: false,
      },
];

export const CULVERT_DETAILS_TABLE_HEADER = {
  [MODULE_TYPES.INSPECTION]: INSPECTION_PAGE_HEADER,
  [MODULE_TYPES.MANAGEMENT]: MANAGEMENT_PAGE_HEADER,
};
