<template>
  <v-btn
    :class="styleClass"
    :id="`btn_${id}`"
    style="min-width: 100px"
    variant="outlined"
    :aria-describedby="`btn_${id}`"
    aria-label="ReportsButton"
    @click="
      {
        dialog = true;
      }
    "
    >Reports</v-btn
  >
  <v-dialog v-model="dialog" :width="800">
    <v-card>
      <button
        @click="hideDialog"
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon
          size="x-large"
          id="closeReportsDialog"
          icon="fas fa-xmark mx-2"
          class="no-outline"
        />
        <small>CLOSE</small>
      </button>
      <v-card-text>
        <v-row dense>
          <v-col cols="2"><v-spacer /></v-col>
          <v-col>
            <v-tooltip>
              <template v-slot:activator="{ props }">
                <v-checkbox
                  id="checkbox_report_selectall"
                  v-model="selectAllReports"
                  :label="(selectAllReports ? 'Deselect' : 'Select') + ` All`"
                  @click="handleAllReportsSelected"
                  hide-details
                  v-bind="props"
                />
              </template>
              <div class="infoDialog">
                <span class="blackText"
                  >Check this box to select all available reports for this
                  structure. Uncheck this box to de-select all the reports
                  selected.</span
                >
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col sm="5">
            <v-checkbox
              id="checkbox_report_general"
              :value="INSPECTION_PAGES.GENERAL"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.GENERAL"
              :disabled="!navigation.includes(INSPECTION_PAGES.GENERAL)"
            />
            <v-checkbox
              id="checkbox_report_schedule"
              :value="INSPECTION_PAGES.SCHEDULE"
              :disabled="!navigation.includes(INSPECTION_PAGES.RATINGS)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.SCHEDULE"
            />
            <v-checkbox
              id="checkbox_report_ratings"
              :value="INSPECTION_PAGES.RATINGS"
              :disabled="!navigation.includes(INSPECTION_PAGES.RATINGS)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.RATINGS"
            />
            <v-checkbox
              id="checkbox_report_approach"
              :value="INSPECTION_PAGES.APPROACH"
              :disabled="!navigation.includes(INSPECTION_PAGES.APPROACH)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.APPROACH"
            />
            <v-checkbox
              id="checkbox_report_deck"
              :value="INSPECTION_PAGES.DECK"
              :disabled="!navigation.includes(INSPECTION_PAGES.DECK)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.DECK"
            />
            <v-checkbox
              id="checkbox_report_joints"
              :value="INSPECTION_PAGES.JOINTS"
              :disabled="!navigation.includes(INSPECTION_PAGES.JOINTS)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.JOINTS"
            />
            <v-checkbox
              id="checkbox_report_superstructure"
              :value="INSPECTION_PAGES.SUPERSTRUCTURE"
              :disabled="!navigation.includes(INSPECTION_PAGES.SUPERSTRUCTURE)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.SUPERSTRUCTURE"
            />
            <v-checkbox
              id="checkbox_report_bearings"
              :value="INSPECTION_PAGES.BEARINGS"
              :disabled="!navigation.includes(INSPECTION_PAGES.BEARINGS)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.BEARINGS"
            />
            <v-checkbox
              id="checkbox_report_substructure"
              :value="INSPECTION_PAGES.SUBSTRUCTURE"
              :disabled="!navigation.includes(INSPECTION_PAGES.SUBSTRUCTURE)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.SUBSTRUCTURE"
            />
            <v-checkbox
              id="checkbox_report_culvert"
              :value="INSPECTION_PAGES.CULVERT"
              :disabled="!navigation.includes(INSPECTION_PAGES.CULVERT)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.CULVERT"
            />
            <v-checkbox
              id="checkbox_report_waterway"
              :value="INSPECTION_PAGES.WATERWAY"
              :disabled="!navigation.includes(INSPECTION_PAGES.WATERWAY)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.WATERWAY"
            />
          </v-col>
          <v-col sm="7">
            <v-checkbox
              id="checkbox_load_ratings"
              :value="INSPECTION_PAGES.LOAD_RATING"
              :disabled="!navigation.includes(INSPECTION_PAGES.LOAD_RATING)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.LOAD_RATING"
            />
            <v-checkbox
              id="checkbox_nstm"
              :value="INSPECTION_PAGES.NSTM_FATIGUE"
              :disabled="!navigation.includes(INSPECTION_PAGES.NSTM_FATIGUE)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.NSTM_FATIGUE"
            />
            <v-checkbox
              id="checkbox_load_elements"
              :value="INSPECTION_PAGES.ELEMENTS"
              :disabled="!navigation.includes(INSPECTION_PAGES.ELEMENTS)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.ELEMENTS"
            />
            <v-checkbox
              id="checkbox_features"
              :value="INSPECTION_PAGES.FEATURES"
              :disabled="!navigation.includes(INSPECTION_PAGES.FEATURES)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.FEATURES"
            />
            <v-checkbox
              id="checkbox_load_maintenance"
              :value="INSPECTION_PAGES.MAINTENANCE_ITEMS"
              :disabled="
                !navigation.includes(INSPECTION_PAGES.MAINTENANCE_ITEMS)
              "
              v-model="selectedReports"
              @update:modelValue="clickMaintenance()"
              :label="INSPECTION_PAGES.MAINTENANCE_ITEMS"
              class="spacing-fixes"
            />
            <v-checkbox
              id="checkbox_load_maintenance_include"
              :value="INSPECTION_PAGES.MAINTENANCE_INCLUDE"
              :disabled="
                !navigation.includes(INSPECTION_PAGES.MAINTENANCE_ITEMS)
              "
              v-model="selectedReports"
              @update:modelValue="clickIncludeMaintenance()"
              :label="INSPECTION_PAGES.MAINTENANCE_INCLUDE"
              class="small ml-6"
            />
            <v-checkbox
              id="checkbox_notes"
              :value="INSPECTION_PAGES.NOTES"
              :disabled="!navigation.includes(INSPECTION_PAGES.NOTES)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.NOTES"
            />
            <v-checkbox
              id="checkbox_signs_lights"
              :value="INSPECTION_PAGES.SIGNS_AND_LIGHTS"
              :disabled="
                !navigation.includes(INSPECTION_PAGES.SIGNS_AND_LIGHTS)
              "
              v-model="selectedReports"
              :label="INSPECTION_PAGES.SIGNS_AND_LIGHTS"
            />
            <v-checkbox
              id="checkbox_walls"
              :value="INSPECTION_PAGES.WALLS"
              :disabled="!navigation.includes(INSPECTION_PAGES.WALLS)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.WALLS"
            />
            <v-checkbox
              id="checkbox_tunnels"
              :value="INSPECTION_PAGES.TUNNELS"
              :disabled="!navigation.includes(INSPECTION_PAGES.TUNNELS)"
              v-model="selectedReports"
              :label="INSPECTION_PAGES.TUNNELS"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          variant="outlined"
          :disabled="
            selectedReports.length == 0 || inspectionStore.getDirtyFlag
          "
          @click="createPdf"
        >
          Create PDF Report
        </v-btn>
        <v-btn
          variant="outlined"
          @click="openFileReader"
          :disabled="isSubmitDisabled()"
          >Submit PDF Report</v-btn
        >
        <v-btn
          variant="outlined"
          :disabled="
            selectedReports.length == 0 ||
            isSubmitDisabled() ||
            inspectionStore.getDirtyFlag
          "
          @click="getPdfS"
          >Create and Submit PDF Report</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <input
    type="file"
    aria-describedby="file_reportFile"
    aria-label="reportFileInput"
    ref="doc"
    @change="getPdf"
    style="display: none"
    accept=".pdf"
    id="file_reportFile"
  />
</template>
<script setup>
import { ref, computed, watch } from "vue";
import {
  INSPECTION_PAGES,
  DISABLE_REPORT_SUBMIT,
} from "@/constants/Inspections";
import { generateReport } from "@/reports/GenerateReport";
import { useInspectionStore } from "@/stores/inspection";
import { useConnectivityStore } from "@/stores/connectivity";
import { HTTP_STATUS_CODE } from "@/constants/CommonWebCodes";
import {
  SUBMISSION_FAILURE,
  UPLOAD_SUCCESS,
  CREATE_SUCCESS,
  CREATE_AND_UPLOAD_SUCCESS,
} from "@/constants/CommonWebConstants";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

const connectivity = useConnectivityStore();
const inspectionStore = useInspectionStore();
const snackbarStore = useSnackbarStore();
const structure = computed(() => inspectionStore.selectedInspection);
let navigation = ref([]);
let doc = ref(null);
function setNavigation() {
  navigation.value = inspectionStore.getNavigation;
}
setNavigation();
defineProps({
  styleClass: { default: "" },
  id: { default: "" },
});
let selectedReports = ref([]);
let dialog = ref(false);
let file = computed(() => inspectionStore.reportToSubmit);
let justCreate = ref(false);
let justSubmit = ref(false);
let createAndSubmit = ref(false);
let selectAllReports = ref(false);
let snackBarId = ref("");
let warningSubmitPdfFailId = ref("");

const setSnackBarMessage = () => {
  let message = "";
  if (justSubmit.value) {
    message = UPLOAD_SUCCESS;
  }
  if (justCreate.value) {
    message = CREATE_SUCCESS;
  }
  if (createAndSubmit.value) {
    message = CREATE_AND_UPLOAD_SUCCESS;
  }
  snackBarId.value = snackbarStore.showMessage({
    displayText: message,
    timeout: 10000,
    messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
  });
};
const handleAllReportsSelected = () => {
  if (!selectAllReports.value) {
    navigation.value.forEach((nav) => {
      if (nav != INSPECTION_PAGES.EDOCS) selectedReports.value.push(nav);
    });
  } else {
    selectedReports.value = [];
  }
  clickMaintenance();
};
const createPdf = () => {
  resetShowSubmitSuccessMessage();
  if (selectedReports.value?.length > 1) {
    //sort the selected reports so the generated pdf will be in navigation order.
    selectedReports.value.sort(
      (a, b) => navigation.value.indexOf(a) - navigation.value.indexOf(b)
    );
  }
  justCreate.value = true;
  generateReport(selectedReports.value, justCreate.value);
  selectedReports.value = [];
  selectAllReports.value = false;
  setSnackBarMessage();
};
const getPdf = async (event) => {
  resetShowSubmitSuccessMessage();
  if (event.target.files.length > 0) {
    justSubmit.value = true;
    inspectionStore.setReportToSubmit(event.target.files[0]);
    doc.value.value = null;
    await submitReport();
  }
};
const getPdfS = async () => {
  resetShowSubmitSuccessMessage();
  if (selectedReports.value?.length > 1) {
    //sort the selected reports so the generated pdf will be in navigation order.
    selectedReports.value.sort(
      (a, b) => navigation.value.indexOf(a) - navigation.value.indexOf(b)
    );
  }
  createAndSubmit.value = true;
  generateReport(selectedReports.value, justCreate.value);
  selectAllReports.value = false;
  selectedReports.value = [];
  await submitReport();
};
const openFileReader = () => {
  resetShowSubmitSuccessMessage();
  doc.value.click();
};
const submitReport = async () => {
  inspectionStore.submissionErrors.set(structure.value.Bridge.BRKEY, []);
  await inspectionStore.submitReport(structure.value, file.value);
  finishSubmission();
};
const finishSubmission = () => {
  doc.value = null;
  inspectionStore.setReportToSubmit(null);
  const submissionErrors = inspectionStore.submissionErrors?.get(
    structure.value.Bridge.BRKEY
  );
  hideDialog();
  if (
    submissionErrors.includes(HTTP_STATUS_CODE.NOT_FOUND) ||
    submissionErrors.includes(HTTP_STATUS_CODE.NOT_AUTHORIZED) ||
    submissionErrors.includes(HTTP_STATUS_CODE.BAD_REQUEST) ||
    submissionErrors.includes(HTTP_STATUS_CODE.SERVICE_UNAVAILABLE) ||
    submissionErrors.includes(HTTP_STATUS_CODE.SERVER_ERROR) ||
    submissionErrors.includes(HTTP_STATUS_CODE.GATEWAY_TIMEOUT) ||
    submissionErrors.includes(HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY)
  ) {
    warningSubmitPdfFailId.value = snackbarStore.showMessage({
    displayText: SUBMISSION_FAILURE.PDF_FAILURE,
    timeout: 10000,
    messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
  });
  } else {
    setSnackBarMessage();
  }
};
const resetShowSubmitSuccessMessage = () => {
  createAndSubmit.value = false;
  justCreate.value = false;
  justSubmit.value = false;
  snackbarStore.removeMessage(snackBarId.value);
};
const clickIncludeMaintenance = () => {
  const index = selectedReports.value.findIndex((a) => {
    return a == INSPECTION_PAGES.MAINTENANCE_ITEMS;
  });
  if (index == -1) {
    selectedReports.value.push(INSPECTION_PAGES.MAINTENANCE_ITEMS);
  }
};
const clickMaintenance = () => {
  const indexMaintenance = selectedReports.value.findIndex((a) => {
    return a == INSPECTION_PAGES.MAINTENANCE_ITEMS;
  });
  const indexInclude = selectedReports.value.findIndex((a) => {
    return a == INSPECTION_PAGES.MAINTENANCE_INCLUDE;
  });
  if (indexMaintenance != -1 && indexInclude == -1) {
    selectedReports.value.push(INSPECTION_PAGES.MAINTENANCE_INCLUDE);
  }
  if (indexMaintenance == -1 && indexInclude != -1) {
    selectedReports.value.splice(indexInclude, 1);
  }
};
const hideDialog = () => {
  selectedReports.value = [];
  dialog.value = false;
};
const isSubmitDisabled = () => {
  return (
    !connectivity.getOnlineServiceStatus ||
    DISABLE_REPORT_SUBMIT.includes(structure.value.InspEvnt.INSPSTAT)
  );
};
//watch changes in selectedReportss
watch(
  () => selectedReports.value,
  (selected) => {
    if (selected.length === 0) {
      selectAllReports.value = false;
    }
  }
);
</script>
<style scoped lang="scss">
.small :deep(.v-label) {
  font-size: 80% !important;
  padding-top: 5px !important;
}

.spacing-fixes :deep(.v-input__details) {
  display: none !important;
}
</style>
