<template>
  <v-container>
    <h3
      class="withBlueBackground d-flex justify-space-between align-center px-2 py-1"
    >
      <span class="header-text"
        ><v-icon
          icon="fa-solid fa-right-left"
          class="non-clickable-icon icon-adjust mx-3"
        ></v-icon
        >Microservice Status</span
      >
      <div class="header-actions custom-padding">
        <IconTooltip
          :icon="isRefreshing ? 'fa-solid fa-spinner' : 'fa-solid fa-refresh'"
          tooltip="Refresh Services"
          class="custom-padding"
          @click="refreshData"
          aria-label="Refresh Services"
        />
        <IconTooltip
          icon="fa-solid fa-trash"
          tooltip="Clear All Caches"
          class="custom-padding"
          @click="showClearAllConfirm"
          aria-label="Clear All Caches"
        />
      </div>
    </h3>
    <v-data-table :headers="headers" :items="services" class="elevation-1">
      <!-- Status Column-->
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="item.status === 'Active' ? 'green' : 'red'"
          theme="light"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <!-- Cache Column-->
      <template v-slot:[`item.cache`]="{ item }">
        <!-- Only show these buttons if the item status is 'Active' -->
        <div v-if="item.status === 'Active'">
          <IconTooltip
            class="mx-2"
            @click="openDialog(item)"
            :id="`search-icon-tooltip-${item.service.replace(/\s+/g, '-')}`"
            icon="fa-solid fa-search"
            tooltip="View Cache Details"
          />
          <IconTooltip
            icon="fa-solid fa-trash"
            tooltip="Clear Cache"
            class="mx-2"
            @click="confirmDeleteItem(item)"
            aria-label="Clear Cache"
          />
        </div>
      </template>
    </v-data-table>

    <!--Magnifier dialog-->
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <div class="dialog-header">
          <v-card-title class="text-h5">{{
            selectedItem?.service
          }}</v-card-title>
          <div class="close-container" @click="closeDialog">
            <IconTooltip icon="fa-solid fa-times" tooltip="Close" />
            <span class="close-text">CLOSE</span>
          </div>
        </div>
        <v-container class="custom-padding">
          <v-data-table
            :headers="caches"
            :items="tempCaches"
            class="elevation-1"
          >
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Confirmation dialog for deletion -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Confirm Deletion</v-card-title>
        <div class="close-container" @click="deleteDialog = false">
          <IconTooltip icon="fa-solid fa-times" tooltip="Close" />
          <span class="close-text">CLOSE</span>
        </div>
        <v-card-text
          >Are you sure you want to clear the cache for service "{{
            selectedItemToDelete?.service
          }}"?</v-card-text
        >
        <v-card-actions class="justify-center">
          <!-- Use updated color and variant prop for buttons -->
          <v-spacer></v-spacer>
          <v-btn variant="outlined" @click="confirmDeletion">Yes</v-btn>
          <v-btn variant="outlined" @click="deleteDialog = false">No</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirmation dialog for deletion of all services -->
    <v-dialog v-model="clearAllDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Confirm Clear All</v-card-title>
        <div class="close-container" @click="clearAllDialog = false">
          <IconTooltip icon="fa-solid fa-times" tooltip="Close" />
          <span class="close-text">CLOSE</span>
        </div>
        <v-card-text
          >Are you sure you want to delete all the service caches?</v-card-text
        >
        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>
          <v-btn variant="outlined" @click="confirmClearAll">Yes</v-btn>
          <v-btn variant="outlined" @click="clearAllDialog = false">No</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import { SERVICES } from "@/constants/Endpoints";
import IconTooltip from "@/components/common/IconTooltip.vue";

export default {
  setup() {
    // Array of endpoints
    const endpoints = ref([]);

    // Service headers
    const headers = ref([
      { title: "Service", align: "center", key: "service", sortable: true },
      { title: "Status", align: "center", key: "status", sortable: true },
      {
        title: "Build Version",
        align: "center",
        key: "buildVersion",
        sortable: true,
      },
      {
        title: "Build Time",
        align: "center",
        key: "buildTime",
        sortable: true,
      },
      { title: "Cache", align: "center", key: "cache", sortable: false },
    ]);

    // Cache header
    const caches = ref([
      { title: "Name", align: "center", key: "name" },
      { title: "Level", align: "center", key: "level" },
      { title: "Type", align: "center", key: "type" },
      { title: "Size", align: "center", key: "size" },
      { title: "Hit Count", align: "center", key: "hitCount" },
    ]);

    // Printed services
    const services = ref([]);

    // Printed caches
    const tempCaches = ref([]);

    // Fetch data from API
    const fetchData = async (endpoint, position = null) => {
      let serviceStatus = "Inactive";
      let buildVersion = "Unknown";
      let buildTime = "Unknown";

      try {
        // Try to get service health
        const healthResponse = await axios.get(endpoint.PING_ACTUATOR_HEALTH);
        if (healthResponse.data.status === "UP") {
          serviceStatus = "Active";
          // Only call the info endpoint if the health check is up
          const infoResponse = await axios.get(endpoint.PING_ACTUATOR_INFO);
          buildVersion = infoResponse.data.build.version;
          buildTime = new Date(infoResponse.data.build.time).toLocaleString();
        }
      } catch (healthError) {
        // Test error: console.error(`Error fetching health for endpoint ${endpoint.PING_ACTUATOR_HEALTH}:`, healthError);
      }

      // Construct the service object
      const tempService = {
        service: endpoint.NAME,
        status: serviceStatus,
        buildVersion: buildVersion,
        buildTime: buildTime,
        cache: "Enabled", // Assuming cache is enabled by default
        endpoint: endpoint,
      };

      // Insert the service object at the specified position or push if position is out of bounds or not provided regardless of health check
      if (
        typeof position === "number" &&
        position >= 0 &&
        position < services.value.length
      ) {
        services.value.splice(position, 0, tempService);
      } else {
        services.value.push(tempService);
      }
    };

    // Loop through the SERVICES object and create endpoint objects
    const populateEndpoints = async () => {
      for (const key in SERVICES) {
        // Assuming SERVICES[key] is a string representing the base URL of the service
        const serviceUrl = SERVICES[key];

        // Check if serviceUrl is a string
        if (typeof serviceUrl === "string") {
          // Derive the endpoint name from the serviceUrl
          const serviceName = serviceUrl.substring(
            serviceUrl.lastIndexOf("/") + 1
          );

          const endpoint = {
            CACHES: `${serviceUrl}/caches`,
            PING_ACTUATOR_HEALTH: `${serviceUrl}/actuator/health`,
            PING_ACTUATOR_INFO: `${serviceUrl}/actuator/info`,
            NAME: serviceName,
          };

          endpoints.value.push(endpoint);
        } else {
          // Handle case where serviceUrl is not a string
          console.error(`Invalid service URL for key ${key}:`, serviceUrl);
        }
      }
    };

    // Fetch data when component is mounted
    onMounted(() => {
      populateEndpoints();
      refreshData();
    });

    // Track if refresh is in progress
    const isRefreshing = ref(false);

    const refreshData = async () => {
      if (!isRefreshing.value) {
        services.value = [];
        isRefreshing.value = true; // Indicate that refreshing has started

        // Map each endpoint to a fetchData call that returns a Promise
        const fetchDataPromises = endpoints.value.map((endpoint) =>
          fetchData(endpoint)
        );

        try {
          // Wait for all fetchData calls to complete
          await Promise.all(fetchDataPromises);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          isRefreshing.value = false; // Indicate that refreshing has finished
        }
      }
    };

    // Dialog box switches
    const dialog = ref(false);
    const selectedItem = ref(null);

    const openDialog = async (item) => {
      try {
        // Reset cache list
        tempCaches.value = [];

        // caches GET
        const cacheResponse = await axios.get(item.endpoint.CACHES);

        // Loop through all caches
        for (const cache of cacheResponse.data) {
          const temp = {
            name: cache.cacheName,
            level: cache.cacheLevel,
            type: cache.cacheType,
            size: cache.size,
            hitCount: cache.hitCount,
          };

          // Add to cache list
          tempCaches.value.push(temp);
        }

        selectedItem.value = item;
        dialog.value = true;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const closeDialog = () => {
      dialog.value = false;
      selectedItem.value = null;
    };

    // Delete individual caches
    const deleteItem = async (item) => {
      if (item.status === "Active") {
        try {
          for (let i = 0; i < 2; i++) {
            await axios.delete(item.endpoint.CACHES);
          }
          services.value = services.value.filter((service) => service !== item);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    // Delete confirmation dialog
    const deleteDialog = ref(false);
    const selectedItemToDelete = ref(null);

    // Open delete confirmation dialog
    const confirmDeleteItem = (item) => {
      selectedItemToDelete.value = item;
      deleteDialog.value = true;
    };

    // Delete the item after confirmation
    const confirmDeletion = async () => {
      if (selectedItemToDelete.value) {
        try {
          await axios.delete(selectedItemToDelete.value.endpoint.CACHES);

          // Find the location of selectedItemToDelete in services array
          const location = services.value.findIndex(
            (service) =>
              service.endpoint.NAME === selectedItemToDelete.value.endpoint.NAME
          );

          // Delete selectedItemToDelete
          services.value = services.value.filter(
            (service) => service !== selectedItemToDelete.value
          );

          // Readd selectedItemToDelete to the same location as before
          fetchData(selectedItemToDelete.value.endpoint, location);
        } catch (error) {
          console.error("Error deleting data:", error);
        }
      }
      deleteDialog.value = false;
      selectedItemToDelete.value = null;
    };

    // Method to delete all services
    const deleteAllServices = async () => {
      try {
        // Map each deleteItem call to a promise and wait for all of them to complete
        const deletePromises = services.value.map((service) =>
          deleteItem(service)
        );
        await Promise.all(deletePromises);

        // After all delete operations, clear the services array
        services.value = [];

        refreshData();
      } catch (error) {
        console.error("Error deleting all services:", error);
      }
    };

    // Clear all confirmation dialog
    const clearAllDialog = ref(false);

    // Method to confirm clear all action
    const confirmClearAll = async () => {
      clearAllDialog.value = false;
      deleteAllServices();
    };

    // Method to open clear all confirmation dialog
    const showClearAllConfirm = () => {
      clearAllDialog.value = true;
    };

    return {
      headers,
      services,
      caches,
      tempCaches,
      refreshData,
      dialog,
      selectedItem,
      openDialog,
      closeDialog,
      deleteItem,
      isRefreshing,
      deleteDialog,
      selectedItemToDelete,
      confirmDeleteItem,
      confirmDeletion,
      deleteAllServices,
      clearAllDialog,
      confirmClearAll,
      showClearAllConfirm,
      populateEndpoints,
    };
  },
  components: { IconTooltip },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;

.blackText {
  color: c.$p-black;
}

.infoDialog {
  background-color: c.$p-white;
  width: 350px;
}

td {
  white-space: nowrap;
}

.fill-height {
  height: 600px;
}

.iconAlign {
  margin-left: -3em;
}

// X-Mark with the CLOSE text
.dialog-header {
  position: relative;
}

.close-container {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dialog-close-icon {
  transform: scale(1.3);
  cursor: pointer;
}

.close-text {
  margin-top: 4px;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  text-indent: 10px;
  user-select: none;
}

.withBlueBackground {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-actions {
  display: flex;
  align-items: center;
}

// Make the services icon not clickable and move it upwards a few pixels
.non-clickable-icon {
  pointer-events: none;
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
}

.icon-adjust {
  margin-top: -5px;
}

.custom-padding {
  padding: 10px;
}
</style>
