<template>
  <v-expansion-panels v-model="panel" multiple color="#fff">
    <v-expansion-panel>
      <v-expansion-panel-title
        class="d-flex justify-start"
        hide-actions
        expand-icon="fa-solid fa-plus"
        collapse-icon="fa-solid fa-minus"
      >
        <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
        <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
        <h3>{{ abutmentType }} Abutment</h3>
      </v-expansion-panel-title>
      <v-expansion-panel-text v-if="!substructureUnit">
        <v-row align="center">
          <v-col>
            <v-label class="noRecordsMessage d-flex justify-center"
              >This structure currently contains no {{ abutmentType }} Abutment.
            </v-label>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
      <v-expansion-panel-text v-if="substructureUnit">
        <v-expansion-panels v-model="subPanel" multiple color="#fff">
          <v-expansion-panel v-if="substructureUnit">
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon v-if="!subPanel.includes(0)" icon="fa-solid fa-plus" />
              <v-icon v-if="subPanel.includes(0)" icon="fa-solid fa-minus" />
              <h3>{{ abutmentType }} Abutment Inspection Notes</h3>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    id="btn_copyCurrentToTarget"
                    variant="outlined"
                    class="my-2"
                    :disabled="!isEditable"
                    @click="copyCurrentToTarget"
                    >Copy Abutment Notes</v-btn
                  >
                </v-col>
              </v-row>
              <StructureUnitCommentTypeTextField
                :id="`textarea_backwall_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.BACKWALL"
                :readOnly="!isEditable"
                label="Backwall"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                :id="`textarea_bridge_seats_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.BRIDGE_SEATS"
                :readOnly="!isEditable"
                label="Bridge Seats"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                :id="`textarea_cheekwalls_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.CHEEKWALLS"
                :readOnly="!isEditable"
                label="Cheekwalls"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                :id="`textarea_stem_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.STEM"
                :readOnly="!isEditable"
                label="Stem"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                :id="`textarea_wings_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.WINGS"
                :readOnly="!isEditable"
                label="Wings"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                :id="`textarea_footing_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.FOOTING"
                :readOnly="!isEditable"
                label="Footing"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                :id="`textarea_piles_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.PILES"
                :readOnly="!isEditable"
                label="Piles"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                :id="`textarea_settlement_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.SETTLEMENT"
                :readOnly="!isEditable"
                label="Settlement"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                :id="`textarea_embank_slope_wall_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.EMBANK_SLOPE_WALL"
                :readOnly="!isEditable"
                label="Embank Slope-Wall"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
              <StructureUnitCommentTypeTextField
                :id="`textarea_wall_drainage_${abutmentType}`"
                :inspectionCommentType="INSPECTION_COMMENTS.WALL_DRAINAGE"
                :readOnly="!isEditable"
                label="Wall Drainage"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :structureUnitKey="substructureUnit.STRUNITKEY"
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel v-if="substructureUnit.IN_500YR_FP == '1'">
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon v-if="!subPanel.includes(1)" icon="fa-solid fa-plus" />
              <v-icon v-if="subPanel.includes(1)" icon="fa-solid fa-minus" />
              <h3>{{ abutmentType }} Abutment – Waterway</h3>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <UnderwaterInspection
                ref="underwaterInsp"
                :id="`abutment_${substructureUnit?.STRUNITKEY}_Underwater`"
                :subStructure="substructureUnit"
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel v-if="substructureUnit">
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon
                v-if="!subPanel.includes(subPanelValue)"
                icon="fa-solid fa-plus"
              />
              <v-icon
                v-if="subPanel.includes(subPanelValue)"
                icon="fa-solid fa-minus"
              />
              <h3>{{ abutmentType }} Abutment – NSTM/Fatigue</h3>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <NSTMFatigueTable
                :id="`Abutment_NSTMFatigue_Table_${abutmentType}`"
                substructureFatigueTable
                :unitKey="substructureUnit.STRUNITKEY"
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { computed, ref, watch } from "vue";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import UnderwaterInspection from "@/components/inspection/substructureComponents/UnderwaterInspection.vue";
import {
  INSPECTION_COMMENTS,
  SUB_STRUCTURE_UNIT_KEY,
} from "@/constants/Inspections";
import StructureUnitCommentTypeTextField from "@/components/common/StructureUnitCommentTypeTextField.vue";
import NSTMFatigueTable from "@/components/shared/NSTMFatigueTable.vue";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "AbutmentComponent",
  props: {
    id: {},
    abutmentType: { default: "" },
    unitKey: { default: "" },
    isExpanded: { default: true },
  },
  setup(props) {
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    const snackbarStore = useSnackbarStore();
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });
    let structure = computed(() => inspectionStore.selectedInspection);
    const subUnitLabel = computed(() => {
      if (props.abutmentType == "Far") {
        return SUB_STRUCTURE_UNIT_KEY.FAR_ABUTMENT;
      } else {
        return SUB_STRUCTURE_UNIT_KEY.NEAR_ABUTMENT;
      }
    });
    const substructureUnit = computed({
      get() {
        return structure.value?.Structure_Unit?.find(
          (elementToFind) => elementToFind?.STRUNITKEY == props?.unitKey
        );
      },
    });
    let underwaterInsp = ref(null);
    let subPanelValue = ref(substructureUnit.value?.IN_500YR_FP == "1" ? 2 : 1);
    async function validate() {
      if (substructureUnit.value.IN_500YR_FP == "1") {
        await underwaterInsp.value.validate();
        return underwaterInsp.value.valid;
      } else {
        return true;
      }
    }
    let snackBarId = ref("");
    let snackBarIdenticalId = ref("");

    const panel = ref([0]);
    const subPanel = ref([0, 1, 2]);
    if (!substructureUnit.value) {
      panel.value = [];
      subPanel.value = [];
    }

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    const currentDate = new Date();

    function onHideSnackbar() {
      snackbarStore.removeMessage(snackBarId.value);
      snackbarStore.removeMessage(snackBarIdenticalId.value);
    }

    function copyCurrentToTarget() {
      let editOccurred = false;
      let targetKey;
      if (subUnitLabel.value == SUB_STRUCTURE_UNIT_KEY.NEAR_ABUTMENT) {
        targetKey = structure.value?.Structure_Unit?.find(
          (elementToFind) => elementToFind?.STRUNITLABEL == "FAB"
        ).STRUNITKEY;
      } else {
        targetKey = structure.value?.Structure_Unit?.find(
          (elementToFind) => elementToFind?.STRUNITLABEL == "NAB"
        ).STRUNITKEY;
      }
      let currentBackWallIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.BACKWALL
        );
      let targetBackWallIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.BACKWALL
        );
      if (
        currentBackWallIndex != -1 &&
        targetBackWallIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetBackWallIndex].NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentBackWallIndex].NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[targetBackWallIndex].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[currentBackWallIndex].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[targetBackWallIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetBackWallIndex == -1 &&
        currentBackWallIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.BACKWALL,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentBackWallIndex]
              .NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (targetBackWallIndex != -1 && currentBackWallIndex == -1) {
        structure.value.T_Struc_Unit_Insp_Comment[targetBackWallIndex].NOTES =
          "";
        structure.value.T_Struc_Unit_Insp_Comment[targetBackWallIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      }

      let currentBridgeSeatsIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.BRIDGE_SEATS
        );
      let targetBridgeSeatsIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.BRIDGE_SEATS
        );
      if (
        currentBridgeSeatsIndex != -1 &&
        targetBridgeSeatsIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetBridgeSeatsIndex]
          .NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentBridgeSeatsIndex]
            .NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[
          targetBridgeSeatsIndex
        ].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[
            currentBridgeSeatsIndex
          ].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[
          targetBridgeSeatsIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetBridgeSeatsIndex == -1 &&
        currentBridgeSeatsIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.BRIDGE_SEATS,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentBridgeSeatsIndex]
              .NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (
        targetBridgeSeatsIndex != -1 &&
        currentBridgeSeatsIndex == -1
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[
          targetBridgeSeatsIndex
        ].NOTES = "";
        structure.value.T_Struc_Unit_Insp_Comment[
          targetBridgeSeatsIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      }

      let currentCheekwallsIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CHEEKWALLS
        );
      let targetCheekwallsIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CHEEKWALLS
        );
      if (
        currentCheekwallsIndex != -1 &&
        targetCheekwallsIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetCheekwallsIndex]
          .NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentCheekwallsIndex]
            .NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[targetCheekwallsIndex].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[
            currentCheekwallsIndex
          ].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[
          targetCheekwallsIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetCheekwallsIndex == -1 &&
        currentCheekwallsIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.CHEEKWALLS,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentCheekwallsIndex]
              .NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (targetCheekwallsIndex != -1 && currentCheekwallsIndex == -1) {
        structure.value.T_Struc_Unit_Insp_Comment[targetCheekwallsIndex].NOTES =
          "";
        structure.value.T_Struc_Unit_Insp_Comment[
          targetCheekwallsIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      }

      let currentStemIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.STEM
        );
      let targetStemIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.STEM
        );
      if (
        currentStemIndex != -1 &&
        targetStemIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetStemIndex].NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentStemIndex].NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[targetStemIndex].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[currentStemIndex].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[targetStemIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      } else if (targetStemIndex == -1 && currentStemIndex != -1 && targetKey) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.STEM,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentStemIndex].NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (targetStemIndex != -1 && currentStemIndex == -1) {
        structure.value.T_Struc_Unit_Insp_Comment[targetStemIndex].NOTES = "";
        structure.value.T_Struc_Unit_Insp_Comment[targetStemIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      }

      let currentWingsIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WINGS
        );
      let targetWingsIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WINGS
        );
      if (
        currentWingsIndex != -1 &&
        targetWingsIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetWingsIndex].NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentWingsIndex].NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[targetWingsIndex].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[currentWingsIndex].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[targetWingsIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetWingsIndex == -1 &&
        currentWingsIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.WINGS,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentWingsIndex].NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (targetWingsIndex != -1 && currentWingsIndex == -1) {
        structure.value.T_Struc_Unit_Insp_Comment[targetWingsIndex].NOTES = "";
        structure.value.T_Struc_Unit_Insp_Comment[targetWingsIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      }

      let currentFootingIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.FOOTING
        );
      let targetFootingIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.FOOTING
        );
      if (
        currentFootingIndex != -1 &&
        targetFootingIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetFootingIndex].NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentFootingIndex].NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[targetFootingIndex].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[currentFootingIndex].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[targetFootingIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetFootingIndex == -1 &&
        currentFootingIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.FOOTING,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentFootingIndex]
              .NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (targetFootingIndex != -1 && currentFootingIndex == -1) {
        structure.value.T_Struc_Unit_Insp_Comment[targetFootingIndex].NOTES =
          "";
        structure.value.T_Struc_Unit_Insp_Comment[targetFootingIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      }

      let currentPilesIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.PILES
        );
      let targetPilesIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.PILES
        );
      if (
        currentPilesIndex != -1 &&
        targetPilesIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetPilesIndex].NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentPilesIndex].NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[targetPilesIndex].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[currentPilesIndex].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[targetPilesIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetPilesIndex == -1 &&
        currentPilesIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.PILES,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentPilesIndex].NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (targetPilesIndex != -1 && currentPilesIndex == -1) {
        structure.value.T_Struc_Unit_Insp_Comment[targetPilesIndex].NOTES = "";
        structure.value.T_Struc_Unit_Insp_Comment[targetPilesIndex].MODTIME =
          currentDate.toISOString();
        editOccurred = true;
      }

      let currentSettlementIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SETTLEMENT
        );
      let targetSettlementIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SETTLEMENT
        );
      if (
        currentSettlementIndex != -1 &&
        targetSettlementIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetSettlementIndex]
          .NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentSettlementIndex]
            .NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[targetSettlementIndex].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[
            currentSettlementIndex
          ].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[
          targetSettlementIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetSettlementIndex == -1 &&
        currentSettlementIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.SETTLEMENT,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentSettlementIndex]
              .NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (targetSettlementIndex != -1 && currentSettlementIndex == -1) {
        structure.value.T_Struc_Unit_Insp_Comment[targetSettlementIndex].NOTES =
          "";
        structure.value.T_Struc_Unit_Insp_Comment[
          targetSettlementIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      }

      let currentEmbankSlopeWallIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.EMBANK_SLOPE_WALL
        );
      let targetEmbankSlopeWallIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.EMBANK_SLOPE_WALL
        );
      if (
        currentEmbankSlopeWallIndex != -1 &&
        targetEmbankSlopeWallIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetEmbankSlopeWallIndex]
          .NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentEmbankSlopeWallIndex]
            .NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[
          targetEmbankSlopeWallIndex
        ].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[
            currentEmbankSlopeWallIndex
          ].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[
          targetEmbankSlopeWallIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetEmbankSlopeWallIndex == -1 &&
        currentEmbankSlopeWallIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.EMBANK_SLOPE_WALL,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[
              currentEmbankSlopeWallIndex
            ].NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (
        targetEmbankSlopeWallIndex != -1 &&
        currentEmbankSlopeWallIndex == -1
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[
          targetEmbankSlopeWallIndex
        ].NOTES = "";
        structure.value.T_Struc_Unit_Insp_Comment[
          targetEmbankSlopeWallIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      }

      let currentWallDrainageIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL_DRAINAGE
        );
      let targetWallDrainageIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL_DRAINAGE
        );
      if (
        currentWallDrainageIndex != -1 &&
        targetWallDrainageIndex != -1 &&
        structure.value.T_Struc_Unit_Insp_Comment[targetWallDrainageIndex]
          .NOTES !=
          structure.value.T_Struc_Unit_Insp_Comment[currentWallDrainageIndex]
            .NOTES
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[
          targetWallDrainageIndex
        ].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[
            currentWallDrainageIndex
          ].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[
          targetWallDrainageIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetWallDrainageIndex == -1 &&
        currentWallDrainageIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.WALL_DRAINAGE,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentWallDrainageIndex]
              .NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (
        targetWallDrainageIndex != -1 &&
        currentWallDrainageIndex == -1
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[
          targetWallDrainageIndex
        ].NOTES = "";
        structure.value.T_Struc_Unit_Insp_Comment[
          targetWallDrainageIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      }

      let currentCombustibleIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == substructureUnit.value.STRUNITKEY &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.COMBUSTIBLE_MATERIAL
        );
      let targetCombustibleIndex =
        structure.value?.T_Struc_Unit_Insp_Comment.findIndex(
          (element) =>
            element?.STRUNITKEY == targetKey &&
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.COMBUSTIBLE_MATERIAL
        );
      if (currentCombustibleIndex != -1 && targetCombustibleIndex != -1) {
        structure.value.T_Struc_Unit_Insp_Comment[
          targetCombustibleIndex
        ].NOTES =
          structure.value.T_Struc_Unit_Insp_Comment[
            currentCombustibleIndex
          ].NOTES;
        structure.value.T_Struc_Unit_Insp_Comment[
          targetCombustibleIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      } else if (
        targetCombustibleIndex == -1 &&
        currentCombustibleIndex != -1 &&
        targetKey
      ) {
        structure.value.T_Struc_Unit_Insp_Comment.push({
          BRKEY: structure.value.Bridge.BRKEY,
          COMMENT_TYPE: INSPECTION_COMMENTS.COMBUSTIBLE_MATERIAL,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          MODTIME: currentDate.toISOString(),
          NOTES:
            structure.value.T_Struc_Unit_Insp_Comment[currentCombustibleIndex]
              .NOTES,
          STRUNITKEY: targetKey,
          USERKEY: structure.value.InspEvnt.USERKEY,
        });
        editOccurred = true;
      } else if (
        targetCombustibleIndex != -1 &&
        currentCombustibleIndex == -1
      ) {
        structure.value.T_Struc_Unit_Insp_Comment[
          targetCombustibleIndex
        ].NOTES = "";
        structure.value.T_Struc_Unit_Insp_Comment[
          targetCombustibleIndex
        ].MODTIME = currentDate.toISOString();
        editOccurred = true;
      }
      if (editOccurred) {
        if (subUnitLabel.value == SUB_STRUCTURE_UNIT_KEY.FAR_ABUTMENT)
          snackBarId.value = snackbarStore.showMessage({
            displayText:
              "Abutment Notes copied from Far Abutment to Near Abutment. You must save to keep the changes.",
            timeout: 3000,
            callback: onHideSnackbar,
          });
        else {
          snackBarId.value = snackbarStore.showMessage({
            displayText:
              "Abutment Notes copied from Near Abutment to Far Abutment. You must save to keep the changes.",
            timeout: 3000,
            callback: onHideSnackbar,
          });
        }
      } else {
        snackBarIdenticalId.value = snackbarStore.showMessage({
          displayText: "Near and Far Abutment Notes are identical.",
          timeout: 3000,
          callback: onHideSnackbar,
        });
      }
    }

    watch(
      () => [props.isExpanded],
      () => {
        if (props.isExpanded) {
          panel.value = [0];
        } else {
          panel.value = [];
        }
      },
      { deep: true }
    );

    return {
      substructureUnit,
      validate,
      underwaterInsp,
      subPanel,
      subUnitLabel,
      copyCurrentToTarget,
      commentsMaxLength,
      structure,
      REFERENCE_TABLE,
      panel,
      inspectionStore,
      isEditable,
      INSPECTION_COMMENTS,
      SUB_STRUCTURE_UNIT_KEY,
      subPanelValue,
      onHideSnackbar,
    };
  },
  components: {
    UnderwaterInspection,
    StructureUnitCommentTypeTextField,
    NSTMFatigueTable,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  order: 1;
  color: c.$p-white !important;
}

.v-expansion-panel-title__icon {
  order: 0;
}

.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}

.v-label.v-field-label {
  color: c.$p-black !important;
  opacity: 1 !important;
  font-weight: bold !important;
}
</style>
