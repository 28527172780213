<template>
  <ExpandCollapseToggle
    id="elementsPanelToggle"
    @toggleAllPanels="toggleAllPanels"
    styleClass="mt-3"
    :panel="panel"
  />
  <v-container fluid>
    <v-row v-if="inspectionPage === INSPECTION_PAGES.ELEMENTS">
      <v-col sm="12">
        <v-btn
          id="btn_addElement"
          variant="outlined"
          class="mr-5"
          @click="addElement"
          :disabled="!isEditable"
          >Add Element</v-btn
        >
        <v-btn
          id="btn_copyElement"
          variant="outlined"
          class="mx-5"
          @click="copyElement"
          :disabled="!isEditable"
          >Copy Element</v-btn
        >
        <v-tooltip>
          <template v-slot:activator="{ props }">
            <v-icon
              id="icon_copyElementInfo"
              class="ml-2 infoIcon"
              icon="fas fa-circle-info"
              v-bind="props"
            />
          </template>
          <span
            >Options available in Source Elem ID and Target Structure ID are
            filtered based on the selection of Source Structure Unit selected.
            Target Structure ID will not display any Structure IDs that already
            contain the Elem ID selected. Save button will be disabled until all
            three fields are successfully selected.
          </span>
        </v-tooltip>
        <v-btn
          id="btn_copyStructureUnit"
          variant="outlined"
          class="mx-5"
          @click="copyStructureUnit"
          :disabled="!isEditable"
          >Copy Structure Unit</v-btn
        >
        <v-tooltip>
          <template v-slot:activator="{ props }">
            <v-icon
              id="icon_copyStructureUnitInfo"
              class="ml-2 infoIcon"
              icon="fas fa-circle-info"
              v-bind="props"
            />
          </template>
          <span
            >The Target Structure Unit cannot have any elements defined. Save
            button will be disabled until both Source and Target Units are
            successfully selected.
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" class="flex-center pt-0">
        <v-radio-group
          id="radio_elements"
          v-model="elementQtyGroup"
          inline
          hide-details
        >
          <v-radio :value="ELEMENTS.QUANTITY" color="primary">
            <template v-slot:label> Quantity </template>
          </v-radio>
          <v-radio :value="ELEMENTS.PERCENTAGE" color="primary"
            ><template v-slot:label> Percentage </template></v-radio
          >
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple color="#fff">
          <v-expansion-panel
            v-for="(structureUnit, key, index) in structureUnitGroups"
            :key="structureUnit"
            :id="'expansion_structure_' + index"
            :value="'structureUnit_' + index"
          >
            <v-expansion-panel-title
              class="d-flex justify-start"
              hide-actions
              expand-icon="fa-solid fa-plus"
              collapse-icon="fa-solid fa-minus"
            >
              <v-icon
                v-if="panel.includes('structureUnit_' + index)"
                icon="fa-solid fa-minus"
              />
              <v-icon v-else icon="fa-solid fa-plus" />

              <v-row>
                <v-col>
                  <h3>
                    SP02 Structure Unit ID:&nbsp;{{
                      structureUnit.STRUNITLABEL
                    }}
                  </h3>
                </v-col>
                <v-col>
                  <h3>
                    SP01 Span Type:&nbsp;{{
                      configStore.getReferenceValue(
                        REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
                        structureUnit.STRUNITTYPE
                      )
                    }}
                  </h3>
                </v-col>
                <v-col>
                  <h3>5D01 Unit Key:&nbsp;{{ structureUnit.STRUNITKEY }}</h3>
                </v-col>
              </v-row>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <v-label
                class="noRecordsMessage d-flex justify-center"
                v-if="
                  !structureUnit?.elements ||
                  structureUnit?.elements?.length == 0
                "
                >This structure unit currently contains no elements.
              </v-label>
              <v-table v-else class="compactTable">
                <thead>
                  <tr>
                    <th id="th_empty" class="table-cell_width__expansion">
                      &nbsp;
                    </th>
                    <th
                      id="th_elem"
                      class="table-cell_width__desc table--row__align-top"
                    >
                      <div class="align-center">
                        1B01 <br />Element ID Description
                      </div>
                    </th>

                    <th
                      id="th_group"
                      v-if="
                        getStructureType(structure.Bridge?.SERVTYPON) === 'T'
                      "
                      class="table--row__align-top"
                    >
                      <br />Group/ Unit
                    </th>
                    <th id="th_qty" class="table--row__align-top">
                      <div class="align-center">1A10 <br />Qty</div>
                    </th>
                    <th id="th_uom">
                      <br />
                      <div class="align-center">UOM</div>
                    </th>
                    <th
                      id="th_pct1"
                      v-if="elementQtyGroup === ELEMENTS.PERCENTAGE"
                      class="table--row__align-top"
                    >
                      <div class="align-center">
                        1A11<br />
                        CS1 Pct
                      </div>
                    </th>
                    <th id="th_qty1" v-else class="table--row__align-top">
                      <div class="align-center">1A11 <br />CS1 Qty</div>
                    </th>
                    <th
                      id="th_pct2"
                      v-if="elementQtyGroup === ELEMENTS.PERCENTAGE"
                      class="table--row__align-top"
                    >
                      <div class="align-center">
                        1A11<br />
                        CS2 Pct
                      </div>
                    </th>
                    <th id="th_qty2" v-else class="table--row__align-top">
                      <div class="align-center">
                        1A11<br />
                        CS2 Qty
                      </div>
                    </th>
                    <th
                      id="th_pct3"
                      v-if="elementQtyGroup === ELEMENTS.PERCENTAGE"
                      class="table--row__align-top"
                    >
                      <div class="align-center">
                        1A11<br />
                        CS3 Pct
                      </div>
                    </th>
                    <th id="th_qty3" v-else class="table--row__align-top">
                      <div class="align-center">
                        1A11<br />
                        CS3 Qty
                      </div>
                    </th>
                    <th
                      id="th_pct4"
                      v-if="elementQtyGroup === ELEMENTS.PERCENTAGE"
                      class="table--row__align-top"
                    >
                      <div class="align-center">
                        1A11<br />
                        CS4 Pct
                      </div>
                    </th>
                    <th id="th_qty4" v-else class="table--row__align-top">
                      <div class="align-center">1A11 <br />CS4 Qty</div>
                    </th>

                    <th id="th_action">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(element, index) in structureUnit.elements"
                    :key="element"
                  >
                    <tr :id="'tr_' + index" class="element">
                      <td class="compactCell table-cell_width__expansion">
                        <v-icon
                          v-if="
                            !elementsOpened.includes(
                              `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}`
                            ) && element?.children?.length > 0
                          "
                          icon="fa-sharp fa-solid fa-chevron-right"
                          @click="
                            toggle(
                              `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}`
                            )
                          "
                        />
                        <v-icon
                          v-if="
                            elementsOpened.includes(
                              `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}`
                            ) && element?.children?.length > 0
                          "
                          icon="fa-solid fa-chevron-down"
                          @click="
                            toggle(
                              `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}`
                            )
                          "
                        />
                      </td>
                      <InspectionElementRow
                        :element="element"
                        :structureType="
                          getStructureType(structure.Bridge?.SERVTYPON)
                        "
                        :elementQtyGroup="elementQtyGroup"
                      />

                      <td style="white-space: nowrap">
                        <div
                          v-if="inspectionPage === INSPECTION_PAGES.ELEMENTS"
                        >
                          <v-icon
                            :icon="
                              isEditable
                                ? 'fa-solid fa-pencil'
                                : 'fa-solid fa-search'
                            "
                            @click="
                              {
                                editUnit(element);
                                showElementDialog.show = true;
                              }
                            "
                          />
                          &nbsp;&nbsp;
                          <v-icon
                            v-if="isEditable"
                            icon="fa-solid fa-trash"
                            @click="
                              deleteUnit(element, structureUnit.STRUNITKEY)
                            "
                          />
                        </div>
                        <div
                          v-else-if="
                            inspectionPage === INSPECTION_PAGES.TUNNELS
                          "
                        >
                          <v-icon
                            icon="fa-solid fa-search"
                            @click="
                              {
                                editUnit(element);
                                showElementDialog.show = true;
                              }
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <template
                      v-for="(defect, idx) in element.children"
                      :key="defect"
                    >
                      <tr
                        :id="'tr_defect_' + idx"
                        :class="
                          getColor(
                            configStore.getElementDefinition(defect.ELEM_KEY)
                          )
                        "
                        v-if="
                          elementsOpened.includes(
                            `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}`
                          )
                        "
                      >
                        <td class="compactCell">
                          <span
                            v-if="defect?.children?.length > 0"
                            style="padding-left: 1rem"
                          ></span>
                          <v-icon
                            v-if="
                              defect?.children?.length > 0 &&
                              !protectiveSystemselementsOpened?.includes(
                                `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}`
                              )
                            "
                            icon="fa-sharp fa-solid fa-chevron-right"
                            @click="
                              toggleProtectiveSystems(
                                `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}`
                              )
                            "
                          />
                          <v-icon
                            v-if="
                              defect?.children?.length > 0 &&
                              protectiveSystemselementsOpened?.includes(
                                `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}`
                              )
                            "
                            icon="fa-solid fa-chevron-down"
                            @click="
                              toggleProtectiveSystems(
                                `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}`
                              )
                            "
                          />
                          <span
                            v-if="defect?.children?.length > 0"
                            style="padding-left: 0.2rem"
                          ></span>
                          <span v-else style="padding-left: 1rem"> </span>

                          <v-icon
                            v-if="
                              elementsOpened.includes(
                                `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}`
                              )
                            "
                            :icon="
                              getIcon(
                                configStore.getElementDefinition(
                                  defect.ELEM_KEY
                                )
                              )
                            "
                          />
                        </td>
                        <InspectionElementRow
                          :element="defect"
                          :structureType="
                            getStructureType(structure.Bridge?.SERVTYPON)
                          "
                          :elementQtyGroup="elementQtyGroup"
                        />

                        <td style="white-space: nowrap">
                          <v-icon
                            v-if="
                              getIcon(
                                configStore.getElementDefinition(
                                  defect.ELEM_KEY
                                )
                              ) === 'fa-solid no-outline'
                            "
                            :icon="
                              isEditable
                                ? 'fa-solid fa-pencil'
                                : 'fa-solid fa-search'
                            "
                            @click="
                              {
                                editUnit(defect);
                                showElementDialog.show = true;
                              }
                            "
                          />
                          &nbsp;&nbsp;
                          <v-icon
                            v-if="
                              isEditable &&
                              getIcon(
                                configStore.getElementDefinition(
                                  defect.ELEM_KEY
                                )
                              ) === 'fa-solid no-outline'
                            "
                            icon="fa-solid fa-trash"
                            @click="deleteUnit(defect, defect.STRUNITKEY)"
                          />
                        </td>
                      </tr>
                      <template
                        v-for="(protectSystemDefect, i) in defect?.children"
                        :key="protectSystemDefect"
                      >
                        <tr
                          :class="
                            getColor(
                              configStore.getElementDefinition(
                                protectSystemDefect.ELEM_KEY
                              )
                            )
                          "
                          :id="'tr_protectiveSystemDefect_' + i"
                          v-if="
                            protectiveSystemselementsOpened.includes(
                              `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}`
                            )
                          "
                        >
                          <td class="compactCell">
                            <span
                              v-if="protectSystemDefect?.children?.length > 0"
                              style="padding-left: 2rem"
                            ></span>
                            <v-icon
                              v-if="
                                protectSystemDefect?.children?.length > 0 &&
                                !functionalElementsOpened?.includes(
                                  `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}-${i}`
                                )
                              "
                              icon="fa-sharp fa-solid fa-chevron-right"
                              @click="
                                toggleFunctionalElements(
                                  `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}-${i}`
                                )
                              "
                            />
                            <v-icon
                              v-if="
                                protectSystemDefect?.children?.length > 0 &&
                                functionalElementsOpened?.includes(
                                  `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}-${i}`
                                )
                              "
                              icon="fa-solid fa-chevron-down"
                              @click="
                                toggleFunctionalElements(
                                  `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}-${i}`
                                )
                              "
                            />
                            <span
                              v-if="protectSystemDefect?.children?.length > 0"
                              style="padding-left: 0.2rem"
                            ></span>
                            <span v-else style="padding-left: 3rem"> </span>
                            <v-icon
                              :icon="
                                getIcon(
                                  configStore.getElementDefinition(
                                    protectSystemDefect.ELEM_KEY
                                  )
                                )
                              "
                            />
                          </td>
                          <InspectionElementRow
                            :element="protectSystemDefect"
                            :structureType="
                              getStructureType(structure.Bridge?.SERVTYPON)
                            "
                            :elementQtyGroup="elementQtyGroup"
                          />
                          <td></td>
                        </tr>
                        <template
                          v-for="fnSystemProtectiveSys in protectSystemDefect?.children"
                          :key="fnSystemProtectiveSys"
                        >
                          <tr
                            v-if="
                              functionalElementsOpened?.includes(
                                `${structureUnit.STRUNITKEY}-${element.ELEM_KEY}-${idx}-${i}`
                              )
                            "
                            :class="
                              getColor(
                                configStore.getElementDefinition(
                                  fnSystemProtectiveSys.ELEM_KEY
                                )
                              )
                            "
                          >
                            <td>
                              <v-icon
                                style="padding-left: 4.2rem"
                                :icon="
                                  getIcon(
                                    configStore.getElementDefinition(
                                      fnSystemProtectiveSys.ELEM_KEY
                                    )
                                  )
                                "
                              />
                            </td>
                            <InspectionElementRow
                              :element="fnSystemProtectiveSys"
                              :structureType="
                                getStructureType(structure.Bridge?.SERVTYPON)
                              "
                              :elementQtyGroup="elementQtyGroup"
                            />

                            <td></td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </template>
                </tbody>
              </v-table>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
  <!--Add or Edit Element Dialog -->
  <v-dialog
    v-model="showElementDialog.show"
    scrollable
    persistent
    class="dialogForm"
  >
    <v-card>
      <v-card-title>Element Selector</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          v-model="valid"
          ref="modifyElementForm"
          @change="changesDetected = true"
        >
          <v-row>
            <v-col sm="12" md="6">
              <LabelSelect
                :labelXl="3"
                :labelLg="4"
                :labelMd="5"
                :labelSm="4"
                :selectXl="8"
                :selectLg="7"
                :selectMd="6"
                :selectSm="8"
                id="structureUnit"
                label="1B02 Structure Unit"
                :options="structureUnitTypes"
                :isEditable="isEditable && showElementDialog.isAdd"
                v-model="selectedElement.STRUNITKEY"
                :isRequired="isEditable"
                @update:modelValue="
                  {
                    resetAddElementForUnitKeyChange(selectedElement.STRUNITKEY);
                  }
                "
              ></LabelSelect>
            </v-col>
            <v-col sm="12" md="6">
              <LabelSelect
                :labelXl="3"
                :labelLg="4"
                :labelMd="5"
                :labelSm="4"
                :selectXl="8"
                :selectLg="7"
                :selectMd="6"
                :selectSm="8"
                id="elemDescription"
                label="1B01 Element ID"
                :options="getUniqueElements(selectedElement.STRUNITKEY)"
                :isEditable="isEditable && showElementDialog.isAdd"
                v-model="selectedElement.ELEM_KEY"
                :isRequired="isEditable"
                @update:modelValue="getDefectsAndProtectiveSystems"
              ></LabelSelect>

              <InputMessage
                :showMessage="!!noFunctionalSystemMessage"
                :message="noFunctionalSystemMessage"
              />
            </v-col>
          </v-row>
          <v-row v-if="$vuetify.display.mdAndUp">
            <v-col sm="6">
              <v-row
                v-if="getStructureType(structure.Bridge?.SERVTYPON) === 'T'"
              >
                <v-col sm="4">
                  <LabelText
                    noGrid
                    id="elemTag"
                    label="Group/Unit"
                    v-model="selectedElement.ELEM_TAG"
                    :isEditable="isEditable"
                    :counter="null"
                  >
                  </LabelText>
                </v-col>
                <v-col sm="4" class="d-flex">
                  <LabelText
                    noGrid
                    id="envKey"
                    label="1B03 Environment"
                    :refTable="REFERENCE_TABLE.ENVIRONMENT"
                    v-model="selectedElement.ENVKEY"
                    class="text_noPaddingLeft"
                  >
                  </LabelText>
                </v-col>
                <v-col sm="4" class="d-flex">
                  <LabelText
                    noGrid
                    id="uom"
                    label="Unit/Measure"
                    v-model="selectedElement.uom"
                    class="text_noPaddingLeft"
                  >
                  </LabelText>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col sm="6" class="d-flex">
                  <LabelText
                    noGrid
                    id="envKey"
                    label="1B03 Environment"
                    :refTable="REFERENCE_TABLE.ENVIRONMENT"
                    v-model="selectedElement.ENVKEY"
                  >
                  </LabelText>
                </v-col>
                <v-col sm="6" class="d-flex">
                  <LabelText
                    noGrid
                    id="uom"
                    label="Unit/Measure"
                    v-model="selectedElement.uom"
                  >
                  </LabelText>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col sm="6">
                  <LabelText
                    noGrid
                    id="quantity"
                    label="1A10 Quantity"
                    v-model="selectedElement.ELEM_QUANTITY"
                    type="number"
                    :allowCommas="false"
                    :isRequired="isEditable"
                    :maxlength="quantityCountMaxLength"
                    @input="updateDefectCount(null)"
                    :isEditable="isEditable"
                    textStyle="width:50%"
                  >
                  </LabelText>
                </v-col>
                <v-col sm="6">
                  <LabelText
                    noGrid
                    id="sf"
                    label="1B05 Scale Factor"
                    v-model="selectedElement.ELEM_SCALE_FACTOR"
                    type="number"
                    :isRequired="isEditable"
                    :maxlength="10"
                    @input="updateDefectCount(null)"
                    :isEditable="isEditable"
                    :decimalPlaces="1"
                    :rules="
                      isEditable
                        ? [
                            (value) => {
                              return lessThanValueRule(value, 9999999.9);
                            },
                          ]
                        : []
                    "
                    textStyle="width:40%"
                  >
                  </LabelText>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col sm="6" class="d-flex">
              <LabelText
                :labelSm="8"
                :textSm="4"
                id="envKey"
                label="1B03 Environment"
                :refTable="REFERENCE_TABLE.ENVIRONMENT"
                v-model="selectedElement.ENVKEY"
                textStyle="width:100%"
              >
              </LabelText>
            </v-col>
            <v-col sm="6">
              <LabelText
                :labelSm="5"
                :textSm="7"
                id="elemTag"
                label="Group/Unit"
                v-model="selectedElement.ELEM_TAG"
                :isEditable="isEditable"
                :counter="null"
                v-if="getStructureType(structure.Bridge?.SERVTYPON) === 'T'"
              >
              </LabelText>
            </v-col>
            <v-col sm="8">
              <LabelText
                :labelSm="6"
                :textSm="6"
                id="quantity"
                label="1A10 Quantity"
                :allowCommas="false"
                v-model="selectedElement.ELEM_QUANTITY"
                type="number"
                :isRequired="isEditable"
                :maxlength="quantityCountMaxLength"
                @input="updateDefectCount(null)"
                :isEditable="isEditable"
                textStyle="width:100%"
              >
              </LabelText>
            </v-col>
            <v-col sm="4" class="d-flex">
              <LabelText
                :labelSm="8"
                :textSm="4"
                id="uom"
                label="Unit/Measure"
                v-model="selectedElement.uom"
                textStyle="width:100%"
                class="text_noPaddingLeft"
              >
              </LabelText>
            </v-col>
            <v-col sm="12">
              <LabelText
                :labelSm="4"
                :textSm="4"
                id="sf"
                label="1B05 Scale Factor"
                v-model="selectedElement.ELEM_SCALE_FACTOR"
                type="number"
                :isRequired="isEditable"
                :maxlength="10"
                @input="updateDefectCount(null)"
                :isEditable="isEditable"
                :decimalPlaces="1"
                textStyle="width:100%"
                :rules="
                  isEditable
                    ? [
                        (value) => {
                          return lessThanValueRule(value, 9999999.99);
                        },
                      ]
                    : []
                "
              >
              </LabelText>
            </v-col>
          </v-row>
          <v-row>
            <v-col colspan="2">
              <LabelText
                labelXl="12"
                labelLg="12"
                labelMd="12"
                labelSm="12"
                textXl="12"
                textLg="12"
                textMd="12"
                textSm="12"
                type="textarea"
                label="1B07 Element Desc"
                id="elemDesc"
                v-model="selectedElement.ELEM_DESC"
                :isEditable="isEditable"
                :counter="elemDescriptionMaxLength"
                :maxlength="elemDescriptionMaxLength"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col colspan="2">
              <LabelText
                labelXl="12"
                labelLg="12"
                labelMd="12"
                labelSm="12"
                textXl="12"
                textLg="12"
                textMd="12"
                textSm="12"
                type="textarea"
                label="1A12 Elem Cond"
                id="replacementComment"
                v-model="selectedElement.ELEM_NOTES"
                :isEditable="isEditable"
                :counter="elemConditionMaxLength"
                :maxlength="elemConditionMaxLength"
              />
            </v-col>
          </v-row>
          <v-row v-if="$vuetify.display.lgAndUp" class="elementsInput">
            <v-col cols="2" lg="2" class="mr-0 pr-0">
              <v-radio-group
                v-model="updateElementQtyGroup"
                hide-details
                density="compact"
              >
                <v-radio
                  :value="ELEMENTS.QUANTITY"
                  color="primary"
                  class="font-weight-bold"
                  ><template v-slot:label> Quantity </template></v-radio
                >
                <v-radio
                  :value="ELEMENTS.PERCENTAGE"
                  color="primary"
                  class="font-weight-bold"
                  ><template v-slot:label> Percentage </template></v-radio
                >
              </v-radio-group></v-col
            >
            <v-col class="ml-0 pl-0" cols="10" lg="10">
              <v-row>
                <template v-if="updateElementQtyGroup === ELEMENTS.QUANTITY">
                  <v-col cols="3" lg="3">
                    <LabelText
                      noGrid
                      id="cs1Qty"
                      label="1A11 CS1 Qty"
                      v-model="selectedElement.ELEM_QTYSTATE1"
                      textStyle="background-color:green;width:96px;margin-left:8px;margin-right:5px;padding-right:5px;padding-left: 0 !important;"
                      :rules="[greaterThanZeroRule]"
                      alignRight
                      readOnlyValidation
                      type="number"
                    >
                    </LabelText>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="3">
                    <LabelText
                      noGrid
                      id="cs1Pct"
                      label="1A11 CS1 Pct"
                      v-model="selectedElement.ELEM_PCTSTATE1"
                      textStyle="background-color:green;width:96px;margin-left:8px;margin-right:5px;padding-right:5px;"
                      :rules="[greaterThanZeroRule]"
                      type="number"
                      readOnlyValidation
                      :decimalPlaces="2"
                      alignRight
                    >
                    </LabelText>
                  </v-col>
                </template>

                <template v-if="updateElementQtyGroup === ELEMENTS.QUANTITY">
                  <v-col cols="3" lg="3">
                    <LabelText
                      noGrid
                      id="cs2Qty"
                      label="1A11 CS2 Qty"
                      v-model="selectedElement.ELEM_QTYSTATE2"
                      textStyle="background-color:yellow;width:96px;margin-left:8px;margin-right:5px;padding-right:5px;"
                      :rules="[greaterThanZeroRule]"
                      readOnlyValidation
                      type="number"
                      alignRight
                    >
                    </LabelText>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="3" lg="3">
                    <LabelText
                      noGrid
                      id="cs2Pct"
                      label="1A11 CS2 Pct"
                      v-model="selectedElement.ELEM_PCTSTATE2"
                      textStyle="background-color:yellow;width:96px;margin-left:8px;margin-right:5px;padding-right:5px;"
                      :rules="[greaterThanZeroRule]"
                      readOnlyValidation
                      type="number"
                      :decimalPlaces="2"
                      alignRight
                    >
                    </LabelText>
                  </v-col>
                </template>
                <template v-if="updateElementQtyGroup === ELEMENTS.QUANTITY">
                  <v-col cols="3" lg="3">
                    <LabelText
                      noGrid
                      id="cs3Qty"
                      label="1A11 CS3 Qty"
                      v-model="selectedElement.ELEM_QTYSTATE3"
                      textStyle="background-color:orange;width:100%;width:96px;margin-left:8px;margin-right:5px;padding-right:5px;"
                      :rules="[greaterThanZeroRule]"
                      readOnlyValidation
                      type="number"
                      alignRight
                    >
                    </LabelText>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="3" lg="3">
                    <LabelText
                      noGrid
                      id="cs3Pct"
                      label="1A11 CS3 Pct"
                      v-model="selectedElement.ELEM_PCTSTATE3"
                      textStyle="background-color:orange;width:100%;width:96px;margin-left:8px;margin-right:5px;padding-right:5px;"
                      :rules="[greaterThanZeroRule]"
                      type="number"
                      readOnlyValidation
                      :decimalPlaces="2"
                      alignRight
                    >
                    </LabelText>
                  </v-col>
                </template>
                <template v-if="updateElementQtyGroup === ELEMENTS.QUANTITY">
                  <v-col cols="3" lg="3">
                    <LabelText
                      noGrid
                      id="cs4Qty"
                      label="1A11 CS4 Qty"
                      v-model="selectedElement.ELEM_QTYSTATE4"
                      textStyle="background-color:red;width:96px;margin-left:8px;margin-right:5px;padding-right:5px;"
                      :rules="[greaterThanZeroRule]"
                      readOnlyValidation
                      type="number"
                      alignRight
                    >
                    </LabelText>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="3" lg="3">
                    <LabelText
                      noGrid
                      id="cs4Pct"
                      label="1A11 CS4 Pct"
                      v-model="selectedElement.ELEM_PCTSTATE4"
                      textStyle="background-color:red;width:96px;margin-left:8px;margin-right:5px;padding-right:5px;"
                      :rules="[greaterThanZeroRule]"
                      type="number"
                      :decimalPlaces="2"
                      readOnlyValidation
                      alignRight
                    >
                    </LabelText>
                  </v-col>
                </template>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" class="pt-1 pl-0">
              <ExpandCollapseToggle
                id="approachPanelToggle"
                @toggleAllPanels="toggleAllPopupPanels"
                styleClass="ml-3"
                :panel="elementsPanel"
              />
            </v-col>
          </v-row>
          <v-row v-else class="elementsInput">
            <v-col cols="12" sm="12" class="py-0 pl-0">
              <v-radio-group
                v-model="updateElementQtyGroup"
                inline
                hide-details
              >
                <v-radio
                  :value="ELEMENTS.QUANTITY"
                  color="primary"
                  class="font-weight-bold"
                  ><template v-slot:label> Quantity </template></v-radio
                >
              </v-radio-group></v-col
            >
            <v-col cols="12" sm="12" class="py-0 pl-0">
              <v-radio-group
                v-model="updateElementQtyGroup"
                inline
                hide-details
              >
                <v-radio
                  :value="ELEMENTS.PERCENTAGE"
                  color="primary"
                  class="font-weight-bold"
                  ><template v-slot:label> Percentage </template></v-radio
                >
              </v-radio-group></v-col
            >
            <v-col cols="12" sm="12" class="py-0 pl-0">
              <template v-if="updateElementQtyGroup === ELEMENTS.QUANTITY">
                <v-col cols="12" sm="12" md="4" lg="3" class="pb-2 pt-1">
                  <LabelText
                    :labelXl="5"
                    :labelLg="6"
                    :labelMd="6"
                    :labelSm="3"
                    :textXl="6"
                    :textLg="5"
                    :textMd="5"
                    :textSm="3"
                    id="cs1Qty"
                    label="1A11 CS1 Qty"
                    v-model="selectedElement.ELEM_QTYSTATE1"
                    textStyle="background-color:green;width:100%;padding-right:5px"
                    :rules="[greaterThanZeroRule]"
                    alignRight
                    readOnlyValidation
                    type="number"
                  >
                  </LabelText>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12" sm="12" md="4" lg="3" class="pb-2 pt-1">
                  <LabelText
                    :labelXl="5"
                    :labelLg="6"
                    :labelMd="6"
                    :labelSm="3"
                    :textXl="6"
                    :textLg="5"
                    :textMd="5"
                    :textSm="3"
                    id="cs1Pct"
                    label="1A11 CS1 Pct"
                    v-model="selectedElement.ELEM_PCTSTATE1"
                    textStyle="background-color:green;width:100%;padding-right:5px"
                    :rules="[greaterThanZeroRule]"
                    type="number"
                    readOnlyValidation
                    :decimalPlaces="2"
                    alignRight
                  >
                  </LabelText>
                </v-col>
              </template>

              <template v-if="updateElementQtyGroup === ELEMENTS.QUANTITY">
                <v-col cols="12" sm="12" md="4" lg="3" class="pb-2 pt-0">
                  <LabelText
                    :labelXl="5"
                    :labelLg="6"
                    :labelMd="6"
                    :labelSm="3"
                    :textXl="6"
                    :textLg="5"
                    :textMd="5"
                    :textSm="3"
                    id="cs2Qty"
                    label="1A11 CS2 Qty"
                    v-model="selectedElement.ELEM_QTYSTATE2"
                    textStyle="background-color:yellow;width:100%;padding-right:5px"
                    :rules="[greaterThanZeroRule]"
                    readOnlyValidation
                    type="number"
                    alignRight
                  >
                  </LabelText>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12" sm="12" md="4" lg="3" class="pb-2 pt-0">
                  <LabelText
                    :labelXl="5"
                    :labelLg="6"
                    :labelMd="6"
                    :labelSm="3"
                    :textXl="6"
                    :textLg="5"
                    :textMd="5"
                    :textSm="3"
                    id="cs2Pct"
                    label="1A11 CS2 Pct"
                    v-model="selectedElement.ELEM_PCTSTATE2"
                    textStyle="background-color:yellow;width:100%;padding-right:5px"
                    :rules="[greaterThanZeroRule]"
                    readOnlyValidation
                    type="number"
                    :decimalPlaces="2"
                    alignRight
                  >
                  </LabelText>
                </v-col>
              </template>
              <template v-if="updateElementQtyGroup === ELEMENTS.QUANTITY">
                <v-col cols="12" sm="12" md="4" lg="3" class="pb-2 pt-0">
                  <LabelText
                    :labelXl="5"
                    :labelLg="6"
                    :labelMd="6"
                    :labelSm="3"
                    :textXl="6"
                    :textLg="5"
                    :textMd="5"
                    :textSm="3"
                    id="cs3Qty"
                    label="1A11 CS3 Qty"
                    v-model="selectedElement.ELEM_QTYSTATE3"
                    textStyle="background-color:orange;width:100%;padding-right:5px"
                    :rules="[greaterThanZeroRule]"
                    readOnlyValidation
                    type="number"
                    alignRight
                  >
                  </LabelText>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12" sm="12" md="4" lg="3" class="pb-2 pt-0">
                  <LabelText
                    :labelXl="5"
                    :labelLg="6"
                    :labelMd="6"
                    :labelSm="3"
                    :textXl="6"
                    :textLg="5"
                    :textMd="5"
                    :textSm="3"
                    id="cs3Pct"
                    label="1A11 CS3 Pct"
                    v-model="selectedElement.ELEM_PCTSTATE3"
                    textStyle="background-color:orange;width:100%;padding-right:5px"
                    :rules="[greaterThanZeroRule]"
                    type="number"
                    readOnlyValidation
                    :decimalPlaces="2"
                    alignRight
                  >
                  </LabelText>
                </v-col>
              </template>
              <template v-if="updateElementQtyGroup === ELEMENTS.QUANTITY">
                <v-col cols="12" sm="12" md="4" lg="3" class="pb-2 pt-0">
                  <LabelText
                    :labelXl="5"
                    :labelLg="6"
                    :labelMd="6"
                    :labelSm="3"
                    :textXl="6"
                    :textLg="5"
                    :textMd="5"
                    :textSm="3"
                    id="cs4Qty"
                    label="1A11 CS4 Qty"
                    v-model="selectedElement.ELEM_QTYSTATE4"
                    textStyle="background-color:red;width:100%;padding-right:5px"
                    :rules="[greaterThanZeroRule]"
                    readOnlyValidation
                    type="number"
                    alignRight
                  >
                  </LabelText>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12" sm="12" md="4" lg="3" class="pb-2 pt-0">
                  <LabelText
                    :labelXl="5"
                    :labelLg="6"
                    :labelMd="6"
                    :labelSm="3"
                    :textXl="6"
                    :textLg="5"
                    :textMd="5"
                    :textSm="3"
                    id="cs4Pct"
                    label="1A11 CS4 Pct"
                    v-model="selectedElement.ELEM_PCTSTATE4"
                    textStyle="background-color:red;width:100%;padding-right:5px"
                    :rules="[greaterThanZeroRule]"
                    type="number"
                    :decimalPlaces="2"
                    readOnlyValidation
                    alignRight
                  >
                  </LabelText>
                </v-col>
              </template>
            </v-col>
            <v-col cols="12" sm="12" class="pt-1 pl-0">
              <ExpandCollapseToggle
                id="approachPanelToggle"
                @toggleAllPanels="toggleAllPopupPanels"
                styleClass="ml-3"
                :panel="elementsPanel"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-expansion-panels v-model="elementsPanel" multiple color="#fff">
                <v-expansion-panel id="panel_defects" value="defects">
                  <v-expansion-panel-title
                    class="d-flex justify-start"
                    hide-actions
                    expand-icon="fa-solid fa-plus"
                    collapse-icon="fa-solid fa-minus"
                  >
                    <v-icon
                      v-if="elementsPanel.includes('defects')"
                      icon="fa-solid fa-minus"
                    />
                    <v-icon v-else icon="fa-solid fa-plus" />
                    <h3>Defects</h3>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-table class="w-100 compactTable elementsTable">
                      <thead>
                        <tr>
                          <th style="width: 7%" id="th_defect_applied">
                            <br />Applied
                          </th>
                          <th
                            style="width: 23%"
                            id="th_defect_desc"
                            class="dialog-table-cell_width__desc table--row__align-top"
                          >
                            <div class="align-center">
                              1B01<br />
                              Element Desc
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_qty"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1B10<br />
                              Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_sf"
                            class="align-center"
                          >
                            <br />SF
                          </th>
                          <th
                            id="th_defect_uom"
                            style="width: 8%"
                            class="align-center"
                          >
                            <br />UOM
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_pct1"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS1 Pct
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_qty1"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS1 Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_pct2"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS2 Pct
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_qty2"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS2 Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_pct3"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">1A11 <br />CS3 Pct</div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_qty3"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS3 Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_pct4"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS4 Pct
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_defect_qty4"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS4 Qty
                            </div>
                          </th>

                          <th style="width: 2%" id="th_defect_info"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(defect, idx) in allDefects"
                          :key="defect"
                          class="table--row__align-top"
                        >
                          <td>
                            <v-checkbox
                              :id="'checkbox_selectDefect_' + idx"
                              v-model="defect.isSelected"
                              @click="updateDefectSelection(defect)"
                              hide-details
                              :disabled="!isEditable"
                            ></v-checkbox>
                          </td>
                          <td style="padding-top: 8px !important">
                            {{ defect.ELEM_KEY }} - {{ defect.ELEM_SHORTNAME }}
                          </td>
                          <td
                            class="align-center"
                            :style="
                              updateElementQtyGroup === ELEMENTS.PERCENTAGE &&
                              defect.isSelected &&
                              isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              :id="'text_defectQty_' + idx"
                              v-model="defect.ELEM_QUANTITY"
                              :maxlength="quantityCountMaxLength"
                              :readonly="
                                !(
                                  updateElementQtyGroup ===
                                    ELEMENTS.PERCENTAGE &&
                                  defect.isSelected &&
                                  isEditable
                                )
                              "
                              :allowCommas="false"
                              @change="
                                defect.ELEM_PCTSTATE1 = '100.00';
                                defect.ELEM_QTYSTATE1 = defect.ELEM_QUANTITY;
                                updateDefectCount(defect);
                              "
                            />
                            <InputMessage
                              :showMessage="
                                isEditable &&
                                defect.isSelected &&
                                updateElementQtyGroup === ELEMENTS.PERCENTAGE &&
                                (!defect.ELEM_QUANTITY ||
                                  defect.ELEM_QUANTITY <= 0)
                              "
                              :message="ERROR_MESSAGES.GREATER_THAN_ZERO"
                            />
                          </td>
                          <td
                            class="align-center"
                            :style="
                              defect.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              :id="'text_defectSF_' + idx"
                              v-model="defect.ELEM_SCALE_FACTOR"
                              :readonly="!defect.isSelected || !isEditable"
                              :decimalPlaces="1"
                              :maxlength="12"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return lessThanValueRule(
                                          value,
                                          9999999.99
                                        );
                                      },
                                    ]
                                  : []
                              "
                              @change="updateDefectCount(defect)"
                            />
                          </td>
                          <td
                            style="padding-top: 8px !important"
                            class="align-center"
                          >
                            {{
                              configStore.getElementDefinition(
                                defect.ELEM_PARENT_KEY
                              )?.elemScaleUnit
                            }}
                          </td>
                          <td
                            class="align-center"
                            :style="
                              defect.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :id="'text_defectQty1_' + idx"
                              v-model="defect.ELEM_QTYSTATE1"
                              readonly
                              readOnlyValidation
                              :rules="
                                !defect.isSelected || !isEditable
                                  ? []
                                  : [
                                      (value) => {
                                        return shouldbeEqual(value, 0);
                                      },
                                    ]
                              "
                            />
                            <NumberField
                              v-else
                              :id="'text_defectPct1_' + idx"
                              v-model="defect.ELEM_PCTSTATE1"
                              readonly
                              :decimalPlaces="2"
                              readOnlyValidation
                              :rules="
                                !defect.isSelected || !isEditable
                                  ? []
                                  : [
                                      (value) => {
                                        return shouldbeEqual(value, 0);
                                      },
                                    ]
                              "
                            />
                          </td>
                          <td
                            class="align-center"
                            :style="
                              defect.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :id="'text_defectQty2_' + idx"
                              v-model="defect.ELEM_QTYSTATE2"
                              :allowCommas="false"
                              :readonly="!defect.isSelected || !isEditable"
                              @change="updateDefectCount(defect)"
                            />
                            <NumberField
                              v-else
                              :id="'text_defectPct2_' + idx"
                              v-model="defect.ELEM_PCTSTATE2"
                              :readonly="!defect.isSelected || !isEditable"
                              :decimalPlaces="2"
                              @change="updateDefectCount(defect)"
                              :rules="
                                !defect.isSelected || !isEditable
                                  ? []
                                  : [(value) => lessThanValueRule(value, 100)]
                              "
                            />
                            <InputMessage
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :showMessage="
                                showErrorMessage(defect, ELEMENTS.QUANTITY)
                              "
                              :message="
                                ERROR_MESSAGES.ATLEAST_ONE_GREATER_THAN_ZERO
                              "
                            />
                            <InputMessage
                              v-else
                              :showMessage="
                                showPercentErrorMessage(
                                  defect,
                                  ELEMENTS.PERCENTAGE
                                )
                              "
                              :message="
                                ERROR_MESSAGES.TOTAL_PERCENT_EQUAL_TO_100
                              "
                            />
                          </td>
                          <td
                            class="align-center"
                            :style="
                              defect.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :id="'text_defectQty3_' + idx"
                              v-model="defect.ELEM_QTYSTATE3"
                              :allowCommas="false"
                              :readonly="!defect.isSelected || !isEditable"
                              @change="updateDefectCount(defect)"
                            />
                            <NumberField
                              v-else
                              :id="'text_defectPct3_' + idx"
                              v-model="defect.ELEM_PCTSTATE3"
                              :readonly="!defect.isSelected || !isEditable"
                              :decimalPlaces="2"
                              @change="updateDefectCount(defect)"
                              :rules="
                                !defect.isSelected || !isEditable
                                  ? []
                                  : [(value) => lessThanValueRule(value, 100)]
                              "
                            />
                            <InputMessage
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :showMessage="
                                showErrorMessage(defect, ELEMENTS.QUANTITY)
                              "
                              :message="
                                ERROR_MESSAGES.ATLEAST_ONE_GREATER_THAN_ZERO
                              "
                            />
                            <InputMessage
                              v-else
                              :showMessage="
                                showPercentErrorMessage(
                                  defect,
                                  ELEMENTS.PERCENTAGE
                                )
                              "
                              :message="
                                ERROR_MESSAGES.TOTAL_PERCENT_EQUAL_TO_100
                              "
                            />
                          </td>
                          <td
                            class="align-center"
                            :style="
                              defect.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :id="'text_defectQty4_' + idx"
                              v-model="defect.ELEM_QTYSTATE4"
                              :allowCommas="false"
                              :readonly="!defect.isSelected || !isEditable"
                              @change="updateDefectCount(defect)"
                            />
                            <NumberField
                              v-else
                              :id="'text_defectPct4_' + idx"
                              v-model="defect.ELEM_PCTSTATE4"
                              :readonly="!defect.isSelected || !isEditable"
                              :decimalPlaces="2"
                              @change="updateDefectCount(defect)"
                              :rules="
                                !defect.isSelected || !isEditable
                                  ? []
                                  : [(value) => lessThanValueRule(value, 100)]
                              "
                            />
                            <InputMessage
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :showMessage="
                                showErrorMessage(defect, ELEMENTS.QUANTITY)
                              "
                              :message="
                                ERROR_MESSAGES.ATLEAST_ONE_GREATER_THAN_ZERO
                              "
                            />
                            <InputMessage
                              v-else
                              :showMessage="
                                showPercentErrorMessage(
                                  defect,
                                  ELEMENTS.PERCENTAGE
                                )
                              "
                              :message="
                                ERROR_MESSAGES.TOTAL_PERCENT_EQUAL_TO_100
                              "
                            />
                          </td>
                          <td style="padding-top: 8px !important">
                            <v-icon
                              icon="fas fa-circle-info"
                              class="infoIcon"
                              @click="openElementConditionDialog(defect)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel
                  id="panel_protectiveSystems"
                  value="protectiveSystems"
                >
                  <v-expansion-panel-title
                    class="d-flex justify-start"
                    hide-actions
                    expand-icon="fa-solid fa-plus"
                    collapse-icon="fa-solid fa-minus"
                  >
                    <v-icon
                      v-if="elementsPanel.includes('protectiveSystems')"
                      icon="fa-solid fa-minus"
                    />
                    <v-icon v-else icon="fa-solid fa-plus" />
                    <h3>Protective Systems</h3>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-table class="w-100 compactTable elementsTable">
                      <thead>
                        <tr>
                          <th style="width: 7%" id="th_proSys_applied">
                            <br />Applied
                          </th>
                          <th
                            style="width: 23%"
                            id="th_proSys_elem"
                            class="dialog-table-cell_width__desc table--row__align-top"
                          >
                            <div class="align-center">
                              1B01<br />
                              Element Desc
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_qty"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1B10<br />
                              Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_sf"
                            class="align-center"
                          >
                            <br />SF
                          </th>
                          <th
                            id="th_proSys_uom"
                            style="width: 8%"
                            class="align-center"
                          >
                            <br />UOM
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_pct1"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS1 Pct
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_qty1"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS1 Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_pct2"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS2 Pct
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_qty2"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS2 Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_pct3"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">1A11 <br />CS3 Pct</div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_qty3"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS3 Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_pct4"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS4 Pct
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_proSys_qty4"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS4 Qty
                            </div>
                          </th>

                          <th style="width: 2%" id="th_proSys_info"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(
                            protectiveSystem, idx
                          ) in allProtectiveSystems"
                          :key="protectiveSystem"
                        >
                          <tr class="table--row__align-top">
                            <td>
                              <v-checkbox
                                :id="'checkbox_selectProtectiveSystem_' + idx"
                                v-model="protectiveSystem.isSelected"
                                @click="
                                  updateProtectiveSystemSelection(
                                    protectiveSystem
                                  )
                                "
                                hide-details
                                :disabled="!isEditable"
                              ></v-checkbox>
                            </td>
                            <td style="padding-top: 8px !important">
                              {{ protectiveSystem.ELEM_KEY }} -
                              {{ protectiveSystem.ELEM_SHORTNAME }}
                            </td>
                            <td
                              class="align-center"
                              :style="
                                protectiveSystem.isSelected && isEditable
                                  ? ''
                                  : 'padding-top:8px !important'
                              "
                            >
                              <NumberField
                                :id="'text_systemQty_' + idx"
                                v-model="protectiveSystem.ELEM_QUANTITY"
                                :maxlength="quantityCountMaxLength"
                                :readonly="
                                  !protectiveSystem.isSelected || !isEditable
                                "
                                :allowCommas="false"
                                @change="
                                  protectiveSystem.ELEM_QTYSTATE1 =
                                    protectiveSystem.ELEM_QUANTITY;
                                  protectiveSystem.ELEM_PCTSTATE1 = '100.00';
                                  updateProtectiveSystemCount(protectiveSystem);
                                "
                              />
                              <InputMessage
                                :showMessage="
                                  isEditable &&
                                  protectiveSystem.isSelected &&
                                  (!protectiveSystem.ELEM_QUANTITY ||
                                    protectiveSystem.ELEM_QUANTITY <= 0)
                                "
                                :message="ERROR_MESSAGES.GREATER_THAN_ZERO"
                              />
                            </td>
                            <td
                              class="align-center"
                              :style="
                                protectiveSystem.isSelected && isEditable
                                  ? ''
                                  : 'padding-top:8px !important'
                              "
                            >
                              <NumberField
                                :id="'text_systemSF_' + idx"
                                v-model="protectiveSystem.ELEM_SCALE_FACTOR"
                                :readonly="
                                  !protectiveSystem.isSelected || !isEditable
                                "
                                :decimalPlaces="1"
                                @change="
                                  updateProtectiveSystemCount(protectiveSystem)
                                "
                                :maxlength="12"
                                :rules="
                                  isEditable
                                    ? [
                                        (value) => {
                                          return lessThanValueRule(
                                            value,
                                            9999999.99
                                          );
                                        },
                                      ]
                                    : []
                                "
                              />
                            </td>
                            <td
                              style="padding-top: 8px !important"
                              class="align-center"
                            >
                              {{
                                configStore.getElementDefinition(
                                  protectiveSystem.ELEM_KEY
                                )?.elemScaleUnit
                              }}
                            </td>
                            <td class="align-center">
                              <NumberField
                                v-if="
                                  updateElementQtyGroup === ELEMENTS.QUANTITY
                                "
                                :id="'text_systemQty1_' + idx"
                                v-model="protectiveSystem.ELEM_QTYSTATE1"
                                readonly
                                readOnlyValidation
                                :rules="isEditable ? [greaterThanZeroRule] : []"
                              />
                              <NumberField
                                v-else
                                :id="'textsystemPct1_' + idx"
                                v-model="protectiveSystem.ELEM_PCTSTATE1"
                                readonly
                                readOnlyValidation
                                :rules="isEditable ? [greaterThanZeroRule] : []"
                                :decimalPlaces="2"
                              />
                            </td>
                            <td class="align-center">
                              <NumberField
                                v-if="
                                  updateElementQtyGroup === ELEMENTS.QUANTITY
                                "
                                :id="'text_systemQty2_' + idx"
                                v-model="protectiveSystem.ELEM_QTYSTATE2"
                                readonly
                                :rules="isEditable ? [greaterThanZeroRule] : []"
                                readOnlyValidation
                              />
                              <NumberField
                                v-else
                                :id="'text_systemPct2_' + idx"
                                v-model="protectiveSystem.ELEM_PCTSTATE2"
                                readonly
                                :decimalPlaces="2"
                                :rules="isEditable ? [greaterThanZeroRule] : []"
                                readOnlyValidation
                              />
                            </td>
                            <td class="align-center">
                              <NumberField
                                v-if="
                                  updateElementQtyGroup === ELEMENTS.QUANTITY
                                "
                                :id="'text_systemQty3_' + idx"
                                v-model="protectiveSystem.ELEM_QTYSTATE3"
                                readonly
                                readOnlyValidation
                                :rules="isEditable ? [greaterThanZeroRule] : []"
                              />
                              <NumberField
                                v-else
                                :id="'text_systemPct3_' + idx"
                                v-model="protectiveSystem.ELEM_PCTSTATE3"
                                readonly
                                :decimalPlaces="2"
                                readOnlyValidation
                                :rules="isEditable ? [greaterThanZeroRule] : []"
                              />
                            </td>
                            <td class="align-center">
                              <NumberField
                                v-if="
                                  updateElementQtyGroup === ELEMENTS.QUANTITY
                                "
                                :id="'text_systemQty4_' + idx"
                                v-model="protectiveSystem.ELEM_QTYSTATE4"
                                readonly
                                readOnlyValidation
                                :rules="isEditable ? [greaterThanZeroRule] : []"
                              />
                              <NumberField
                                v-else
                                :id="'text_systemPct4_' + idx"
                                v-model="protectiveSystem.ELEM_PCTSTATE4"
                                readonly
                                :decimalPlaces="2"
                                readOnlyValidation
                                :rules="isEditable ? [greaterThanZeroRule] : []"
                              />
                            </td>
                            <td></td>
                          </tr>
                          <template
                            v-for="(
                              subSystem, subIdx
                            ) in protectiveSystem?.SUB_COMPS"
                            :key="subSystem"
                          >
                            <tr class="table--row__align-top">
                              <td>
                                <div
                                  style="float: left"
                                  class="pl-10 h-100 pt-2"
                                >
                                  <v-icon
                                    icon="fa-solid fa-bolt-lightning"
                                    :style="
                                      protectiveSystem.isSelected
                                        ? 'opacity: 1'
                                        : 'opacity: 0.5'
                                    "
                                  />
                                </div>
                                <v-checkbox
                                  :id="
                                    'checkbox_selectSubSystem_' +
                                    idx +
                                    '_' +
                                    subIdx
                                  "
                                  v-model="subSystem.isSelected"
                                  @click="
                                    updateProtectiveSystemSelection(subSystem)
                                  "
                                  :disabled="
                                    !protectiveSystem.isSelected || !isEditable
                                  "
                                  hide-details
                                ></v-checkbox>
                              </td>
                              <td style="padding-top: 8px !important">
                                {{ subSystem.ELEM_KEY }} -
                                {{ subSystem.ELEM_SHORTNAME }}
                              </td>
                              <td class="align-center">
                                <NumberField
                                  :id="
                                    'text_subSystemQty_' + idx + '_' + subIdx
                                  "
                                  :readonly="
                                    !(
                                      updateElementQtyGroup ===
                                        ELEMENTS.PERCENTAGE &&
                                      subSystem.isSelected &&
                                      isEditable
                                    )
                                  "
                                  :allowCommas="false"
                                  @change="
                                    subSystem.ELEM_PCTSTATE1 = '100.00';
                                    subSystem.ELEM_QTYSTATE1 =
                                      subSystem.ELEM_QUANTITY;
                                    updateProtectiveSystemCount(subSystem);
                                  "
                                  v-model="subSystem.ELEM_QUANTITY"
                                  :rules="
                                    isEditable ? [greaterThanZeroRule] : []
                                  "
                                  readOnlyValidation
                                />
                                <InputMessage
                                  :showMessage="
                                    isEditable &&
                                    subSystem.isSelected &&
                                    updateElementQtyGroup ===
                                      ELEMENTS.PERCENTAGE &&
                                    (!subSystem.ELEM_QUANTITY ||
                                      subSystem.ELEM_QUANTITY <= 0)
                                  "
                                  :message="ERROR_MESSAGES.GREATER_THAN_ZERO"
                                />
                              </td>
                              <td class="align-center">
                                <NumberField
                                  :id="'text_subSystemSF_' + idx + '_' + subIdx"
                                  :readonly="
                                    !subSystem.isSelected || !isEditable
                                  "
                                  v-model="subSystem.ELEM_SCALE_FACTOR"
                                  :rules="
                                    isEditable
                                      ? [
                                          (value) => {
                                            return lessThanValueRule(
                                              value,
                                              9999999.99
                                            );
                                          },
                                        ]
                                      : []
                                  "
                                  :decimalPlaces="1"
                                  @change="
                                    updateProtectiveSystemCount(subSystem)
                                  "
                                />
                              </td>
                              <td
                                style="padding-top: 8px !important"
                                class="align-center"
                              >
                                {{
                                  configStore.getElementDefinition(
                                    subSystem.ELEM_PARENT_KEY
                                  )?.elemScaleUnit
                                }}
                              </td>
                              <td
                                class="align-center"
                                :style="
                                  subSystem.isSelected && isEditable
                                    ? ''
                                    : 'padding-top:8px !important'
                                "
                              >
                                <NumberField
                                  v-if="
                                    updateElementQtyGroup === ELEMENTS.QUANTITY
                                  "
                                  :id="
                                    'text_subSystemQty1_' + idx + '_' + subIdx
                                  "
                                  v-model="subSystem.ELEM_QTYSTATE1"
                                  readonly
                                  readOnlyValidation
                                  :rules="
                                    !subSystem.isSelected || !isEditable
                                      ? []
                                      : [
                                          (value) => {
                                            return shouldbeEqual(value, 0);
                                          },
                                        ]
                                  "
                                />
                                <NumberField
                                  v-else
                                  :id="
                                    'text_subSystemPct1_' + idx + '_' + subIdx
                                  "
                                  v-model="subSystem.ELEM_PCTSTATE1"
                                  readonly
                                  :decimalPlaces="2"
                                  readOnlyValidation
                                  :rules="
                                    !subSystem.isSelected || !isEditable
                                      ? []
                                      : [
                                          (value) => {
                                            return shouldbeEqual(value, 0);
                                          },
                                        ]
                                  "
                                />
                              </td>
                              <td
                                class="align-center"
                                :style="
                                  subSystem.isSelected && isEditable
                                    ? ''
                                    : 'padding-top:8px !important'
                                "
                              >
                                <NumberField
                                  v-if="
                                    updateElementQtyGroup === ELEMENTS.QUANTITY
                                  "
                                  :id="
                                    'text_subSystemQty2_' + idx + '_' + subIdx
                                  "
                                  v-model="subSystem.ELEM_QTYSTATE2"
                                  :readonly="
                                    !subSystem.isSelected || !isEditable
                                  "
                                  :allowCommas="false"
                                  @change="
                                    updateProtectiveSystemCount(subSystem)
                                  "
                                />
                                <NumberField
                                  v-else
                                  :id="
                                    'text_subSystemPct2_' + idx + '_' + subIdx
                                  "
                                  v-model="subSystem.ELEM_PCTSTATE2"
                                  :readonly="
                                    !subSystem.isSelected || !isEditable
                                  "
                                  :decimalPlaces="2"
                                  @change="
                                    updateProtectiveSystemCount(subSystem)
                                  "
                                  :rules="
                                    !subSystem.isSelected || !isEditable
                                      ? []
                                      : [
                                          (value) =>
                                            lessThanValueRule(value, 100),
                                        ]
                                  "
                                />
                                <InputMessage
                                  v-if="
                                    updateElementQtyGroup === ELEMENTS.QUANTITY
                                  "
                                  :showMessage="
                                    showErrorMessage(
                                      subSystem,
                                      ELEMENTS.QUANTITY
                                    )
                                  "
                                  :message="
                                    ERROR_MESSAGES.ATLEAST_ONE_GREATER_THAN_ZERO
                                  "
                                />
                                <InputMessage
                                  v-else
                                  :showMessage="
                                    showPercentErrorMessage(
                                      subSystem,
                                      ELEMENTS.PERCENTAGE
                                    )
                                  "
                                  :message="
                                    ERROR_MESSAGES.TOTAL_PERCENT_EQUAL_TO_100
                                  "
                                />
                              </td>
                              <td
                                class="align-center"
                                :style="
                                  subSystem.isSelected && isEditable
                                    ? ''
                                    : 'padding-top:8px !important'
                                "
                              >
                                <NumberField
                                  v-if="
                                    updateElementQtyGroup === ELEMENTS.QUANTITY
                                  "
                                  :id="
                                    'text_subSystemQty3_' + idx + '_' + subIdx
                                  "
                                  v-model="subSystem.ELEM_QTYSTATE3"
                                  :readonly="
                                    !subSystem.isSelected || !isEditable
                                  "
                                  :allowCommas="false"
                                  @change="
                                    updateProtectiveSystemCount(subSystem)
                                  "
                                />
                                <NumberField
                                  v-else
                                  :id="
                                    'text_subSystemPct3_' + idx + '_' + subIdx
                                  "
                                  v-model="subSystem.ELEM_PCTSTATE3"
                                  :readonly="
                                    !subSystem.isSelected || !isEditable
                                  "
                                  :decimalPlaces="2"
                                  @change="
                                    updateProtectiveSystemCount(subSystem)
                                  "
                                  :rules="
                                    !subSystem.isSelected || !isEditable
                                      ? []
                                      : [
                                          (value) =>
                                            lessThanValueRule(value, 100),
                                        ]
                                  "
                                />
                                <InputMessage
                                  v-if="
                                    updateElementQtyGroup === ELEMENTS.QUANTITY
                                  "
                                  :showMessage="
                                    showErrorMessage(
                                      subSystem,
                                      ELEMENTS.QUANTITY
                                    )
                                  "
                                  :message="
                                    ERROR_MESSAGES.ATLEAST_ONE_GREATER_THAN_ZERO
                                  "
                                />
                                <InputMessage
                                  v-else
                                  :showMessage="
                                    showPercentErrorMessage(
                                      subSystem,
                                      ELEMENTS.PERCENTAGE
                                    )
                                  "
                                  :message="
                                    ERROR_MESSAGES.TOTAL_PERCENT_EQUAL_TO_100
                                  "
                                />
                              </td>
                              <td
                                class="align-center"
                                :style="
                                  subSystem.isSelected && isEditable
                                    ? ''
                                    : 'padding-top:8px !important'
                                "
                              >
                                <NumberField
                                  v-if="
                                    updateElementQtyGroup === ELEMENTS.QUANTITY
                                  "
                                  :id="
                                    'text_subSystemQty4_' + idx + '_' + subIdx
                                  "
                                  v-model="subSystem.ELEM_QTYSTATE4"
                                  :readonly="
                                    !subSystem.isSelected || !isEditable
                                  "
                                  :allowCommas="false"
                                  @change="
                                    updateProtectiveSystemCount(subSystem)
                                  "
                                />
                                <NumberField
                                  v-else
                                  :id="
                                    'text_subSystemPct4_' + idx + '_' + subIdx
                                  "
                                  v-model="subSystem.ELEM_PCTSTATE4"
                                  :readonly="
                                    !subSystem.isSelected || !isEditable
                                  "
                                  :decimalPlaces="2"
                                  @change="
                                    updateProtectiveSystemCount(subSystem)
                                  "
                                  :rules="
                                    !subSystem.isSelected || !isEditable
                                      ? []
                                      : [
                                          (value) =>
                                            lessThanValueRule(value, 100),
                                        ]
                                  "
                                />
                                <InputMessage
                                  v-if="
                                    updateElementQtyGroup === ELEMENTS.QUANTITY
                                  "
                                  :showMessage="
                                    showErrorMessage(
                                      subSystem,
                                      ELEMENTS.QUANTITY
                                    )
                                  "
                                  :message="
                                    ERROR_MESSAGES.ATLEAST_ONE_GREATER_THAN_ZERO
                                  "
                                />
                                <InputMessage
                                  v-else
                                  :showMessage="
                                    showPercentErrorMessage(
                                      subSystem,
                                      ELEMENTS.PERCENTAGE
                                    )
                                  "
                                  :message="
                                    ERROR_MESSAGES.TOTAL_PERCENT_EQUAL_TO_100
                                  "
                                />
                              </td>
                              <td style="padding-top: 8px !important">
                                <v-icon
                                  icon="fas fa-circle-info"
                                  class="infoIcon"
                                  @click="openElementConditionDialog(subSystem)"
                                />
                              </td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </v-table>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel
                  id="panel_subComponents"
                  v-if="allSubComponents.length > 0"
                  value="subComponents"
                >
                  <v-expansion-panel-title
                    class="d-flex justify-start"
                    hide-actions
                    expand-icon="fa-solid fa-plus"
                    collapse-icon="fa-solid fa-minus"
                  >
                    <v-icon
                      v-if="elementsPanel.includes('subComponents')"
                      icon="fa-solid fa-minus"
                    />
                    <v-icon v-else icon="fa-solid fa-plus" />
                    <h3>Sub Components</h3>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-table class="compactTable w-100 elementsTable">
                      <thead>
                        <tr>
                          <th id="th_subcomp_applied" style="width: 7%">
                            <br />Applied
                          </th>
                          <th
                            style="width: 23%"
                            id="th_subcomp_elem"
                            class="dialog-table-cell_width__desc table--row__align-top"
                          >
                            <div class="align-center">
                              1B01<br />
                              Element Desc
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_qty"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1B10<br />
                              Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_sf"
                            class="align-center"
                          >
                            <br />SF
                          </th>
                          <th
                            style="width: 8%"
                            id="th_subcomp_uom"
                            class="align-center"
                          >
                            <br />UOM
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_pct1"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">1A11 <br />CS1 Pct</div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_qty1"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS1 Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_pct2"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">1A11 <br />CS2 Pct</div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_qty2"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS2 Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_pct3"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">1A11 <br />CS3 Pct</div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_qty3"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS3 Qty
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_pct4"
                            v-if="updateElementQtyGroup === ELEMENTS.PERCENTAGE"
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS4 Pct
                            </div>
                          </th>
                          <th
                            style="width: 10%"
                            id="th_subcomp_qty4"
                            v-else
                            class="table--row__align-top"
                          >
                            <div class="align-center">
                              1A11<br />
                              CS4 Qty
                            </div>
                          </th>

                          <th style="width: 2%" id="th_subcomp_info"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(subcomp, sidx) in allSubComponents"
                          :key="subcomp"
                          class="table--row__align-top"
                        >
                          <td>
                            <v-checkbox
                              :id="'checkbox_selectsubcomp_' + sidx"
                              v-model="subcomp.isSelected"
                              @click="updateDefectSelection(subcomp)"
                              hide-details
                              :disabled="!isEditable"
                            ></v-checkbox>
                          </td>
                          <td style="padding-top: 8px !important">
                            {{ subcomp.ELEM_KEY }} -
                            {{ subcomp.ELEM_SHORTNAME }}
                          </td>
                          <td
                            class="align-center"
                            :style="
                              subcomp.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              :id="'text_subcompQty_' + sidx"
                              v-model="subcomp.ELEM_QUANTITY"
                              :maxlength="quantityCountMaxLength"
                              :readonly="!(subcomp.isSelected && isEditable)"
                              :allowCommas="false"
                              @change="
                                subcomp.ELEM_PCTSTATE1 = '100.00';
                                subcomp.ELEM_QTYSTATE1 = subcomp.ELEM_QUANTITY;
                                updateSubComponentCount(subcomp);
                              "
                            />
                            <InputMessage
                              :showMessage="
                                isEditable &&
                                subcomp.isSelected &&
                                (!subcomp.ELEM_QUANTITY ||
                                  subcomp.ELEM_QUANTITY <= 0)
                              "
                              :message="ERROR_MESSAGES.GREATER_THAN_ZERO"
                            />
                          </td>
                          <td
                            class="align-center"
                            :style="
                              subcomp.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              :id="'text_subcompSF_' + sidx"
                              v-model="subcomp.ELEM_SCALE_FACTOR"
                              :readonly="!subcomp.isSelected || !isEditable"
                              :decimalPlaces="1"
                              :maxlength="12"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return lessThanValueRule(
                                          value,
                                          9999999.99
                                        );
                                      },
                                    ]
                                  : []
                              "
                              @change="updateSubComponentCount(subcomp)"
                            />
                          </td>
                          <td
                            style="padding-top: 8px !important"
                            class="align-center"
                          >
                            {{
                              configStore.getElementDefinition(subcomp.ELEM_KEY)
                                ?.elemScaleUnit
                            }}
                          </td>
                          <td class="align-center">
                            <NumberField
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :id="'text_subcompQty1_' + sidx"
                              v-model="subcomp.ELEM_QTYSTATE1"
                              readonly
                              readOnlyValidation
                              :rules="isEditable ? [greaterThanZeroRule] : []"
                            />
                            <NumberField
                              v-else
                              :id="'text_subcompPct1_' + sidx"
                              v-model="subcomp.ELEM_PCTSTATE1"
                              readonly
                              readOnlyValidation
                              :rules="isEditable ? [greaterThanZeroRule] : []"
                              :decimalPlaces="2"
                            />
                          </td>
                          <td
                            class="align-center"
                            :style="
                              subcomp.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :id="'text_subcompQty2_' + sidx"
                              :allowCommas="false"
                              v-model="subcomp.ELEM_QTYSTATE2"
                              :readonly="!subcomp.isSelected || !isEditable"
                              @change="updateSubComponentCount(subcomp)"
                            />
                            <NumberField
                              v-else
                              :id="'text_subcompPct2_' + sidx"
                              v-model="subcomp.ELEM_PCTSTATE2"
                              :readonly="!subcomp.isSelected || !isEditable"
                              :decimalPlaces="2"
                              @change="updateSubComponentCount(subcomp)"
                              :rules="
                                !subcomp.isSelected || !isEditable
                                  ? []
                                  : [(value) => lessThanValueRule(value, 100)]
                              "
                            />
                          </td>
                          <td
                            class="align-center"
                            :style="
                              subcomp.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :id="'text_subcompQty3_' + sidx"
                              v-model="subcomp.ELEM_QTYSTATE3"
                              :allowCommas="false"
                              :readonly="!subcomp.isSelected || !isEditable"
                              @change="updateSubComponentCount(subcomp)"
                            />
                            <NumberField
                              v-else
                              :id="'text_subcompPct3_' + sidx"
                              v-model="subcomp.ELEM_PCTSTATE3"
                              :readonly="!subcomp.isSelected || !isEditable"
                              :decimalPlaces="2"
                              @change="updateSubComponentCount(subcomp)"
                              :rules="
                                !subcomp.isSelected || !isEditable
                                  ? []
                                  : [(value) => lessThanValueRule(value, 100)]
                              "
                            />
                          </td>
                          <td
                            class="align-center"
                            :style="
                              subcomp.isSelected && isEditable
                                ? ''
                                : 'padding-top:8px !important'
                            "
                          >
                            <NumberField
                              v-if="updateElementQtyGroup === ELEMENTS.QUANTITY"
                              :id="'text_subcompQty4_' + sidx"
                              v-model="subcomp.ELEM_QTYSTATE4"
                              :allowCommas="false"
                              :readonly="!subcomp.isSelected || !isEditable"
                              @change="updateSubComponentCount(subcomp)"
                            />
                            <NumberField
                              v-else
                              :id="'text_subcompPct4_' + sidx"
                              v-model="subcomp.ELEM_PCTSTATE4"
                              :readonly="!subcomp.isSelected || !isEditable"
                              :decimalPlaces="2"
                              @change="updateSubComponentCount(subcomp)"
                              :rules="
                                !subcomp.isSelected || !isEditable
                                  ? []
                                  : [(value) => lessThanValueRule(value, 100)]
                              "
                            />
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          variant="outlined"
          class="mx-5"
          @click="validate"
          :disabled="!isEditable"
          v-if="inspectionPage === INSPECTION_PAGES.ELEMENTS"
        >
          <span v-if="showElementDialog.isAdd">Add Element</span>
          <span v-else>Update Element</span>
        </v-btn>
        <v-btn @click="closeUpdateDialog" variant="outlined" class="mx-5">
          Close
        </v-btn></v-card-actions
      >
    </v-card>
  </v-dialog>
  <!-- Element Definition lookup -->
  <v-dialog v-model="dialog" class="dialogForm">
    <v-card>
      <v-card-title>
        <span class="text-h5" style="font-weight: bold"
          >{{ selectedElementToShowInfo?.COND_TEXT?.[0]?.ELEM_KEY }}-{{
            selectedElementToShowInfo?.COND_TEXT?.[0]?.ELEM_STATE_NAME_SHORT
          }}</span
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <ol class="pl-3">
          <li
            v-for="condition in selectedElementToShowInfo?.COND_TEXT"
            :key="condition"
            class="pa-2"
          >
            <span>{{ condition.ELEM_STATE_DESC }}</span>
          </li>
        </ol>
        <v-divider />
        <span>{{
          getElemConditionLegalText(selectedElementToShowInfo.LEGAL_TXT_CD)
        }}</span>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          variant="outlined"
          class="mx-5"
          @click="
            {
              dialog = false;
              resetSelections();
            }
          "
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Copy Element -->
  <v-dialog v-model="showCopyElementDialog" class="dialogForm" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Copy NBE Element</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form v-model="validCopyElementForm" ref="copyElementForm">
          <v-row
            ><v-col>
              <v-label class="bold">Source Structure Unit: </v-label>
              <v-select
                id="select_sourceStructureUnit"
                label=""
                :items="sourceStructureUnits"
                v-model="selectedCopyElementSourceStructureUnitId"
                @update:modelValue="
                  {
                    selectedCopyElementTargetStructureUnitId = null;
                    selectedCopyElementElementId = null;
                    getElements(selectedCopyElementSourceStructureUnitId);
                    setTargetUnits();
                    copyElementForm.validate();
                  }
                "
                hide-details="auto"
                persistent-placeholder
                required
                :rules="[requiredRule]"
              >
              </v-select
            ></v-col>
            <v-col>
              <v-label class="bold">Source Elem ID: </v-label>
              <v-select
                id="select_sourceElement"
                label=""
                v-model="selectedCopyElementElementId"
                :items="sourceElemsArr"
                @update:modelValue="
                  {
                    selectedCopyElementTargetStructureUnitId = null;
                    setTargetUnits();
                    copyElementForm.validate();
                  }
                "
                hide-details="auto"
                persistent-placeholder
                :disabled="!selectedCopyElementSourceStructureUnitId"
                required
                :rules="[requiredRule]"
              >
              </v-select
            ></v-col>
            <v-col>
              <v-label class="bold">Target Structure Unit: </v-label>
              <v-select
                id="select_targetStructureUnit"
                label=""
                v-model="selectedCopyElementTargetStructureUnitId"
                :items="targetCopyElementStructureUnits"
                :hide-details="!selectedCopyElementElementId ? 'true' : 'auto'"
                @update:modelValue="
                  {
                    copyElementForm.validate();
                  }
                "
                persistent-placeholder
                :disabled="!selectedCopyElementElementId"
                required
                :rules="[requiredRule]"
              >
              </v-select></v-col></v-row></v-form
      ></v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          variant="outlined"
          class="mx-5"
          @click="
            {
              copyElementSave();
              showCopyElementDialog = false;
            }
          "
          :disabled="!validCopyElementForm"
        >
          Save
        </v-btn>
        <v-btn
          variant="outlined"
          class="mx-5"
          @click="
            {
              showCopyElementDialog = false;
              resetSelections();
            }
          "
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Copy Structure Unit -->
  <v-dialog v-model="showCopyStructureUnitDialog" class="dialogForm" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Copy All Elements from a Structure Unit</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form v-model="validCopyStructureUnitForm">
          <v-row
            ><v-col>
              <v-label class="bold">Source Structure Unit: </v-label>
              <v-select
                id="select_sourceStructureUnit"
                :items="sourceStructureUnits"
                v-model="selectedCopyStructureUnitSourceId"
                label=""
                hide-details="auto"
                persistent-placeholder
                required
                :rules="[requiredRule]"
              >
              </v-select
            ></v-col>
            <v-col>
              <v-label class="bold">Target Structure Unit: </v-label>
              <v-select
                id="select_targetStructureUnit"
                label=""
                :items="targetCopyStructureUnits"
                v-model="selectedCopyStructureUnitTargetId"
                hide-details="auto"
                persistent-placeholder
                :disabled="!selectedCopyStructureUnitSourceId"
                required
                :rules="[requiredRule]"
              >
              </v-select></v-col></v-row
          ><v-row
            ><v-col
              ><span
                >Cannot copy to Structure Unit that already has elements
                defined</span
              ></v-col
            ></v-row
          >
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          variant="outlined"
          class="mx-5"
          @click="
            {
              copyStructureUnitSave();
              showCopyStructureUnitDialog = false;
            }
          "
          :disabled="!validCopyStructureUnitForm"
        >
          Save
        </v-btn>
        <v-btn
          variant="outlined"
          class="mx-5"
          @click="showCopyStructureUnitDialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
  <v-dialog v-model="systemElementMessage.show">
    <v-card class="pa-3 error-dialog">
      <p class="h3 mb-6"><strong>&nbsp;</strong></p>
      <p class="mb-2">{{ systemElementMessage.message }}</p>
      <button
        @click="systemElementMessage.show = false"
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon size="x-large" icon="fas fa-xmark mx-2" />
        <small>CLOSE</small>
      </button>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed, reactive, watch, toRefs, nextTick } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { clone } from "@/util/clone";
import { useConfigStore } from "@/stores/config";
import {
  ELEMENTS,
  BUTTONS,
  ERROR_MESSAGES,
} from "@/constants/CommonWebConstants";
import useStructureType from "@/composables/structureType";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import {
  requiredRule,
  greaterThanZeroRule,
  lessThanValueRule,
  shouldbeEqual,
} from "@/composables/validationRules";
import {
  convertToInt,
  convertToFloat,
  convertToPercent,
  convertFromPercent,
  convertToDecimalString,
} from "@/composables/util";
import NumberField from "@/components/common/NumberField.vue";
import { LOGGER } from "@/util/logger";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import {
  STRUCTURE_TYPE,
  FUNCTIONAL_SYSTEM,
  INSPECTION_MESSAGES,
} from "@/constants/InspectionConstants";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import InputMessage from "@/components/common/InputMessage.vue";
import InspectionElementRow from "@/components/inspection/InspectionElementRow.vue";
import LabelText from "@/components/common/LabelText.vue";
import { INSPECTION_PAGES } from "@/constants/Inspections";
import LabelSelect from "@/components/common/LabelSelect.vue";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

export default {
  name: "InspectionElements",
  props: ["inspectionPage"],
  setup(props) {
    const inspectionStore = useInspectionStore();
    const configStore = useConfigStore();
    const snackbarStore = useSnackbarStore();
    let allDefects = ref([]);
    let allProtectiveSystems = ref([]);
    let allSubComponents = ref([]);

    let structure = computed(() => inspectionStore.selectedInspection);
    let structureUnitGroups = reactive({});
    let dialog = ref(false);
    let selectedElementToShowInfo = ref({});
    const { getStructureType } = useStructureType();
    let tempSelectedElemInspections = [];
    const modifyElementForm = ref(null);
    let valid = ref(true);
    let changesDetected = ref(false);
    const closeConfirm = ref(null);
    let validCopyElementForm = ref(false);
    let copyElementForm = ref(null);
    let validCopyStructureUnitForm = ref(false);
    const quantityCountMaxLength = ref(7);
    const { inspectionPage } = toRefs(props);
    let snackBarId = ref("");

    const isEditable = computed(() => {
      if (inspectionPage.value === INSPECTION_PAGES.TUNNELS) {
        return false;
      } else {
        return inspectionStore.isEditable;
      }
    });

    const elementTypes = configStore
      .getAddElementDefinitions(
        getStructureType(structure.value.Bridge?.SERVTYPON)
      )
      .map((e) => ({
        value: e.elemId,
        title: `${e.elemId} - ${e.elemShortName}`,
      }))
      .sort((a, b) => {
        return a.value - b.value;
      });

    const structureUnitsToAddElems = structure.value?.Structure_Unit?.filter(
      (a) => {
        if (
          getStructureType(structure.value.Bridge?.SERVTYPON) ===
          STRUCTURE_TYPE.TUNNEL
        ) {
          return (
            a.STRUNITTYPE == "N" ||
            a.STRUNITTYPE == "R" ||
            a.STRUNITTYPE == "S" ||
            a.STRUNITTYPE == "Z"
          );
        } else if (
          getStructureType(structure.value.Bridge?.SERVTYPON) ===
          STRUCTURE_TYPE.BRIDGE
        ) {
          return (
            a.STRUNITTYPE == "A" || a.STRUNITTYPE == "M" || a.STRUNITTYPE == "C"
          );
        }
      }
    );
    const structureUnitTypes = structureUnitsToAddElems?.map((b) => ({
      title: `${b.STRUNITKEY} - Type - ${configStore.getReferenceValue(
        REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
        b.STRUNITTYPE
      )}`,
      value: b.STRUNITKEY,
    }));

    let functionalSystemElements = {};
    //build the hierarchical view of elements/defects/protective systems
    function buildElements(inspElements) {
      let tree = [];
      let mappedArr = {};
      // Build a hash table and map items to objects
      inspElements?.forEach(function (item) {
        //id is combination of keys (can't use elemKey as one defect with an elemKey can be part of multiple elements)
        let id =
          item.ELEM_KEY +
          "-" +
          (item.ELEM_PARENT_KEY || 0) +
          "-" +
          (item.ELEM_GRANDPARENT_KEY || 0);
        mappedArr[id] = item;
        mappedArr[id].children = [];
      });

      buildChildElements(mappedArr, tree);
      buildProtectiveSystemChildElements(mappedArr, tree);

      return tree;
    }
    function buildChildElements(mappedArr, tree) {
      //loop through the hash table and add children(defects and protective systems) to elements
      for (let id in mappedArr) {
        let mappedElem = mappedArr[id];
        if (!mappedElem.children) {
          mappedElem.children = [];
        }
        //add children
        if (
          (!mappedElem.ELEM_GRANDPARENT_KEY ||
            mappedElem.ELEM_GRANDPARENT_KEY == 0) &&
          mappedElem.ELEM_PARENT_KEY > 0
        ) {
          mappedArr[mappedElem.ELEM_PARENT_KEY + "-0-0"].children.push(
            mappedElem
          );
        } else if (mappedElem.ELEM_GRANDPARENT_KEY > 0) {
          //skip sub systems as they are taken care in the below loop
        } else {
          checkIfFunctionalSystems(mappedElem, tree);
        }
      }
    }
    function checkIfFunctionalSystems(mappedElem, tree) {
      const fnSystem = getFunctionalSystem(mappedElem.ELEM_KEY);
      if (!fnSystem) {
        tree.push(mappedElem);
      }
    }

    function getFunctionalSystem(elementKey) {
      //get the functional system for an element key(if exists)
      //get elemCat and elemType keys for elementKey
      const elemDef = configStore.getElementDefinition(elementKey);
      //get matching functional system
      return configStore
        .getAddElementDefinitions(
          getStructureType(structure.value.Bridge?.SERVTYPON)
        )
        ?.find(
          (elem) =>
            elem.elemCatKey === elemDef?.elemCatKey &&
            elem.elemTypeKey === elemDef?.elemTypeKey &&
            elem.elemMatKey != elemDef?.elemMatKey &&
            elem.elemMatKey === FUNCTIONAL_SYSTEM.MAT_KEY
        );
    }

    function buildProtectiveSystemChildElements(mappedArr, tree) {
      //loop through the hash table and add children(sub components) to protective systems added to the tree above
      for (let id in mappedArr) {
        let mappedElem = mappedArr[id];
        if (mappedElem.ELEM_GRANDPARENT_KEY > 0) {
          let element = tree.find(
            (g) => g.ELEM_KEY == mappedElem.ELEM_GRANDPARENT_KEY
          );
          //if element is not found is specific structure, check if it is in the functional element list
          const fnSystem = getFunctionalSystem(mappedElem.ELEM_GRANDPARENT_KEY);
          if (!element) {
            element = functionalSystemElements[fnSystem?.elemId]?.find(
              (a) => a.ELEM_KEY == mappedElem.ELEM_GRANDPARENT_KEY
            );
          }
          let protectiveSystem = element?.children.find(
            (c) => c.ELEM_KEY == mappedElem.ELEM_PARENT_KEY
          );
          if (protectiveSystem?.children.indexOf(mappedElem) == -1) {
            protectiveSystem.children.push(mappedElem);
          }
        }
      }
    }

    // Function to convert the json array of elements and its defects/protective systems to hierarchy so it can be displayed on UI
    function convertToHierarchy() {
      functionalSystemElements = {};
      let elems = clone(structure.value?.Pon_Elem_Insp)?.sort(
        (a, b) => a.STRUNITKEY - b.STRUNITKEY || a.ELEM_KEY - b.ELEM_KEY
      );
      //Add all structure units that can have elements to the hashtable
      structureUnitsToAddElems?.forEach(
        (a) => (structureUnitGroups[a.STRUNITKEY] = clone(a))
      );
      //structureUnitGroups is a hashtable with StructureUnit as key and list of elements as value
      elems?.forEach((item) => {
        if (item && item != null) {
          const list = structureUnitGroups[item?.STRUNITKEY]?.tempElements;
          if (list) {
            list.push(item);
          } else if (structureUnitGroups[item?.STRUNITKEY]) {
            structureUnitGroups[item?.STRUNITKEY].tempElements = [item];
          }
        }
        const fnSystem = getFunctionalSystem(item.ELEM_KEY);
        if (!fnSystem) {
          //handled below
        } else if (functionalSystemElements[fnSystem.elemId]) {
          functionalSystemElements[fnSystem.elemId].push(item);
        } else {
          functionalSystemElements[fnSystem.elemId] = [item];
        }
      });
      for (let key in structureUnitGroups) {
        let structureUnitElements = structureUnitGroups[key];
        //build elements hierarchy for each structure unit
        structureUnitElements.elements = buildElements(
          structureUnitElements?.tempElements
        ).sort((a, b) => a.ELEM_KEY - b.ELEM_KEY);
        sortElements(structureUnitElements.elements);
      }
    }

    function sortElements(structureUnitElements) {
      structureUnitElements?.forEach((element) => {
        element.children?.sort((a, b) => {
          const elemA = configStore.getElementDefinition(a.ELEM_KEY);
          const elemB = configStore.getElementDefinition(b.ELEM_KEY);

          const compareProtectSys =
            (elemA.elemProtectSys === "Y") - (elemB.elemProtectSys === "Y");
          if (compareProtectSys !== 0) {
            return compareProtectSys;
          }

          const compareSubComp =
            (elemA.elemSubComp === "Y") - (elemB.elemSubComp === "Y");
          if (compareSubComp !== 0) {
            return compareSubComp;
          }

          return a.ELEM_KEY - b.ELEM_KEY;
        });
        // // If Functional system array has elements for the elem_key add them to the children list
        // //adding the elements at the end of the list
        if (functionalSystemElements[element.ELEM_KEY]) {
          element.children = element.children.concat(
            functionalSystemElements[element.ELEM_KEY]
          );
        }

        element.children.forEach((child) => {
          child.children?.sort((a, b) => {
            return a.ELEM_KEY - b.ELEM_KEY;
          });
        });
      });
    }

    convertToHierarchy();
    //hadle expand/collapse of structure unit expansion panel
    let panel = ref([]);
    const allPanels = [];

    if (structureUnitGroups) {
      for (
        let index = 0;
        index < Object.keys(structureUnitGroups).length;
        index++
      ) {
        panel.value.push("structureUnit_" + index);
        allPanels.push("structureUnit_" + index);
      }
    }

    //hadle  expand/collapse of elements under structure unit
    let elementsOpened = ref([]);
    //hadle  expand/collapse of sub components under protective system
    let protectiveSystemselementsOpened = ref([]);
    let functionalElementsOpened = ref([]);

    let showElementDialog = ref({});
    let showCopyElementDialog = ref(false);
    let showCopyStructureUnitDialog = ref(false);
    let selectedElement = ref({});
    let elementsPanel = ref(["defects", "protectiveSystems", "subComponents"]);

    const elemDescriptionMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.ELEM_DESC_MAX_LENGTH
      );
    });

    const elemConditionMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.ELEM_CONDITION_MAX_LENGTH
      );
    });

    function getElemConditionLegalText(legalTextCode) {
      if (legalTextCode == 1) {
        return configStore.getEnvConfigValue(
          ENV_CONFIG_PROPERTY.ELEM_CONDITION_LEGAL_TEXT1
        );
      } else if (legalTextCode == 2) {
        return configStore.getEnvConfigValue(
          ENV_CONFIG_PROPERTY.ELEM_CONDITION_LEGAL_TEXT2
        );
      } else {
        return legalTextCode;
      }
    }

    let elementQtyGroup = ref(ELEMENTS.QUANTITY);
    let updateElementQtyGroup = ref(ELEMENTS.QUANTITY);

    function resetAddElementForUnitKeyChange(structureUnitKey) {
      updateElementQtyGroup.value = ELEMENTS.QUANTITY;
      elementsPanel.value = [];
      allDefects.value = [];
      allProtectiveSystems.value = [];
      allSubComponents.value = [];
      selectedElement.value = {
        ELEM_KEY: null,
        STRUNITKEY: structureUnitKey,
        ELEM_TAG: "",
        ENVKEY: 3,
        uom: "",
        ELEM_QUANTITY: null,
        ELEM_SCALE_FACTOR: "1.0",
        ELEM_DESC: "",
        ELEM_NOTES: "",
        ELEM_QTYSTATE1: null,
        ELEM_QTYSTATE2: null,
        ELEM_QTYSTATE3: null,
        ELEM_QTYSTATE4: null,
        ELEM_PCTSTATE1: null,
        ELEM_PCTSTATE2: null,
        ELEM_PCTSTATE3: null,
        ELEM_PCTSTATE4: null,
        ELEM_PARENT_KEY: 0,
        ELEM_GRANDPARENT_KEY: 0,
        defects: [],
        protectiveSystems: [],
      };
      nextTick(() => {
        modifyElementForm.value.validate();
      });
    }

    function addElement() {
      changesDetected.value = false;
      //clone the non null inspection elements - Pon_Elem_Insp contains null object when there are no elements
      tempSelectedElemInspections = clone(
        structure.value.Pon_Elem_Insp
          ? structure.value.Pon_Elem_Insp?.filter((n) => n)
          : []
      );

      showElementDialog.value.show = true;
      showElementDialog.value.isAdd = true;
      updateElementQtyGroup.value = ELEMENTS.QUANTITY;
      elementsPanel.value = [];
      allDefects.value = [];
      allProtectiveSystems.value = [];
      allSubComponents.value = [];
      selectedElement.value = {
        ELEM_KEY: null,
        STRUNITKEY: null,
        ELEM_TAG: "",
        ENVKEY: 3,
        uom: "",
        ELEM_QUANTITY: null,
        ELEM_SCALE_FACTOR: "1.0",
        ELEM_DESC: "",
        ELEM_NOTES: "",
        ELEM_QTYSTATE1: null,
        ELEM_QTYSTATE2: null,
        ELEM_QTYSTATE3: null,
        ELEM_QTYSTATE4: null,
        ELEM_PCTSTATE1: null,
        ELEM_PCTSTATE2: null,
        ELEM_PCTSTATE3: null,
        ELEM_PCTSTATE4: null,
        ELEM_PARENT_KEY: 0,
        ELEM_GRANDPARENT_KEY: 0,
        defects: [],
        protectiveSystems: [],
      };
      nextTick(() => {
        modifyElementForm.value.validate();
      });
    }
    //Copy Element or Copy StructureUnit
    let selectedCopyElementSourceStructureUnitId = ref(null);
    let selectedCopyElementElementId = ref(null);
    let selectedCopyElementTargetStructureUnitId = ref(null);
    let selectedCopyStructureUnitSourceId = ref(null);
    let selectedCopyStructureUnitTargetId = ref(null);
    let sourceStructureUnitsArr = ref([]);
    let sourceStructureUnits = ref([]);
    let targetCopyStructureUnitsArr = ref([]);
    let targetCopyStructureUnits = ref([]);
    let targetCopyElementStructureUnitsArr = ref([]);
    let targetCopyElementStructureUnits = ref([]);

    function setCopySourceStructureUnits() {
      for (let key in structureUnitGroups) {
        //Functional systems can't be copied
        if (
          structure.value?.Structure_Unit?.find((a) => a?.STRUNITKEY == key)
            ?.STRUNITTYPE != FUNCTIONAL_SYSTEM.STR_UNIT_TYPE
        ) {
          let structureUnit = structureUnitGroups?.[key];
          if (structureUnit?.elements?.length > 0) {
            sourceStructureUnitsArr.value.push(structureUnit);
          } else {
            targetCopyStructureUnitsArr.value.push(structureUnit);
          }
        }
      }
      sourceStructureUnits.value = sourceStructureUnitsArr.value?.map((b) => ({
        title: `${b?.STRUNITKEY} - Type -  ${configStore.getReferenceValue(
          REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
          b?.STRUNITTYPE
        )}`,
        value: b?.STRUNITKEY,
      }));
      targetCopyStructureUnits.value = targetCopyStructureUnitsArr.value?.map(
        (b) => ({
          title: `${b?.STRUNITKEY} - Type - ${configStore.getReferenceValue(
            REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
            b?.STRUNITTYPE
          )}`,
          value: b?.STRUNITKEY,
        })
      );
    }

    //get elements of a structure unit
    let sourceElemsArr = ref([]);
    function getElements(structureUnitKey) {
      sourceElemsArr.value = structureUnitGroups[structureUnitKey]?.elements
        .map((b) => ({
          title: `${b.ELEM_INV_ID}/${b.ENVKEY}/${b.ELEM_KEY}-${
            configStore.getElementDefinition(b.ELEM_KEY)?.elemLongName
          }`,
          value: b.ELEM_KEY,
        }))
        .sort((a, b) => a.value - b.value);
    }

    function setTargetUnits() {
      targetCopyElementStructureUnitsArr.value = [];
      targetCopyElementStructureUnits.value = [];
      for (let key in structureUnitGroups) {
        let structureUnit = structureUnitGroups[key];
        if (
          structure.value?.Structure_Unit?.find((a) => a.STRUNITKEY == key)
            ?.STRUNITTYPE != FUNCTIONAL_SYSTEM.STR_UNIT_TYPE &&
          !structureUnit?.elements.find(
            (a) => a.ELEM_KEY == selectedCopyElementElementId.value
          )
        ) {
          targetCopyElementStructureUnitsArr.value.push(structureUnit);
        }
      }
      targetCopyElementStructureUnits.value =
        targetCopyElementStructureUnitsArr.value.map((b) => ({
          title: `${b.STRUNITKEY} - Type - ${configStore.getReferenceValue(
            REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
            b.STRUNITTYPE
          )}`,
          value: b.STRUNITKEY,
        }));
    }

    function copyElement() {
      resetSelections();
      setCopySourceStructureUnits();
      showCopyElementDialog.value = true;
    }
    function copyStructureUnit() {
      resetSelections();
      setCopySourceStructureUnits();
      showCopyStructureUnitDialog.value = true;
    }
    function resetSelections() {
      selectedCopyElementSourceStructureUnitId.value = null;
      selectedCopyElementElementId.value = null;
      selectedCopyElementTargetStructureUnitId.value = null;
      selectedCopyStructureUnitSourceId.value = null;
      selectedCopyStructureUnitTargetId.value = null;
      sourceStructureUnitsArr.value = [];
      sourceStructureUnits.value = [];
      targetCopyStructureUnitsArr.value = [];
      targetCopyStructureUnits.value = [];
      targetCopyElementStructureUnitsArr.value = [];
      targetCopyElementStructureUnits.value = [];
      sourceElemsArr.value = [];
    }
    let noFunctionalSystemMessage = ref(null);
    function getDefectsAndProtectiveSystems(elemKey) {
      selectedElement.value.uom =
        configStore.getElementDefinition(elemKey)?.elemScaleUnit;
      noFunctionalSystemMessage.value = null;
      //check if selected element should have a functional system before added
      const fnSystem = getFunctionalSystem(elemKey);

      if (!fnSystem) {
        getAllDefects(elemKey);
        getAllProtectiveSystems(elemKey);
        getAllSubComponents(elemKey);
      } else if (
        tempSelectedElemInspections.find(
          (addedElem) => addedElem.ELEM_KEY === fnSystem.elemId
        )
      ) {
        //if functional system is needed, check if it is already added
        noFunctionalSystemMessage.value = null;
        getAllDefects(elemKey);
        getAllProtectiveSystems(elemKey);
        getAllSubComponents(elemKey);
      } else {
        noFunctionalSystemMessage.value =
          INSPECTION_MESSAGES.SYSTEM_ELEMENT_REQUIRED +
          ` - ${fnSystem.elemId}-${fnSystem.elemShortName}`;
      }
    }

    function getAllDefects(elemKey) {
      let elemWithChildren = configStore.getElementChildren(elemKey);
      allDefects.value = clone(elemWithChildren.defects);
    }
    function getAllProtectiveSystems(elemKey) {
      let elemWithChildren = configStore.getElementChildren(elemKey);
      allProtectiveSystems.value = clone(elemWithChildren.protectiveSystems);
    }
    function getAllSubComponents(elemKey) {
      let elemWithChildren = configStore.getElementChildren(elemKey);
      allSubComponents.value = clone(elemWithChildren.subComponents);
    }

    function toggle(id) {
      const index = elementsOpened.value.indexOf(id);
      if (index > -1) {
        elementsOpened.value.splice(index, 1);
        //close underlying protective system sub components.
        protectiveSystemselementsOpened.value =
          protectiveSystemselementsOpened.value.filter(
            (a) => a.indexOf(id) == -1
          );
        //close underlying functional system system sub components.
        functionalElementsOpened.value = functionalElementsOpened.value.filter(
          (a) => a.indexOf(id) == -1
        );
      } else {
        elementsOpened.value.push(id);
      }
    }
    function toggleProtectiveSystems(id) {
      const index = protectiveSystemselementsOpened.value.indexOf(id);
      if (index > -1) {
        protectiveSystemselementsOpened.value.splice(index, 1);
        //close underlying functional system system sub components.
        functionalElementsOpened.value = functionalElementsOpened.value.filter(
          (a) => a.indexOf(id) == -1
        );
      } else {
        protectiveSystemselementsOpened.value.push(id);
      }
    }
    function toggleFunctionalElements(id) {
      const index = functionalElementsOpened.value.indexOf(id);
      if (index > -1) {
        functionalElementsOpened.value.splice(index, 1);
      } else {
        functionalElementsOpened.value.push(id);
      }
    }

    function setCounts(selected, element) {
      selected.isSelected = true;
      selected.ELEM_QUANTITY = element.ELEM_QUANTITY;
      selected.ELEM_QTYSTATE1 = element.ELEM_QTYSTATE1;
      selected.ELEM_QTYSTATE2 = element.ELEM_QTYSTATE2;
      selected.ELEM_QTYSTATE3 = element.ELEM_QTYSTATE3;
      selected.ELEM_QTYSTATE4 = element.ELEM_QTYSTATE4;
      selected.ELEM_PCTSTATE1 = convertToDecimalString(
        element.ELEM_PCTSTATE1,
        2
      );
      selected.ELEM_PCTSTATE2 = convertToDecimalString(
        element.ELEM_PCTSTATE2,
        2
      );
      selected.ELEM_PCTSTATE3 = convertToDecimalString(
        element.ELEM_PCTSTATE3,
        2
      );
      selected.ELEM_PCTSTATE4 = convertToDecimalString(
        element.ELEM_PCTSTATE4,
        2
      );
      selected.ELEM_SCALE_FACTOR = element.ELEM_SCALE_FACTOR;
    }

    function editUnit(element) {
      changesDetected.value = false;
      updateElementQtyGroup.value = ELEMENTS.QUANTITY;
      tempSelectedElemInspections = clone(structure.value.Pon_Elem_Insp);
      showElementDialog.value.isAdd = false;
      elementsPanel.value = ["defects", "protectiveSystems", "subComponents"];
      let { children, ...props } = element;
      selectedElement.value = { ...props };
      selectedElement.value.ELEM_QUANTITY = getQuantityRoundedWithoutComma(
        selectedElement.value.ELEM_KEY,
        selectedElement.value.ELEM_QUANTITY
      );
      selectedElement.value.ELEM_QTYSTATE1 = getQuantityRoundedWithoutComma(
        selectedElement.value.ELEM_KEY,
        selectedElement.value.ELEM_QTYSTATE1
      );
      selectedElement.value.ELEM_QTYSTATE2 = getQuantityRoundedWithoutComma(
        selectedElement.value.ELEM_KEY,
        selectedElement.value.ELEM_QTYSTATE2
      );
      selectedElement.value.ELEM_QTYSTATE3 = getQuantityRoundedWithoutComma(
        selectedElement.value.ELEM_KEY,
        selectedElement.value.ELEM_QTYSTATE3
      );
      selectedElement.value.ELEM_QTYSTATE4 = getQuantityRoundedWithoutComma(
        selectedElement.value.ELEM_KEY,
        selectedElement.value.ELEM_QTYSTATE4
      );
      //set scale factor to decimal
      selectedElement.value.ELEM_SCALE_FACTOR = parseFloat(
        selectedElement.value.ELEM_SCALE_FACTOR
      ).toFixed(1);
      selectedElement.value.defects = [];
      selectedElement.value.protectiveSystems = [];
      selectedElement.value.subComponents = [];

      children.forEach((e) => {
        //set scale factor to decimal
        e.ELEM_SCALE_FACTOR = parseFloat(e.ELEM_SCALE_FACTOR).toFixed(1);
        if (
          configStore.getElementDefinition(e.ELEM_KEY).elemProtectSys === "Y"
        ) {
          selectedElement.value.protectiveSystems.push(e);
          e.children.forEach((subSystem) => {
            selectedElement.value.protectiveSystems.push(subSystem);
          });
        } else if (
          configStore.getElementDefinition(e.ELEM_KEY).elemSubComp === "Y"
        ) {
          selectedElement.value.subComponents.push(e);
        } else if (
          configStore.getElementDefinition(e.ELEM_KEY).elemSmartFlag === "Y"
        ) {
          selectedElement.value.defects.push(e);
        }
      });

      getDefectsAndProtectiveSystems(element.ELEM_KEY);
      //loop through selected defects and set selected flag
      selectedElement.value.defects.forEach((defect) => {
        let selectedDefect = allDefects.value.find(
          (a) => a.ELEM_KEY == defect.ELEM_KEY
        );
        setCounts(selectedDefect, defect);
      });
      selectedElement.value.subComponents.forEach((subComp) => {
        let selectedSubComp = allSubComponents.value.find(
          (a) => a.ELEM_KEY == subComp.ELEM_KEY
        );
        setCounts(selectedSubComp, subComp);
      });

      //loop through selected protective systems and set selected flag
      selectedElement.value.protectiveSystems.forEach((system) => {
        //sub components under protective system
        if (system.ELEM_GRANDPARENT_KEY) {
          let selectedSubcomponent = allProtectiveSystems.value
            .find((a) => a.ELEM_KEY == system.ELEM_PARENT_KEY)
            .SUB_COMPS.find((c) => c.ELEM_KEY == system.ELEM_KEY);
          selectedSubcomponent.isSelected = true;
          selectedSubcomponent.ELEM_QUANTITY = system.ELEM_QUANTITY;
          selectedSubcomponent.ELEM_QTYSTATE1 = system.ELEM_QTYSTATE1;
          selectedSubcomponent.ELEM_QTYSTATE2 = system.ELEM_QTYSTATE2;
          selectedSubcomponent.ELEM_QTYSTATE3 = system.ELEM_QTYSTATE3;
          selectedSubcomponent.ELEM_QTYSTATE4 = system.ELEM_QTYSTATE4;
          selectedSubcomponent.ELEM_PCTSTATE1 = convertToDecimalString(
            system.ELEM_PCTSTATE1,
            2
          );
          selectedSubcomponent.ELEM_PCTSTATE2 = convertToDecimalString(
            system.ELEM_PCTSTATE2,
            2
          );
          selectedSubcomponent.ELEM_PCTSTATE3 = convertToDecimalString(
            system.ELEM_PCTSTATE3,
            2
          );
          selectedSubcomponent.ELEM_PCTSTATE4 = convertToDecimalString(
            system.ELEM_PCTSTATE4,
            2
          );
          selectedSubcomponent.ELEM_SCALE_FACTOR = system.ELEM_SCALE_FACTOR;
        } else if (system.ELEM_PARENT_KEY) {
          let selectedProtectiveSystem = allProtectiveSystems.value.find(
            (a) => a.ELEM_KEY == system.ELEM_KEY
          );
          selectedProtectiveSystem.isSelected = true;
          selectedProtectiveSystem.ELEM_QUANTITY =
            getQuantityRoundedWithoutComma(
              system.ELEM_KEY,
              system.ELEM_QUANTITY
            );
          selectedProtectiveSystem.ELEM_QTYSTATE1 =
            getQuantityRoundedWithoutComma(
              system.ELEM_KEY,
              system.ELEM_QTYSTATE1
            );
          selectedProtectiveSystem.ELEM_QTYSTATE2 =
            getQuantityRoundedWithoutComma(
              system.ELEM_KEY,
              system.ELEM_QTYSTATE2
            );
          selectedProtectiveSystem.ELEM_QTYSTATE3 =
            getQuantityRoundedWithoutComma(
              system.ELEM_KEY,
              system.ELEM_QTYSTATE3
            );
          selectedProtectiveSystem.ELEM_QTYSTATE4 =
            getQuantityRoundedWithoutComma(
              system.ELEM_KEY,
              system.ELEM_QTYSTATE4
            );
          selectedProtectiveSystem.ELEM_PCTSTATE1 = convertToFloat(
            system.ELEM_PCTSTATE1,
            0
          );
          selectedProtectiveSystem.ELEM_PCTSTATE2 = convertToFloat(
            system.ELEM_PCTSTATE2,
            0
          );
          selectedProtectiveSystem.ELEM_PCTSTATE3 = convertToFloat(
            system.ELEM_PCTSTATE3,
            0
          );
          selectedProtectiveSystem.ELEM_PCTSTATE4 = convertToFloat(
            system.ELEM_PCTSTATE4,
            0
          );
          selectedProtectiveSystem.ELEM_SCALE_FACTOR = system.ELEM_SCALE_FACTOR;
        }
      });
      nextTick(() => {
        modifyElementForm.value?.validate();
      });
    }
    let systemElementMessage = ref({ show: false, message: null });

    function deleteUnit(element, structureUnitKey) {
      systemElementMessage.value.show = false;
      const fnSystem =
        configStore.getElementDefinition(element.ELEM_KEY)?.elemMatKey ===
        FUNCTIONAL_SYSTEM.MAT_KEY
          ? configStore.getElementDefinition(element.ELEM_KEY)
          : null;
      let fnElement;
      if (fnSystem) {
        //check if system has functional elements
        fnElement = element.children?.find((a) => {
          const def = configStore.getElementDefinition(a.ELEM_KEY);
          if (
            !(
              def.elemProtectSys === "Y" ||
              def.elemSubComp === "Y" ||
              def.elemSmartFlag === "Y"
            )
          ) {
            //functional element exists
            systemElementMessage.value.show = true;
            systemElementMessage.value.message =
              INSPECTION_MESSAGES.SYSTEM_DELETE_ERROR;
            return a;
          } else {
            systemElementMessage.value.message = null;
            systemElementMessage.value.show = false;
            return null;
          }
        });
      }
      if (!fnElement) {
        structure.value.Pon_Elem_Insp = structure.value.Pon_Elem_Insp.filter(
          (a) =>
            !(
              a.STRUNITKEY == structureUnitKey &&
              (a.ELEM_KEY == element.ELEM_KEY ||
                a.ELEM_PARENT_KEY == element.ELEM_KEY ||
                a.ELEM_GRANDPARENT_KEY == element.ELEM_KEY)
            )
        );
        convertToHierarchy();
      }
    }

    function openElementConditionDialog(element) {
      dialog.value = true;
      selectedElementToShowInfo.value = element;
    }

    function updateDefectSelection(defect) {
      if (!defect.isSelected) {
        defect.isSelected = true;
        defect.ELEM_PARENT_KEY = selectedElement.value.ELEM_KEY;
        defect.ELEM_SCALE_FACTOR = "1.0";
        defect.STRUNITKEY = selectedElement.value.STRUNITKEY;
        defect.BRKEY = structure.value.Bridge.BRKEY;
        defect.INSPKEY = structure.value.InspEvnt.INSPKEY;
        defect.ELEM_INV_ID = getNextInvId(tempSelectedElemInspections);
        defect.ELEM_DOCREFKEY = "";
        defect.ELEM_PARENT_ID = "";
        defect.ELEM_CREATEDATETIME = new Date().toISOString();
        defect.ELEM_MODTIME = new Date().toISOString();
        defect.ELEM_MODUSERKEY = "";
        defect.ELEM_CREATEUSERKEY = "";
        defect.ELEM_TAG = selectedElement.value.ELEM_TAG;
        defect.ENVKEY = selectedElement.value.ENVKEY;
        defect.ELEM_DESC = "";
        defect.ELEM_NOTES = "";
        tempSelectedElemInspections.push(defect);
      } else {
        defect.isSelected = false;
        clearElementValues(defect, true);
        const selectedIndex = tempSelectedElemInspections.findIndex(
          (d) =>
            d.STRUNITKEY == selectedElement.value.STRUNITKEY &&
            d.ELEM_KEY == defect.ELEM_KEY &&
            d.ELEM_PARENT_KEY == (defect.ELEM_PARENT_KEY || 0)
        );
        if (selectedIndex > -1) {
          tempSelectedElemInspections.splice(selectedIndex, 1);
        }
        modifyElementForm.value.validate();
      }
      defect.isModified = true;
    }
    function getInspectionElementObject(value) {
      return {
        BRKEY: structure.value.Bridge.BRKEY,
        INSPKEY: structure.value.InspEvnt.INSPKEY,
        ELEM_INV_ID: value?.ELEM_INV_ID,
        ELEM_CREATEDATETIME:
          value.ELEM_CREATEDATETIME || new Date().toISOString(),
        ELEM_CREATEUSERKEY: value.ELEM_CREATEUSERKEY || "",
        ELEM_DESC: value?.ELEM_DESC,
        ELEM_DOCREFKEY: value.ELEM_DOCREFKEY || "",
        ELEM_GRANDPARENT_KEY: value.ELEM_GRANDPARENT_KEY || 0,
        ELEM_KEY: value.ELEM_KEY,
        ELEM_MODTIME: value.ELEM_MODTIME || new Date().toISOString(),
        ELEM_MODUSERKEY: value.ELEM_MODUSERKEY || "",
        ELEM_NOTES: value.ELEM_NOTES,
        ELEM_PARENT_ID: value.ELEM_PARENT_ID || 0,
        ELEM_PARENT_KEY: value?.ELEM_PARENT_KEY,
        ELEM_PCTSTATE1: convertToFloat(value.ELEM_PCTSTATE1, 0),
        ELEM_PCTSTATE2: convertToFloat(value.ELEM_PCTSTATE2, 0),
        ELEM_PCTSTATE3: convertToFloat(value.ELEM_PCTSTATE3, 0),
        ELEM_PCTSTATE4: convertToFloat(value.ELEM_PCTSTATE4, 0),
        ELEM_QTYSTATE1: convertToFloat(value.ELEM_QTYSTATE1, 0),
        ELEM_QTYSTATE2: convertToFloat(value.ELEM_QTYSTATE2, 0),
        ELEM_QTYSTATE3: convertToFloat(value.ELEM_QTYSTATE3, 0),
        ELEM_QTYSTATE4: convertToFloat(value.ELEM_QTYSTATE4, 0),
        ELEM_QUANTITY: convertToFloat(value.ELEM_QUANTITY),
        ELEM_SCALE_FACTOR: convertToFloat(value.ELEM_SCALE_FACTOR),
        ELEM_TAG: value?.ELEM_TAG,
        ENVKEY: value?.ENVKEY,
        STRUNITKEY: selectedElement.value.STRUNITKEY,
      };
    }

    function editElementCount(element) {
      if (element) {
        let editedElement = tempSelectedElemInspections.find(
          (d) =>
            d.STRUNITKEY == selectedElement.value.STRUNITKEY &&
            d.ELEM_KEY == element.ELEM_KEY &&
            d.ELEM_PARENT_KEY == element.ELEM_PARENT_KEY &&
            d.ELEM_GRANDPARENT_KEY == (element?.ELEM_GRANDPARENT_KEY || 0)
        );
        updateElementCounts(editedElement, element);
        //For sub components update protective systems count
        if (element.ELEM_GRANDPARENT_KEY && element.ELEM_PARENT_KEY) {
          let protectiveSystem = tempSelectedElemInspections.find(
            (d) =>
              d.STRUNITKEY == selectedElement.value.STRUNITKEY &&
              d.ELEM_KEY == element.ELEM_PARENT_KEY &&
              d.ELEM_PARENT_KEY == element.ELEM_GRANDPARENT_KEY
          );
          //recalculate protective system counts
          let sysWithNewCounts = allProtectiveSystems.value.find(
            (p) =>
              p.ELEM_KEY == element.ELEM_PARENT_KEY &&
              p.ELEM_PARENT_KEY == element.ELEM_GRANDPARENT_KEY
          );
          updateElementCounts(protectiveSystem, sysWithNewCounts);
        }
      }
    }
    function updateElementCounts(editedElement, element) {
      if (editedElement) {
        editedElement.ELEM_QUANTITY = convertToFloat(element.ELEM_QUANTITY);
        editedElement.ELEM_QTYSTATE1 = convertToFloat(element.ELEM_QTYSTATE1);
        editedElement.ELEM_QTYSTATE2 = convertToFloat(element.ELEM_QTYSTATE2);
        editedElement.ELEM_QTYSTATE3 = convertToFloat(element.ELEM_QTYSTATE3);
        editedElement.ELEM_QTYSTATE4 = convertToFloat(element.ELEM_QTYSTATE4);
        editedElement.ELEM_PCTSTATE1 = element.ELEM_PCTSTATE1;
        editedElement.ELEM_PCTSTATE2 = element.ELEM_PCTSTATE2;
        editedElement.ELEM_PCTSTATE3 = element.ELEM_PCTSTATE3;
        editedElement.ELEM_PCTSTATE4 = element.ELEM_PCTSTATE4;
        editedElement.ELEM_SCALE_FACTOR = element.ELEM_SCALE_FACTOR;
        editedElement.ELEM_QUANTITY_Converted = element.ELEM_QUANTITY_Converted;
      }
    }

    function updateSubComponentCount(subcomp) {
      allSubComponents.value.forEach((a) => {
        updateSubCompCount(a);
      });
      editElementCount(subcomp);
    }

    function updateSubCompCount(a) {
      let totalElemQuantity = convertToInt(a.ELEM_QUANTITY);
      if (updateElementQtyGroup.value === ELEMENTS.PERCENTAGE) {
        //When percentage is selected
        if (totalElemQuantity > 0) {
          a.ELEM_QTYSTATE2 = convertFromPercent(
            a.ELEM_PCTSTATE2,
            totalElemQuantity
          );
          a.ELEM_QTYSTATE3 = convertFromPercent(
            a.ELEM_PCTSTATE3,
            totalElemQuantity
          );
          a.ELEM_QTYSTATE4 = convertFromPercent(
            a.ELEM_PCTSTATE4,
            totalElemQuantity
          );
        }
      }
      if (a.ELEM_QUANTITY > 0) {
        calculatePercentages(a);
        a.ELEM_PCTSTATE1 = convertToDecimalString(
          100.0 -
            (convertToFloat(a.ELEM_PCTSTATE2, 0) +
              convertToFloat(a.ELEM_PCTSTATE3, 0) +
              convertToFloat(a.ELEM_PCTSTATE4, 0)),
          2
        );
        a.ELEM_QTYSTATE1 =
          a.ELEM_QUANTITY -
          (convertToInt(a.ELEM_QTYSTATE2) +
            convertToInt(a.ELEM_QTYSTATE3) +
            convertToInt(a.ELEM_QTYSTATE4));
      }
    }

    function updateCount(a) {
      if (updateElementQtyGroup.value === ELEMENTS.QUANTITY) {
        a.ELEM_QUANTITY =
          !a.ELEM_QTYSTATE2 && !a.ELEM_QTYSTATE3 && !a.ELEM_QTYSTATE4
            ? null
            : convertToInt(a.ELEM_QTYSTATE2) +
              convertToInt(a.ELEM_QTYSTATE3) +
              convertToInt(a.ELEM_QTYSTATE4);
      } else {
        //When percentage is selected
        let totalElemQuantity = convertToInt(a.ELEM_QUANTITY);

        if (totalElemQuantity > 0) {
          a.ELEM_QTYSTATE2 = convertFromPercent(
            a.ELEM_PCTSTATE2,
            totalElemQuantity
          );
          a.ELEM_QTYSTATE3 = convertFromPercent(
            a.ELEM_PCTSTATE3,
            totalElemQuantity
          );
          a.ELEM_QTYSTATE4 = convertFromPercent(
            a.ELEM_PCTSTATE4,
            totalElemQuantity
          );
        }
      }
      if (a.ELEM_QUANTITY > 0) {
        calculatePercentages(a);
        a.ELEM_PCTSTATE1 = convertToDecimalString(
          100.0 -
            (convertToFloat(a.ELEM_PCTSTATE2, 0) +
              convertToFloat(a.ELEM_PCTSTATE3, 0) +
              convertToFloat(a.ELEM_PCTSTATE4, 0)),
          2
        );
        a.ELEM_QTYSTATE1 =
          a.ELEM_QUANTITY -
          (convertToInt(a.ELEM_QTYSTATE2) +
            convertToInt(a.ELEM_QTYSTATE3) +
            convertToInt(a.ELEM_QTYSTATE4));
      }
    }

    function updateDefectCount(defect) {
      let totalCS2Qty = 0;
      let totalCS3Qty = 0;
      let totalCS4Qty = 0;
      let totalCS2Pct = 0.0;
      let totalCS3Pct = 0.0;
      let totalCS4Pct = 0.0;

      allDefects.value.forEach((a) => {
        updateCount(a);
        totalCS2Qty = totalCS2Qty + convertToInt(a.ELEM_QTYSTATE2);
        totalCS3Qty = totalCS3Qty + convertToInt(a.ELEM_QTYSTATE3);
        totalCS4Qty = totalCS4Qty + convertToInt(a.ELEM_QTYSTATE4);

        totalCS2Pct = totalCS2Pct + convertToFloat(a.ELEM_PCTSTATE2, 0);
        totalCS3Pct = totalCS3Pct + convertToFloat(a.ELEM_PCTSTATE3, 0);
        totalCS4Pct = totalCS4Pct + convertToFloat(a.ELEM_PCTSTATE4, 0);
      });

      selectedElement.value.ELEM_QTYSTATE1 =
        convertToInt(selectedElement.value.ELEM_QUANTITY) -
        (totalCS2Qty + totalCS3Qty + totalCS4Qty);
      selectedElement.value.ELEM_QTYSTATE2 = totalCS2Qty;
      selectedElement.value.ELEM_QTYSTATE3 = totalCS3Qty;
      selectedElement.value.ELEM_QTYSTATE4 = totalCS4Qty;

      selectedElement.value.ELEM_PCTSTATE1 = convertToPercent(
        selectedElement.value.ELEM_QTYSTATE1,
        selectedElement.value.ELEM_QUANTITY
      );
      selectedElement.value.ELEM_PCTSTATE2 = convertToPercent(
        selectedElement.value.ELEM_QTYSTATE2,
        selectedElement.value.ELEM_QUANTITY
      );
      selectedElement.value.ELEM_PCTSTATE3 = convertToPercent(
        selectedElement.value.ELEM_QTYSTATE3,
        selectedElement.value.ELEM_QUANTITY
      );
      selectedElement.value.ELEM_PCTSTATE4 = convertToPercent(
        selectedElement.value.ELEM_QTYSTATE4,
        selectedElement.value.ELEM_QUANTITY
      );
      editElementCount(defect);
    }

    function setProtectiveSystemCount(c) {
      if (updateElementQtyGroup.value === ELEMENTS.QUANTITY) {
        c.ELEM_QUANTITY =
          !c.ELEM_QTYSTATE2 && !c.ELEM_QTYSTATE3 && !c.ELEM_QTYSTATE4
            ? null
            : convertToInt(c.ELEM_QTYSTATE2) +
              convertToInt(c.ELEM_QTYSTATE3) +
              convertToInt(c.ELEM_QTYSTATE4);
      } else {
        //When percentage is selected
        let totalElemQuantity = convertToInt(c.ELEM_QUANTITY);
        if (totalElemQuantity > 0) {
          c.ELEM_QTYSTATE2 = convertFromPercent(
            c.ELEM_PCTSTATE2,
            totalElemQuantity
          );
          c.ELEM_QTYSTATE3 = convertFromPercent(
            c.ELEM_PCTSTATE3,
            totalElemQuantity
          );
          c.ELEM_QTYSTATE4 = convertFromPercent(
            c.ELEM_PCTSTATE4,
            totalElemQuantity
          );
        }
      }
    }

    function updateProtectiveSystemCount(subSystem) {
      allProtectiveSystems.value.forEach((p) => {
        let totalCS2Qty = 0;
        let totalCS3Qty = 0;
        let totalCS4Qty = 0;
        let totalCS2Pct = 0.0;
        let totalCS3Pct = 0.0;
        let totalCS4Pct = 0.0;

        p.SUB_COMPS.forEach((c) => {
          setProtectiveSystemCount(c);
          totalCS2Qty = totalCS2Qty + convertToInt(c.ELEM_QTYSTATE2);
          totalCS3Qty = totalCS3Qty + convertToInt(c.ELEM_QTYSTATE3);
          totalCS4Qty = totalCS4Qty + convertToInt(c.ELEM_QTYSTATE4);

          if (c.ELEM_QUANTITY > 0) {
            calculatePercentages(c);
            totalCS2Pct = totalCS2Pct + convertToFloat(c.ELEM_PCTSTATE2, 0);
            totalCS3Pct = totalCS3Pct + convertToFloat(c.ELEM_PCTSTATE3, 0);
            totalCS4Pct = totalCS4Pct + convertToFloat(c.ELEM_PCTSTATE4, 0);
            c.ELEM_PCTSTATE1 = convertToDecimalString(
              100.0 -
                (convertToFloat(c.ELEM_PCTSTATE2, 0) +
                  convertToFloat(c.ELEM_PCTSTATE3, 0) +
                  convertToFloat(c.ELEM_PCTSTATE4, 0)),
              2
            );
            c.ELEM_QTYSTATE1 =
              c.ELEM_QUANTITY -
              (convertToInt(c.ELEM_QTYSTATE2) +
                convertToInt(c.ELEM_QTYSTATE3) +
                convertToInt(c.ELEM_QTYSTATE4));
          }
        });

        p.ELEM_QTYSTATE1 =
          convertToInt(p.ELEM_QUANTITY) -
          (totalCS2Qty + totalCS3Qty + totalCS4Qty);

        p.ELEM_QTYSTATE2 = totalCS2Qty;
        p.ELEM_QTYSTATE3 = totalCS3Qty;
        p.ELEM_QTYSTATE4 = totalCS4Qty;
        //Store the converted values in separate variable so they won't interfere with the calculations and will be sent to the API
        p.ELEM_QUANTITY_Converted = configStore.getEnglishToMetricConversions(
          configStore.getElementDefinition(p.ELEM_KEY).elemPairCode,
          p.ELEM_QUANTITY
        );

        p.ELEM_PCTSTATE1 = convertToPercent(p.ELEM_QTYSTATE1, p.ELEM_QUANTITY);
        p.ELEM_PCTSTATE2 = convertToPercent(p.ELEM_QTYSTATE2, p.ELEM_QUANTITY);
        p.ELEM_PCTSTATE3 = convertToPercent(p.ELEM_QTYSTATE3, p.ELEM_QUANTITY);
        p.ELEM_PCTSTATE4 = convertToPercent(p.ELEM_QTYSTATE4, p.ELEM_QUANTITY);
      });
      editElementCount(subSystem);
    }

    function calculatePercentages(a) {
      a.ELEM_PCTSTATE2 = convertToPercent(a.ELEM_QTYSTATE2, a.ELEM_QUANTITY);
      a.ELEM_PCTSTATE3 = convertToPercent(a.ELEM_QTYSTATE3, a.ELEM_QUANTITY);
      a.ELEM_PCTSTATE4 = convertToPercent(a.ELEM_QTYSTATE4, a.ELEM_QUANTITY);
    }

    function updateProtectiveSystemSelection(protectiveSystem) {
      if (!protectiveSystem.isSelected) {
        protectiveSystem.isSelected = true;
        protectiveSystem.STRUNITKEY = selectedElement.value.STRUNITKEY;
        if (!protectiveSystem.SUB_COMPS) {
          //Add sub components
          protectiveSystem.ELEM_GRANDPARENT_KEY =
            selectedElement.value.ELEM_KEY;
        } else {
          //Add protective systems
          protectiveSystem.ELEM_PARENT_KEY = selectedElement.value.ELEM_KEY;
        }
        protectiveSystem.BRKEY = structure.value.Bridge.BRKEY;
        protectiveSystem.INSPKEY = structure.value.InspEvnt.INSPKEY;
        protectiveSystem.ELEM_INV_ID = getNextInvId(
          tempSelectedElemInspections
        );
        protectiveSystem.ELEM_CREATEDATETIME = new Date().toISOString();
        protectiveSystem.ELEM_MODTIME = new Date().toISOString();
        protectiveSystem.ELEM_MODUSERKEY = "";
        protectiveSystem.ELEM_CREATEUSERKEY = "";
        protectiveSystem.ELEM_DOCREFKEY = "";
        protectiveSystem.ELEM_PARENT_ID = "";
        protectiveSystem.ELEM_SCALE_FACTOR = "1.0";
        protectiveSystem.ELEM_TAG = selectedElement.value.ELEM_TAG;
        protectiveSystem.ENVKEY = selectedElement.value.ENVKEY;
        protectiveSystem.ELEM_DESC = "";
        protectiveSystem.ELEM_NOTES = "";
        tempSelectedElemInspections.push(protectiveSystem);
      } else {
        protectiveSystem.SUB_COMPS?.forEach((element) => {
          element.isSelected = false;
          clearElementValues(element, false);
          const index = tempSelectedElemInspections.findIndex(
            (p) =>
              p.STRUNITKEY == selectedElement.value.STRUNITKEY &&
              p.ELEM_KEY == element.ELEM_KEY &&
              p.ELEM_PARENT_KEY == (element.ELEM_PARENT_KEY || 0) &&
              p.ELEM_GRANDPARENT_KEY == (element.ELEM_GRANDPARENT_KEY || 0)
          );
          if (index > -1) {
            tempSelectedElemInspections.splice(index, 1);
          }
        });
        protectiveSystem.isSelected = false;
        clearElementValues(protectiveSystem, false);
        const selectedIndex = tempSelectedElemInspections.findIndex(
          (p) =>
            p.STRUNITKEY == selectedElement.value.STRUNITKEY &&
            p.ELEM_KEY == protectiveSystem.ELEM_KEY &&
            p.ELEM_PARENT_KEY == (protectiveSystem.ELEM_PARENT_KEY || 0) &&
            p.ELEM_GRANDPARENT_KEY ==
              (protectiveSystem.ELEM_GRANDPARENT_KEY || 0)
        );
        if (selectedIndex > -1) {
          tempSelectedElemInspections.splice(selectedIndex, 1);
        }
        modifyElementForm.value.validate();
      }
      protectiveSystem.isModified = true;
    }
    function clearElementValues(element, isDefect) {
      element.ELEM_SCALE_FACTOR = null;
      element.ELEM_QUANTITY = null;
      element.ELEM_QTYSTATE1 = null;
      element.ELEM_QTYSTATE2 = null;
      element.ELEM_QTYSTATE3 = null;
      element.ELEM_QTYSTATE4 = null;
      element.ELEM_PCTSTATE1 = null;
      element.ELEM_PCTSTATE2 = null;
      element.ELEM_PCTSTATE3 = null;
      element.ELEM_PCTSTATE4 = null;

      if (isDefect) {
        updateDefectCount(null);
      } else {
        updateProtectiveSystemCount(null);
        //update parent protective system count
        editElementCount(element);
      }
    }

    function getQuantityRounded(elemKey, value) {
      let quantity = configStore.getMetricToEnglishConversions(
        configStore.getElementDefinition(elemKey)?.elemPairCode,
        value,
        ROUNDING_FACTOR.ROUND
      );
      if (quantity === "") {
        return "0";
      }
      return quantity;
    }

    function getQuantityRoundedWithoutComma(elemKey, value) {
      return parseFloat(getQuantityRounded(elemKey, value).replaceAll(",", ""));
    }

    function convertToElemInsp() {
      //convert modified protective systems and defects to inspection element object
      tempSelectedElemInspections.forEach(function (inspElement, i) {
        //set the metric conversions for protective system(high level row)
        if (inspElement.ELEM_QUANTITY_Converted) {
          inspElement.ELEM_QUANTITY = configStore.getEnglishToMetricConversions(
            configStore.getElementDefinition(inspElement.ELEM_KEY).elemPairCode,
            inspElement.ELEM_QUANTITY
          );
          inspElement.ELEM_QTYSTATE1 =
            configStore.getEnglishToMetricConversions(
              configStore.getElementDefinition(inspElement.ELEM_KEY)
                .elemPairCode,
              inspElement.ELEM_QTYSTATE1
            );
          inspElement.ELEM_QTYSTATE2 =
            configStore.getEnglishToMetricConversions(
              configStore.getElementDefinition(inspElement.ELEM_KEY)
                .elemPairCode,
              inspElement.ELEM_QTYSTATE2
            );
          inspElement.ELEM_QTYSTATE3 =
            configStore.getEnglishToMetricConversions(
              configStore.getElementDefinition(inspElement.ELEM_KEY)
                .elemPairCode,
              inspElement.ELEM_QTYSTATE3
            );
          inspElement.ELEM_QTYSTATE4 =
            configStore.getEnglishToMetricConversions(
              configStore.getElementDefinition(inspElement.ELEM_KEY)
                .elemPairCode,
              inspElement.ELEM_QTYSTATE4
            );
          tempSelectedElemInspections[i] =
            getInspectionElementObject(inspElement);
        } else if (inspElement.isModified) {
          tempSelectedElemInspections[i] =
            getInspectionElementObject(inspElement);
        }
      });
      structure.value.Pon_Elem_Insp = clone(tempSelectedElemInspections);

      //set the metric conversions for defects
      selectedElement.value.ELEM_QTYSTATE1 =
        configStore.getEnglishToMetricConversions(
          configStore.getElementDefinition(selectedElement.value.ELEM_KEY)
            .elemPairCode,
          selectedElement.value.ELEM_QTYSTATE1
        );
      selectedElement.value.ELEM_QTYSTATE2 =
        configStore.getEnglishToMetricConversions(
          configStore.getElementDefinition(selectedElement.value.ELEM_KEY)
            .elemPairCode,
          selectedElement.value.ELEM_QTYSTATE2
        );
      selectedElement.value.ELEM_QTYSTATE3 =
        configStore.getEnglishToMetricConversions(
          configStore.getElementDefinition(selectedElement.value.ELEM_KEY)
            .elemPairCode,
          selectedElement.value.ELEM_QTYSTATE3
        );
      selectedElement.value.ELEM_QTYSTATE4 =
        configStore.getEnglishToMetricConversions(
          configStore.getElementDefinition(selectedElement.value.ELEM_KEY)
            .elemPairCode,
          selectedElement.value.ELEM_QTYSTATE4
        );
      selectedElement.value.ELEM_QUANTITY =
        configStore.getEnglishToMetricConversions(
          configStore.getElementDefinition(selectedElement.value.ELEM_KEY)
            .elemPairCode,
          selectedElement.value.ELEM_QUANTITY
        );
      let index = structure.value.Pon_Elem_Insp.findIndex(
        (a) =>
          a.ELEM_KEY == selectedElement.value.ELEM_KEY &&
          a.STRUNITKEY == selectedElement.value.STRUNITKEY
      );
      if (index > -1) {
        structure.value.Pon_Elem_Insp[index] = getInspectionElementObject(
          selectedElement.value
        );
      } else {
        selectedElement.value.ELEM_INV_ID = getNextInvId(
          structure.value.Pon_Elem_Insp
        );
        structure.value.Pon_Elem_Insp.push(
          getInspectionElementObject(selectedElement.value)
        );
      }
      populateCorrespondingParentId();
      convertToHierarchy();
    }

    function populateCorrespondingParentId() {
      structure.value.Pon_Elem_Insp.forEach((element) => {
        const parent = structure.value.Pon_Elem_Insp.find((p) => {
          if (
            element.ELEM_GRANDPARENT_KEY &&
            element.ELEM_GRANDPARENT_KEY > 0
          ) {
            return (
              p.ELEM_KEY === element.ELEM_PARENT_KEY &&
              p.STRUNITKEY === element.STRUNITKEY &&
              p.ELEM_PARENT_KEY === element.ELEM_GRANDPARENT_KEY
            );
          } else {
            return (
              p.ELEM_KEY === element.ELEM_PARENT_KEY &&
              p.STRUNITKEY === element.STRUNITKEY
            );
          }
        });
        element.ELEM_PARENT_ID = parent ? parent.ELEM_INV_ID : 0;
      });
    }

    //when cancel is clicked on Add element dialog
    function resetInspectionChanges() {
      convertToHierarchy();
    }

    function copyElementSave() {
      let elemInspectionsToBeCopied = structure.value?.Pon_Elem_Insp.filter(
        (a) => a.STRUNITKEY == selectedCopyElementSourceStructureUnitId.value
      ).filter(
        (b) =>
          b.ELEM_KEY == selectedCopyElementElementId.value ||
          b.ELEM_PARENT_KEY == selectedCopyElementElementId.value ||
          b.ELEM_GRANDPARENT_KEY == selectedCopyElementElementId.value
      );
      setCopiedElementIds(
        elemInspectionsToBeCopied,
        selectedCopyElementTargetStructureUnitId.value
      );
      convertToHierarchy();
      resetSelections();
    }

    function copyStructureUnitSave() {
      let elementsToBeCopied = structure.value?.Pon_Elem_Insp.filter(
        (a) => a.STRUNITKEY == selectedCopyStructureUnitSourceId.value
      );
      setCopiedElementIds(
        elementsToBeCopied,
        selectedCopyStructureUnitTargetId.value
      );
      convertToHierarchy();
      resetSelections();
    }

    const setCopiedElementIds = (elementsToBeCopied, targetStrUnitKey) => {
      let oldNewIds = new Map();
      let tempCopiedArr = [];
      let index = getNextInvId(structure.value?.Pon_Elem_Insp);
      elementsToBeCopied.forEach((a) => {
        let copiedInspectionElement = clone(a);
        copiedInspectionElement.STRUNITKEY = targetStrUnitKey;
        const oldId = copiedInspectionElement.ELEM_INV_ID;
        copiedInspectionElement.ELEM_INV_ID = index++;
        oldNewIds.set(oldId, copiedInspectionElement.ELEM_INV_ID);
        tempCopiedArr.push(copiedInspectionElement);
      });

      tempCopiedArr.forEach((a) => {
        if (a.ELEM_PARENT_ID) {
          const newId = oldNewIds.get(a.ELEM_PARENT_ID);
          a.ELEM_PARENT_ID = newId;
        }
        structure.value?.Pon_Elem_Insp.push(a);
      });
    };

    function validate() {
      //form validations
      modifyElementForm.value
        .validate()
        .then(() => {
          if (valid.value) {
            inspectionStore.setShowValidationSnackbar(false);
            let isValid = true;
            //check for valid counts (manual validation)
            for (const selectedInspection of tempSelectedElemInspections) {
              if (
                !selectedInspection.ELEM_QUANTITY ||
                selectedInspection.ELEM_QUANTITY <= 0
              ) {
                isValid = false;
                break;
              }
            }
            //check for no functional system message
            isValid = isValid && !noFunctionalSystemMessage.value;
            if (isValid) {
              convertToElemInsp();
              showElementDialog.value.show = false;
            } else {
              inspectionStore.setShowValidationSnackbar(true);
            }
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => LOGGER.logException(err));
    }

    function closeUpdateDialog() {
      noFunctionalSystemMessage.value = null;
      if (!isEditable.value || !changesDetected.value) {
        showElementDialog.value.show = false;
      } else {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.POPUP_DETECT_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              showElementDialog.value.show = false;
              resetInspectionChanges();
            }
          });
      }
    }

    function getNextInvId(inspectionElements) {
      return Math.max(...inspectionElements.map((a) => a.ELEM_INV_ID), 0) + 1;
    }

    const showErrorMessage = (element, qtyGroup) => {
      return (
        element.isSelected &&
        isEditable.value &&
        updateElementQtyGroup.value === qtyGroup &&
        (!element.ELEM_QTYSTATE2 || element.ELEM_QTYSTATE2 < 0) &&
        (!element.ELEM_QTYSTATE3 || element.ELEM_QTYSTATE3 < 0) &&
        (!element.ELEM_QTYSTATE4 || element.ELEM_QTYSTATE4 < 0)
      );
    };

    const showPercentErrorMessage = (element, qtyGroup) => {
      return (
        element.isSelected &&
        isEditable.value &&
        updateElementQtyGroup.value === qtyGroup &&
        convertToFloat(element.ELEM_PCTSTATE2, 0) +
          convertToFloat(element.ELEM_PCTSTATE3, 0) +
          convertToFloat(element.ELEM_PCTSTATE4, 0) !==
          100
      );
    };

    const getUniqueElements = (value) => {
      let returnedElements = [];
      if (value) {
        const addedElements = structureUnitGroups[
          selectedElement.value.STRUNITKEY
        ]?.elements
          .filter((a) => a.ELEM_GRANDPARENT_KEY == 0 && a.ELEM_PARENT_KEY == 0)
          .map((b) => b.ELEM_KEY);
        const structureType = getStructureType(
          structure.value.Bridge?.SERVTYPON
        );

        //remove already added elements from the list
        returnedElements = configStore
          .getAddElementDefinitions(structureType)
          .filter((f) => {
            if (
              structureType === STRUCTURE_TYPE.TUNNEL &&
              structureUnitGroups[selectedElement?.value.STRUNITKEY]
                ?.STRUNITTYPE === FUNCTIONAL_SYSTEM.STR_UNIT_TYPE
            ) {
              return f.elemMatKey === FUNCTIONAL_SYSTEM.MAT_KEY;
            } else if (structureType === STRUCTURE_TYPE.TUNNEL) {
              return f.elemMatKey != FUNCTIONAL_SYSTEM.MAT_KEY;
            } else {
              //bridge type
              return true;
            }
          })
          .map((e) => ({
            value: e.elemId,
            title: `${e.elemId} - ${e.elemShortName}`,
          }))
          .sort((a, b) => {
            return a.value - b.value;
          });
        addedElements?.forEach((a) => {
          const index = returnedElements.findIndex((e) => e.value == a);
          if (index > -1 && selectedElement.value.ELEM_KEY != a) {
            returnedElements.splice(index, 1); // remove one item only
          }
        });
      }
      return returnedElements;
    };

    const getIcon = (elemDef) => {
      if (elemDef?.elemProtectSys === "Y") {
        return "fa-solid fa-shield no-outline";
      } else if (elemDef?.elemSubComp === "Y") {
        return "fa-solid fa-puzzle-piece no-outline";
      } else if (elemDef?.elemSmartFlag === "Y") {
        return "fa-solid fa-bolt-lightning no-outline";
      } else {
        //use this class to add action elements for functional systems.
        return "fa-solid no-outline";
      }
    };

    const getColor = (elemDef) => {
      if (elemDef?.elemProtectSys === "Y") {
        return "protective-system";
      } else if (elemDef?.elemSubComp === "Y") {
        return "subcomponent";
      } else if (elemDef?.elemSmartFlag === "Y") {
        return "defect";
      } else {
        return "element";
      }
    };

    const showInspectionPerformedMessage = () => {
      snackBarId.value = snackbarStore.showMessage({
        displayText:
          "7A06 Element Inspection Performed Box has been checked because changes have been made to the Elements screen.",
        timeout: 10000,
        messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
      });
    };

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };
    const toggleAllPopupPanels = (expandAll) => {
      if (expandAll) {
        elementsPanel.value = ["defects", "protectiveSystems", "subComponents"];
      } else {
        elementsPanel.value = [];
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.Structure_Unit,
        structure.value.Pon_Elem_Insp,
        structure.value.Bridge,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    watch(updateElementQtyGroup, () => {
      nextTick(() => {
        modifyElementForm.value.validate();
      });
    });
    return {
      resetAddElementForUnitKeyChange,
      copyElementForm,
      toggleAllPopupPanels,
      getColor,
      quantityCountMaxLength,
      addElement,
      panel,
      elementQtyGroup,
      elementsOpened,
      toggle,
      editUnit,
      deleteUnit,
      showElementDialog,
      selectedElement,
      protectiveSystemselementsOpened,
      toggleProtectiveSystems,
      elemDescriptionMaxLength,
      elemConditionMaxLength,
      elementsPanel,
      structureUnitGroups,
      configStore,
      ELEMENTS,
      elementTypes,
      structureUnitTypes,
      allProtectiveSystems,
      allDefects,
      allSubComponents,
      dialog,
      openElementConditionDialog,
      selectedElementToShowInfo,
      updateDefectSelection,
      updateProtectiveSystemSelection,
      getElemConditionLegalText,
      getQuantityRounded,
      getDefectsAndProtectiveSystems,
      updateElementQtyGroup,
      showCopyElementDialog,
      copyElement,
      showCopyStructureUnitDialog,
      copyStructureUnit,
      sourceStructureUnits,
      targetCopyStructureUnits,
      selectedCopyElementSourceStructureUnitId,
      selectedCopyElementElementId,
      selectedCopyElementTargetStructureUnitId,
      selectedCopyStructureUnitSourceId,
      selectedCopyStructureUnitTargetId,
      getElements,
      sourceElemsArr,
      targetCopyElementStructureUnits,
      setTargetUnits,
      resetSelections,
      convertToElemInsp,
      resetInspectionChanges,
      copyElementSave,
      copyStructureUnitSave,
      updateDefectCount,
      updateProtectiveSystemCount,
      convertToFloat,
      convertToDecimalString,
      getStructureType,
      structure,
      requiredRule,
      modifyElementForm,
      valid,
      validate,
      greaterThanZeroRule,
      changesDetected,
      closeUpdateDialog,
      closeConfirm,
      BUTTONS,
      REFERENCE_TABLE,
      validCopyElementForm,
      validCopyStructureUnitForm,
      inspectionStore,
      convertToHierarchy,
      isEditable,
      lessThanValueRule,
      showErrorMessage,
      ERROR_MESSAGES,
      getUniqueElements,
      updateSubComponentCount,
      getIcon,
      noFunctionalSystemMessage,
      toggleFunctionalElements,
      functionalElementsOpened,
      systemElementMessage,
      INSPECTION_PAGES,
      showInspectionPerformedMessage,
      toggleAllPanels,
      shouldbeEqual,
      showPercentErrorMessage,
    };
  },
  components: {
    NumberField,
    ConfirmDialog,
    InputMessage,
    InspectionElementRow,
    LabelText,
    LabelSelect,
    ExpandCollapseToggle,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  color: c.$p-white !important;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compactCell {
  width: 10%;
  padding: 0px !important;
}

.protective-system {
  background-color: c.$e-green;
}

.subcomponent {
  background-color: c.$e-yellow;
}

.defect {
  background-color: c.$e-red;
}
.equipTable :deep(.v-label) {
  font-size: 0.71rem;
  display: flex !important;
  align-items: center;
}

.element {
  background-color: c.$e-blue;
}

.elementsTable :deep(input.v-field__input) {
  padding: 5px !important;
}
</style>
