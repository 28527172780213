<template>
  <v-row>
    <ExpandCollapseToggle
      id="culvertPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel" />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Culvert Conditions</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text eager>
          <v-form ref="culvertConditions" v-model="valid">
            <LabelSelect
              id="sel_Culvert_Condition_Rating"
              label="1A03 Culvert Condition Rating"
              showDurationIcon
              :refTable="REFERENCE_TABLE.CONDITION_RATING"
              :isEditable="isEditable"
              isRequired
              v-model="structure.InspEvnt.CULVRATING"
              :tooltip="
                getHistDuration(
                  structure?.T_Insp_Condition_Hist?.CULV_DURATION,
                  structure?.T_Insp_Condition_Hist?.CULV_DURATION_CHANGES
                )
              "
              @update:modelValue="
                {
                  culvertConditionUpdated = true;
                  conditionUpdated = true;
                }
              "
            ></LabelSelect>

            <CommentTypeTextField
              label="Overall Culvert Notes"
              id="field_Overall_Culvert_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.CULVERT"
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
            />
            <LabelSelect
              id="combustible_Material_Under_Bridge"
              label="6B48 Combustible Material Under Bridge"
              isRequired
              :refTable="REFERENCE_TABLE.MATERIAL_STORED_UNDER"
              :isEditable="isEditable"
              v-model="structure.UserInsp.MAT_STORE_UND"
            ></LabelSelect>

            <CommentTypeTextField
              label="Combustible Material Under Bridge Notes"
              id="field_Combustible_Material_Notes"
              :inspectionCommentType="
                INSPECTION_COMMENTS.COMBUSTIBLE_MATERIALS_COMMENTS
              "
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
            />

            <LabelCheckbox
              labelXl="3"
              labelLg="4"
              labelMd="5"
              labelSm="6"
              class="labelCheckbox"
              id="checkbox_New_Coating_Since_Last_Inspection"
              label="6B35 New Coating Since Last Inspection"
              :isEditable="isEditable"
              v-model="structure.UserInsp.NEW_PAINT"
            >
            </LabelCheckbox>

            <LabelSelect
              id="sel_Protective_Coating_Rating"
              label="6B36 Protective Coating Rating"
              showDurationIcon
              :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING"
              :isEditable="isEditable"
              isRequired
              v-model="structure.UserInsp.PAINT_COND_RATE"
              :tooltip="
                getHistDuration(
                  structure?.T_Insp_Condition_Hist?.PANIT_DURATION,
                  structure?.T_Insp_Condition_Hist?.PANIT_DURATION_CHANGES
                )
              "
              @update:modelValue="
                {
                  protectiveCoatingUpdated = true;
                  conditionUpdated = true;
                }
              "
            ></LabelSelect>

            <LabelSelect
              id="sel_Protective_Coating_Extent_Rating"
              label="6B37 Protective Coating (Extent) Rating"
              showDurationIcon
              isRequired
              :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT"
              :isEditable="isEditable"
              v-model="structure.UserInsp.PAINT_EXTENT_RATE"
              :tooltip="
                getHistDuration(
                  structure?.T_Insp_Condition_Hist?.PAINT_EXT_DURATION,
                  structure?.T_Insp_Condition_Hist?.PAINT_EXT_DURATION_CHANGES
                )
              "
              @update:modelValue="
                {
                  protectiveCoatingExtentUpdated = true;
                  conditionUpdated = true;
                }
              "
            ></LabelSelect>
            <CommentTypeTextField
              label="Overall Protective Coating Notes"
              id="field_Overall_Protective_Coating_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.PAINT_OVERALL"
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
            />
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />

          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />

          <h3>Culvert Details</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-row class="ma-0">
            <v-col sm="6" md="6" lg="6">
              <LabelText
                labelXl="6"
                labelLg="7"
                noGrid
                labelMd="7"
                labelSm="7"
                textXl="6"
                textLg="5"
                textMd="5"
                textSm="5"
                id="number_Main_Spans"
                label="5B11 Main Spans"
                v-model="structure.Bridge.MAINSPANS"
                type="number"
                maxlength="22"
              >
              </LabelText>
            </v-col>
            <v-col sm="6" md="6" lg="6">
              <LabelText
                labelXl="6"
                noGrid
                labelLg="7"
                labelMd="7"
                labelSm="7"
                textXl="6"
                textLg="5"
                textMd="5"
                textSm="5"
                id="number_Approach_Spans"
                label="5B14 Approach Spans"
                v-model="structure.Bridge.APPSPANS"
                type="number"
                maxlength="22"
              >
              </LabelText>
            </v-col>
          </v-row>

          <v-data-table
            class="noScrollTable compactTable"
            :items-per-page="-1"
            :headers="headers"
            :items="culvertInformation"
            return-object
            hide-default-footer
          >
            <template v-slot:[`header.OPENING_TYPE`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.CULVERT_LENGTH_FT`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.MIN_FILL_HEIGHT_FT`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.MAX_FILL_HEIGHT_FT`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.EFF_WIDTH_FT`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.TIE_TYPE`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:[`header.FLOOR_TYPE`]="{ column }">
              <DataTableHeader :lines="3" :column="column" />
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">
                  {{
                    item?.OPENING_TYPE
                      ? configStore.getReferenceValue(
                          REFERENCE_TABLE.CULVERT_OPENING_TYPE,
                          item?.OPENING_TYPE
                        )
                      : ""
                  }}
                </td>
                <td class="text-center">
                  {{
                    item?.CULVERT_LENGTH_FT
                      ? item.CULVERT_LENGTH_FT + " ft"
                      : ""
                  }}
                </td>
                <td class="text-center">
                  {{
                    item?.MIN_FILL_HEIGHT_FT
                      ? item.MIN_FILL_HEIGHT_FT + " ft"
                      : ""
                  }}
                </td>
                <td class="text-center">
                  {{
                    item?.MAX_FILL_HEIGHT_FT
                      ? item.MAX_FILL_HEIGHT_FT + " ft"
                      : ""
                  }}
                </td>
                <td class="text-center">
                  {{ item?.EFF_WIDTH_FT ? item.EFF_WIDTH_FT + " ft" : "" }}
                </td>
                <td class="text-center">
                  {{
                    item?.TIE_TYPE
                      ? configStore.getReferenceValue(
                          REFERENCE_TABLE.CULVERT_TIE_TYPE,
                          item?.TIE_TYPE
                        )
                      : ""
                  }}
                </td>
                <td class="text-center">
                  {{
                    item?.FLOOR_TYPE
                      ? configStore.getReferenceValue(
                          REFERENCE_TABLE.CULVERT_FLOOR_TYPE,
                          item?.FLOOR_TYPE
                        )
                      : ""
                  }}
                </td>
              </tr>
            </template>
            <template v-slot:bottom> </template>
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(2)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(2)" icon="fa-solid fa-minus" />
          <h3>Culvert Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <CommentTypeTextField
            label="Top Slab"
            id="field_Top_Slab"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_TOP_SLAB"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Barrel"
            id="field_Barrel"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_BARREL"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Floor/Paving"
            id="field_Floor_Paving"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_FLOOR_PAVE"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Headwall"
            id="field_Headwall"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_HEADWALL"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Wings"
            id="field_Wings"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_WINGS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Settlement"
            id="field_Settlement"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_SETTLEMENT"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Debris"
            id="field_Debris"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_DEBRIS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Footing"
            id="field_Footing"
            :inspectionCommentType="INSPECTION_COMMENTS.CULVERT_FOOTING"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
        </v-expansion-panel-text>
      </v-expansion-panel> </v-expansion-panels
  ></v-row>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import { getHistDuration } from "@/composables/util";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { clone } from "@/util/clone";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import LabelText from "@/components/common/LabelText.vue";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionCulvert",
  setup() {
    const allPanels = [0, 1, 2];
    const panel = ref(allPanels);
    const inspectionStore = useInspectionStore();
    let structure = computed(() => inspectionStore.selectedInspection);
    let culvertInformation = ref([]);
    const configStore = useConfigStore();
    let valid = ref(true);

    let culvertConditions = ref(null);

    const headers = ref([
      {
        line1: "VD18",
        line2: "Opening",
        line3: "Type",
        key: "OPENING_TYPE",
        sortable: false,
        width: "14%",
      },
      {
        line1: "VD19",
        line2: "Length of",
        line3: "Culvert Barrel",
        key: "CULVERT_LENGTH_FT",
        width: "14%",
        sortable: false,
      },
      {
        line1: "VD20",
        line2: "Min Fill",
        line3: "Height",
        key: "MIN_FILL_HEIGHT_FT",
        sortable: false,
        width: "14%",
      },
      {
        line1: "VD21",
        line2: "Max Fill",
        line3: "Height",
        key: "MAX_FILL_HEIGHT_FT",
        sortable: false,
        width: "14%",
      },
      {
        line1: "VD22",
        line2: "Eff Width",
        line3: null,
        key: "EFF_WIDTH_FT",
        sortable: false,
        width: "14%",
      },
      {
        line1: "VD23",
        line2: "Tie Type",
        line3: null,
        key: "TIE_TYPE",
        sortable: false,
        width: "14%",
      },
      {
        line1: "VD24",
        line2: "Floor Type",
        line3: null,
        key: "FLOOR_TYPE",
        width: "14%",
        sortable: false,
      },
    ]);

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });
    if (!isEditable.value) {
      panel.value = allPanels;
    }
    const inspCommentMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });
    let culvertConditionUpdated = ref(false);
    let protectiveCoatingUpdated = ref(false);
    let protectiveCoatingExtentUpdated = ref(false);
    let conditionUpdated = ref(false);
    const updateDuration = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {};
      }
      if (culvertConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.CULV_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.CULV_DURATION,
            structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES
          );
      }
      if (protectiveCoatingUpdated.value) {
        structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.PANIT_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.PANIT_DURATION,
            structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES
          );
      }

      if (protectiveCoatingExtentUpdated.value) {
        structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION,
            structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES
          );
      }

      culvertConditionUpdated.value = false;
      protectiveCoatingUpdated.value = false;
      protectiveCoatingExtentUpdated.value = false;
      conditionUpdated.value = false;
    };

    const sort = (arr) => {
      arr.value = arr.value?.sort(function (a, b) {
        return a.OPENING_KEY - b.OPENING_KEY;
      });
    };
    async function validate() {
      culvertConditions.value.validate();
    }
    onMounted(async () => {
      await validate();
    });
    const getCulvertInformationDetails = () => {
      if (structure.value.T_Culvert_Opening?.[0]) {
        culvertInformation.value = clone(structure.value.T_Culvert_Opening);
        for (const element of culvertInformation.value) {
          element.CULVERT_LENGTH_FT = configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            element.CULVERT_LENGTH,
            ROUNDING_FACTOR.ROUND
          );
          element.MIN_FILL_HEIGHT_FT =
            configStore.getMetricToEnglishConversions(
              PAIR_CODE.METERS_TO_FEET,
              element.MIN_FILL_HEIGHT,
              ROUNDING_FACTOR.TENTH
            );
          element.MAX_FILL_HEIGHT_FT =
            configStore.getMetricToEnglishConversions(
              PAIR_CODE.METERS_TO_FEET,
              element.MAX_FILL_HEIGHT,
              ROUNDING_FACTOR.TENTH
            );
          element.EFF_WIDTH_FT = configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            element.EFF_WIDTH,
            ROUNDING_FACTOR.TENTH
          );

          element.readOnly = true;
        }
        sort(culvertInformation);
      }
    };
    getCulvertInformationDetails();
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.InspEvnt,
        structure.value.UserInsp,
        structure.value.T_Insp_Comment,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      protectiveCoatingUpdated,
      protectiveCoatingExtentUpdated,
      conditionUpdated,
      culvertConditionUpdated,
      updateDuration,
      culvertConditions,
      valid,
      validate,
      headers,
      panel,
      REFERENCE_TABLE,
      isEditable,
      structure,
      INSPECTION_COMMENTS,
      configStore,
      inspCommentMaxLength,
      getHistDuration,
      culvertInformation,
      toggleAllPanels,
    };
  },
  components: {
    LabelSelect,
    CommentTypeTextField,
    LabelCheckbox,
    LabelText,
    DataTableHeader,
    ExpandCollapseToggle,
  },
};
</script>
<style scoped lang="scss">
.v-data-table :deep(th) {
  margin: 0 auto !important;
  text-align: center !important;
}
</style>
