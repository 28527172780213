import { defineStore } from "pinia";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { DOCUMENT_SERVICE_ENDPOINT } from "@/constants/Endpoints";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

export const useDocumentStore = defineStore("document", () => {
  const snackbarStore = useSnackbarStore();
  const uploadBPRPhoto = async (brkey, photoMetaData, photoFile) => {
    let url = DOCUMENT_SERVICE_ENDPOINT.UPLOAD_BPR_PHOTO;
    url = url.replace("{brkey}", brkey);
    const form = new FormData();
    form.append("json", JSON.stringify(photoMetaData));
    form.append("file", photoFile);

    return await axios
      .post(url, form)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Upload,
          LOGGER.EventCat.RequestResponse,
          "Upload BPR Photo",
          response?.data?.data
        );
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const getDocumentsByDocType = async (brkey, docKeyType) => {
    let url = DOCUMENT_SERVICE_ENDPOINT.GET_DOCUMENTS_FROM_ECS;
    url = url.replace("{brkey}", brkey);

    return await axios
      .get(url, {
        params: { docKeyType: docKeyType },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const getDocument = async (brkey, edmsDoc) => {
    let url = DOCUMENT_SERVICE_ENDPOINT.GET_SINGLE_DOCUMENT_FROM_ECS;
    url = url.replace("{docId}", edmsDoc?.edmsDocId);
    LOGGER.logEvent(
      LOGGER.EventAction.Download,
      LOGGER.EventCat.RequestResponse,
      "Downloading Document by DocId",
      edmsDoc?.edmsDocId
    );

    return await axios
      .get(url, {
        params: { brkey: brkey, repository: edmsDoc?.edmsCsRepository },
      })
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Download,
          LOGGER.EventCat.RequestResponse,
          "Downloaded Document",
          response?.data?.data
        );
        let docData = response.data.data;
        if (docData) {
          docData.docId = edmsDoc?.docContainerSeqNum;
        }
        if(!docData.content?.length) {
          LOGGER.logEvent(
            LOGGER.EventAction.Download,
            LOGGER.EventCat.RequestResponse,
            "Downloaded file content length is 0",
            response?.data?.data
          );
          throw new Error("Downloaded file content length is 0");
        }
        return docData;
      })
      .catch((e) => {
        LOGGER.logException(e);
        snackbarStore.showMessage({
          displayText: "Photo Upload File or ECS File Unavailable.",
          messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
          timeout: 10000,
        });
      });
  };

  const uploadDocument = async ({
    brKey,
    docTypeId,
    filename,
    file,
    description,
  }) => {
    let url = DOCUMENT_SERVICE_ENDPOINT.UPLOAD_DOCUMENT;
    url = url.replace("{brkey}", brKey);
    const form = new FormData();
    const photoMetaData = { filename, docTypeId, description };
    form.append("json", JSON.stringify(photoMetaData));
    form.append("file", file);

    return await axios
      .post(url, form)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Upload,
          LOGGER.EventCat.RequestResponse,
          "Uploaddocument",
          response?.data?.data
        );
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  return { uploadBPRPhoto, uploadDocument, getDocumentsByDocType, getDocument };
});
