import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { decode } from "he";
import { clone } from "./clone";

const nonNextLineCharRegex = /[^\n]*/gm;

export const getQuillMessageLength = (detail) => {
  detail = new QuillDeltaToHtmlConverter(detail?.ops).convert();
  detail = detail.replace(/<[^>]*>?/gm, "");
  detail = decode(detail);
  return detail.length;
};

export const getQuillHTML = (quillContent) => {
  if (!quillContent) {
    return "";
  }
  const ops = clone(quillContent.ops);
  const lastInsert = ops?.length ? ops[ops.length - 1].insert : null;
  const nonNextLineCount =
    (lastInsert?.match(nonNextLineCharRegex) ?? []).filter(
      (item) => item !== ""
    ) ?? [];
  if (nonNextLineCount.length > 0) {
    let l =
      lastInsert.indexOf(nonNextLineCount[nonNextLineCount.length - 1]) +
      nonNextLineCount[nonNextLineCount.length - 1].length;
    ops[ops.length - 1].insert = lastInsert.substring(0, l) + "\n";
    return new QuillDeltaToHtmlConverter(ops).convert();
  }
  if (ops) ops[ops.length - 1].insert = "\n";
  return new QuillDeltaToHtmlConverter(ops).convert();
};

/**
 *
 * @param {Object} param0
 * @returns void
 */
export const onQuillEditorTextChanged = ({
  quillEditor,
  messageTitleMaxLength,
  delta,
  oldContents,
  source,
}) => {
  if (source === "api") {
    return;
  }
  const appendOps = { ops: [...oldContents.ops, ...delta.ops] };
  const length = getQuillMessageLength(appendOps);
  const oldContentLength = getQuillMessageLength(oldContents);
  if (length <= messageTitleMaxLength) {
    return;
  } else if (oldContentLength === messageTitleMaxLength) {
    oldContents.ops.push({ insert: "\n" });
    quillEditor.setContents(oldContents, "api");
  } else if (length > messageTitleMaxLength) {
    trancateText(oldContents, delta, messageTitleMaxLength, quillEditor);
  }
};

/**
 * TrancateText is internal function to bring the Cognitive Complexity of onQuillEditorTextChanged function
 * @param {Object} oldContents
 * @param {Object} delta
 * @param {Number} messageTitleMaxLength
 * @param {QuillEditor} quillEditor
 */
function trancateText(oldContents, delta, messageTitleMaxLength, quillEditor) {
  const lastInsert = oldContents.ops[oldContents.ops.length - 1].insert;
  oldContents.ops[oldContents.ops.length - 1].insert = lastInsert.substring(
    0,
    lastInsert.length - 1
  );
  for (const op of delta.ops) {
    let oldContentLength = getQuillMessageLength(oldContents);
    if (oldContentLength < messageTitleMaxLength) {
      const diff = messageTitleMaxLength - oldContentLength;
      if (!op.insert) {
        continue;
      }
      let opLength = getQuillMessageLength({ ops: [op] });
      if (opLength < diff) {
        oldContents.ops.push(op);
      } else {
        const endIndex = opLength < diff ? opLength : diff;
        oldContents.ops.push({
          ...op,
          insert: op.insert.substring(0, endIndex),
        });
      }
    } else {
      break;
    }
  }
  oldContents.ops.push({ insert: "\n" });
  quillEditor.setContents(oldContents, "api");
}
