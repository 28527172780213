import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import { CUSTOM_ICONS } from "@/constants/Unicode";
import useDateField from "@/composables/dateField.js";
import { buildFutureSchedule } from "@/composables/futureSchedule";
import { sortEquipmentArray } from "@/composables/util";
import { INSPECTION_TYPE_PERFORMED } from "@/constants/Inspections";

const getAllEquipment = () => {
  const configStore = useConfigStore();
  const accessEquipment = [];
  const inspectionEquipment = [];
  const allEquips = configStore.getReferenceValues(
    REFERENCE_TABLE.INSPECTION_EQUIPMENT,
    null,
    null,
    true
  );
  allEquips?.forEach((equipment) => {
    if (equipment.value.startsWith("A")) {
      accessEquipment.push(equipment);
    } else {
      inspectionEquipment.push(equipment);
    }
  });

  return [
    sortEquipmentArray(accessEquipment),
    sortEquipmentArray(inspectionEquipment),
  ];
};

const getSelectedEquipment = (inspEvent) => {
  const selectedEquip = [];
  if (inspEvent) {
    for (let prop in inspEvent) {
      if (prop?.startsWith("EQUIP_") && inspEvent[prop] === "1") {
        selectedEquip.push(prop.replace("EQUIP_", ""));
      }
    }
  }
  return selectedEquip;
};

function setScheduleDocDefinition(structure) {
  const configStore = useConfigStore();
  const { getFormattedDateStringNoTime } = useDateField();

  const inspectionsPerformedDetails = [];
  inspectionsPerformedDetails.push(getInspectionPerformedHeader());
  getInspPerformedDetails(inspectionsPerformedDetails, structure);
  const futureData = getFutureData(structure);
  const teamLeaders = configStore.getTeamLeaders(
    structure,
    structure?.Snbi_Insp_Schedule[0]?.NSTM_REQUIRED == "1"
  );
  const underwaterDivers = configStore.getUnderwaterDivers;
  const tableData = getLegacyScheduleInformation(structure);

  let docDefinition = {
    header: function () {
      return getReportHeader("Schedule Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Schedule Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "SNBI Inspection Types Performed",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
                margin: [0, 3, 0, 2],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        table: {
          headerRows: 0,
          widths: ["20%", "12%", "12%", "20%", "10%", "12%", "*"],
          body: inspectionsPerformedDetails,
          margin: [0, 5, 0, 5],
        },
      },
      {
        stack: [
          {
            table: {
              headerRows: 0,
              widths: ["100%"],
              body: [
                [
                  {
                    text: "SNBI Future Scheduling Information",
                    fillColor: "#ebebeb",
                    alignment: "center",
                    style: ["sectionHeader"],
                  },
                ],
              ],
            },
            layout: "noBorders",
            margin: [0, 5, 0, 5],
          },
          {
            table: {
              headerRows: 1,
              widths: ["20%", "20%", "20%", "20%", "*"],
              body: futureData,
            },
            layout: "noBorders",
          },
        ],
        unbreakable: true,
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Inspection Information",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          [
            {
              width: "70%",
              table: {
                headerRows: 0,
                widths: ["65%", "*"],
                body: [
                  [
                    {
                      text: "7A05 Inspection Performed By: ",
                      bold: true,
                      margin: [0, 5, 0, 0],
                    },
                    {
                      text: getInspPerformedBy(
                        structure.InspEvnt.INSPECTCONTROLID
                      ),
                      margin: [0, 5, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "7A05a  Inspection Organization: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: structure.UserInsp.INSP_ORG_NAME,
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "6B23 Inspection Team Member: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: structure.UserInsp.INSP_TEAM_HELPER,
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "7A22 Underwater Diver Name: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: underwaterDivers.find(
                        (t) => t.value === structure.UserInsp.UW_DIVER_NAME
                      )?.title,
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "6B24 Consultant Hired By: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.CONSULTANT_HIRED_BY,
                        structure.UserInsp.CONSULTANT_HIRED_BY
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "6B25 Inspection Agreement Number: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: structure.UserInsp.LOCAL_INSP_CONTRACT_NUM,
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "7A19  Ext. Insp. Interval Elig.: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.EXTENDED_INSPECTION_INTERVAL_ELIGIBILITY,
                        structure.UserInsp.EXTINSPFREQ_ELIG
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "7A20  Ext. Insp. Interval Conc.: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.INSPECTION_INTERVAL_CONCURRENCE,
                        structure.UserInsp.EXTINSPFREQ_CONC
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "7A21  NBIS Ext. Insp. Interval Elig.: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.METHOD1_ELIGIBILITY,
                        structure.UserInsp.METHOD1_ELIG
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
          [
            {
              table: {
                headerRows: 0,
                widths: ["50%", "*"],
                body: [
                  [
                    {
                      width: "25%",
                      text: "6B49 Inaccessible Area: ",
                      bold: true,
                      margin: [0, 5, 0, 0],
                    },
                    {
                      width: "25%",
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.INACCESSIBLE_AREA,
                        structure.UserInsp.INACCESS_PORTION_STRUCT
                      ),
                      margin: [0, 5, 0, 0],
                    },
                  ],
                  [
                    {
                      width: "25%",
                      text: "6B09 Weather: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      width: "25%",
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.WEATHER_CONDITION,
                        structure.UserInsp.WEATHER_COND
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      width: "25%",
                      text: "6B12 Temperature: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      width: "25%",
                      text: structure?.UserInsp?.TEMP
                        ? parseFloat(structure?.UserInsp?.TEMP).toFixed(1)
                        : "",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      width: "25%",
                      text: "6B26 NBI Crew Hours: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      width: "25%",
                      text: structure?.UserInsp?.CREWHRS
                        ? parseFloat(structure?.UserInsp?.CREWHRS).toFixed(0)
                        : "",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      width: "25%",
                      text: "6B27 Crane Hours: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      width: "25%",
                      text: structure?.UserInsp?.SNOOPERHRS
                        ? parseFloat(structure?.UserInsp?.SNOOPERHRS).toFixed(0)
                        : "",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      width: "25%",
                      text: "6B28 NSTM Hours: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      width: "25%",
                      text: structure?.UserInsp?.FLAGGERHRS
                        ? parseFloat(structure?.UserInsp?.FLAGGERHRS).toFixed(0)
                        : "",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      width: "25%",
                      text: "6B30 UW Hours: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      width: "25%",
                      text: structure?.UserInsp?.HELPERHRS
                        ? parseFloat(structure?.UserInsp?.HELPERHRS).toFixed(0)
                        : "",
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      width: "25%",
                      text: "6B32 Engineering Costs: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      width: "25%",
                      text: structure.UserInsp.FIELD_ENG_INSP_COST?.toLocaleString(),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      width: "25%",
                      text: "6B33 Rigging Costs: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      width: "25%",
                      text: structure.UserInsp.RIGGING_INSP_COST?.toLocaleString(),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      width: "25%",
                      text: "6B34 Office Costs: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      width: "25%",
                      text: structure.UserInsp.OFFICE_INSP_COST?.toLocaleString(),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
        ],
      },
      {
        stack: [
          {
            table: {
              headerRows: 0,
              widths: ["100%"],
              body: [
                [
                  {
                    text: "Legacy Schedule Information",
                    fillColor: "#ebebeb",
                    alignment: "center",
                    style: ["sectionHeader"],
                  },
                ],
              ],
            },
            layout: "noBorders",
            margin: [0, 5, 0, 0],
          },
          {
            columns: [
              {
                margin: [0, 0, 5, 0],
                width: "45%",
                table: {
                  headerRows: 0,
                  widths: ["65%", "*"],
                  body: [
                    [
                      {
                        text: "7A01 Inspection Start Date: ",
                        bold: true,
                        margin: [0, 5, 0, 0],
                      },
                      {
                        text: getFormattedDateStringNoTime(
                          structure.InspEvnt.INSPDATE
                        ),
                        margin: [0, 5, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "7A01e Inspection End Date: ",
                        bold: true,
                        margin: [0, 3, 0, 0],
                      },
                      {
                        text: getFormattedDateStringNoTime(
                          structure.UserInsp.OVERALL_END_DATE
                        ),
                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "7A02 Team Leader: ",
                        bold: true,
                        margin: [0, 3, 0, 0],
                      },
                      {
                        text: teamLeaders.find(
                          (t) => t.value === structure.InspEvnt.INSPUSRKEY
                        )?.title,

                        margin: [0, 3, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "7A03 Primary Inspection Type: ",
                        bold: true,
                        margin: [0, 3, 0, 0],
                      },
                      {
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.INSPECTION_TYPE,
                          structure.InspEvnt.INSPTYPE
                        ),

                        margin: [0, 3, 0, 0],
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
              {
                width: "55%",
                table: {
                  headerRows: 0,
                  widths: ["50%", "*"],
                  body: [
                    [
                      {
                        text: "7A06 Inspection Performed: ",
                        bold: true,
                        margin: [0, 5, 0, 0],
                      },
                      {},
                    ],
                    [
                      {
                        text: "NBI: ",
                        bold: true,
                      },
                      {
                        text:
                          structure.InspEvnt.NBINSPDONE == "1"
                            ? CUSTOM_ICONS.SQUARE_CHECK
                            : CUSTOM_ICONS.SQUARE,
                        style: "customFontAwesome",
                      },
                    ],
                    [
                      {
                        text: "NSTM: ",
                        bold: true,
                      },
                      {
                        text:
                          structure.InspEvnt.FCINSPDONE == "1"
                            ? CUSTOM_ICONS.SQUARE_CHECK
                            : CUSTOM_ICONS.SQUARE,
                        style: "customFontAwesome",
                      },
                    ],
                    [
                      {
                        text: "Underwater: ",
                        bold: true,
                      },
                      {
                        text:
                          structure.InspEvnt.UWINSPDONE == "1"
                            ? CUSTOM_ICONS.SQUARE_CHECK
                            : CUSTOM_ICONS.SQUARE,
                        style: "customFontAwesome",
                      },
                    ],
                    [
                      {
                        text: "Other Special: ",
                        bold: true,
                      },
                      {
                        text:
                          structure.InspEvnt.OSINSPDONE == "1"
                            ? CUSTOM_ICONS.SQUARE_CHECK
                            : CUSTOM_ICONS.SQUARE,
                        style: "customFontAwesome",
                      },
                    ],
                    [
                      {
                        text: "Element: ",
                        bold: true,
                      },
                      {
                        text:
                          structure.InspEvnt.ELINSPDONE == "1"
                            ? CUSTOM_ICONS.SQUARE_CHECK
                            : CUSTOM_ICONS.SQUARE,
                        style: "customFontAwesome",
                      },
                    ],
                    [
                      {
                        colSpan: 2,
                        table: {
                          headerRows: 1,
                          widths: ["25%", "auto", "auto", "auto", "auto"],
                          body: tableData,
                        },
                        layout: "noBorders",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          },
        ],
        unbreakable: true,
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

function getInspectionPerformedHeader() {
  return [
    {
      text: "B.IE.01\nInspection\nType",
      style: ["tableHeader"],
    },
    {
      text: "B.IE.02\nInspection\nStart Date",
      style: ["tableHeader"],
    },
    {
      text: "B.IE.03\nInspection\nEnd Date",
      style: ["tableHeader"],
    },
    {
      text: "B.IE.04\nNCBI\n(Team Leader)",
      style: ["tableHeader"],
    },
    {
      text: "B.IE.05\nInspection\nInterval",
      style: ["tableHeader"],
    },
    {
      text: "B.IE.06\nInspection\nDue Date",
      style: ["tableHeader"],
    },
    {
      text: "B.IE.07\nRBI\nMethod",
      style: ["tableHeader"],
    },
  ];
}

function getFutureScheduleHeader() {
  return [
    {
      text: "Inspection Type",
      style: ["tableHeader"],
    },
    {
      text: "7A57 Required",
      style: ["tableHeader"],
    },
    {
      text: "7A58 Last Date",
      style: ["tableHeader"],
    },
    {
      text: "7A59 Interval",
      style: ["tableHeader"],
    },
    {
      text: "7A60 Next Inspection Date",
      style: ["tableHeader"],
    },
  ];
}

function getInspPerformedDetails(inspectionsPerformedDetails, structure) {
  const configStore = useConfigStore();
  const { getFormattedDateStringNoTime } = useDateField();
  const teamLeaders = configStore.getTeamLeaders(
    structure,
    structure?.Snbi_Insp_Schedule[0]?.NSTM_REQUIRED == "1"
  );
  if (structure?.Snbi_Insp_Event) {
    structure?.Snbi_Insp_Event?.sort((a, b) =>
      a.INSP_TYPE?.localeCompare(b.INSP_TYPE)
    );
  }
  for (const inspEvent of structure?.Snbi_Insp_Event || []) {
    inspectionsPerformedDetails.push(
      [
        configStore.getReferenceValue(
          REFERENCE_TABLE.SNBI_INSPECTION_TYPE,
          inspEvent.INSP_TYPE
        ),
        getFormattedDateStringNoTime(inspEvent?.START_DATE),
        getFormattedDateStringNoTime(inspEvent?.END_DATE),
        teamLeaders?.find((t) => t?.value === inspEvent?.TEAM_LEADER)?.title,
        inspEvent?.INSP_INTERVAL,
        getFormattedDateStringNoTime(inspEvent?.DUE_DATE),
        configStore.getReferenceValue(
          REFERENCE_TABLE.RBI_METHOD,
          inspEvent?.RBM
        ),
      ].map((d) => {
        return {
          text: d,
          alignment: "center",
        };
      })
    );
    getInspPerformedDetailExpanded(
      inspectionsPerformedDetails,
      inspEvent,
      structure
    );
  }
}

function getInspPerformedDetailExpanded(
  inspectionsPerformedDetails,
  inspEvent,
  structure
) {
  const configStore = useConfigStore();
  const [accessEquipment, inspectionEquipment] = getAllEquipment();
  const acessEquip = [];
  const selectedEquip = getSelectedEquipment(inspEvent);
  const equipmentPerRow = 6;
  //contains array of arrays which are 6 elements each
  const chunkedArr = splitArrayIntoChunks(accessEquipment, equipmentPerRow);
  chunkedArr?.forEach((row) => {
    let acessEquipRow = [];
    //each row
    row?.forEach((cell) => {
      acessEquipRow = acessEquipRow?.concat([
        {
          text: [
            {
              text: selectedEquip?.includes(cell.value)
                ? CUSTOM_ICONS.SQUARE_CHECK
                : CUSTOM_ICONS.SQUARE,
              style: "customFontAwesome",
            },
            { text: " " + cell?.title },
          ],
        },
      ]);
    });
    //If any row has less than defined equipmentPerRow, pdfmake will fail and hence adding blank columns for label and checkbox per type
    if (row?.length < equipmentPerRow) {
      for (let i = 0; i < equipmentPerRow - row?.length; i++) {
        acessEquipRow = acessEquipRow?.concat([{}]);
      }
    }
    acessEquip.push(acessEquipRow);
  });

  const inspEquip = [];
  //contains array of arrays which are 6 elements each
  const chunkedInspEquip = splitArrayIntoChunks(
    inspectionEquipment,
    equipmentPerRow
  );
  chunkedInspEquip?.forEach((row) => {
    let inspEquipRow = [];
    //each row
    row?.forEach((cell) => {
      inspEquipRow = inspEquipRow?.concat([
        {
          text: [
            {
              text: selectedEquip?.includes(cell.value)
                ? CUSTOM_ICONS.SQUARE_CHECK
                : CUSTOM_ICONS.SQUARE,
              style: "customFontAwesome",
            },
            { text: " " + cell?.title },
          ],
        },
      ]);
    });
    //If any row has less than defined equipmentPerRow, pdfmake will fail and hence adding blank columns for label and checkbox per type
    if (row?.length < equipmentPerRow) {
      for (let i = 0; i < equipmentPerRow - row?.length; i++) {
        inspEquipRow = inspEquipRow?.concat([{}]);
      }
    }
    inspEquip.push(inspEquipRow);
  });

  const { getFormattedDateStringNoTime } = useDateField();
  inspectionsPerformedDetails.push([
    {
      colSpan: 7,
      stack: [
        {
          columns: [
            {
              table: {
                body: [
                  [
                    {
                      text: "B.IE.01 Inspection Type: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.SNBI_INSPECTION_TYPE,
                        inspEvent?.INSP_TYPE
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "B.IE.02 Inspection Start Date: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: getFormattedDateStringNoTime(inspEvent?.START_DATE),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "B.IE.03 Inspection End Date: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: getFormattedDateStringNoTime(inspEvent?.END_DATE),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "B.IE.04 NCBI (Team Leader): ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore
                        .getTeamLeaders(
                          structure,
                          inspEvent?.INSP_TYPE ==
                            INSPECTION_TYPE_PERFORMED.NSTM ||
                            structure?.Snbi_Insp_Schedule[0]?.NSTM_REQUIRED ==
                              "1"
                        )
                        .find((t) => t.value === inspEvent?.TEAM_LEADER)?.title,
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "B.IE.05 Inspection Interval: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: [inspEvent?.INSP_INTERVAL, " mos"],
                      margin: [0, 3, 0, 0],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              table: {
                body: [
                  [
                    {
                      text: "B.IE.06 Inspection Due Date: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: getFormattedDateStringNoTime(inspEvent?.DUE_DATE),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "B.IE.07 RBI Method: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: configStore.getReferenceValue(
                        REFERENCE_TABLE.RBI_METHOD,
                        inspEvent?.RBM
                      ),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "B.IE.08 Quality Control Date: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: getFormattedDateStringNoTime(inspEvent?.QC_DATE),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  [
                    {
                      text: "B.IE.10 Modified Date: ",
                      bold: true,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: getFormattedDateStringNoTime(inspEvent?.MOD_DATE),
                      margin: [0, 3, 0, 0],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
        },
        {
          colSpan: 7,
          columns: [
            {
              text: "B.IE.11 Limited Scope Descr: ",
              bold: true,
              margin: [0, 3, 0, 0],
              width: "25%",
            },
            {
              text: inspEvent?.SCOPE_NOTE,
              margin: [3, 3, 0, 0],
              width: "*",
            },
          ],
        },
        {
          colSpan: 7,
          columns: [
            {
              text: "B.IE.12 Inspection Equipment: ",
              bold: true,
              margin: [0, 3, 0, 0],
              width: "25%",
            },
            {
              text: selectedEquip?.join("|"),
              margin: [3, 3, 0, 0],
              width: "*",
            },
          ],
        },
        {
          stack: [
            {
              table: {
                headerRows: 0,
                widths: ["100%"],
                body: [
                  [
                    {
                      text: "Access Equipment (Check all that apply, at least one is required)",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["subSectionHeader"],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              table: { headerRows: 0, body: acessEquip },
              layout: "noBorders",
              fontSize: 9,
            },
          ],
          unbreakable: true,
        },
        {
          stack: [
            {
              table: {
                headerRows: 0,
                widths: ["100%"],
                body: [
                  [
                    {
                      text: "Inspection Equipment (Check all that apply, at least one is required)",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["subSectionHeader"],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              table: {
                headerRows: 0,
                widths: ["18%", "18%", "17%", "17%", "17%", "*"],
                body: inspEquip,
              },
              layout: {
                hLineWidth: function () {
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
                paddingLeft: function () {
                  return 0;
                },
                paddingRight: function () {
                  return 0;
                },
                paddingTop: function () {
                  return 0;
                },
                paddingBottom: function () {
                  return 0;
                },
              },
              fontSize: 9,
            },
          ],
          unbreakable: true,
        },
      ],
    },
  ]);
}

const getFutureData = (structure) => {
  const configStore = useConfigStore();
  const { getFormattedDateStringNoTime } = useDateField();

  const futureScheduleData = buildFutureSchedule(structure);
  const futureSchedule = [];
  futureSchedule.push(getFutureScheduleHeader());

  futureScheduleData?.forEach((a) => {
    const checkbox =
      a?.is7A57RequiredValue == "1"
        ? CUSTOM_ICONS.SQUARE_CHECK
        : CUSTOM_ICONS.SQUARE;
    futureSchedule.push([
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.SNBI_INSPECTION_TYPE,
          a?.inspType
        ),
        style: ["tableBody"],
      },
      {
        text: a?.show7A57Checkbox ? checkbox : "N/A",
        style: a?.show7A57Checkbox
          ? ["customFontAwesome", "tableBody"]
          : ["tableBody"],
      },
      {
        text: getFormattedDateStringNoTime(a?.lastInspectionDate),
        style: ["tableBody"],
      },
      { text: `${a?.inspectionInterval || 0} mos`, style: ["tableBody"] },
      {
        text: a?.showInspDueDate
          ? getFormattedDateStringNoTime(a?.inspectionDueDate)
          : "N/A",
        style: ["tableBody"],
      },
    ]);
  });
  return futureSchedule;
};

const getInspPerformedBy = (id) => {
  const configStore = useConfigStore();
  return configStore
    .getReferenceValues(
      REFERENCE_TABLE.INSPECTION_PERFORMED_BY,
      null,
      null,
      true
    )
    ?.find((selectItem) => selectItem?.value == id)?.title;
};

const getLegacyScheduleInformation = (structure) => {
  const { getFormattedDateStringNoTime } = useDateField();

  const tableData = [];
  tableData.push([
    {
      text: "",
      style: ["tableHeader"],
    },
    {
      text: "7A07\nRequired",
      style: ["tableHeader"],
    },
    {
      text: "7A08\nLast Inspection\nDate",
      style: ["tableHeader"],
    },
    {
      text: "7A09\nInspection\nInterval",
      style: ["tableHeader"],
    },
    {
      text: "7A10\nNext Inspection\nDate",
      style: ["tableHeader"],
    },
  ]);

  tableData.push(
    [
      {
        text: "NBI:",
        bold: true,
      },
      {
        text: "",
        alignment: "center",
      },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.LASTINSP),
        alignment: "center",
      },
      { text: structure?.InspEvnt?.BRINSPFREQ + " mos", alignment: "center" },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.NEXTINSP),
        alignment: "center",
      },
    ],
    [
      {
        text: "NSTM:",
        bold: true,
      },
      {
        text:
          structure?.InspEvnt?.FCINSPREQ == "1"
            ? CUSTOM_ICONS.SQUARE_CHECK
            : CUSTOM_ICONS.SQUARE,
        style: "customFontAwesome",
        alignment: "center",
      },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.FCLASTINSP),
        alignment: "center",
      },
      { text: structure?.InspEvnt?.FCINSPFREQ + " mos", alignment: "center" },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.FCNEXTDATE),
        alignment: "center",
      },
    ],
    [
      {
        text: "Underwater:",
        bold: true,
      },
      {
        text:
          structure?.InspEvnt?.UWINSPREQ == "1"
            ? CUSTOM_ICONS.SQUARE_CHECK
            : CUSTOM_ICONS.SQUARE,
        style: "customFontAwesome",
        alignment: "center",
      },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.UWLASTINSP),
        alignment: "center",
      },
      { text: structure?.InspEvnt?.UWINSPFREQ + " mos", alignment: "center" },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.UWNEXTDATE),
        alignment: "center",
      },
    ],
    [
      {
        text: "Other Special:",
        bold: true,
      },
      {
        text:
          structure?.InspEvnt?.OSINSPREQ == "1"
            ? CUSTOM_ICONS.SQUARE_CHECK
            : CUSTOM_ICONS.SQUARE,
        style: "customFontAwesome",
        alignment: "center",
      },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.OSLASTINSP),
        alignment: "center",
      },
      { text: structure?.InspEvnt?.OSINSPFREQ + " mos", alignment: "center" },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.OSNEXTDATE),
        alignment: "center",
      },
    ],
    [
      {
        text: "Element:",
        bold: true,
      },
      {
        text:
          structure?.InspEvnt?.ELINSPREQ == "1"
            ? CUSTOM_ICONS.SQUARE_CHECK
            : CUSTOM_ICONS.SQUARE,
        style: "customFontAwesome",
        alignment: "center",
      },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.ELLASTINSP),
        alignment: "center",
      },
      { text: structure?.InspEvnt?.ELINSPFREQ + " mos", alignment: "center" },
      {
        text: getFormattedDateStringNoTime(structure?.InspEvnt?.ELNEXTDATE),
        alignment: "center",
      },
    ]
  );
  return tableData;
};

function splitArrayIntoChunks(array, chunkSize) {
  const chunkedArray = [];

  for (let i = 0; i < array?.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunkedArray.push(chunk);
  }
  return chunkedArray;
}

export { setScheduleDocDefinition };
