import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import { clone } from "@/util/clone";
import { useInspectionStore } from "@/stores/inspection";
import useDateField from "@/composables/dateField.js";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { CUSTOM_ICONS } from "@/constants/Unicode";

function setWaterwayDocDefinition(structure) {
  const { getFormattedDateStringNoTime } = useDateField();
  const inspectionStore = useInspectionStore();
  const configStore = useConfigStore();

  const currentScourMeasuresAndCountermeasures = [];
  const potentailCountermeasures = [];
  const subUnitsOne = [];
  const subUnitsTwo = [];

  processCurrentScourMeasuresAndCountermeasures();
  processPotentailCountermeasures();
  let floodPlains = getFloodPlains(structure, inspectionStore);
  floodPlains = sortFloodPlains(floodPlains);
  processSubUnitsOne();
  subUnitsTwo.push(getSubUnitsTableTwo());

  let scourCriticalCategory = getScourCriticalCategory(structure);

  let scourPOARequired = getScourPOA(scourCriticalCategory);

  let overallScourHoles = inspectionStore.getOverallScourHoles(structure);

  let underwaterRating = getUnderwaterRating(structure);

  let docDefinition = {
    header: function () {
      return getReportHeader("Waterway Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Waterway Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Waterway Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "25%",
            text: "4A08 SCBI: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR,
              structure?.InspEvnt?.SCOURCRIT
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "1A05 Channel: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.CHANRATING
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "4A08b Scour Critical Category: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: scourCriticalCategory,
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "1A05b Channel Protection: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.CHAN_PROT_RATING
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU03 SCBI Source: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR_SOURCE,
              structure?.T_Scour_Comp?.SCBI_SOURCE
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "1A06 Waterway: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.WATERADEQ
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU04 Overall SCBI: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR,
              structure?.T_Scour_Comp?.OVERALL_OSA
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "1A13 Overall Scour: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_CONDITION_RATING,
              overallScourHoles
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU04b SCBI Recalculated?: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text:
              structure?.T_Scour_Comp?.SCOUR_CALCULATED == "1"
                ? CUSTOM_ICONS.SQUARE_CHECK
                : CUSTOM_ICONS.SQUARE,
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "1A14 Underwater: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              underwaterRating
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "IU29 Scour Vulnerability: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_VULNERABILITY,
              structure?.UserInsp?.SCOUR_VULNERABILITY
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "IU30 Scour POA Required: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_POA_REQUIRED,
              scourPOARequired
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Waterway Details",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "35%",
            text: "IU06 Stream Bed Material: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.STREAM_BED_MATERIAL,
              structure?.T_Scour_Comp?.STREAM_BED_MATERIAL_1
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "35%",
            text: "",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.STREAM_BED_MATERIAL,
              structure?.T_Scour_Comp?.STREAM_BED_MATERIAL_2
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "35%",
            text: "IU07 Streambed Material Description:",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: structure?.T_Scour_Comp?.STREAM_BED_MATERIAL_DESC,
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU02 Number of Subunits: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: structure?.T_Scour_Comp?.UNITS_INSPECTED_QTY,
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "IU17 Horizontal Debris Start: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "7%",
            text: structure?.T_Scour_Comp?.HOR_DEBRIS_START_PCT
              ? parseFloat(
                  structure?.T_Scour_Comp?.HOR_DEBRIS_START_PCT
                ).toFixed(0)
              : "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU11 NAB Location: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ABUTMENT_LOCATION,
              structure?.T_Scour_Comp?.NAB_LOCATION
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "IU18 Horizontal Debris End: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "7%",
            text: structure?.T_Scour_Comp?.HOR_DEBRIS_END_PCT
              ? parseFloat(structure?.T_Scour_Comp?.HOR_DEBRIS_END_PCT).toFixed(
                  0
                )
              : "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "(0%=LAB to 100%=RAB)",
            margin: [3, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU12 FAB Location: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ABUTMENT_LOCATION,
              structure?.T_Scour_Comp?.FAB_LOCATION
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "IU19 Vertical Debris Start: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "7%",
            text: structure?.T_Scour_Comp?.VERT_DEBRIS_START_PCT
              ? parseFloat(
                  structure?.T_Scour_Comp?.VERT_DEBRIS_START_PCT
                ).toFixed(0)
              : "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU13 U/S Left WW Presence: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WINGWALL_PRESENCE,
              structure?.T_Scour_Comp?.US_LEFT_WW_PRESENCE
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "IU20 Vertical Debris End: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "7%",
            text: structure?.T_Scour_Comp?.VERT_DEBRIS_END_PCT
              ? parseFloat(
                  structure?.T_Scour_Comp?.VERT_DEBRIS_END_PCT
                ).toFixed(0)
              : "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "(0%=Str'bed to 100%=Bm)",
            margin: [3, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU14 U/S Left Condition: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.UPSTREAM_WINGWALL_CONDITION,
              structure?.T_Scour_Comp?.US_LEFT_WW_COND
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU15 U/S Right WW Presence: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WINGWALL_PRESENCE,
              structure?.T_Scour_Comp?.US_RIGHT_WW_PRESENCE
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU16 U/S Right Condition: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.UPSTREAM_WINGWALL_CONDITION,
              structure?.T_Scour_Comp?.US_RIGHT_WW_COND
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 3, 0, 0],
          },
        ],
        pageBreak: "after",
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Current Scour Measures and Countermeasures",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["10%", "*", "*", "*", "*"],
          body: currentScourMeasuresAndCountermeasures,
        },
        margin: [0, 5, 0, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Potential Countermeasures",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["10%", "*", "*"],
          body: potentailCountermeasures,
        },
        margin: [0, 5, 0, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Channel and Waterway Adequacy Information",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "13%",
            text: "1A05 Channel: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "17%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.CHANRATING
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "22%",
            text: "1A05b Channel Protection: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "17%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.CHAN_PROT_RATING
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "14%",
            text: "1A06 Waterway: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "17%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.WATERADEQ
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        text: "Channel: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.CHANNEL
        )?.NOTES,
      },
      {
        text: "Channel Protection: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CHANNEL_PROTECTION
        )?.NOTES,
      },
      {
        text: "Banks: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.BANKS
        )?.NOTES,
      },
      {
        text: "Streambed Movements: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.STREAMBED_MOVEMENTS
        )?.NOTES,
      },
      {
        text: "Debris, Vegetation: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.DEBRIS_VEGITATION
        )?.NOTES,
      },
      {
        text: "River (Stream) Control Devices: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.RIVER_CONTROL_DEVICES
        )?.NOTES,
      },
      {
        text: "Embankment/Streambed Controls: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE ==
            INSPECTION_COMMENTS.EMBANKMENT_STREAMBED_CONTROLS
        )?.NOTES,
      },
      {
        text: "Drift, Other: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.DRIFT_OTHER
        )?.NOTES,
      },
      {
        text: "Waterway Adequacy: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WATERWAY_ADEQUACY
        )?.NOTES,
      },
      {
        columns: [
          {
            width: "25%",
            text: "IL02 Overtopping Likelihood: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.OVERTOPPING_RISK,
              structure?.T_Scour_Comp?.OVERTOP_RISK
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "IL03 Traffic Delay: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.TRAFFIC_DELAY,
              structure?.T_Scour_Comp?.TRAFFIC_DELAY
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IL13 Worst Flood Event: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WORST_FLOOD_EVENT,
              structure?.T_Scour_Comp?.OVERTOPPING
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "IL14 Worst Flood Event Date: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: getFormattedDateStringNoTime(
              structure?.T_Scour_Comp?.OVERTOP_DATE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "5C22 Functional Class:",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ROADWAY_FUNCTIONAL_CLASSIFICATION,
              structure?.Roadway?.find((a) => a?.ON_UNDER === "1").FUNCCLASS
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: "",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "22%",
            text: "IL05 High Water Elevation: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "13%",
            text: structure?.T_Scour_Comp?.HIGH_WATER_ELEV
              ? parseInt(
                  configStore.getMetricToEnglishConversions(
                    PAIR_CODE.METERS_TO_FEET,
                    structure?.T_Scour_Comp?.HIGH_WATER_ELEV
                  )
                ) + " ft"
              : " ft",
            margin: [0, 3, 0, 0],
          },
          {
            width: "18%",
            text: "IL06 High Water Date: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "18%",
            text: getFormattedDateStringNoTime(
              structure?.T_Scour_Comp?.HIGH_WATER_DATE
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "22%",
            text: "IL07 New High Water Mark: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "7%",
            text:
              structure?.T_Scour_Comp?.NEW_HIGH_WATER == "1"
                ? CUSTOM_ICONS.SQUARE_CHECK
                : CUSTOM_ICONS.SQUARE,
            style: "customFontAwesome",
            margin: [3, 3, 0, 0],
          },
        ],
      },
      {
        text: "IL08 High Water Notes: ",
        bold: true,
        margin: [0, 3, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.HIGH_WATER_MARK
        )?.NOTES,
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Subunits within 500yr Flood Plain",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        table: {
          widths: ["100%"],
          headerRows: 0,
          body: [
            ["Legend:"],
            [
              {
                table: {
                  headerRows: 0,
                  widths: ["25%", "25%", "25%", "25%"],
                  body: [
                    [
                      "IN01 Subunit",
                      "IN06 Debris Potential",
                      "IN10 Alignment",
                      "IN19 Movement Ind",
                    ],
                    [
                      "IN03 Obs Scour Rtg",
                      "IN07 Scourability",
                      "IN11 Velocity Str Slope",
                      "IU27 SCBI Code",
                    ],
                    [
                      "IN04 Chg Since Last Insp",
                      "IN08 Opening Adeq/Channel",
                      "IN12 Pier/Abut Type",
                      "",
                    ],
                    [
                      "IN05 Scour Hole",
                      "IN09 Sediment",
                      "IN15 Strmbd Matl",
                      "",
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return hLineFunction(i, node.table.body.length);
          },
          vLineWidth: function (i, node) {
            return hLineFunction(i, node.table.widths.length);
          },
          hLineColor: function () {
            return "black";
          },
          vLineColor: function () {
            return "black";
          },
        },
        unbreakable: true,
      },
      {
        table: {
          headerRows: 1,
          widths: [
            "*",
            "*",
            "*",
            "*",
            "*",
            "*",
            "*",
            "*",
            "*",
            "*",
            "*",
            "*",
            "*",
            "*",
          ],
          body: subUnitsOne,
        },
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "20%",
            text: "Other Subunit Details: ",
            bold: true,
            margin: [0, 5, 0, 5],
          },
          {
            width: "*",
            text: "",
            margin: [0, 5, 0, 5],
          },
        ],
      },
      {
        table: {
          widths: ["100%"],
          headerRows: 0,
          body: [
            ["Legend:"],
            [
              {
                table: {
                  headerRows: 0,
                  widths: ["24%", "21%", "22%", "33%"],
                  body: [
                    [
                      "IN01 Subunit",
                      "IN16 UW Insp Type",
                      "IN20 Scour/Undermine",
                      "IN22 100yr Flood Calc Scour Depth (ft)",
                    ],
                    [
                      "IN02 Info From Current Insp",
                      "IN17 Obs Scr Depth (ft)",
                      "IN21 Countermeasures",
                      "IN23 500yr Flood Calc Scour Depth (ft)",
                    ],
                    [
                      "IN14 OSA Found Type",
                      "IN18 Water Depth (ft)",
                      "",
                      "IN25 In 500yr Flood Plain?",
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return hLineFunction(i, node.table.body.length);
          },
          vLineWidth: function (i, node) {
            return hLineFunction(i, node.table.widths.length);
          },
          hLineColor: function () {
            return "black";
          },
          vLineColor: function () {
            return "black";
          },
        },
        unbreakable: true,
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: subUnitsTwo,
        },
        margin: [0, 5, 0, 5],
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  for (const floodPlain of floodPlains) {
    let subUnitsThree = [];
    let subUnitsFour = [];
    subUnitsThree.push([
      {
        text: floodPlain?.SUB_UNIT,
        style: ["tableBody"],
      },

      {
        text: floodPlain?.FOUND_TYPE,
        style: ["tableBody"],
      },
      {
        text: floodPlain?.UNDERWATER_INSP_TYPE,
        style: ["tableBody"],
      },
      {
        text: getFormattedValueFromMetricToEnglishConversion(
          configStore,
          floodPlain.MAX_WATER_DEPTH
        ),
        style: ["tableBody"],
      },
      {
        text: getFormattedValueFromMetricToEnglishConversion(
          configStore,
          floodPlain.OBS_SCOUR_DEPTH
        ),
        style: ["tableBody"],
      },
      {
        text: inspectionStore.getReportCheckbox(floodPlain.SCOUR_UNDERMINE),
        style: ["customFontAwesomeTableBody"],
      },
      {
        text: inspectionStore.getReportCheckbox(floodPlain.COUNTERMEASURES),
        style: ["customFontAwesomeTableBody"],
      },
      {
        text: inspectionStore.getReportCheckbox(floodPlain.PREVIOUS_CURRENT),
        style: ["customFontAwesomeTableBody"],
      },
      {
        text: getFormattedValueFromMetricToEnglishConversion(
          configStore,
          floodPlain.CALC_SCOUR_DEPTH_100
        ),
        style: ["tableBody"],
      },
      {
        text: getFormattedValueFromMetricToEnglishConversion(
          configStore,
          floodPlain.CALC_SCOUR_DEPTH_500
        ),
        style: ["tableBody"],
      },
      {
        text: inspectionStore.getReportCheckbox(
          inspectionStore.getSubStructure(floodPlain?.STRUNITKEY)?.IN_500YR_FP
        ),
        style: ["customFontAwesomeTableBody"],
      },
    ]);
    subUnitsFour.push([
      {
        text: ["IN24 Notes: ", floodPlain?.UNDERWATER_INSP_DESC],
        style: ["waterwayLeftAlignBody"],
      },
    ]);
    docDefinition.content.push(
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: subUnitsThree,
        },
        margin: [0, 0, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["*"],
          body: subUnitsFour,
        },
        margin: [0, 0, 0, 0],
      }
    );
  }
  function processCurrentScourMeasuresAndCountermeasures() {
    currentScourMeasuresAndCountermeasures.push(
      getCurrentScourMeasuresAndCountermeasuresHeader()
    );

    const measuresCurrent = getMeasuresCurrent(structure);
    measuresCurrent?.forEach((measure, idx) => {
      measure.UNIQUE_KEY = idx + 1;
      measure.readOnly = true;
      currentScourMeasuresAndCountermeasures.push([
        {
          text: measure.UNIQUE_KEY,
          style: ["tableBody"],
        },
        {
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.CURRENT_COUNTERMEASURE_TYPE,
            measure.CMEASURE_TYPE,
            false
          ),
          style: ["tableBody"],
        },
        {
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.CURRENT_COUNTERMEASURE_LOCATION,
            measure.CMEASURE_LOCATION,
            false
          ),
          style: ["tableBody"],
        },
        {
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.CURRENT_COUNTERMEASURE_CONDITION,
            measure.CMEASURE_COND,
            false
          ),
          style: ["tableBody"],
        },
        {
          text: getSubUnit(measure.PIER_NUM, inspectionStore),
          style: ["tableBody"],
        },
      ]);
    });
  }

  function processPotentailCountermeasures() {
    potentailCountermeasures.push(getPotentailCountermeasuresHeader());

    const measuresPotential = getMeasuresPotential(structure);
    measuresPotential?.forEach((measure, idx) => {
      measure.UNIQUE_KEY = idx + 1;

      potentailCountermeasures.push([
        {
          text: measure.UNIQUE_KEY,
          style: ["tableBody"],
        },
        {
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.CURRENT_COUNTERMEASURE_LOCATION,
            measure.LOCATION
          ),
          style: ["tableBody"],
        },
        {
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.WORK_CANDIDATE,
            measure.WCKEY
          ),
          style: ["tableBody"],
        },
      ]);
    });
  }

  function processSubUnitsOne() {
    subUnitsOne.push(getSubUnitsTableOne());
    floodPlains?.forEach((floodPlain) => {
      subUnitsOne.push([
        {
          text: floodPlain.SUB_UNIT,
          style: ["tableBody"],
        },
        {
          text: floodPlain.SUBUNIT_TYPE,
          style: ["tableBody"],
        },
        {
          text: floodPlain.STREAM_BED_MATERIAL,
          style: ["tableBody"],
        },
        {
          text: inspectionStore.getReportCheckbox(floodPlain.MOVEMENT),
          style: ["customFontAwesomeTableBody"],
        },
        {
          text: floodPlain.CHG_SINCE_LAST_INSP,
          style: ["tableBody"],
        },
        {
          text: floodPlain.SCOUR_HOLE,
          style: ["tableBody"],
        },
        {
          text: floodPlain.DEBRIS_POTENTIAL,
          style: ["tableBody"],
        },
        {
          text: floodPlain.SUB_SCOUR,
          style: ["tableBody"],
        },
        {
          text: floodPlain.OPEN_ADEQ_CHANNEL,
          style: ["tableBody"],
        },
        {
          text: floodPlain.SED_DEPOSIT,
          style: ["tableBody"],
        },
        {
          text: floodPlain.ALIGNMENT,
          style: ["tableBody"],
        },
        {
          text: floodPlain.VELO_STREAM_SLOPE,
          style: ["tableBody"],
        },
        {
          text: floodPlain.OBS_SCOUR_RATING,
          style: ["tableBody"],
        },
        {
          text: floodPlain.SCBI_CODE,
          style: ["tableBody"],
        },
      ]);
    });
  }

  return docDefinition;
}

function getCurrentScourMeasuresAndCountermeasuresHeader() {
  return [
    {
      text: "Record\n  Key",
      style: ["tableHeader"],
    },
    {
      text: "IU21\n Type",
      style: ["tableHeader"],
    },
    {
      text: "IU22\n Location",
      style: ["tableHeader"],
    },
    {
      text: "IU23\n Condition",
      style: ["tableHeader"],
    },
    {
      text: "IU24\n Subunit",
      style: ["tableHeader"],
    },
  ];
}

function getPotentailCountermeasuresHeader() {
  return [
    {
      text: "Record\n  Key",
      style: ["tableHeader"],
    },
    {
      text: "IU25\n Location",
      style: ["tableHeader"],
    },
    {
      text: "IU26\n Countermeasures",
      style: ["tableHeader"],
    },
  ];
}

function getSubUnitsTableOne() {
  return [
    {
      text: "IN01",
      style: ["tableHeader"],
    },
    {
      text: "IN12",
      style: ["tableHeader"],
    },
    {
      text: "IN15",
      style: ["tableHeader"],
    },
    {
      text: "IN19",
      style: ["tableHeader"],
    },
    {
      text: "IN04",
      style: ["tableHeader"],
    },
    {
      text: "IN05",
      style: ["tableHeader"],
    },
    {
      text: "IN06",
      style: ["tableHeader"],
    },
    {
      text: "IN07",
      style: ["tableHeader"],
    },
    {
      text: "IN08",
      style: ["tableHeader"],
    },
    {
      text: "IN09",
      style: ["tableHeader"],
    },
    {
      text: "IN10",
      style: ["tableHeader"],
    },
    {
      text: "IN11",
      style: ["tableHeader"],
    },
    {
      text: "IN03",
      style: ["tableHeader"],
    },
    {
      text: "IU27",
      style: ["tableHeader"],
    },
  ];
}

function getSubUnitsTableTwo() {
  return [
    {
      text: "IN01",
      style: ["tableHeader"],
    },
    {
      text: "IN14",
      style: ["tableHeader"],
    },
    {
      text: "IN16",
      style: ["tableHeader"],
    },
    {
      text: "IN18",
      style: ["tableHeader"],
    },
    {
      text: "IN17",
      style: ["tableHeader"],
    },
    {
      text: "IN20",
      style: ["tableHeader"],
    },
    {
      text: "IN21",
      style: ["tableHeader"],
    },
    {
      text: "IN02",
      style: ["tableHeader"],
    },
    {
      text: "IN22",
      style: ["tableHeader"],
    },
    {
      text: "IN23",
      style: ["tableHeader"],
    },
    {
      text: "IN25",
      style: ["tableHeader"],
    },
  ];
}

function getUnderwaterRating(structure) {
  let underwaterRating = null;
  if (!structure?.UserInsp?.UNDERWATER_RATING) {
    if (structure?.InspEvnt?.SUBRATING < structure?.InspEvnt?.CULVRATING) {
      underwaterRating = structure?.InspEvnt?.SUBRATING;
    } else {
      underwaterRating = structure?.InspEvnt?.CULVRATING;
    }
  } else {
    underwaterRating = structure?.UserInsp?.UNDERWATER_RATING;
  }
  return underwaterRating;
}

function getScourPOA(scourCriticalCategory) {
  let scourPOARequired = null;
  if (scourCriticalCategory == "--") {
    scourPOARequired = "0";
  } else if (
    scourCriticalCategory == "A" ||
    scourCriticalCategory == "B" ||
    scourCriticalCategory == "C" ||
    scourCriticalCategory == "D"
  ) {
    scourPOARequired = "Y";
  } else {
    scourPOARequired = "N";
  }
  return scourPOARequired;
}

function getScourCriticalCategory(structure) {
  let scourCriticalCategory = structure?.InspEvnt?.SCOUR_CRIT_CTGRY;
  if (!scourCriticalCategory || scourCriticalCategory == "") {
    scourCriticalCategory = "--";
  }
  return scourCriticalCategory;
}

function getFloodPlains(structure, inspectionStore) {
  let floodPlains = clone(structure.T_Underwater_Insp);
  //remove null values from array(if any)
  floodPlains = floodPlains?.filter((a) => !!a) || [];

  for (let idx = 1; idx <= floodPlains?.length; idx++) {
    floodPlains[idx - 1].UNIQUE_KEY = idx;
    floodPlains[idx - 1].SUB_UNIT = inspectionStore.getSubStructure(
      floodPlains[idx - 1]?.STRUNITKEY
    )?.STRUNITLABEL;
  }
  return floodPlains || [];
}

/**
 * @param {number} value - the metric value
 * @param {object} configStore - pinia store object
 * @return value after converting value from metric to english measurment system
 * if value is undefined/null/false/0/"" it returns "0.0 ft".
 */
function getFormattedValueFromMetricToEnglishConversion(configStore, value) {
  return (
    (value
      ? parseFloat(
          configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            value
          )
        )?.toFixed(1)
      : "0.0") + " ft"
  );
}

function getMeasuresCurrent(structure) {
  let measuresCurrent = clone(structure.T_Scour_CMeasure);

  if (!measuresCurrent?.[0]) {
    //remove null values from array(if any)
    measuresCurrent = measuresCurrent?.filter((a) => !!a);
  }
  return measuresCurrent;
}

function getMeasuresPotential(structure) {
  let measuresPotential = clone(structure.T_Scour_Poss_CMeasure);
  if (measuresPotential?.[0]) {
    //remove null values from array(if any)
    measuresPotential = measuresPotential?.filter((a) => !!a);
  }
  return measuresPotential;
}

function getSubUnit(unitKey, store) {
  if (unitKey) {
    return unitKey + " - " + store.getSubStructure(unitKey)?.STRUNITLABEL;
  } else {
    return "";
  }
}

function getPlaceholder(str) {
  const num = str.split("P")?.[1];
  return num?.charAt(0) === "0" ? parseInt(num?.charAt(1)) : parseInt(num);
}

function sortFloodPlains(floodPlains) {
  return floodPlains?.sort((a, b) => {
    const aUnit = String(a.SUB_UNIT);
    const bUnit = String(b.SUB_UNIT);

    const aIsNF = aUnit.includes("N") && bUnit.includes("F");
    const bIsNF = bUnit.includes("N") && aUnit.includes("F");

    if (aIsNF) return 1;
    if (bIsNF) return -1;

    const aIsP = aUnit.includes("P");
    const bIsP = bUnit.includes("P");

    if (!aIsP && bIsP) return -1;
    if (!bIsP && aIsP) return 1;

    if (aIsP && bIsP) {
      const aPlaceholder = getPlaceholder(aUnit);
      const bPlaceholder = getPlaceholder(bUnit);
      return aPlaceholder - bPlaceholder;
    }
  });
}

function hLineFunction(i, value) {
  return i === 0 || i === value ? 1 : 0;
}

export { setWaterwayDocDefinition };
