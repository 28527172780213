<!--This is a custom component for Label Select Input Fields with optional duration icon  -->
<template>
  <div
    v-if="noGrid"
    style="display: flex"
    :class="isEditable ? '' : 'addIconTooltipPadding'"
  >
    <span
      :style="
        isEditable
          ? 'padding-top: 2px; font-weight: bold; padding-right: ' +
            paddingRight +
            'px;'
          : 'font-weight: bold; padding-right: ' + paddingRight + 'px;'
      "
      :class="isRequired ? 'required' : '' + ' d-inline'"
      >{{ label }}<span v-if="showColon">:</span></span
    >
    <v-autocomplete
      :class="expansionPanel ? 'expansionPanel pl-1' : 'pl-1'"
      v-if="isEditable && items.length > 15"
      :id="`select_${id}`"
      :aria-describedby="`select_${id}`"
      :aria-label="ariaLabel"
      :items="items"
      hide-details="auto"
      :disabled="!isEditable"
      v-model="computedValue"
      clearable
      :required="isRequired"
      :rules="computedRules"
      @blur="$emit('blur')"
      @update:model-value="$emit('input')"
      :menu-props="menuProps"
    >
      <template
        v-if="clearable && computedValue != '' && !!computedValue"
        v-slot:clear
      >
        <v-tooltip>
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              @click="clearSelect()"
              icon="fa:fas fa-times-circle"
            ></v-icon>
          </template>
          <span>Clear Selection</span>
        </v-tooltip>
      </template>
    </v-autocomplete>
    <v-select
      :class="expansionPanel ? 'expansionPanel pl-1' : 'pl-1'"
      v-else-if="isEditable"
      :id="`select_${id}`"
      :aria-describedby="`select_${id}`"
      :aria-label="ariaLabel"
      :items="items"
      hide-details="auto"
      :disabled="!isEditable"
      v-model="computedValue"
      :style="textStyle"
      :clearable="clearable && computedValue != '' && !!computedValue"
      :required="isRequired"
      :rules="computedRules"
      @blur="$emit('blur')"
      @update:model-value="$emit('input')"
      :multiple="multiSelect"
      :chips="multiSelect"
      :closable-chips="multiSelect"
      :menu-props="menuProps"
    >
      <template
        v-if="clearable && computedValue != '' && !!computedValue"
        v-slot:clear
      >
        <v-tooltip>
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              @click="clearSelect()"
              icon="fa:fas fa-times-circle"
            ></v-icon>
          </template>
          <span>Clear Selection</span>
        </v-tooltip>
      </template>
    </v-select>
    <span v-else class="pl-4">
      {{ getDisplayValue() }}
    </span>

    <IconTooltip
      v-if="showDurationIcon"
      :id="`icon_${id}`"
      :icon="iconValue"
      :tooltip="tooltip"
    />
  </div>
  <v-row v-else>
    <v-col
      class="d-flex"
      :xl="labelXl"
      :lg="labelLg"
      :md="labelMd"
      :sm="labelSm"
      :cols="labelCols"
      v-if="label"
    >
      <v-label class="bold"
        ><span :class="isRequired ? 'required' : ''">{{ label }}</span
        ><span v-if="showColon">:</span></v-label
      ></v-col
    >
    <v-col
      class="d-flex verticalAlignment"
      :xl="selectXl"
      :lg="selectLg"
      :md="selectMd"
      :sm="selectSm"
      :cols="selectCols"
    >
      <v-autocomplete
        v-if="isEditable && items.length > 15"
        :class="expansionPanel ? 'expansionPanel' : ''"
        :id="`select_${id}`"
        :aria-describedby="`select_${id}`"
        :aria-label="ariaLabel"
        :items="items"
        hide-details="auto"
        :disabled="!isEditable"
        v-model="computedValue"
        clearable
        :required="isRequired"
        :rules="computedRules"
        @blur="$emit('blur')"
        @update:model-value="$emit('input')"
        :menu-props="menuProps"
        ><template
          v-if="clearable && computedValue != '' && !!computedValue"
          v-slot:clear
          ><v-tooltip>
            <template v-slot:activator="{ props }">
              <v-icon
                v-bind="props"
                @click="clearSelect()"
                icon="fa:fas fa-times-circle"
              ></v-icon>
            </template>
            <span>Clear Selection</span>
          </v-tooltip>
        </template>
      </v-autocomplete>
      <v-select
        v-else-if="isEditable"
        :class="expansionPanel ? 'expansionPanel' : ''"
        :id="`select_${id}`"
        :aria-describedby="`select_${id}`"
        :aria-label="ariaLabel"
        :items="items"
        hide-details="auto"
        :style="textStyle"
        :disabled="!isEditable"
        v-model="computedValue"
        :clearable="clearable && computedValue != '' && !!computedValue"
        :required="isRequired"
        :rules="computedRules"
        @blur="$emit('blur')"
        @update:model-value="$emit('input')"
        :multiple="multiSelect"
        :chips="multiSelect"
        :closable-chips="multiSelect"
        :menu-props="menuProps"
        ><template
          v-if="clearable && computedValue != '' && !!computedValue"
          v-slot:clear
          ><v-tooltip>
            <template v-slot:activator="{ props }">
              <v-icon
                v-bind="props"
                @click="clearSelect()"
                icon="fa:fas fa-times-circle"
              ></v-icon>
            </template>
            <span>Clear Selection</span>
          </v-tooltip>
        </template>
      </v-select>
      <span v-else class="pl-4 pt-1">
        {{ getDisplayValue() }}
      </span>

      <IconTooltip
        v-if="showDurationIcon"
        :id="`icon_${id}`"
        :icon="iconValue"
        :tooltip="tooltip"
      />
    </v-col>
  </v-row>
</template>

<script>
import { computed } from "vue";
import IconTooltip from "@/components/common/IconTooltip.vue";
import { requiredRule } from "@/composables/validationRules";
import { useConfigStore } from "@/stores/config";

export default {
  name: "LabelSelect",
  props: {
    id: {},
    labelXl: { default: 3 },
    labelLg: { default: 4 },
    labelMd: { default: 5 },
    labelSm: { default: 6 },
    labelCols: { default: 6 },

    selectXl: { default: 4 },
    selectLg: { default: 5 },
    selectMd: { default: 5 },
    selectSm: { default: 6 },
    selectCols: { default: 6 },
    textStyle: { default: "width: 50%" },
    expansionPanel: { type: Boolean, default: false },
    showDurationIcon: { type: Boolean, default: false },
    icon: { default: "" },
    tooltip: { default: "" },
    label: { default: null },
    refTable: { default: "" },
    isEditable: { type: Boolean, default: false },
    modelValue: { default: null },
    value: { default: null },
    isRequired: { type: Boolean, default: false },
    noGrid: { type: Boolean, default: false },
    rules: { default: [] },
    showColon: { type: Boolean, default: true },
    options: { default: [] },
    isNumericValue: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    paddingRight: { default: "0" },
    multiSelect: { default: false, type: Boolean },
    menuProps: { default: null },
  },
  setup(props, context) {
    const configStore = useConfigStore();

    const computedValue = computed({
      get: () => {
        if (props.value && !props.modelValue) {
          return props.value;
        } else {
          return props.modelValue;
        }
      },
      set: (value) => context.emit("update:modelValue", value),
    });
    const ariaLabel = computed({
      get() {
        if (props.label == "" || !props.label) {
          return props.id + " has no label";
        } else {
          return props.label + "_" + props.id;
        }
      },
    });

    const iconValue = computed({
      get: () => {
        if (props.showDurationIcon) {
          return "fa-regular fa-clock";
        } else {
          return props.icon;
        }
      },
    });

    const clearSelect = () => {
      if (props.multiSelect) {
        context.emit("update:modelValue", null);
      } else {
        context.emit("update:modelValue", "");
      }
    };

    const items = computed(() => {
      if (props.options?.length > 0) {
        return props.options;
      } else {
        return configStore.getReferenceValues(props.refTable);
      }
    });

    const computedRules = computed(() => {
      let validationRules = [];

      if (props.rules && props.rules.length > 0) {
        validationRules = validationRules.concat(props.rules);
      }
      if (props.isRequired) {
        validationRules.push(requiredRule);
      }
      return validationRules;
    });

    const getDisplayValue = () => {
      if (props.refTable) {
        return computedValue.value
          ? configStore.getReferenceValue(props.refTable, computedValue.value)
          : null;
      } else if (props.options) {
        const item = computedValue.value
          ? props.options.find(
              (selectItem) =>
                selectItem.value?.toString() ==
                computedValue.value?.toString().trimEnd()
            )
          : null;
        if (item) {
          return item.title;
        } else {
          //if isNumericValue=true return the int format of computed value
          return props.isNumericValue
            ? parseInt(computedValue.value) || -1
            : computedValue.value;
        }
      }
    };

    return {
      ariaLabel,
      clearSelect,
      computedValue,
      iconValue,
      computedRules,
      items,
      getDisplayValue,
    };
  },
  components: { IconTooltip },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;
.v-row,
.verticalAlignment {
  align-items: center;
}
.expansionPanel {
  :deep(.v-input__control) {
    background-color: white !important;
  }
  color: c.$p-black !important;
}
.addIconTooltipPadding :deep(i) {
  padding-top: 4px !important;
}
</style>
